import React from 'react';
import { Button, Input, message, Select, Popconfirm } from 'antd';
import { useMutation, Reference } from '@apollo/client';
import { DeleteOutlined, WarningFilled } from '@ant-design/icons';
import { loader } from 'graphql.macro';
import { Controller, useForm } from 'react-hook-form';
import styles from './UserForm.module.scss';
import {
  AddFarmMutation,
  AddFarmMutationVariables,
  Farm,
  Farm_Insert_Input,
  Pen_Insert_Input,
  UpdateFarmMutation,
  UpdateFarmMutationVariables,
  DeleteFarmMutation,
  DeleteFarmMutationVariables,
} from '../graphql/graphql-types';
import zipCodeData from '../data/usZips.json';
import { hasKey, logger } from '../utils/helpers';

// query used to delete herd
const deleteHerdMutation = loader('../graphql/mutations/deleteFarmMutation.graphql');
// This is the mutation schema for adding the herd in the graphql
const addHerdMutation = loader('../graphql/mutations/addFarmMutation.graphql');
// This is the mutation schema for adding the herd in the graphql
const updateHerdMutation = loader('../graphql/mutations/updateFarmMutation.graphql');

// this types are created for form of herd
type HerdFormFieldsTypes = Pick<
  Farm_Insert_Input,
  | 'address_line_1'
  | 'address_line_2'
  | 'city'
  | 'manager_email'
  | 'manager_name'
  | 'manager_phone'
  | 'name'
  | 'state'
  | 'zipcode'
> & { penNames: string[] };

// This is the types of props coming from the parent component
type HerdFormPropTypes = {
  // This is the mode of form whether form is add form or edit form
  mode: 'add' | 'edit';
  // This is the css property how to show the form in edit form drawer or in add form
  inputsFlexDirection: 'row' | 'column';
  // This is the css property for fields padding how to apply in edit form drawer or in add form
  paddingBottomInName: number;
  // This is the data for editing the herd
  dataToEdit?: Pick<
    Farm,
    | 'address_line_1'
    | 'address_line_2'
    | 'city'
    | 'id'
    | 'manager_email'
    | 'manager_name'
    | 'manager_phone'
    | 'name'
    | 'state'
    | 'zipcode'
  >;
  // This is the id of the herd which is going to be deleted
  editHerdId?: number | null;
  // This is function for controlling the open and close of the drawer
  setVisibleDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  // used to update the state value of selected herd id
  setSelectedHerdId?: React.Dispatch<React.SetStateAction<number | undefined>>;
  // used to update the value of selected sub herd id
  setSelectedSubHerdId?: React.Dispatch<React.SetStateAction<string>>;
};

// This is the main functional component
const HerdForm: React.FC<HerdFormPropTypes> = (props) => {
  // Destructing props
  const {
    mode,
    inputsFlexDirection,
    paddingBottomInName,
    dataToEdit,
    editHerdId,
    setVisibleDrawer,
    setSelectedSubHerdId,
    setSelectedHerdId,
  } = props;
  // loader for add button
  const [submitLoader, setSubmitLoader] = React.useState<boolean>(false);
  // indicates whether to show loader on delete button
  const [showLoaderWhileDelete, setShowLoaderWhileDelete] = React.useState<boolean>(false);

  // this variable is used to store the values of useForm
  const methods = useForm<HerdFormFieldsTypes>({
    defaultValues: {
      address_line_1: '',
      address_line_2: '',
      city: '',
      manager_email: '',
      manager_name: '',
      manager_phone: '',
      name: '',
      state: '',
      zipcode: '',
      penNames: [],
    },
  });

  // Inside this useEffect methods we set the value of our forms initial value when it is in edit mode
  React.useEffect(() => {
    if (mode === 'edit' && dataToEdit) {
      methods.setValue('name', dataToEdit.name);
      if (dataToEdit.address_line_1) {
        methods.setValue('address_line_1', dataToEdit.address_line_1);
      }
      if (dataToEdit.address_line_2) {
        methods.setValue('address_line_2', dataToEdit.address_line_2);
      }
      if (dataToEdit.manager_email) {
        methods.setValue('manager_email', dataToEdit.manager_email);
      }
      if (dataToEdit.city) {
        methods.setValue('city', dataToEdit.city);
      }
      if (dataToEdit.manager_name) {
        methods.setValue('manager_name', dataToEdit.manager_name);
      }
      if (dataToEdit.manager_phone) {
        methods.setValue('manager_phone', dataToEdit.manager_phone);
      }
      if (dataToEdit.state) {
        methods.setValue('state', dataToEdit.state);
      }
      if (dataToEdit.zipcode) {
        methods.setValue('zipcode', dataToEdit.zipcode);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToEdit, mode]);

  /*
  onChangeZipField is invoke when user enter zipcode
  and if zipcode match with zipCodeData it auto fills the city and state fields
  */
  const valueOfZipcode = methods.watch('zipcode');
  if (valueOfZipcode) {
    if (valueOfZipcode.length === 5 && hasKey(zipCodeData, valueOfZipcode)) {
      const dataForEnteredZip = zipCodeData[valueOfZipcode];
      if (dataForEnteredZip) {
        methods.setValue('city', dataForEnteredZip.city);
        methods.setValue('state', dataForEnteredZip.state);
      }
    }
  }

  // This mutation is used for adding the new herd in the graphql
  const [addHerd] = useMutation<AddFarmMutation, AddFarmMutationVariables>(addHerdMutation);

  // This is the mutation function for updating the values of herd details in the graphql
  const [updateHerd] = useMutation<UpdateFarmMutation, UpdateFarmMutationVariables>(
    updateHerdMutation,
  );

  // This is the mutation data for deleting the herd data from graphql
  const [deleteHerd] = useMutation<DeleteFarmMutation, DeleteFarmMutationVariables>(
    deleteHerdMutation,
  );

  // This function is invoke when user click on the add or save button
  const onSubmit = (data: HerdFormFieldsTypes) => {
    // destructuring penNames  from data of form
    const { penNames } = data;
    // Array of string is convert to array of object e.g. pen {name: string}
    let penNamesArray: Pick<Pen_Insert_Input, 'name'>[] = [];
    if (Array.isArray(penNames) && penNames.length > 0) {
      penNamesArray = penNames.map((name) => {
        return { name };
      });
    }
    setSubmitLoader(true);
    const valuesToSend = {
      address_line_1: data.address_line_1 ? data.address_line_1 : null,
      address_line_2: data.address_line_2 ? data.address_line_2 : null,
      city: data.city ? data.city : null,
      manager_email: data.manager_email ? data.manager_email : null,
      manager_name: data.manager_name ? data.manager_name : null,
      manager_phone: data.manager_phone ? data.manager_phone : null,
      name: data.name ? data.name : null,
      state: data.state ? data.state : null,
      zipcode: data.zipcode ? data.zipcode : null,
    };
    if (mode === 'add') {
      addHerd({
        variables: {
          object: { pens: { data: penNamesArray }, ...valuesToSend },
        },
        // updating the cache of herd list
        update: (cache, result) => {
          // This is the new data of herd which is going to be added in the cache
          const dataToAdd = result.data?.insert_farm_one;
          // This is the modify function for the cache
          cache.modify({
            // This is the input of the modify function by which we get access to the cache data here we access herd field
            fields: {
              // existingHerdsRef by this we get all the data of herd present the cache
              farm(existingHerdsRef: Array<Reference>) {
                return [...existingHerdsRef, dataToAdd];
              },
            },
          });
        },
      })
        .then(() => {
          methods.reset();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Herd is successfully added.');
          setSubmitLoader(false);
          setVisibleDrawer(false);
        })
        .catch((err) => {
          setSubmitLoader(false);
          logger(err);
        });
    }
    if (mode === 'edit' && editHerdId) {
      updateHerd({
        variables: {
          id: editHerdId,
          _set: valuesToSend,
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Herd is successfully updated.');
          setVisibleDrawer(false);
          setSubmitLoader(false);
        })
        .catch((err) => {
          logger(err);
          setSubmitLoader(false);
        });
    }
  };

  // handleDelete is invoked when user hits the yes button from the pop confirm of delete button
  const handleDelete = () => {
    if (editHerdId) {
      setShowLoaderWhileDelete(true);
      // this is the mutation in which we pass the id of the herd which is going to be delete
      deleteHerd({
        variables: {
          id: editHerdId,
        },
        // update function is used here to see the updated list immediately after deleting the farm
        update: (cache, { data: deletedData }) => {
          const idToDelete: number | undefined = deletedData?.delete_farm_by_pk?.id;
          // This is the modify function in which fields is one input where we get all the data of our cache and according to our need we apply field name and get ref of field
          cache.modify({
            fields: {
              /* inside fields we get all our fields of cache here we need to update farm field
               existingFarmRefs is the all farm present in our cache */
              farm(existingFarmRefs: Array<Reference>, { readField }) {
                if (idToDelete) {
                  return existingFarmRefs.filter((farmRef) => {
                    // inside readField first parameter is key and the second parameter is object by this we get id of object
                    return idToDelete !== readField('id', farmRef);
                  });
                }
                return existingFarmRefs;
              },
            },
          });
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success(`${dataToEdit ? dataToEdit.name : ''} is successfully deleted`);
          setShowLoaderWhileDelete(false);
          if (setSelectedHerdId && setSelectedSubHerdId) {
            setSelectedHerdId(undefined);
            setSelectedSubHerdId('');
            setVisibleDrawer(false);
          }
        })
        .catch((error) => {
          logger(error);
          setShowLoaderWhileDelete(false);

          setVisibleDrawer(false);
        });
    }
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <div>
        <div className={styles.inputContainer} style={{ paddingTop: 25 }}>
          <div className={`${styles.labelName} requiredField`}>Herd Name:</div>
          <Controller
            className={styles.inputField}
            name="name"
            rules={{ required: 'Please enter name of herd and try again' }}
            control={methods.control}
            as={<Input placeholder="Please enter name of herd" />}
          />
        </div>
        <div>
          {methods.errors.name ? (
            <p className={styles.errorText}>{methods.errors.name.message}</p>
          ) : null}
          <div
            style={{
              display: 'flex',
              flexDirection: inputsFlexDirection,
              paddingTop: paddingBottomInName,
            }}
          >
            <div>
              <div className={styles.inputContainer} style={{ paddingTop: 25 }}>
                <div className={styles.labelName}>Address line-1:</div>
                <Controller
                  className={styles.inputField}
                  name="address_line_1"
                  control={methods.control}
                  as={<Input placeholder="Please enter address of the herd" />}
                />
              </div>

              <div className={styles.inputContainer} style={{ paddingTop: 25 }}>
                <div className={styles.labelName}>Address line-2:</div>
                <Controller
                  className={styles.inputField}
                  name="address_line_2"
                  control={methods.control}
                  as={<Input placeholder="Please enter address of the herd" />}
                />
              </div>
              <div className={styles.inputContainer} style={{ paddingTop: 25 }}>
                <div className={`${styles.labelName} requiredField`}>ZIP code:</div>
                <Controller
                  className={styles.inputField}
                  name="zipcode"
                  rules={{
                    required: {
                      value: true,
                      message: 'Please enter ZIP code and try again',
                    },
                    pattern: {
                      value: /^[0-9]{5}(?:-[0-9]{4})?$/,
                      message: 'Please enter a valid ZIP code.',
                    },
                  }}
                  control={methods.control}
                  as={<Input placeholder="Please enter ZIP code of herd" />}
                />
              </div>
              {methods.errors.zipcode ? (
                <p className={styles.errorText}>{methods.errors.zipcode.message}</p>
              ) : null}
              <div className={styles.inputContainer} style={{ paddingTop: 25 }}>
                <div className={styles.labelName}>City:</div>
                <Controller
                  className={styles.inputField}
                  name="city"
                  control={methods.control}
                  as={<Input placeholder="Pre-filled based on ZIP code" />}
                />
              </div>
              <div className={styles.inputContainer} style={{ paddingTop: 25 }}>
                <div className={styles.labelName}>State:</div>
                <Controller
                  className={styles.inputField}
                  name="state"
                  control={methods.control}
                  as={<Input placeholder="Pre-filled based on ZIP code" />}
                />
              </div>
            </div>
            <div>
              <div className={styles.inputContainer} style={{ paddingTop: 25 }}>
                <div className={styles.labelName}>Manager Name:</div>
                <Controller
                  className={styles.inputField}
                  name="manager_name"
                  control={methods.control}
                  as={<Input placeholder="Please enter name of manager" />}
                />
              </div>
              <div className={styles.inputContainer} style={{ paddingTop: 25 }}>
                <div className={styles.labelName}>Manager Email:</div>
                <Controller
                  className={styles.inputField}
                  name="manager_email"
                  rules={{
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Please enter a valid email ID.',
                    },
                  }}
                  control={methods.control}
                  as={<Input placeholder="Please enter email ID of manager" />}
                />
              </div>
              {methods.errors.manager_email ? (
                <p className={styles.errorText}>{methods.errors.manager_email.message}</p>
              ) : null}
              <div className={styles.inputContainer} style={{ paddingTop: 25 }}>
                <div className={styles.labelName}>Manager Phone:</div>
                <Controller
                  rules={{
                    pattern: {
                      value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                      message: 'Please enter a valid 10-digit mobile number.',
                    },
                  }}
                  className={styles.inputField}
                  name="manager_phone"
                  control={methods.control}
                  as={<Input placeholder="Please enter phone/cell of manager" />}
                />
              </div>
              {methods.errors.manager_phone ? (
                <p className={styles.errorText}>{methods.errors.manager_phone.message}</p>
              ) : null}

              {/* as per new update this field should be not displayed in form */}
              {/* {mode === 'add' ? (
                <div className={styles.inputContainer} style={{ paddingTop: 25 }}>
                  <div className={styles.labelName}>Pen IDs:</div>
                  <Controller
                    className={styles.inputField}
                    name="penNames"
                    control={methods.control}
                    as={
                      <Select
                        mode="tags"
                        style={{ width: 400, borderRadius: 5, padding: 0 }}
                        placeholder="Please enter IDs of the Pens on this herd"
                        tokenSeparators={[',']}
                      />
                    }
                  />
                </div>
              ) : null} */}
            </div>
          </div>
          <div
            style={
              mode === 'add'
                ? { paddingTop: 20, paddingLeft: 200 }
                : { paddingTop: 20, display: 'flex', justifyContent: 'center' }
            }
          >
            <Button
              loading={submitLoader}
              htmlType="submit"
              className="primaryBtn"
              style={{ width: 100, margin: 10, marginLeft: mode === 'edit' ? 125 : 0 }}
            >
              {mode === 'add' ? 'Add' : 'Save'}
            </Button>
            <Button
              className="secondaryBtn"
              onClick={() => {
                setVisibleDrawer(false);
                methods.reset();
              }}
              type="primary"
              style={{ width: 100, margin: 10, marginLeft: 10 }}
            >
              Cancel
            </Button>
            {mode === 'edit' ? (
              <Popconfirm
                title="Delete Herd. Are you sure?"
                onConfirm={(): void => {
                  handleDelete();
                }}
                icon={<WarningFilled style={{ color: '#ce0e2d' }} />}
                cancelText="No"
                okText="Yes"
              >
                <Button
                  className="primary"
                  icon={<DeleteOutlined />}
                  style={{ marginTop: 10, width: 125, marginLeft: 10 }}
                  loading={showLoaderWhileDelete}
                >
                  Delete Herd
                </Button>
              </Popconfirm>
            ) : null}
          </div>
        </div>
      </div>
    </form>
  );
};

export default HerdForm;
