import React, { ReactText, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, DatePicker, Divider, Input, message, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import styles from '../screens/HeifersOfSubHerdScreen.module.scss';
import {
  AddHeiferMutation,
  AddHeiferMutationVariables,
  Heifer,
  Herd,
  PensOfFarmQuery,
  PensOfFarmQueryVariables,
  UpdateHeiferMutation,
  UpdateHeiferMutationVariables,
  AddPensMutation,
  AddPensMutationVariables,
  UpdateHeiferMeasurementDateAgeMutation,
  UpdateHeiferMeasurementDateAgeMutationVariables,
} from '../graphql/graphql-types';
import { logger } from '../utils/helpers';

import { HerdDataType, HeiferType } from '../utils/types';
import { dateFormat, dateFormatForDisplay } from '../utils/globals';
// using Select option component
const { Option } = Select;

// importing and loading addHeifer mutation
const addHeifersMutation = loader('../graphql/mutations/addHeifersMutation.graphql');
// loading updateHeifer mutation
const updateHeiferMutation = loader('../graphql/mutations/updateHeiferMutation.graphql');
// used to add new pen
const addPenMutation = loader('../graphql/mutations/addPensMutation.graphql');
// This is the query for fetching all the pen id of selected herd
const pensOfHerdQuery = loader('../graphql/queries/pensOfFarmQuery.graphql');
const updateHeiferMeasurementDateAgeMutation = loader(
  '../graphql/mutations/updateHeiferMeasurementDateAgeMutation.graphql',
);

// heifer profile form fields type for edit and add modes
type FormFieldsType = Pick<Heifer, 'name' | 'pen_id'> & {
  herdId: number | null;
  subHerdId: string | null;
  dob: moment.Moment;
};
// component props
type HeiferFormType = {
  // sub herd id related to heifer
  subHerdId: string;
  // identifier for adding or editing the heifer profile
  mode: 'add' | 'edit';
  // setState function to toggle heifer form modal in add mode
  setHeiferModal?: React.Dispatch<React.SetStateAction<boolean>>;
  // initial data to edit heifer profile
  dataToEdit?: HeiferType;
  // setState function to toggle drawer visibility state in edit mode
  setDrawerVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  // all herds data
  herdData?: HerdDataType;
  // selected herd id for the herd
  selectedHerdId?: number;
  // heifer unique id
  heiferId?: string;
};
// sub herds data type
type SubHerdsType = Pick<Herd, 'id' | 'name'>[];

// FC
const HeiferForm: React.FC<HeiferFormType> = ({
  subHerdId,
  heiferId,
  herdData,
  selectedHerdId,
  setDrawerVisible,
  dataToEdit,
  mode,
  setHeiferModal,
}) => {
  // storing loading state for save button
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  // used to store value added in new pen input
  const [newPenInputValue, setNewPenInputValue] = useState<string | undefined>(undefined);
  // used to indicate whether to show loader in add button
  const [showLoaderOnAddButton, setShowLoaderInAddButton] = useState<boolean>(false);
  /* State to store new pen input err message */
  const [newPenInputErr, setNewPenNameInputErr] = useState<string | undefined>(undefined);

  //  mutation for adding the pen data
  const [addPen] = useMutation<AddPensMutation, AddPensMutationVariables>(addPenMutation);

  // useForm methods
  const methods = useForm<FormFieldsType>({
    defaultValues: {
      herdId: selectedHerdId && mode === 'edit' ? selectedHerdId : null,
      subHerdId: mode === 'edit' ? subHerdId : '',
      name: '',
      dob: dataToEdit && mode === 'edit' ? moment(dataToEdit.dob, dateFormat) : '',
      pen_id: mode === 'edit' && dataToEdit && dataToEdit.pen ? dataToEdit.pen.id : null,
    },
  });

  // storing sub herds specific to the selected herd in edit mode
  let subHerdsOfSelectedHerd: SubHerdsType = [];

  // mutation for adding a heifer in the table
  const [addHeifer] = useMutation<AddHeiferMutation, AddHeiferMutationVariables>(
    addHeifersMutation,
  );
  // mutation for updating heifer profile data in edit mode
  const [updateHeifer] = useMutation<UpdateHeiferMutation, UpdateHeiferMutationVariables>(
    updateHeiferMutation,
  );
  // updating each heifer measurement after updating heifer's dob
  const [updateHeiferMeasurement] = useMutation<
    UpdateHeiferMeasurementDateAgeMutation,
    UpdateHeiferMeasurementDateAgeMutationVariables
  >(updateHeiferMeasurementDateAgeMutation);

  // fetching data for pens of selected herd
  const { data: pensOfHerd } = useQuery<PensOfFarmQuery, PensOfFarmQueryVariables>(
    pensOfHerdQuery,
    {
      variables: { farm_id: selectedHerdId as number },
    },
  );

  // This is the pens array of herd
  const pens = pensOfHerd?.pen;

  // function to hide drawer, disable loader and show success message
  const handleHeiferUpdate = () => {
    if (setDrawerVisible) {
      setDrawerVisible(false);
    }
    setBtnLoading(false);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.success('Heifer profile has been successfully updated');
  };

  // handling form data after successful submission of form items
  const onSubmit = (data: FormFieldsType): void => {
    setBtnLoading(true);
    if (mode === 'edit' && heiferId) {
      // edited date of birth for specific heifer
      updateHeifer({
        variables: {
          _set: {
            dob: data.dob.format(dateFormat),
            herd_id: data.subHerdId,
            pen_id: data.pen_id,
          },
          id: heiferId,
        },
        update: (cache, { data: cacheData }) => {
          // edited heifer data
          const updatedHeiferData = cacheData?.update_heifer_by_pk;
          // edited heiferId
          const updatedHeiferId = updatedHeiferData?.id;
          // updating the cache for updated herd and sub herds
          cache.modify({
            fields: {
              heifer(existingHeiferRef: Array<Reference>, { readField }) {
                return existingHeiferRef.filter((ref) => {
                  return updatedHeiferId !== readField('id', ref);
                });
              },
            },
          });
        },
      })
        .then((response) => {
          // updated date of birth
          const updatedDob = response.data?.update_heifer_by_pk?.dob;
          // measurements for updated heifer
          const measurements = response.data?.update_heifer_by_pk?.heifer_measurements;

          if (Array.isArray(measurements) && measurements.length > 0 && updatedDob) {
            measurements.forEach(({ measurement_date, id }, index) => {
              // calculating date of measurement in months
              const doMAge =
                (moment(measurement_date, dateFormat).diff(
                  moment(updatedDob, dateFormat),
                  'days',
                  true,
                ) +
                  1) /
                30;
              // updating heifer's date of measurement age
              updateHeiferMeasurement({
                variables: {
                  id,
                  measurement_date_age: doMAge,
                },
              })
                .then(() => {
                  // run heifer update logic only after updating last measurement
                  if (index === measurements.length - 1) {
                    handleHeiferUpdate();
                  }
                })
                .catch((err) => {
                  setBtnLoading(false);
                  logger(err);
                });
            });
          } else {
            handleHeiferUpdate();
          }
        })
        .catch((heiferError: ApolloError) => {
          if (
            heiferError.message ===
            'Uniqueness violation. duplicate key value violates unique constraint "heifer_dob_name_herd_id_key"'
          ) {
            methods.reset();
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error('Heifer ID with entered DOB already exists. Cannot overwrite.');
          }
          logger(heiferError);
          if (setDrawerVisible) {
            setDrawerVisible(false);
          }
          setBtnLoading(false);
        });
    }
    if (mode === 'add') {
      // running mutation for adding heifer
      addHeifer({
        variables: {
          object: {
            herd_id: subHerdId,
            name: data.name,
            dob: data.dob.format(dateFormat),
            pen_id: data.pen_id,
          },
        },
        update: (cache, { data: cacheData }) => {
          const dataToUpdate = cacheData?.insert_heifer_one;
          cache.modify({
            fields: {
              heifer(existingHeiferDataRef: Array<Reference>, { readField }) {
                if (
                  existingHeiferDataRef.some(
                    (ref) => readField('id', ref) === cacheData?.insert_heifer_one?.id,
                  )
                ) {
                  return existingHeiferDataRef;
                }
                return [...existingHeiferDataRef, dataToUpdate];
              },
            },
          });
        },
      })
        .then((): void => {
          setBtnLoading(false);
          if (setHeiferModal) {
            setHeiferModal(false);
          }
          // resetting form data after adding a heifer
          methods.reset();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Heifer has been successfully added');
        })
        .catch((addHeiferError: ApolloError) => {
          if (
            addHeiferError.message ===
            'Uniqueness violation. duplicate key value violates unique constraint "heifer_dob_name_herd_id_key"'
          ) {
            methods.reset();
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error('Heifer already exists in system.');
          }
          if (setHeiferModal) {
            setHeiferModal(false);
          }
          // logging errors
          logger(addHeiferError);
          setBtnLoading(false);
        });
    }
  };

  // checking for the existing herd data and finding respective sub herds for a selected herd using "herdId"
  if (Array.isArray(herdData) && herdData.length > 0) {
    // finding the existing herd data for selected herd id
    const selectedHerdData = herdData.find((herdEntry) => {
      return herdEntry.id === methods.watch('herdId');
    });
    if (selectedHerdData) {
      // setting sub herds data for the selected herd
      subHerdsOfSelectedHerd = selectedHerdData.herds;
    }
  }

  // used to add new pen id
  const handleAddNewPen = () => {
    /* Variable to store pen data with existing pen name */
    const existingPen = pens ? pens.find((item) => item.name === newPenInputValue) : undefined;

    if (existingPen) {
      setNewPenNameInputErr(
        `Following Pen(s) already exist : ${
          newPenInputValue || ''
        }. Please enter unique pen names and try again`,
      );
      return;
    }

    setShowLoaderInAddButton(true);
    addPen({
      variables: {
        objects: [{ name: newPenInputValue, farm_id: selectedHerdId }],
      },
      // for updating the cache
      update: (cache, result) => {
        // This is the new data of pen which is going to be added in the cache
        const dataToAdd = result.data?.insert_pen;
        // This is the modify function for the cache update
        cache.modify({
          // This is the input of the modify function by which we get access to the cache data here we access pen field
          fields: {
            // existingPensRef consists all the data of pens present the cache
            pen(existingPensRef: Array<Reference>) {
              return [...existingPensRef, dataToAdd];
            },
          },
        });
      },
    })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Successfully added new pen.');
        // resets the value after adding new pen in input
        setNewPenInputValue(undefined);
        setShowLoaderInAddButton(false);
      })
      .catch((error: ApolloError) => {
        setShowLoaderInAddButton(false);
        logger(error);
      });
  };

  return (
    <form className={styles.formContainer} onSubmit={methods.handleSubmit(onSubmit)}>
      <div className={styles.inputContainer}>
        <div className={`${mode === 'add' ? 'requiredField' : ''} ${styles.label}`}>Heifer ID:</div>
        {mode === 'add' ? (
          <Controller
            className={styles.inputField}
            name="name"
            rules={{ required: 'Please enter ID of heifer and try again' }}
            control={methods.control}
            as={<Input style={{ width: 360 }} placeholder="Please enter heifer's ID" />}
          />
        ) : (
          <span style={{ width: 320 }}>{dataToEdit?.name}</span>
        )}
      </div>
      {mode === 'edit' ? (
        <>
          <div className={styles.inputContainer}>
            <div className={`requiredField ${styles.label}`} style={{ textAlign: 'start' }}>
              Herd ID:
            </div>
            <Controller
              className={styles.inputField}
              name="herdId"
              rules={{ required: 'Please enter ID of herd and try again' }}
              control={methods.control}
              render={({ onChange, value }) => (
                <Select
                  className="selectBox"
                  showSearch
                  style={{ width: 320, border: '1px solid grey' }}
                  placeholder="Select a herd to start collecting data"
                  optionFilterProp="data"
                  onChange={(val) => {
                    onChange(val);
                    methods.setValue('subHerdId', null);
                    methods.setValue('pen_id', null);
                  }}
                  value={value as string}
                >
                  {herdData &&
                    herdData.map((item) => (
                      <Option value={item.id} key={item.id} data={item.name}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              )}
            />
          </div>
          {methods.errors.herdId && mode === 'edit' ? (
            <p style={{ marginLeft: 140, color: '#ce0e2d' }}>{methods.errors.herdId.message}</p>
          ) : null}
          <div className={styles.inputContainer}>
            <div className={`requiredField ${styles.label}`} style={{ textAlign: 'start' }}>
              Sub-herd ID:
            </div>
            <Controller
              className={styles.inputField}
              name="subHerdId"
              rules={{ required: 'Please enter ID of sub-herd and try again' }}
              control={methods.control}
              render={({ onChange, value }) => (
                <Select
                  className="selectBox"
                  showSearch
                  style={{ width: 320, border: '1px solid grey' }}
                  onChange={(val) => {
                    onChange(val);
                  }}
                  value={value as ReactText[]}
                  placeholder="Select a sub-herd to add heifers and heifers measurements"
                  optionFilterProp="data"
                  disabled={subHerdsOfSelectedHerd.length === 0}
                >
                  {Array.isArray(subHerdsOfSelectedHerd) &&
                    subHerdsOfSelectedHerd.map((data) => (
                      <Option value={data.id} key={data.id} data={data.name}>
                        {data.name}
                      </Option>
                    ))}
                </Select>
              )}
            />
          </div>
          {Array.isArray(subHerdsOfSelectedHerd) && subHerdsOfSelectedHerd.length === 0 ? (
            <p style={{ marginLeft: mode === 'edit' ? 140 : 160, fontStyle: 'italic' }}>
              No Sub-herds added in this herd
            </p>
          ) : null}
          {methods.errors.subHerdId && mode === 'edit' ? (
            <p style={{ marginLeft: 140, color: '#ce0e2d' }}>{methods.errors.subHerdId.message}</p>
          ) : null}
        </>
      ) : null}
      {methods.errors.name && mode === 'add' ? (
        <p style={{ marginLeft: 165, color: '#ce0e2d' }}>{methods.errors.name.message}</p>
      ) : null}
      <div className={styles.inputContainer}>
        <div
          className={`requiredField ${styles.label}`}
          style={{ textAlign: mode === 'edit' ? 'start' : 'end' }}
        >
          Date Of Birth:
        </div>
        <Controller
          className={styles.inputField}
          name="dob"
          rules={{ required: true }}
          control={methods.control}
          render={({ onChange, value }) => {
            return (
              <DatePicker
                onChange={(momentDate) => {
                  onChange(momentDate);
                }}
                value={value as moment.Moment}
                format={dateFormatForDisplay}
                style={{ width: mode === 'add' ? 360 : 320, borderColor: 'grey' }}
                disabledDate={(currentDate): boolean => {
                  return moment().endOf('d') <= currentDate;
                }}
                placeholder="Please enter Heifer's DOB"
              />
            );
          }}
        />
      </div>
      {methods.errors.dob ? (
        <p style={{ marginLeft: mode === 'add' ? 165 : 140, color: '#ce0e2d' }}>
          Please enter date of birth and try again
        </p>
      ) : null}
      <div className={styles.inputContainer}>
        <div className={styles.label} style={{ textAlign: mode === 'edit' ? 'start' : 'end' }}>
          Pen ID:
        </div>
        <Controller
          className={styles.inputField}
          name="pen_id"
          control={methods.control}
          as={
            <Select
              style={{ width: mode === 'add' ? 360 : 320, borderColor: 'grey' }}
              placeholder="Please select Heifer's pen ID"
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                    <Input
                      style={{ flex: 'auto' }}
                      value={newPenInputValue}
                      onChange={(event) => {
                        setNewPenInputValue(event.target.value);
                        if (newPenInputErr) {
                          setNewPenNameInputErr(undefined);
                        }
                      }}
                      placeholder="Please add Heifer's pen ID"
                    />
                    <Button
                      icon={<PlusOutlined />}
                      style={{ marginLeft: 15 }}
                      className="primaryBtn"
                      htmlType="submit"
                      onClick={() => {
                        handleAddNewPen();
                      }}
                      loading={showLoaderOnAddButton}
                    >
                      Add
                    </Button>
                  </div>
                  {newPenInputErr ? (
                    <p
                      style={{
                        padding: 10,
                        margin: 0,
                        marginTop: -10,
                        color: '#ce0e2d',
                        fontSize: 13,
                      }}
                    >
                      {newPenInputErr}
                    </p>
                  ) : null}
                </div>
              )}
            >
              {Array.isArray(pens) && pens.length > 0
                ? pens.map((pen) => (
                    <Option value={pen.id} key={pen.id}>
                      {pen.name}
                    </Option>
                  ))
                : null}
            </Select>
          }
        />
      </div>
      {!Array.isArray(pens) || pens.length === 0 ? (
        <p style={{ marginLeft: mode === 'edit' ? 140 : 160, fontStyle: 'italic' }}>
          No Pens added in this herd
        </p>
      ) : null}

      <div
        style={{
          display: 'flex',
          justifyContent: mode === 'edit' ? 'center' : 'flex-end',
          padding: '13px 20px',
          marginTop: mode === 'edit' ? 30 : 25,
          borderTop: '0.4px solid #dcdcdc',
        }}
      >
        {mode === 'edit' ? (
          <Button
            style={{ width: 70 }}
            type="primary"
            className={styles.editSaveBtn}
            htmlType="submit"
            loading={btnLoading}
            disabled={btnLoading}
          >
            Save
          </Button>
        ) : null}
        <Button
          type={mode === 'edit' ? undefined : 'default'}
          className={mode === 'add' ? undefined : 'secondaryBtn'}
          style={{ marginLeft: mode === 'edit' ? 60 : 'inherit' }}
          onClick={(): void => {
            if (mode === 'add') {
              if (setHeiferModal) {
                setHeiferModal(false);
              }
              methods.reset();
            }
            if (mode === 'edit' && setDrawerVisible) {
              setDrawerVisible(false);
              methods.setValue('dob', '');
              methods.setValue('pen_id', null);
            }
          }}
        >
          Cancel
        </Button>

        {mode === 'add' ? (
          <Button
            className="tintColoredButton"
            htmlType="submit"
            loading={btnLoading}
            disabled={btnLoading}
            style={{ marginLeft: 15 }}
          >
            Save
          </Button>
        ) : null}
      </div>
    </form>
  );
};

export default HeiferForm;
