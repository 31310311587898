import React from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { ApolloError, Reference, useMutation } from '@apollo/client';
import { Button, message, Select } from 'antd';
import { loader } from 'graphql.macro';
import { PlusOutlined } from '@ant-design/icons';
import {
  AddPensMutation,
  AddPensMutationVariables,
  Pen_Insert_Input,
} from '../graphql/graphql-types';
import styles from '../screens/TransferScreen.module.scss';
import { logger } from '../utils/helpers';
import { ExistingPensType } from '../utils/types';

const addPensMutation = loader('../graphql/mutations/addPensMutation.graphql');

// This is the type of form to add pen
type AddPenFormType = { penNames: string[] };

// This is the type of props coming from parent component
type AddPensFormProps = {
  // herdId is used for adding pen
  herdId: number;
  /* Existing pens data */
  existingPens: ExistingPensType;
};

// This is the main functional component
const AddPensForm: React.FC<AddPensFormProps> = ({ herdId, existingPens }) => {
  // All the methods of the useForm is stored in the methods variable
  const methods = useForm<AddPenFormType>({
    defaultValues: {
      penNames: [],
    },
  });
  // When this state is true it indicate the loading of the button
  const [isBtnLoading, setIsBtnLoading] = React.useState<boolean>(false);

  // This is the mutation query for adding the pen data
  const [addPens] = useMutation<AddPensMutation, AddPensMutationVariables>(addPensMutation);

  // This function is invoke when user hit the add new pens button
  const onSubmit = (data: AddPenFormType) => {
    if (!Array.isArray(data.penNames) || data.penNames.length === 0) {
      methods.setError('penNames', {
        message: 'Please enter Pen IDs and try again',
      });
      return;
    }

    /* Variable to store already existing pen names */
    const alreadyExistingPenNames = data.penNames.filter((item) => {
      /* Variable to check if pen with same name already exists in db */
      const checkIfPenNameAlreadyExisting = existingPens
        ? existingPens.find((val) => val.name === item)
        : undefined;
      return !!checkIfPenNameAlreadyExisting;
    });

    if (alreadyExistingPenNames.length > 0) {
      methods.setError('penNames', {
        message: `Following Pen(s) already exist : ${alreadyExistingPenNames.join(
          ', ',
        )}. Please enter unique pen names and try again`,
      });
      return;
    }
    /* Clearing errors if there are no errors */
    methods.clearErrors();
    // Array of string is convert to array of object e.g. pen {name: string}
    const newPens: Pick<Pen_Insert_Input, 'name' | 'farm_id'>[] = data.penNames.map((name) => {
      return { name, farm_id: herdId };
    });

    setIsBtnLoading(true);
    // Here we are adding pen data by addPens query
    addPens({
      variables: {
        objects: newPens,
      },
      // for updating the cache update method is used
      update: (cache, result) => {
        // This is the new data of pen which is going to be added in the cache
        const dataToAdd = result.data?.insert_pen;
        // This is the modify function for the cache update
        cache.modify({
          // This is the input of the modify function by which we get access to the cache data here we access pen field
          fields: {
            // existingPensRef consists all the data of pens present the cache
            pen(existingPensRef: Array<Reference>) {
              return [...existingPensRef, dataToAdd];
            },
          },
        });
      },
    })
      .then(() => {
        methods.reset();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Pens are successfully added.');
        setIsBtnLoading(false);
      })
      .catch((error: ApolloError) => {
        methods.reset();
        setIsBtnLoading(false);
        logger(error);
      });
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
        <div
          className={`${styles.labelName} requiredField`}
          style={{ marginLeft: 20, fontSize: 17, marginRight: 10 }}
        >
          Pen IDs:
        </div>
        <Controller
          className={styles.inputField}
          style={{ width: 400, border: '1px solid grey' }}
          name="penNames"
          rules={{ required: true }}
          control={methods.control}
          as={
            <Select
              mode="tags"
              placeholder="Please enter IDs of the new Pens on this herd"
              tokenSeparators={[',']}
              bordered
            />
          }
        />
      </div>
      <Button
        icon={<PlusOutlined />}
        style={{ marginLeft: 95, marginTop: 10 }}
        className="primaryBtn"
        htmlType="submit"
        loading={isBtnLoading}
      >
        Add new pens
      </Button>
      {methods.errors.penNames ? (
        <p className={styles.errorText} style={{ marginLeft: 100 }}>
          {((methods.errors.penNames as unknown) as FieldError).message}
        </p>
      ) : null}
    </form>
  );
};

export default AddPensForm;
