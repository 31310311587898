import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import { UseFormMethods } from 'react-hook-form';
import Heading from '../components/Heading';
import commonStyles from './HeifersOfSubHerdScreen.module.scss';
import SubHerdDataForm from '../forms/SubHerdDataForm';
import { Herd_Data_Avg_Fields } from '../graphql/graphql-types';
import { renderWarningMessage } from '../utils/helpers';

// type definition for component props
type AddSubHerdDataScreenProps = {
  // selected subHerdId for a specific selected herd
  selectedSubHerdId: string;
  // state to check whether next btn on AddSubHerdDataScreen is disabled or not
  nextBtnDisabled: boolean;
  // setState fn to set boolean state of the next btn
  setNextBtnDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  // setState fn to set subHerd data form values
  setSubHerdDataFormValues: React.Dispatch<React.SetStateAction<Herd_Data_Avg_Fields>>;
  // subHerdDataForm methods
  subHerdDataFormMethods: UseFormMethods<Herd_Data_Avg_Fields>;
  // boolean state to store the visibility of bulk heifer data modal
  showBulkAddDataModal: boolean;
  // setState fn to handle the visibility of bulk heifer data modal
  setShowBulkAddDataModal: React.Dispatch<React.SetStateAction<boolean>>;
  // id of selected herd
  selectedHerdId: number;
};

// functional component
const AddSubHerdDataScreen: React.FC<AddSubHerdDataScreenProps> = ({
  selectedSubHerdId,
  setSubHerdDataFormValues,
  nextBtnDisabled,
  setNextBtnDisabled,
  subHerdDataFormMethods,
}): JSX.Element => {
  // stores state to either show or hide the drawer consisting SubHerdDataForm
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  // stores the unique mode to determine which btn was clicked
  const [btnMode, setBtnMode] = useState<'add' | 'edit' | null>(null);

  return (
    <div>
      <Drawer
        bodyStyle={{ padding: '0 24px' }}
        title={
          <div style={{ fontSize: 22 }}>
            {btnMode === 'add' ? 'Add' : 'Update'} sub-herd information
          </div>
        }
        closable
        width={560}
        className="drawerContainer"
        visible={isDrawerVisible}
        onClose={(): void => {
          setIsDrawerVisible(false);
          if (btnMode === 'add') {
            subHerdDataFormMethods.reset();
          }
        }}
        forceRender={!isDrawerVisible}
        destroyOnClose
      >
        <SubHerdDataForm
          textAlign="left"
          setSubHerdDataFormValues={setSubHerdDataFormValues}
          setNextBtnDisabled={setNextBtnDisabled}
          nextBtnDisabled={nextBtnDisabled}
          layoutDirection="vertical"
          selectedSubHerdId={selectedSubHerdId}
          subHerdDataFormMethods={subHerdDataFormMethods}
          btnMode={btnMode}
          setDrawerVisible={setIsDrawerVisible}
        />
      </Drawer>
      <Heading headingName="Add Sub-herd Information" />
      <div>
        {renderWarningMessage(
          <span>
            No <b>Sub-herd Information</b> present for this sub-herd. Click{' '}
            <b>Add Sub-herd Information</b> to begin.
          </span>,
          { marginLeft: 0 },
        )}
        <Button
          style={{ marginTop: 16 }}
          type="primary"
          className={commonStyles.addHeiferBtn}
          onClick={() => {
            setBtnMode('add');
            setIsDrawerVisible(true);
          }}
        >
          Add sub-herd information
        </Button>
      </div>
    </div>
  );
};

export default AddSubHerdDataScreen;
