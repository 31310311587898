import React, { useContext, useState } from 'react';
import { User } from '../graphql/graphql-types';
import { ContextUserDataType } from '../utils/types';

// context type
type AppContextType = {
  /* logged in user data */
  user: ContextUserDataType | undefined;
  /* Function to update or add user */
  setUser: (user: ContextUserDataType | undefined) => void;
};

/* context that will be used to store data,which can be accessed from any where in the app */
export const AppContext = React.createContext<AppContextType>({
  user: undefined,
  setUser: () => {},
});

/* hook to call context wherever needed */
export const useApp = (): AppContextType => useContext<AppContextType>(AppContext);

/* Used to provide the values of context to children */
export const AppProvider = ({ children }: { children: JSX.Element }): JSX.Element | null => {
  /* State to store logged in user details */
  const [user, setUser] = useState<ContextUserDataType | undefined>(undefined);
  return <AppContext.Provider value={{ user, setUser }}>{children}</AppContext.Provider>;
};
