import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input } from 'antd';
import { MailOutlined, LockFilled } from '@ant-design/icons';
import { firebaseAuth } from '../utils/firebase';
import styles from './SignInForm.module.scss';

// Inputs is the type of data which we get from the form
export type Inputs = {
  // email is the string variable which contain the type of email field
  email: string;
  // password is the string variable which contain the type of password field
  password: string;
};

// This is the main functional component
const SignInForm: React.FC = () => {
  // this state is for loader for the sign in button
  const [signInLoading, setSignInLoading] = useState<boolean>(false);
  // this state is for indicating a message for changing the password from the mail
  const [resetMessage, setResetMessage] = useState<string>('');
  // this is for the loader of thr reset password button
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  // this variable is used to store the values of useForm
  const methods = useForm<Inputs>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  // this function is invoked when user hits sign in button
  const onSubmit = (data: Inputs) => {
    setSignInLoading(true);
    // this function is for logging in with verified user
    firebaseAuth.signInWithEmailAndPassword(data.email, data.password).catch((error) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (error.code === 'auth/user-not-found' || error.code === 'auth/invalid-email') {
        methods.setError('email', {
          type: 'manual',
          message: 'The entered Email is incorrect',
        });
        setSignInLoading(false);
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (error.code === 'auth/wrong-password') {
        methods.setError('password', {
          type: 'manual',
          message: 'The entered Password is incorrect',
        });
        setSignInLoading(false);
      }
      setSignInLoading(false);
    });
  };

  // This handleReset func is invoke by clicking reset password
  const handleReset = (): void => {
    setResetLoading(true);
    setResetMessage('');
    // getting value of  email field
    const emailValue = methods.getValues('email');
    // This firebase connectivity is invoke when user click resetpassword and sending email
    firebaseAuth
      .sendPasswordResetEmail(emailValue)
      .then(() => {
        setResetMessage(
          'An email has been sent to your Email ID. Please follow the instructions in it to reset your password.',
        );
        methods.reset({
          email: '',
        });
        setResetLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (error.code === 'auth/user-not-found' || error.code === 'auth/invalid-email') {
          methods.setError('email', {
            type: 'manual',
            message: 'The entered Email is incorrect',
          });
          setResetLoading(false);
        } else {
          setResetLoading(false);
        }
      });
  };

  return (
    <div className={styles.formBorder}>
      <h1 className={styles.signInHeading}>Dairy Heifer Growth Tracker</h1>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={styles.signInForm}>
        <Controller
          name="email"
          control={methods.control}
          as={
            <Input
              prefix={<MailOutlined style={{ color: '#c7bfbf', marginRight: 10 }} />}
              className={styles.signInInputField}
              placeholder="Please enter email ID"
            />
          }
        />
        <Controller
          name="password"
          control={methods.control}
          as={
            <Input.Password
              prefix={<LockFilled style={{ color: '#c7bfbf', marginRight: 10 }} />}
              className={styles.signInInputField}
              type="password"
              placeholder="Please enter password"
            />
          }
        />
        {methods.errors.email?.message ? (
          <p className={styles.errorText}>{methods.errors.email.message}</p>
        ) : null}
        {methods.errors.password?.message ? (
          <p className={styles.errorText}>{methods.errors.password.message}</p>
        ) : null}
        {resetMessage ? <p className={styles.resetPasswordText}>{resetMessage}</p> : null}

        <Button
          className={
            methods.watch('email') === '' || methods.watch('password') === ''
              ? styles.buttonColorDisabled
              : 'primaryBtn'
          }
          htmlType="submit"
          style={{
            width: 150,
            height: 50,
            fontSize: 20,
            marginTop: '40px',
            marginBottom: '20px',
          }}
          disabled={methods.watch('email') === '' || methods.watch('password') === ''}
          loading={signInLoading}
        >
          Sign In
        </Button>
        <Button
          disabled={methods.watch('email') === ''}
          style={{
            border: 'none',
            backgroundColor: 'transparent',
            // marginTop: 40,
            color: methods.watch('email') === '' ? 'grey' : 'black',
          }}
          onClick={handleReset}
          loading={resetLoading}
        >
          <span
            style={{
              fontSize: 20,
              textDecoration: methods.watch('email') === '' ? 'none' : 'underline',
            }}
          >
            Reset Password
          </span>
        </Button>
      </form>
    </div>
  );
};

export default SignInForm;
