import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Select, Row, Col } from 'antd';
import moment from 'moment-timezone';
import { HeifersOfHerdQuery, HeifersOfHerdQueryVariables } from '../graphql/graphql-types';
import Heading from '../components/Heading';
import { renderWarningMessage } from '../utils/helpers';
import MakeTransferForm from '../forms/MakeTransferForm';
import { HeiferType } from '../utils/types';
import { dateFormat, dateFormatForDisplay } from '../utils/globals';

// type definition for props
type TransferScreenProps = {
  // value of selected herd id
  selectedHerdId: number | undefined;
  // value of selected sub herd id
  selectedSubHerdId: string;
  // value of selected heifer
  selectedHeiferId: string | undefined;
  // used to update the state value of selected heifer id
  setSelectedHeiferId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

// This is the query for fetching all the heifers of selected herd
const heifersOfHerdQuery = loader('../graphql/queries/heifersOfHerdQuery.graphql');

// Destructuring Options from select
const { Option } = Select;

const TransferScreen: React.FC<TransferScreenProps> = ({
  selectedHerdId,
  selectedSubHerdId,
  selectedHeiferId,
  setSelectedHeiferId,
}) => {
  // obtains the heifer data
  const { data } = useQuery<HeifersOfHerdQuery, HeifersOfHerdQueryVariables>(heifersOfHerdQuery, {
    variables: { herd_id: selectedSubHerdId },
  });

  // heifer data fetched from query
  const heifersData = data?.heifer;

  // This function is invoke when user select heifer
  const handleChangeSelectHeifer = (value: string) => {
    setSelectedHeiferId(value);
  };

  // storing heifer data for respective heifer id
  let selectedHeiferData: HeiferType | undefined;
  if (Array.isArray(heifersData) && heifersData.length > 0) {
    selectedHeiferData = heifersData.find((heifer) => {
      return heifer.id === selectedHeiferId;
    });
  }

  // penId of selected heifer
  const currentPen: string | null | undefined = selectedHeiferData?.pen?.name;

  return (
    <div style={{ marginLeft: 34 }}>
      {selectedHerdId ? (
        <Row className="selectBoxContainer" style={{ padding: `10px 0 0 0` }}>
          <Col className="selectBoxLabel" span={4} style={{ textAlign: 'start', paddingTop: 5 }}>
            Select a Heifer:
          </Col>
          <Col span={16}>
            <Select
              className="selectBox"
              showSearch
              disabled={Array.isArray(heifersData) && heifersData.length === 0}
              style={{ width: 400, marginLeft: 0 }}
              placeholder="Search and Select the heifer you want to transfer"
              optionFilterProp="data"
              onChange={handleChangeSelectHeifer}
              value={selectedHeiferId === '' ? undefined : selectedHeiferId}
            >
              {Array.isArray(heifersData)
                ? heifersData.map((item) => (
                    <Option key={item.id} value={item.id} data={item.name}>
                      <span>{item.name}</span>
                      <span style={{ float: 'right', color: '#c6c2c2' }}>
                        {moment(item.dob, dateFormat).format(dateFormatForDisplay)}
                      </span>
                    </Option>
                  ))
                : null}
            </Select>
            {selectedSubHerdId && Array.isArray(heifersData) && heifersData.length === 0
              ? renderWarningMessage('No heifer present in selected sub-herd.', {
                  marginTop: 10,
                })
              : null}
          </Col>
        </Row>
      ) : null}

      {selectedHeiferData ? (
        <>
          <Row
            className="selectBoxContainer"
            style={{ alignItems: 'center', padding: `10px 0 0 0` }}
          >
            <Col
              className="selectBoxLabel"
              span={4}
              style={{ textAlign: 'start', paddingRight: 24 }}
            >
              Current Pen:
            </Col>
            <Col span={18}>{!currentPen ? '-' : currentPen}</Col>
          </Row>
          <div>
            <div style={{ margin: '25px 0 0 0' }}>
              <Heading headingName="Make Transfer" />
            </div>
            {selectedHerdId !== undefined && selectedHeiferId ? (
              <MakeTransferForm
                selectedHerdId={selectedHerdId}
                selectedSubHerdId={selectedSubHerdId}
                selectedPen={currentPen}
                heiferDob={selectedHeiferData.dob}
                heiferId={selectedHeiferId}
                setSelectedHeiferId={setSelectedHeiferId}
              />
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default TransferScreen;
