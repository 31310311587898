import React from 'react';

// type def for component props
type ScatterChartTriangleShapeProps = {
  // x coordinate of the center of triangle
  cx?: number;
  // y coordinate of the center of triangle
  cy?: number;
  // fill color of shape
  fillColor: string;
};

// functional component
const ScatterChartTriangleShape: React.FC<ScatterChartTriangleShapeProps> = ({
  fillColor,
  cx,
  cy,
}) => (
  <svg
    x={cx}
    y={cy}
    width={85}
    height={85}
    fill={fillColor}
    viewBox="0 0 1024 1024"
    style={{ overflow: 'visible' }}
  >
    <path d="M50 15L100 100 0 100z" transform="translate(-50,-60)" />
  </svg>
);

export default ScatterChartTriangleShape;
