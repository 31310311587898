import {
  ChartDataPoints,
  SubHerdSummaryAgeFilterOptions,
  SubHerdSummaryHeiferDataType,
  SubHerdSummarySelectOptions,
} from './types';

// sub herd basic summary table data calculations return type
export type SubHerdSummaryCalcType = {
  // minimum weight of heifer
  minWeight?: number;
  // maximum weight of heifer
  maxWeight?: number;
  // average weight of all heifers
  avgWeight: number | null;
  // median weight of all heifers
  medianWeight: number | null;
  // minimum height of heifer
  minHeight?: number;
  // maximum height of heifer
  maxHeight?: number;
  // average height of all heifers
  avgHeight: number | null;
  // median height of all heifers
  medianHeight: number | null;
  // number of heifer measurements based on which summary data is evaluated
  measurementsCount: number;
};

// sub herd summary calculations input object type
type SubHerdSummaryInputDataType = {
  // fetched subHerdHeifer data for heifer weight chart
  weightChartSubHerdHeiferData: ChartDataPoints;
  // fetched subHerdHeifer data for heifer height chart
  heightChartSubHerdHeiferData: ChartDataPoints;
  // historical data for heifer weight chart for heifers with age > 24 months
  weightChartHistoricalData?: ChartDataPoints;
  // fetched historical data for heifer height chart for heifers with age > 24 months
  heightChartHistoricalData?: ChartDataPoints;
  // currently selected radio option for filtering sub herd heifer data
  selectedOption: SubHerdSummarySelectOptions;
} & SubHerdSummaryAgeFilterOptions;

// method to calculate median for number data, handling both even and odd length series
export const findMedian = (data: number[]): number | null => {
  // check if series length is even
  const isSeriesEven = data.length % 2 === 0;
  // checking if data series is odd in length
  if (!isSeriesEven) {
    return data[(data.length - 1) / 2];
  }
  return (data[data.length / 2] + data[data.length / 2 - 1]) / 2;
};

// method to sort input heifer data ranging from lowest
export const sortHeiferData = (data: ChartDataPoints, key: 'weight' | 'height'): number[] => {
  // storing sorted data
  const dataToSort: number[] = [];

  data
    .filter((val) => {
      return val[key] !== null && val[key] !== undefined;
    })
    .forEach((item) => {
      dataToSort.push(item[key] as number);
    });
  // sorting obtained weight / height array of numbers depending upon supplied key
  return dataToSort.sort((prev, next) => {
    return prev - next;
  });
};

// method to find average of weight/height data
export const findAverage = (data: number[]): number | null => {
  // checking for non-empty array
  if (data.length > 0) {
    // storing sum of input data
    const aggregate: number = data.reduce((acc: number, curr: number) => {
      return acc + curr;
    });
    return aggregate / data.length;
  }
  return null;
};

/* function to calculate heifer's weight and height data based
on 'baseAge' and 'baseAgeErrDeviation' input values */
export const calFilteredHeiferData = ({
  weightData,
  heightData,
  baseAge,
  baseAgeErrDeviation,
}: SubHerdSummaryHeiferDataType & SubHerdSummaryAgeFilterOptions): SubHerdSummaryHeiferDataType => {
  // lower limit of age range (in months)
  const lowerLimit = baseAge - baseAgeErrDeviation;
  // upper limit of age range (in months)
  const upperLimit = baseAge + baseAgeErrDeviation;

  return {
    weightData: weightData.filter((item) => {
      return item.age ? item.age >= lowerLimit && item.age <= upperLimit : false;
    }),
    heightData: heightData.filter((item) => {
      return item.age ? item.age >= lowerLimit && item.age <= upperLimit : false;
    }),
  };
};

// helper function to calculate sub herd basic summary table data
const subHerdSummaryCalculations = ({
  weightChartSubHerdHeiferData,
  heightChartSubHerdHeiferData,
  weightChartHistoricalData,
  heightChartHistoricalData,
  selectedOption,
  baseAge,
  baseAgeErrDeviation,
}: SubHerdSummaryInputDataType): SubHerdSummaryCalcType[] => {
  // filtered heifer data between the age range (inclusive lower and upper limit)
  const filteredHeiferData = calFilteredHeiferData({
    weightData: weightChartHistoricalData
      ? weightChartSubHerdHeiferData.concat(weightChartHistoricalData)
      : weightChartSubHerdHeiferData,
    heightData: heightChartHistoricalData
      ? heightChartSubHerdHeiferData.concat(heightChartHistoricalData)
      : heightChartSubHerdHeiferData,
    baseAge,
    baseAgeErrDeviation,
  });

  // sorted weight data points consisting current sub herd heifer data and historical data
  const sortedOverallWeightData: number[] = sortHeiferData(filteredHeiferData.weightData, 'weight');
  // sorted height data points consisting current sub herd heifer data and historical data
  const sortedOverallHeightData: number[] = sortHeiferData(filteredHeiferData.heightData, 'height');

  // reusable function that returns calculated table data for sub herd summary
  const calTableData = (
    weightData: number[],
    heightData: number[],
    measurementsCount: number,
  ): SubHerdSummaryCalcType[] => [
    {
      minWeight: weightData[0],
      maxWeight: weightData[weightData.length - 1],
      avgWeight: findAverage(weightData),
      medianWeight: findMedian(weightData),
      minHeight: heightData[0],
      maxHeight: heightData[heightData.length - 1],
      avgHeight: findAverage(heightData),
      medianHeight: findMedian(heightData),
      measurementsCount,
    },
  ];

  if (selectedOption === 'current') {
    // filtered weight, height data between the age range (inclusive lower and upper limit)
    const { weightData, heightData } = calFilteredHeiferData({
      weightData: weightChartSubHerdHeiferData,
      heightData: heightChartSubHerdHeiferData,
      baseAge,
      baseAgeErrDeviation,
    });
    // sorted sub herd heifer weight data
    const sortedWeightData = sortHeiferData(weightData, 'weight');
    // sorted sub herd heifer height data
    const sortedHeightData = sortHeiferData(heightData, 'height');

    return calTableData(sortedWeightData, sortedHeightData, weightData.length);
  }

  if (selectedOption === 'historical') {
    // filtered weight, height data between the age range (inclusive lower and upper limit)
    const { weightData, heightData } = calFilteredHeiferData({
      weightData: !weightChartHistoricalData ? [] : weightChartHistoricalData,
      heightData: !heightChartHistoricalData ? [] : heightChartHistoricalData,
      baseAge,
      baseAgeErrDeviation,
    });

    // sorted historical weight data
    const sortedWeightData = weightData.length > 0 ? sortHeiferData(weightData, 'weight') : [];
    // sorted historical height data
    const sortedHeightData = heightData.length > 0 ? sortHeiferData(heightData, 'height') : [];

    return calTableData(sortedWeightData, sortedHeightData, weightData.length);
  }

  return calTableData(
    sortedOverallWeightData,
    sortedOverallHeightData,
    filteredHeiferData.weightData.length,
  );
};

export default subHerdSummaryCalculations;
