import React from 'react';
import { Col, Row, Space } from 'antd';
import Heading from './Heading';
import subHerdDataCalculations from '../utils/subHerdDataCalculations';
import { Herd_Data_Avg_Fields } from '../graphql/graphql-types';
import { UserInputDataType } from '../utils/types';

// type def for component props
type ViewLatestSubHerdDataSectionProps = {
  // user input data type
  userInputData: UserInputDataType;
  // latest sub herd data layout direction
  layoutDirection: 'horizontal' | 'vertical';
  // layout size
  layoutSize?: number;
  // calculated average data fields
  avgInputDataFields?: Herd_Data_Avg_Fields;
};

// functional component
const ViewLatestSubHerdDataSection: React.FC<ViewLatestSubHerdDataSectionProps> = ({
  userInputData,
  avgInputDataFields = {},
  layoutDirection,
  layoutSize = 0,
}) => (
  <Space
    direction={layoutDirection}
    style={{
      display: 'flex',
      justifyContent: 'start',
      alignItems: layoutDirection === 'vertical' ? 'normal' : 'baseline',
    }}
    size={layoutSize}
  >
    <div
      style={{
        width: layoutDirection === 'vertical' ? '100%' : 'calc(100% + 5rem)',
        marginBottom: layoutDirection === 'vertical' ? 0 : 22,
      }}
    >
      <Space>
        <Heading
          headingName={<b>Herd Information</b>}
          headingColor="#292929"
          renderHrLine={false}
        />
      </Space>
      <div>
        {userInputData
          ? Object.values(userInputData).map((item) => (
              <Row key={item.title} style={{ display: 'flex', padding: `20px 0 3px 0` }}>
                <Col span={19}>{item.title}</Col>
                <Col span={5} style={{ textAlign: 'end' }}>
                  {item.displayValue}
                </Col>
              </Row>
            ))
          : null}
      </div>
    </div>
    <div
      style={{
        marginTop: 24,
        width: layoutDirection === 'vertical' ? '100%' : 'calc(100% + 2rem)',
      }}
    >
      <Heading
        headingName={
          <b
            style={{
              width: layoutDirection === 'vertical' ? '100%' : 'calc(100% + 2rem)',
              marginTop: layoutDirection === 'vertical' ? 20 : 0,
            }}
          >
            Targets based on DCHA recommendations
          </b>
        }
        headingColor="#292929"
        renderHrLine={false}
      />
      {avgInputDataFields ? (
        <div>
          {Object.values(subHerdDataCalculations(avgInputDataFields)).map(
            (entry): JSX.Element => (
              <Row key={entry.title} style={{ display: 'flex', padding: '20px 0 3px 0' }}>
                <Col span={19}>{entry.title}</Col>
                <Col span={5} style={{ textAlign: 'end' }}>
                  {entry.displayValue}
                </Col>
              </Row>
            ),
          )}
        </div>
      ) : null}
    </div>
  </Space>
);

export default ViewLatestSubHerdDataSection;
