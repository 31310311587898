import { Button, message, Select, Row, Col } from 'antd';
import React from 'react';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Controller, useForm } from 'react-hook-form';
import {
  DataFarmsHerdsQuery,
  DataFarmsHerdsQueryVariables,
  Heifer,
  Herd,
  PensOfFarmQuery,
  PensOfFarmQueryVariables,
  UpdateHeiferMutation,
  UpdateHeiferMutationVariables,
} from '../graphql/graphql-types';
import styles from '../screens/TransferScreen.module.scss';
import { logger, renderWarningMessage } from '../utils/helpers';
import { SelectedHerdType } from '../utils/types';

// This is the query to fetch all the dataHerdsSubHerds
const dataHerdsSubHerdsQuery = loader('../graphql/queries/dataFarmsHerdsQuery.graphql');
// This query is for updating heifers
const updateHeiferMutation = loader('../graphql/mutations/updateHeiferMutation.graphql');
// This is the query for fetching all the pens of selected herd
const pensOfHerdQuery = loader('../graphql/queries/pensOfFarmQuery.graphql');

// This is the types of props coming from the parent component
type MakeTransferFormProps = {
  // This is the id of selected herd use for pre filling the select a herd
  selectedHerdId: number;
  // This is the id of selected sub herd use for pre filling the select a sub herd
  selectedSubHerdId: string | null;
  // This is the pen id of selected heifer
  selectedPen: string | null | undefined;
  // This is the dob of selected heifer
  heiferDob: string | Date;
  // This is the id of selected heifer
  heiferId: string;
  // This is the set state of selected heifer used for cancel button
  setSelectedHeiferId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

// This is the type of form data for making transfer of heifer
type HeiferFormType = Pick<Heifer, 'pen_id'> & {
  herdId: number;
  subHerdId: string | null;
};

// Destructuring Options from select
const { Option } = Select;

// This is the main functional component
const MakeTransferForm: React.FC<MakeTransferFormProps> = ({
  selectedHerdId,
  selectedSubHerdId,
  selectedPen,
  heiferDob,
  heiferId,
  setSelectedHeiferId,
}) => {
  // this state holds the value of loading indication for save button
  const [isSaveButtonLoading, setIsSaveButtonLoading] = React.useState<boolean>(false);
  // This state is used for fetching pen ids of herd initially it stores the value of selected herd then by changed in select new herd field value id gets updated
  const [idOfHerd, setIdOfHerd] = React.useState<number>(selectedHerdId);
  // By this variable we are going to use all the methods of useForm hook
  const methods = useForm<HeiferFormType>();

  // This is the query for fetching data of herd with sub herds
  const dataHerdsSubHerdsResult = useQuery<DataFarmsHerdsQuery, DataFarmsHerdsQueryVariables>(
    dataHerdsSubHerdsQuery,
  );

  // storing data of herd
  const herds = dataHerdsSubHerdsResult?.data?.farm;

  // mutation for transfer the heifer from one herd, sub herd, pen to another
  const [updateHeifer] = useMutation<UpdateHeiferMutation, UpdateHeiferMutationVariables>(
    updateHeiferMutation,
  );

  // This is the value of selected herd when user select another herd from select box
  const watchHerdId = methods.watch('herdId');

  //  This is the single herd detail which is selected in the select new herd
  let selectedHerd: SelectedHerdType | undefined;
  if (herds) {
    selectedHerd = herds.find((eachHerd) => {
      if (watchHerdId) {
        return watchHerdId === eachHerd.id;
      }
      return selectedHerdId === eachHerd.id;
    });
  }

  // This is the sub herd data of selected herd
  const subHerdsData: Pick<Herd, 'id' | 'name'>[] | undefined = selectedHerd?.herds;

  // fetching data for penIds of selected herd
  const { data: penIdsOfHerd } = useQuery<PensOfFarmQuery, PensOfFarmQueryVariables>(
    pensOfHerdQuery,
    {
      variables: { farm_id: idOfHerd },
    },
  );

  // This is the pen ids array of herd
  const penIds = penIdsOfHerd?.pen;

  // This function is invoked when user hit the save button of the modal
  const onSubmit = (values: HeiferFormType) => {
    setIsSaveButtonLoading(true);
    updateHeifer({
      variables: {
        _set: {
          dob: heiferDob,
          herd_id: values.subHerdId,
          pen_id: values.pen_id,
        },
        id: heiferId,
      },
      update: (cache, { data: cacheData }) => {
        // transferred heifer data
        const updatedHeiferData = cacheData?.update_heifer_by_pk;
        // transferred heiferId
        const updatedHeiferId = updatedHeiferData?.id;
        // updating the cache for updated herd and sub herds
        cache.modify({
          fields: {
            heifer(existingHeiferRef: Array<Reference>, { readField }) {
              return existingHeiferRef.filter((ref) => {
                return updatedHeiferId !== readField('id', ref);
              });
            },
          },
        });
      },
    })
      .then(() => {
        setIsSaveButtonLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Heifer has been successfully transferred.');
        setSelectedHeiferId('');
      })
      .catch((heiferError: ApolloError) => {
        setIsSaveButtonLoading(false);
        logger(heiferError);
        setSelectedHeiferId('');
      });
  };

  // This is the option of the sub herds select box
  const renderOptionsOfSubHerd = (): JSX.Element[] | null => {
    if (Array.isArray(subHerdsData)) {
      return subHerdsData.map((item) => (
        <Option value={item.id} key={item.id} data={item.name}>
          {item.name}
        </Option>
      ));
    }
    return null;
  };

  // This is the options of the pen id select box
  const renderOptionsOfPenIds = (): JSX.Element[] | null => {
    if (Array.isArray(penIds)) {
      return penIds.map((item) => (
        <Option value={item.id} key={item.id} data={item.name}>
          {item.name}
        </Option>
      ));
    }
    return null;
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <>
        <Row className="selectBoxContainer" style={{ alignItems: 'center', padding: `10px 0 0 0` }}>
          <Col
            className="selectBoxLabel requiredField"
            span={4}
            style={{ textAlign: 'start', paddingRight: 24 }}
          >
            Select new Herd:
          </Col>
          <Col span={18}>
            <Controller
              className={styles.inputField}
              name="herdId"
              rules={{ required: 'Please select herd and try again' }}
              control={methods.control}
              defaultValue={selectedHerdId}
              render={({ onChange, value }) => (
                <Select
                  className="selectBox"
                  showSearch
                  style={{ width: 400, marginLeft: 0 }}
                  placeholder="Select new herd of heifer"
                  optionFilterProp="data"
                  onChange={(val) => {
                    onChange(val);
                    setIdOfHerd(val);
                    methods.setValue('subHerdId', null);
                    methods.setValue('pen_id', null);
                  }}
                  value={value as number}
                >
                  {Array.isArray(herds) && herds.length > 0
                    ? herds.map((item) => (
                        <Option value={item.id} key={item.id} data={item.name}>
                          {item.name}
                        </Option>
                      ))
                    : null}
                </Select>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col offset={3}>
            {methods.errors.herdId ? (
              <p className={styles.errorText}>{methods.errors.herdId.message}</p>
            ) : null}
          </Col>
        </Row>

        <Row className="selectBoxContainer" style={{ alignItems: 'center', padding: `10px 0 0 0` }}>
          <Col
            className="selectBoxLabel requiredField"
            span={4}
            style={{ textAlign: 'start', paddingRight: 24 }}
          >
            Select new Sub-herd:
          </Col>
          <Col span={18}>
            <Controller
              className={styles.inputField}
              defaultValue={selectedSubHerdId !== null ? selectedSubHerdId : null}
              name="subHerdId"
              rules={{ required: 'Please select sub-herd and try again' }}
              control={methods.control}
              render={({ onChange, value }) => (
                <Select
                  className="selectBox"
                  showSearch
                  style={{ width: 400, marginLeft: 0 }}
                  disabled={!Array.isArray(subHerdsData) || subHerdsData.length === 0}
                  placeholder="Select new sub-herd of heifer"
                  optionFilterProp="data"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  value={value as string}
                >
                  {renderOptionsOfSubHerd()}
                </Select>
              )}
            />
          </Col>
        </Row>

        {!Array.isArray(subHerdsData) || subHerdsData.length === 0
          ? renderWarningMessage(
              <span>
                No sub-herd present in selected herd. Add sub-herd to herd in <b>Data</b> section.
              </span>,
            )
          : null}

        {methods.errors.subHerdId ? (
          <Row>
            <Col offset={4}>
              <p className={styles.errorText}>{methods.errors.subHerdId.message}</p>
            </Col>
          </Row>
        ) : null}

        <Row className="selectBoxContainer" style={{ alignItems: 'center', padding: `10px 0 0 0` }}>
          <Col className="selectBoxLabel" span={4} style={{ textAlign: 'start', paddingRight: 24 }}>
            Select new Pen:
          </Col>
          <Col span={18}>
            <Controller
              className={styles.inputField}
              name="pen_id"
              defaultValue={selectedPen}
              control={methods.control}
              render={({ onChange, value }) => (
                <Select
                  className="selectBox"
                  showSearch
                  style={{ width: 400, marginLeft: 0 }}
                  placeholder="Select new pen of heifer"
                  optionFilterProp="data"
                  disabled={!Array.isArray(penIds) || penIds.length === 0}
                  onChange={(val) => {
                    onChange(val);
                  }}
                  value={value as string}
                >
                  {renderOptionsOfPenIds()}
                </Select>
              )}
            />
          </Col>
        </Row>

        {!Array.isArray(penIds) || penIds.length === 0
          ? renderWarningMessage(
              <span>
                No Pen is present in selected herd. Add pen to herd in <b>Herds</b> section.
              </span>,
              { marginTop: 10 },
            )
          : null}

        <Row style={{ marginTop: 20 }}>
          <Col offset={4}>
            <Button
              className="primaryBtn"
              style={{ marginRight: 30, width: 100 }}
              htmlType="submit"
              loading={isSaveButtonLoading}
            >
              Save
            </Button>
            <Button
              className="secondaryBtn"
              style={{ border: '1px solid #17A697', marginLeft: 30, width: 100 }}
              onClick={() => {
                setSelectedHeiferId('');
              }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </>
    </form>
  );
};

export default MakeTransferForm;
