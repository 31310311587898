import { Herd_Data_Avg_Fields } from '../graphql/graphql-types';

// calculated data key's value type
export type CalculatedDataObjValueType = {
  // original calculated value
  value: number | null | undefined;
  // value to render with appropriate units
  displayValue: string;
  // title to display beside displayValue
  title: string;
};

// calculated sub herdData object type with value and displayValue
export type CalculatedSubHerdDataType = Record<
  | 'mintarget_calf_bw_56days'
  | 'mintarget_calf_height_56days'
  | 'adg_birth_56days'
  | 'bw_heifer_1st_conception'
  | 'height_heifer_1st_conception'
  | 'bw_heifer_calving'
  | 'height_heifer_calving'
  | 'bw_heifer_1week_precalving',
  CalculatedDataObjValueType
>;

// helper function to calculate sub herd data statistics
const subHerdDataCalculations = (ipData: Herd_Data_Avg_Fields): CalculatedSubHerdDataType => {
  // Minimum target calf body weight at 56 days of age
  const minTargetCalfBodyWeight56Days = (ipData.avg_birth_bw_calves as number) * 2;
  // Minimum target calf height at 56 days of age
  const minTargetCalfHeight56Days = (ipData.avg_birth_height_calves as number) * 1.156;
  // Average daily gain from birth to 56th day
  const adgBirth56Days = (ipData.avg_birth_bw_calves as number) / 56;
  // Body weight of heifers at first conception
  const bwHeifer1stConception = (ipData.avg_bw_3rdlact_cow as number) * 0.55;
  // Height of heifers at first conception
  const heightHeifer1stConception = (ipData.avg_height_3rdlact_cow as number) * 0.85;
  // Body weight of heifers at calving
  const bwHeiferCalving = (ipData.avg_bw_3rdlact_cow as number) * 0.85;
  // Height of heifers at calving
  const heightHeiferCalving = (ipData.avg_height_3rdlact_cow as number) * 0.96;
  // Body weight of heifers 1 week before calving
  const bwHeifer1WeekPreCalving =
    (73.195 * (((ipData.avg_birth_bw_calves as number) * 0.45359) / 45)) / 0.45359 +
    0.85 * (ipData.avg_bw_3rdlact_cow as number);

  return {
    mintarget_calf_bw_56days: {
      title: 'Minimum target calf body weight at 56 days of age',
      value: minTargetCalfBodyWeight56Days,
      displayValue: `${minTargetCalfBodyWeight56Days.toFixed(0)} lbs`,
    },
    mintarget_calf_height_56days: {
      title: 'Minimum target calf height at 56 days of age',
      value: minTargetCalfHeight56Days,
      displayValue: `${minTargetCalfHeight56Days.toFixed(0)} inches`,
    },
    adg_birth_56days: {
      title: 'Average daily gain from birth to 56th day',
      value: adgBirth56Days,
      displayValue: `${adgBirth56Days.toFixed(2)} lbs/day`,
    },
    bw_heifer_1st_conception: {
      title: 'Body weight of heifers at first conception',
      value: bwHeifer1stConception,
      displayValue: `${bwHeifer1stConception.toFixed(0)} lbs`,
    },
    height_heifer_1st_conception: {
      title: 'Height of heifers at first conception',
      value: heightHeifer1stConception,
      displayValue: `${heightHeifer1stConception.toFixed(2)} inches`,
    },
    bw_heifer_calving: {
      title: 'Body weight of heifers at calving',
      value: bwHeiferCalving,
      displayValue: `${bwHeiferCalving.toFixed(0)} lbs`,
    },
    height_heifer_calving: {
      title: 'Height of heifers at calving',
      value: heightHeiferCalving,
      displayValue: `${heightHeiferCalving.toFixed(2)} inches`,
    },
    bw_heifer_1week_precalving: {
      title: 'Body weight of heifers 1 week before calving',
      value: bwHeifer1WeekPreCalving,
      displayValue: `${bwHeifer1WeekPreCalving.toFixed(0)} lbs`,
    },
  };
};

export default subHerdDataCalculations;
