import React from 'react';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import styles from '../charts/Charts.module.scss';
import ScatterChartTriangleShape from '../components/ScatterChartTriangleShape';
import {
  LineChartDataType,
  ScatterChartDataType,
  HeiferChartDataSeriesIdentifiers,
  ChartDataPoints,
  LegendPayloadType,
} from './types';

// global regex patterns for form validations
export const validationRegex: Record<'decimal', { value: RegExp; msg: string }> = {
  decimal: {
    value: /^\d+(\.\d+)?$/,
    msg: 'Invalid format, please try again',
  },
};

/* Variable to store colors to be shown in chart for Heifer weight and height */
export const primaryColors = ['#102030', '#E5A62B', '#00B1AD', '#808078', '#B55713'];
export const secondaryColors = ['#7A171A', '#00605E', '#6D90A3'];
export const tertiaryColors = ['#911eb4', '#000075', '#ffd8b1', '#42d4f4'];

/* Variable to store colors */
export const colors = [...primaryColors, ...secondaryColors, ...tertiaryColors];

// common legend payload for heifer weight chart & heifer height chart
export const weightHeightChartLegendPayload: Payload<JSX.Element, string>[] = [
  {
    value: <b className={styles.legendLabel}>DCHA Targets</b>,
    type: 'line',
    id: 'dchaTargets',
    color: '#F20505',
    payload: { strokeDasharray: 0 },
  },
  {
    value: <b className={styles.legendLabel}>DCHA Standards</b>,
    type: 'plainline',
    id: 'dchaStandards',
    color: '#F20505',
    payload: { strokeDasharray: '10 8' },
  },
  {
    value: <b className={styles.legendLabel}>Purina Model</b>,
    type: 'plainline',
    id: 'purina',
    color: '#BE0001',
    payload: {
      strokeDasharray: 0,
    },
  },
  {
    value: <b className={styles.legendLabel}>PSU 2013</b>,
    type: 'plainline',
    id: 'psu',
    color: '#4E5F72',
    payload: { strokeDasharray: 0 },
  },
  {
    // Sub-herd Heifers is replaced with current heifers
    value: <b className={styles.legendLabel}>Current Heifers</b>,
    // eslint-disable-next-line
    // @ts-ignore
    type: <circle cx={0} cy={0} r={2} stroke="black" strokeWidth={1} fill="#000" />,
    id: 'subHerdHeifer',
    color: '#000',
    payload: { strokeDasharray: 0 },
  },
  {
    // Historical Sub-herd Information is replaced with historical heifers
    value: <b className={styles.legendLabel}>Historical Heifers</b>,
    type: 'triangle',
    id: 'historicalHeifer',
    color: '#1075E6',
    payload: { strokeDasharray: '10 8' },
  },
];

export const pcMatureWeightHeightChartLegendPayload: LegendPayloadType = [
  {
    value: <b className={styles.legendLabel}>DCHA Standards BW</b>,
    type: 'plainline',
    id: 'dchaStandardsBw',
    color: '#F20505',
    payload: { strokeDasharray: '10 8' },
  },
  {
    value: <b className={styles.legendLabel}>Purina Model BW</b>,
    type: 'plainline',
    id: 'purinaModelBw',
    color: '#BE0001',
    payload: {
      strokeDasharray: 0,
    },
  },
  {
    value: <b className={styles.legendLabel}>PSU 2013 BW</b>,
    type: 'plainline',
    id: 'psuBw',
    color: '#4E5F72',
    payload: { strokeDasharray: 0 },
  },
  {
    // Sub-herd is replaced with current heifers
    value: <b className={styles.legendLabel}>Current Heifers BW</b>,
    type: 'circle',
    id: 'subHerdHeifersBw',
    color: '#000',
    payload: { strokeDasharray: 0 },
  },
  {
    // historical sub-herd Information BW is replaced with Historical Heifers BW
    value: <b className={styles.legendLabel}>Historical Heifers BW</b>,
    type: 'triangle',
    id: 'historicalSubHerdBw',
    color: '#000',
    payload: { strokeDasharray: 0 },
  },
  {
    value: <b className={styles.legendLabel}>DCHA Standards Hip Ht</b>,
    type: 'plainline',
    id: 'dchaStandardsHipHt',
    color: '#F20505',
    payload: { strokeDasharray: '4 4' },
  },
  {
    value: <b className={styles.legendLabel}>Purina Model Hip Ht</b>,
    type: 'plainline',
    id: 'purinaModelHipHt',
    color: '#BE0001',
    payload: {
      strokeDasharray: '15 6',
      // eslint-disable-next-line
      // @ts-ignore
      stroke: 'yellow',
    },
  },
  {
    value: <b className={styles.legendLabel}>PSU 2013 Hip Ht</b>,
    type: 'plainline',
    id: 'psuHipHt',
    color: '#4E5F72',
    payload: { strokeDasharray: '15 12' },
  },
  {
    // sub herd is replaced with current
    value: <b className={styles.legendLabel}>Current Heifers Hip Ht</b>,
    type: 'circle',
    id: 'subHerdHeifersHipHt',
    color: '#1075e6',
    payload: { strokeDasharray: 0 },
  },
  {
    // sub herd information is replaced with heifers
    value: <b className={styles.legendLabel}>Historical Heifers Hip Ht</b>,
    type: 'triangle',
    id: 'historicalSubHerdHipHt',
    color: '#1075e6',
    payload: { strokeDasharray: 0 },
  },
];

// heifer chart options data
export const heiferChartDataOptions: Array<{
  id: HeiferChartDataSeriesIdentifiers;
  seriesTitle: string;
}> = [
  {
    id: 'psu',
    seriesTitle: 'PSU 2013',
  },
  {
    id: 'purina',
    seriesTitle: 'Purina Model',
  },
  {
    id: 'subHerdHeifer',
    // subherd is replaced with current
    seriesTitle: 'Current Heifers',
  },
  {
    // Historical Heifer Data is replaced with Historical Heifers
    id: 'historicalHeifer',
    seriesTitle: 'Historical Heifers',
  },
];

// dummy line chart data points for PSU 2013 data (heifer weight chart)
export const heiferWeightChartPsuData: ChartDataPoints = [
  { age: 0, weight: 90 },
  { age: 21.84855175, weight: null },
  { age: 1, weight: 135 },
  { age: 2, weight: 180 },
  { age: 3, weight: 244.8111555 },
  { age: 4, weight: 309.6223109 },
  { age: 5, weight: 374.4334664 },
  { age: 6, weight: 439.2446218 },
  { age: 7, weight: 504.0557773 },
  { age: 8, weight: 568.8669327 },
  { age: 9, weight: 633.6780882 },
  { age: 10, weight: 698.4892436 },
  { age: 11, weight: 763.3003991 },
  { age: 12, weight: 828.1115546 },
  { age: 13, weight: 896.2019372 },
  { age: 14, weight: 964.2923199 },
  { age: 15, weight: 1032.382703 },
  { age: 16, weight: 1100.473085 },
  { age: 17, weight: 1168.563468 },
  { age: 18, weight: 1236.653851 },
  { age: 19, weight: 1304.744233 },
  { age: 20, weight: 1372.834616 },
  { age: 21, weight: 1440.924999 },
  { age: 22, weight: 1509.015381 },
];

// dummy line chart data points for purina model (heifer weight chart)
export const heiferWeightChartPurinaModelData = [
  { age: 0, weight: 70.75195871 },
  { age: 0.229984755, weight: 85.36318968 },
  { age: 0.459969511, weight: 99.97442065 },
  { age: 0.689954266, weight: 114.5856516 },
  { age: 0.919939021, weight: 129.1968826 },
  { age: 1.149923776, weight: 143.8081135 },
  { age: 1.379908532, weight: 158.4193445 },
  { age: 1.609893287, weight: 173.0305755 },
  { age: 1.839878042, weight: 187.6418065 },
  { age: 2.069862798, weight: 202.2530374 },
  { age: 2.299847553, weight: 216.8642684 },
  { age: 2.529832308, weight: 231.4754994 },
  { age: 2.759817064, weight: 246.0867303 },
  { age: 2.989801819, weight: 260.6979613 },
  { age: 3.219786574, weight: 275.3091923 },
  { age: 3.449771329, weight: 289.9204232 },
  { age: 3.679756085, weight: 304.5316542 },
  { age: 3.90974084, weight: 319.1428852 },
  { age: 4.139725595, weight: 333.7541161 },
  { age: 4.369710351, weight: 348.3653471 },
  { age: 4.599695106, weight: 362.9765781 },
  { age: 4.829679861, weight: 377.587809 },
  { age: 5.059664617, weight: 392.19904 },
  { age: 5.289649372, weight: 406.810271 },
  { age: 5.519634127, weight: 421.4215019 },
  { age: 5.749618882, weight: 436.0327329 },
  { age: 5.979603638, weight: 450.6439639 },
  { age: 6.209588393, weight: 465.2551948 },
  { age: 6.439573148, weight: 479.8664258 },
  { age: 6.669557904, weight: 494.4776568 },
  { age: 6.899542659, weight: 509.0888877 },
  { age: 7.129527414, weight: 523.7001187 },
  { age: 7.359512169, weight: 538.3113497 },
  { age: 7.589496925, weight: 552.9225806 },
  { age: 7.81948168, weight: 567.5338116 },
  { age: 8.049466435, weight: 582.1450426 },
  { age: 8.279451191, weight: 596.7562735 },
  { age: 8.509435946, weight: 611.3675045 },
  { age: 8.739420701, weight: 625.9787355 },
  { age: 8.969405457, weight: 640.5899665 },
  { age: 9.199390212, weight: 655.2011974 },
  { age: 9.429374967, weight: 669.8124284 },
  { age: 9.659359722, weight: 684.4236594 },
  { age: 9.889344478, weight: 699.0348903 },
  { age: 10.11932923, weight: 713.6461213 },
  { age: 10.34931399, weight: 728.2573523 },
  { age: 10.57929874, weight: 742.8685832 },
  { age: 10.8092835, weight: 757.4798142 },
  { age: 11.03926825, weight: 772.0910452 },
  { age: 11.26925301, weight: 786.7022761 },
  { age: 11.49923776, weight: 801.3135071 },
  { age: 11.72922252, weight: 815.9247381 },
  { age: 11.95920728, weight: 830.535969 },
  { age: 12.18919203, weight: 845.1472 },
  { age: 12.41917679, weight: 859.758431 },
  { age: 12.64916154, weight: 874.3696619 },
  { age: 12.8791463, weight: 888.9808929 },
  { age: 13.10913105, weight: 903.5921239 },
  { age: 13.33911581, weight: 918.2033548 },
  { age: 13.56910056, weight: 932.8145858 },
  { age: 13.79908532, weight: 947.4258168 },
  { age: 14.02907007, weight: 962.0370477 },
  { age: 14.25905483, weight: 976.6482787 },
  { age: 14.48903958, weight: 991.2595097 },
  { age: 14.71902434, weight: 1005.870741 },
  { age: 14.94900909, weight: 1020.481972 },
  { age: 15.17899385, weight: 1035.093203 },
  { age: 15.4089786, weight: 1049.704434 },
  { age: 15.63896336, weight: 1064.315665 },
  { age: 15.86894812, weight: 1078.926895 },
  { age: 16.09893287, weight: 1093.538126 },
  { age: 16.32891763, weight: 1108.149357 },
  { age: 16.55890238, weight: 1122.760588 },
  { age: 16.78888714, weight: 1137.371819 },
  { age: 17.01887189, weight: 1151.98305 },
  { age: 17.24885665, weight: 1166.594281 },
  { age: 17.4788414, weight: 1181.205512 },
  { age: 17.70882616, weight: 1195.816743 },
  { age: 17.93881091, weight: 1210.427974 },
  { age: 18.16879567, weight: 1225.039205 },
  { age: 18.39878042, weight: 1239.650436 },
  { age: 18.62876518, weight: 1254.261667 },
  { age: 18.85874993, weight: 1268.872898 },
  { age: 19.08873469, weight: 1283.484129 },
  { age: 19.31871944, weight: 1298.09536 },
  { age: 19.5487042, weight: 1312.706591 },
  { age: 19.77868896, weight: 1327.317822 },
  { age: 20.00867371, weight: 1341.929053 },
  { age: 20.23865847, weight: 1356.540284 },
  { age: 20.46864322, weight: 1371.151515 },
  { age: 20.69862798, weight: 1385.762746 },
  { age: 20.92861273, weight: 1400.373977 },
  { age: 21.15859749, weight: 1414.985208 },
  { age: 21.38858224, weight: 1429.596439 },
  { age: 21.618567, weight: 1444.20767 },
  { age: 21.84855175, weight: 1458.818901 },
];

// dummy DCHA data points for heifer weight chart
export const heiferWeightChartDchaData = [
  { targetAge: 4, percentStandardWeight: 20 },
  { targetAge: 6, percentStandardWeight: 25 },
  { targetAge: null, percentStandardWeight: 55 },
  { targetAge: 22, percentStandardWeight: 85 },
  { targetAge: null, percentStandardWeight: 100 },
];

// dummy line chart data points for PSU 2013 (heifer height chart)
export const heiferHeightChartPsuData: ChartDataPoints = [
  { age: 0, height: 31.9 },
  { age: 1, height: 33.88166667 },
  { age: 2, height: 35.86333333 },
  { age: 3, height: 37.845 },
  { age: 4, height: 39.82666667 },
  { age: 5, height: 41.80833333 },
  { age: 6, height: 43.79 },
  { age: 7, height: 44.78083333 },
  { age: 8, height: 45.77166667 },
  { age: 9, height: 46.7625 },
  { age: 10, height: 47.75333333 },
  { age: 11, height: 48.74416667 },
  { age: 12, height: 49.735 },
  { age: 13, height: 50.3295 },
  { age: 14, height: 50.924 },
  { age: 15, height: 51.5185 },
  { age: 16, height: 52.113 },
  { age: 17, height: 52.7075 },
  { age: 18, height: 53.302 },
  { age: 19, height: 53.8965 },
  { age: 20, height: 54.491 },
  { age: 21, height: 55.0855 },
  { age: 22, height: 55.68 },
];

// dummy purina model data points for heifer height chart
export const heiferHeightChartPurinaModelData: ChartDataPoints = [
  { age: 0, height: 29.69201675 },
  { age: 0.229984755, height: 30.4456334 },
  { age: 0.459969511, height: 31.18286744 },
  { age: 0.689954266, height: 31.90393222 },
  { age: 0.919939021, height: 32.60904109 },
  { age: 1.149923776, height: 33.29840739 },
  { age: 1.379908532, height: 33.97224446 },
  { age: 1.609893287, height: 34.63076565 },
  { age: 1.839878042, height: 35.2741843 },
  { age: 2.069862798, height: 35.90271375 },
  { age: 2.299847553, height: 36.51656736 },
  { age: 2.529832308, height: 37.11595846 },
  { age: 2.759817064, height: 37.70110039 },
  { age: 2.989801819, height: 38.27220651 },
  { age: 3.219786574, height: 38.82949015 },
  { age: 3.449771329, height: 39.37316467 },
  { age: 3.679756085, height: 39.90344339 },
  { age: 3.90974084, height: 40.42053968 },
  { age: 4.139725595, height: 40.92466686 },
  { age: 4.369710351, height: 41.4160383 },
  { age: 4.599695106, height: 41.89486732 },
  { age: 4.829679861, height: 42.36136727 },
  { age: 5.059664617, height: 42.81575151 },
  { age: 5.289649372, height: 43.25823336 },
  { age: 5.519634127, height: 43.68902619 },
  { age: 5.749618882, height: 44.10834332 },
  { age: 5.979603638, height: 44.51639811 },
  { age: 6.209588393, height: 44.91340389 },
  { age: 6.439573148, height: 45.29957402 },
  { age: 6.669557904, height: 45.67512183 },
  { age: 6.899542659, height: 46.04026068 },
  { age: 7.129527414, height: 46.3952039 },
  { age: 7.359512169, height: 46.74016484 },
  { age: 7.589496925, height: 47.07535684 },
  { age: 7.81948168, height: 47.40099325 },
  { age: 8.049466435, height: 47.71728741 },
  { age: 8.279451191, height: 48.02445266 },
  { age: 8.509435946, height: 48.32270235 },
  { age: 8.739420701, height: 48.61224983 },
  { age: 8.969405457, height: 48.89330843 },
  { age: 9.199390212, height: 49.1660915 },
  { age: 9.429374967, height: 49.43081239 },
  { age: 9.659359722, height: 49.68768444 },
  { age: 9.889344478, height: 49.93692099 },
  { age: 10.11932923, height: 50.17873539 },
  { age: 10.34931399, height: 50.41334098 },
  { age: 10.57929874, height: 50.6409511 },
  { age: 10.8092835, height: 50.86177911 },
  { age: 11.03926825, height: 51.07603834 },
  { age: 11.26925301, height: 51.28394213 },
  { age: 11.49923776, height: 51.48570384 },
  { age: 11.72922252, height: 51.68153681 },
  { age: 11.95920728, height: 51.87165437 },
  { age: 12.18919203, height: 52.05626988 },
  { age: 12.41917679, height: 52.23559667 },
  { age: 12.64916154, height: 52.4098481 },
  { age: 12.8791463, height: 52.5792375 },
  { age: 13.10913105, height: 52.74397822 },
  { age: 13.33911581, height: 52.90428361 },
  { age: 13.56910056, height: 53.06036701 },
  { age: 13.79908532, height: 53.21244175 },
  { age: 14.02907007, height: 53.3607212 },
  { age: 14.25905483, height: 53.50541868 },
  { age: 14.48903958, height: 53.64674755 },
  { age: 14.71902434, height: 53.78492115 },
  { age: 14.94900909, height: 53.92015282 },
  { age: 15.17899385, height: 54.0526559 },
  { age: 15.4089786, height: 54.18264375 },
  { age: 15.63896336, height: 54.3103297 },
  { age: 15.86894812, height: 54.4359271 },
  { age: 16.09893287, height: 54.55964929 },
  { age: 16.32891763, height: 54.68170962 },
  { age: 16.55890238, height: 54.80232143 },
  { age: 16.78888714, height: 54.92169807 },
  { age: 17.01887189, height: 55.04005287 },
  { age: 17.24885665, height: 55.15759919 },
  { age: 17.4788414, height: 55.27455036 },
  { age: 17.70882616, height: 55.39111974 },
  { age: 17.93881091, height: 55.50752066 },
  { age: 18.16879567, height: 55.62396647 },
  { age: 18.39878042, height: 55.74067051 },
  { age: 18.62876518, height: 55.85784613 },
  { age: 18.85874993, height: 55.97570668 },
  { age: 19.08873469, height: 56.09446548 },
  { age: 19.31871944, height: 56.2143359 },
  { age: 19.5487042, height: 56.33553127 },
  { age: 19.77868896, height: 56.45826494 },
  { age: 20.00867371, height: 56.58275025 },
  { age: 20.23865847, height: 56.70920055 },
  { age: 20.46864322, height: 56.83782917 },
  { age: 20.69862798, height: 56.96884947 },
  { age: 20.92861273, height: 57.10247479 },
  { age: 21.15859749, height: 57.23891847 },
  { age: 21.38858224, height: 57.37839386 },
  { age: 21.618567, height: 57.52111429 },
  { age: 21.84855175, height: 57.66729313 },
];

// dummy DCHA data points for heifer height chart
export const heiferHeightChartDchaData = [
  { targetAge: 8, percentStandardHeight: 75 },
  { targetAge: 10, percentStandardHeight: 75 },
  { targetAge: 22, percentStandardHeight: 95 },
  { targetAge: null, percentStandardHeight: 100 },
];

// dummy line chart data points for PercentMatureWeightHeightChart
export const pcMatureWeightHeightChartPurinaData = [
  {
    name: 'Purina Model BW',
    strokeColor: '#BE0001',
    strokeWidth: 3,
    strokeDashArray: 0,
    yAxisId: 0,
    dataKey: 'weight',
    data: [
      { age: 0, weight: 4.421997419 },
      { age: 0.229984755, weight: 5.168474375 },
      { age: 0.459969511, weight: 6.05313875 },
      { age: 0.689954266, weight: 6.937803125 },
      { age: 0.919939021, weight: 7.8224675 },
      { age: 1.149923776, weight: 8.707131875 },
      { age: 1.379908532, weight: 9.59179625 },
      { age: 1.609893287, weight: 10.47646063 },
      { age: 1.839878042, weight: 11.361125 },
      { age: 2.069862798, weight: 12.24578938 },
      { age: 2.299847553, weight: 13.13045375 },
      { age: 2.529832308, weight: 14.01511813 },
      { age: 2.759817064, weight: 14.8997825 },
      { age: 2.989801819, weight: 15.78444688 },
      { age: 3.219786574, weight: 16.66911125 },
      { age: 3.449771329, weight: 17.55377563 },
      { age: 3.679756085, weight: 18.43844 },
      { age: 3.90974084, weight: 19.32310438 },
      { age: 4.139725595, weight: 20.20776875 },
      { age: 4.369710351, weight: 21.09243313 },
      { age: 4.599695106, weight: 21.9770975 },
      { age: 4.829679861, weight: 22.86176188 },
      { age: 5.059664617, weight: 23.74642625 },
      { age: 5.289649372, weight: 24.63109063 },
      { age: 5.519634127, weight: 25.515755 },
      { age: 5.749618882, weight: 26.40041938 },
      { age: 5.979603638, weight: 27.28508375 },
      { age: 6.209588393, weight: 28.16974813 },
      { age: 6.439573148, weight: 29.0544125 },
      { age: 6.669557904, weight: 29.93907688 },
      { age: 6.899542659, weight: 30.82374125 },
      { age: 7.129527414, weight: 31.70840563 },
      { age: 7.359512169, weight: 32.59307 },
      { age: 7.589496925, weight: 33.47773438 },
      { age: 7.81948168, weight: 34.36239875 },
      { age: 8.049466435, weight: 35.24706313 },
      { age: 8.279451191, weight: 36.1317275 },
      { age: 8.509435946, weight: 37.01639188 },
      { age: 8.739420701, weight: 37.90105625 },
      { age: 8.969405457, weight: 38.78572063 },
      { age: 9.199390212, weight: 39.670385 },
      { age: 9.429374967, weight: 40.55504938 },
      { age: 9.659359722, weight: 41.43971375 },
      { age: 9.889344478, weight: 42.32437813 },
      { age: 10.11932923, weight: 43.2090425 },
      { age: 10.34931399, weight: 44.09370688 },
      { age: 10.57929874, weight: 44.97837125 },
      { age: 10.8092835, weight: 45.86303563 },
      { age: 11.03926825, weight: 46.7477 },
      { age: 11.26925301, weight: 47.63236438 },
      { age: 11.49923776, weight: 48.51702875 },
      { age: 11.72922252, weight: 49.40169313 },
      { age: 11.95920728, weight: 50.2863575 },
      { age: 12.18919203, weight: 51.17102188 },
      { age: 12.41917679, weight: 52.05568625 },
      { age: 12.64916154, weight: 52.94035063 },
      { age: 12.8791463, weight: 53.825015 },
      { age: 13.10913105, weight: 54.70967938 },
      { age: 13.33911581, weight: 55.59434375 },
      { age: 13.56910056, weight: 56.47900813 },
      { age: 13.79908532, weight: 57.3636725 },
      { age: 14.02907007, weight: 58.24833688 },
      { age: 14.25905483, weight: 59.13300125 },
      { age: 14.48903958, weight: 60.01766563 },
      { age: 14.71902434, weight: 60.90233 },
      { age: 14.94900909, weight: 61.78699438 },
      { age: 15.17899385, weight: 62.67165875 },
      { age: 15.4089786, weight: 63.55632313 },
      { age: 15.63896336, weight: 64.4409875 },
      { age: 15.86894812, weight: 65.32565188 },
      { age: 16.09893287, weight: 66.21031625 },
      { age: 16.32891763, weight: 67.09498063 },
      { age: 16.55890238, weight: 67.979645 },
      { age: 16.78888714, weight: 68.86430938 },
      { age: 17.01887189, weight: 69.74897375 },
      { age: 17.24885665, weight: 70.63363813 },
      { age: 17.4788414, weight: 71.5183025 },
      { age: 17.70882616, weight: 72.40296688 },
      { age: 17.93881091, weight: 73.28763125 },
      { age: 18.16879567, weight: 74.17229563 },
      { age: 18.39878042, weight: 75.05696 },
      { age: 18.62876518, weight: 75.94162438 },
      { age: 18.85874993, weight: 76.82628875 },
      { age: 19.08873469, weight: 77.71095313 },
      { age: 19.31871944, weight: 78.5956175 },
      { age: 19.5487042, weight: 79.48028188 },
      { age: 19.77868896, weight: 80.36494625 },
      { age: 20.00867371, weight: 81.24961063 },
      { age: 20.23865847, weight: 82.134275 },
      { age: 20.46864322, weight: 83.01893938 },
      { age: 20.69862798, weight: 83.90360375 },
      { age: 20.92861273, weight: 84.78826813 },
      { age: 21.15859749, weight: 85.6729325 },
      { age: 21.38858224, weight: 86.55759688 },
      { age: 21.618567, weight: 87.44226125 },
      { age: 21.84855175, weight: 88.32692563 },
    ],
  },
  {
    name: 'Purina Model Hip Ht',
    strokeColor: '#BE0001',
    strokeWidth: 3,
    strokeDashArray: '15 6',
    yAxisId: 1,
    dataKey: 'height',
    data: [
      { age: 0, height: 52.69362069 },
      { age: 0.229984755, height: 54.03104381 },
      { age: 0.459969511, height: 55.33939317 },
      { age: 0.689954266, height: 56.61904738 },
      { age: 0.919939021, height: 57.87038505 },
      { age: 1.149923776, height: 59.09378481 },
      { age: 1.379908532, height: 60.28962528 },
      { age: 1.609893287, height: 61.45828506 },
      { age: 1.839878042, height: 62.60014277 },
      { age: 2.069862798, height: 63.71557703 },
      { age: 2.299847553, height: 64.80496645 },
      { age: 2.529832308, height: 65.86868965 },
      { age: 2.759817064, height: 66.90712525 },
      { age: 2.989801819, height: 67.92065186 },
      { age: 3.219786574, height: 68.9096481 },
      { age: 3.449771329, height: 69.87449259 },
      { age: 3.679756085, height: 70.81556393 },
      { age: 3.90974084, height: 71.73324075 },
      { age: 4.139725595, height: 72.62790166 },
      { age: 4.369710351, height: 73.49992527 },
      { age: 4.599695106, height: 74.34969022 },
      { age: 4.829679861, height: 75.1775751 },
      { age: 5.059664617, height: 75.98395853 },
      { age: 5.289649372, height: 76.76921914 },
      { age: 5.519634127, height: 77.53373553 },
      { age: 5.749618882, height: 78.27788633 },
      { age: 5.979603638, height: 79.00205015 },
      { age: 6.209588393, height: 79.7066056 },
      { age: 6.439573148, height: 80.3919313 },
      { age: 6.669557904, height: 81.05840587 },
      { age: 6.899542659, height: 81.70640792 },
      { age: 7.129527414, height: 82.33631608 },
      { age: 7.359512169, height: 82.94850894 },
      { age: 7.589496925, height: 83.54336514 },
      { age: 7.81948168, height: 84.12126328 },
      { age: 8.049466435, height: 84.68258199 },
      { age: 8.279451191, height: 85.22769987 },
      { age: 8.509435946, height: 85.75699555 },
      { age: 8.739420701, height: 86.27084764 },
      { age: 8.969405457, height: 86.76963476 },
      { age: 9.199390212, height: 87.25373551 },
      { age: 9.429374967, height: 87.72352853 },
      { age: 9.659359722, height: 88.17939242 },
      { age: 9.889344478, height: 88.6217058 },
      { age: 10.11932923, height: 89.05084729 },
      { age: 10.34931399, height: 89.4671955 },
      { age: 10.57929874, height: 89.87112904 },
      { age: 10.8092835, height: 90.26302654 },
      { age: 11.03926825, height: 90.64326661 },
      { age: 11.26925301, height: 91.01222787 },
      { age: 11.49923776, height: 91.37028892 },
      { age: 11.72922252, height: 91.7178284 },
      { age: 11.95920728, height: 92.0552249 },
      { age: 12.18919203, height: 92.38285706 },
      { age: 12.41917679, height: 92.70110348 },
      { age: 12.64916154, height: 93.01034279 },
      { age: 12.8791463, height: 93.31095359 },
      { age: 13.10913105, height: 93.6033145 },
      { age: 13.33911581, height: 93.88780415 },
      { age: 13.56910056, height: 94.16480114 },
      { age: 13.79908532, height: 94.43468409 },
      { age: 14.02907007, height: 94.69783161 },
      { age: 14.25905483, height: 94.95462233 },
      { age: 14.48903958, height: 95.20543486 },
      { age: 14.71902434, height: 95.45064781 },
      { age: 14.94900909, height: 95.69063981 },
      { age: 15.17899385, height: 95.92578946 },
      { age: 15.4089786, height: 96.15647538 },
      { age: 15.63896336, height: 96.38307619 },
      { age: 15.86894812, height: 96.60597051 },
      { age: 16.09893287, height: 96.82553694 },
      { age: 16.32891763, height: 97.04215411 },
      { age: 16.55890238, height: 97.25620064 },
      { age: 16.78888714, height: 97.46805513 },
      { age: 17.01887189, height: 97.67809621 },
      { age: 17.24885665, height: 97.88670248 },
      { age: 17.4788414, height: 98.09425258 },
      { age: 17.70882616, height: 98.3011251 },
      { age: 17.93881091, height: 98.50769867 },
      { age: 18.16879567, height: 98.71435191 },
      { age: 18.39878042, height: 98.92146343 },
      { age: 18.62876518, height: 99.12941184 },
      { age: 18.85874993, height: 99.33857576 },
      { age: 19.08873469, height: 99.54933381 },
      { age: 19.31871944, height: 99.76206461 },
      { age: 19.5487042, height: 99.97714676 },
      { age: 19.77868896, height: 100.1949589 },
      { age: 20.00867371, height: 100.4158796 },
      { age: 20.23865847, height: 100.6402875 },
      { age: 20.46864322, height: 100.8685613 },
      { age: 20.69862798, height: 101.1010795 },
      { age: 20.92861273, height: 101.3382207 },
      { age: 21.15859749, height: 101.5803636 },
      { age: 21.38858224, height: 101.8278868 },
      { age: 21.618567, height: 102.0811689 },
      { age: 21.84855175, height: 102.3405886 },
    ],
  },
];

// % of mature weight-height chart PSU 2013 body weight and hip height data points
export const pcMatureWeightHeightChartPsuData: LineChartDataType[] = [
  {
    name: 'PSU 2013 BW',
    strokeColor: '#4E5F72',
    strokeWidth: 1.8,
    strokeDashArray: 0,
    yAxisId: 0,
    dataKey: 'weight',
    data: [
      { age: 0, weight: 5.625 },
      { age: 1, weight: 8.4375 },
      { age: 2, weight: 11.25 },
      { age: 3, weight: 15.30069722 },
      { age: 4, weight: 19.35139443 },
      { age: 5, weight: 23.40209165 },
      { age: 6, weight: 27.45278886 },
      { age: 7, weight: 31.50348608 },
      { age: 8, weight: 35.5541833 },
      { age: 9, weight: 39.60488051 },
      { age: 10, weight: 43.65557773 },
      { age: 11, weight: 47.70627494 },
      { age: 12, weight: 51.75697216 },
      { age: 13, weight: 56.01262108 },
      { age: 14, weight: 60.26827 },
      { age: 15, weight: 64.52391891 },
      { age: 16, weight: 68.77956783 },
      { age: 17, weight: 73.03521675 },
      { age: 18, weight: 77.29086567 },
      { age: 19, weight: 81.54651459 },
      { age: 20, weight: 85.8021635 },
      { age: 21, weight: 90.05781242 },
      { age: 22, weight: 94.31346134 },
    ],
  },
  {
    name: 'PSU 2013 Hip Ht',
    strokeColor: '#4E5F72',
    strokeWidth: 1.8,
    strokeDashArray: '15 12',
    yAxisId: 1,
    dataKey: 'height',
    data: [
      { age: 0, height: 55 },
      { age: 1, height: 58.41666667 },
      { age: 2, height: 61.83333333 },
      { age: 3, height: 65.25 },
      { age: 4, height: 68.66666667 },
      { age: 5, height: 72.08333333 },
      { age: 6, height: 75.5 },
      { age: 7, height: 77.20833333 },
      { age: 8, height: 78.91666667 },
      { age: 9, height: 80.625 },
      { age: 10, height: 82.33333333 },
      { age: 11, height: 84.04166667 },
      { age: 12, height: 85.75 },
      { age: 13, height: 86.775 },
      { age: 14, height: 87.8 },
      { age: 15, height: 88.825 },
      { age: 16, height: 89.85 },
      { age: 17, height: 90.875 },
      { age: 18, height: 91.9 },
      { age: 19, height: 92.925 },
      { age: 20, height: 93.95 },
      { age: 21, height: 94.975 },
      { age: 22, height: 96 },
    ],
  },
];

// dummy scatter chart data for PercentMatureWeightHeight chart
export const pcMatureWeightHeightChartSubHerdHeifersData: Omit<ScatterChartDataType, 'data'>[] = [
  {
    // sub herd is replaced with current
    legendName: 'Current Heifers BW',
    fillColor: '#000',
    legendType: 'circle',
    dataKey: 'weight',
    shape: <circle cx={0} cy={0} r={3} stroke="black" fill="#000" strokeWidth={0.5} />,
    errBarDataKey: 'weightErrLimit',
  },
  {
    // sub herd is replaced with current
    legendName: 'Current Heifers Hip Ht',
    fillColor: '#1075e6',
    legendType: 'circle',
    dataKey: 'height',
    shape: <circle cx={0} cy={0} r={3} fill="#1075e6" strokeWidth={0.5} />,
    errBarDataKey: 'heightErrLimit',
  },
];

// % of mature weight-height chart historical sub herd body weight and hip height data points
export const pcMatureWeightHeightChartHistoricalData: Omit<ScatterChartDataType, 'data'>[] = [
  {
    // Sub-herd Information is replaced with heifers
    legendName: 'Historical Heifers BW',
    legendType: 'triangle',
    dataKey: 'weight',
    shape: <ScatterChartTriangleShape fillColor="#000" cx={0} cy={0} />,
    fillColor: '#000',
    strokeColor: '',
    errBarDataKey: 'weightErrLimit',
  },
  {
    // Sub-herd Information is replaced with heifers
    legendName: 'Historical Heifers Hip Ht',
    legendType: 'triangle',
    dataKey: 'height',
    shape: <ScatterChartTriangleShape fillColor="#1075e6" cx={0} cy={0} />,
    fillColor: '#1075e6',
    strokeColor: '#1075e6',
    errBarDataKey: 'heightErrLimit',
  },
];

// dummy DCHA data for percent matute weight height chart DCHA data
export const pcMatureWeightHeightDchaData = [
  { percentStandardWeight: 20, percentStandardHeight: 75 },
  { percentStandardWeight: 25, percentStandardHeight: 95 },
  { percentStandardWeight: 55, percentStandardHeight: 100 },
  { percentStandardWeight: 85, percentStandardHeight: undefined },
  { percentStandardWeight: 100, percentStandardHeight: undefined },
];

// age interval data options to render radio buttons
export const ageIntervalOptions: number[] = [4, 8, 22];
// bulk add heifer data download URL
export const bulkAddHeiferMeasurementFileDownloadUrl =
  'https://eumentis-cloud-public.s3.ap-south-1.amazonaws.com/bulkAddHeiferData.xlsx';

// bulk add heifer download URL
export const bulkAddHeiferDownloadUrl =
  'https://eumentis-cloud-public.s3.ap-south-1.amazonaws.com/bulkAddHeifer.xlsx';

// amount of error present for each heifer height data point
export const heiferHeightErrRatio = 0.03;
// amount of error present for each heifer weight data point
export const heiferWeightErrRatio = 0.09;
// stores the color of error range in purina model
export const purinaErrorRangeColor = '#F9FFC3';
// stores the color of error range  stroke in purina model
export const purinaErrorRangeStrokeColor = '#FFFB02';
// date format to store date
export const dateFormat = 'YYYY-MM-DD';
// date format for display
export const dateFormatForDisplay = 'MM/DD/YYYY';
// dash date format used
export const dashDateFormat = 'MM-DD-YYYY';

export default null;
