import React, { BaseSyntheticEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Row, Col, Input, message } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { FirebaseError } from 'firebase';
import Heading from '../components/Heading';
import ECFormItem from '../components/ECFormItem';
import formStyles from '../forms/SignInForm.module.scss';
import firebase, { firebaseAuth } from '../utils/firebase';
import { logger } from '../utils/helpers';

// modify current password form type
type ModifyPasswordForm = {
  // password with which app is logged in currently
  currentPassword: string;
  // new password to modify current one
  newPassword: string;
  // confirm password for cross-verifying new password
  confirmNewPassword: string;
};

// functional component
const SettingsScreen: React.FC = () => {
  // form methods
  const methods = useForm<ModifyPasswordForm>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });
  // saving whether to show/hide confirm new password when suffix icon is clicked
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState<boolean>(true);
  // saving whether to show/hide save btn loading indicator
  const [showBtnLoader, setShowBtnLoader] = useState<boolean>(false);

  // handling form submission
  const onSubmit = (formData: ModifyPasswordForm, event: BaseSyntheticEvent | undefined) => {
    if (event) {
      event.preventDefault();
    }
    setShowBtnLoader(true);
    // accessing currentUser methods and props
    const { currentUser } = firebaseAuth;
    if (currentUser && currentUser.email) {
      // storing auth credentials
      const credentials = firebase.auth.EmailAuthProvider.credential(
        currentUser.email,
        formData.currentPassword,
      );
      currentUser
        .reauthenticateWithCredential(credentials)
        .then((): void => {
          currentUser
            .updatePassword(formData.newPassword)
            .then((): void => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.success('Password has been successfully updated');
              setShowBtnLoader(false);
              methods.reset();
            })
            .catch((updatePasswordErr: FirebaseError): void => {
              logger(updatePasswordErr);
              methods.reset();
              setShowBtnLoader(false);
            });
        })
        .catch((error: FirebaseError) => {
          setShowBtnLoader(false);
          if (error.code === 'auth/wrong-password') {
            methods.setError('currentPassword', {
              type: 'manual',
              message: 'The Current Password you have entered is incorrect',
            });
          }
        });
    }
  };

  return (
    <div style={{ margin: '0 34px', overflowX: 'hidden' }}>
      <Heading headingName="Modify Password" renderHrLine />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ECFormItem
          rules={{ required: 'Please enter current password and try again' }}
          textAlign="end"
          labelSpan={4}
          wrapperSpan={20}
          label="Current Password"
          name="currentPassword"
          control={methods.control}
          render={({ onChange, value }) => (
            <Input.Password
              value={value as string}
              onChange={(val) => {
                onChange(val);
              }}
              className={formStyles.settingsInputField}
              style={{ width: 300 }}
              placeholder="Please enter current password"
            />
          )}
          errors={
            methods.errors.currentPassword ? (
              <span style={{ color: '#ce0e2d' }}>{methods.errors.currentPassword.message}</span>
            ) : null
          }
        />
        <ECFormItem
          rules={{
            required: 'Please enter new password and try again',
            minLength: {
              value: 6,
              message: 'Password must be atleast 6 characters long',
            },
          }}
          textAlign="end"
          labelSpan={4}
          wrapperSpan={20}
          label="New Password"
          name="newPassword"
          control={methods.control}
          render={({ onChange, value }) => (
            <Input.Password
              value={value as string}
              onChange={(val) => {
                onChange(val);
              }}
              className={formStyles.settingsInputField}
              style={{ width: 300 }}
              placeholder="Please enter new password"
            />
          )}
          errors={
            methods.errors.newPassword ? (
              <span style={{ color: '#ce0e2d' }}>{methods.errors.newPassword.message}</span>
            ) : null
          }
        />
        <ECFormItem
          rules={{
            required: 'Please enter confirm new password and try again',
            validate: (value: string): string | boolean =>
              value !== methods.watch('newPassword')
                ? 'The entered passwords do not match. Please check and try again'
                : true,
          }}
          textAlign="end"
          labelSpan={4}
          wrapperSpan={20}
          label="New Confirm Password"
          name="confirmNewPassword"
          control={methods.control}
          render={({ onChange, value }) => (
            <Input
              type={showConfirmNewPassword ? 'text' : 'password'}
              placeholder="Please re-enter password to confirm"
              suffix={
                <Button
                  type="link"
                  style={{ padding: 0, margin: 0, height: 'inherit' }}
                  onClick={(): void => {
                    setShowConfirmNewPassword(!showConfirmNewPassword);
                  }}
                >
                  {showConfirmNewPassword ? (
                    <EyeOutlined className={formStyles.customSuffixClick} />
                  ) : (
                    <EyeInvisibleOutlined className={formStyles.customSuffixClick} />
                  )}
                </Button>
              }
              value={value as string}
              onChange={(val): void => {
                onChange(val);
              }}
              className={formStyles.settingsInputField}
              style={{ width: 300 }}
            />
          )}
          errors={
            methods.errors.confirmNewPassword ? (
              <span style={{ color: '#ce0e2d' }}>{methods.errors.confirmNewPassword.message}</span>
            ) : null
          }
        />
        <Row style={{ marginTop: 40 }}>
          <Col offset={4}>
            <Button
              loading={showBtnLoader}
              htmlType="submit"
              style={{ padding: '0 34px', alignItems: 'center', display: 'flex' }}
              type="primary"
              className="primaryBtn"
            >
              Save
            </Button>
          </Col>
          <Col offset={2}>
            <Button
              className="secondaryBtn"
              htmlType="reset"
              onClick={(): void => {
                methods.reset();
              }}
              style={{
                padding: '0 26px',
                border: '1px solid #17A697',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default SettingsScreen;
