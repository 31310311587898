import React from 'react';
import { Checkbox } from 'antd';
import { Control, Controller } from 'react-hook-form';
import { GenerateReportForm } from '../utils/types';

// component props
type Props = {
  // form name
  formName: string;
  // form control prop
  control: Control<GenerateReportForm>;
  // checkbox label
  label: string;
};

const ReportSectionHeader = ({ control, formName, label }: Props): JSX.Element => (
  <Controller
    name={formName}
    control={control}
    style={{ fontSize: 22, color: '#000' }}
    render={({ onChange, value }): JSX.Element => {
      return (
        <Checkbox
          checked={value as boolean}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          style={{ fontSize: 18 }}
        >
          {label}
        </Checkbox>
      );
    }}
  />
);

export default ReportSectionHeader;
