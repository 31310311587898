import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import UsersScreen from './screens/UsersScreen';
import { Enum_User_Role_Enum } from './graphql/graphql-types';
import ListOfAllUsers from './components/ListOfAllUsers';
import UserForm from './forms/UserForm';
import DataMeasurmentScreen from './screens/DataMeasurmentScreen';
import AnalyzeScreen from './screens/AnalyzeScreen';
import SettingsScreen from './screens/SettingsScreen';
import { useApp } from './context/AppContext';

// This is the main functional component
const AppRoutes: React.FC = () => {
  /* destructing user data from context */
  const { user } = useApp();

  /* const used to store role of the user */
  const userRole = user?.role;

  return (
    <BrowserRouter>
      <Header roleOfUser={userRole} />
      <Routes>
        <Route path="/" element={<Navigate to="/analyze" />} />
        <Route path="/analyze" element={<AnalyzeScreen />} />
        <Route path="/data" element={<DataMeasurmentScreen />} />
        {userRole === Enum_User_Role_Enum.AppAdmin ? (
          <Route path="/users/*" element={<UsersScreen />}>
            <Route path="/" element={<ListOfAllUsers />} />
            <Route
              path="create"
              element={<UserForm mode="add" padding_left={100} padding_top={40} />}
            />
          </Route>
        ) : null}
        <Route path="/settings" element={<SettingsScreen />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
