import React, { BaseSyntheticEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EyeOutlined, EyeInvisibleOutlined, LockFilled } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { FirebaseError } from 'firebase';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import {
  SetUserFirstLoginMutation,
  SetUserFirstLoginMutationVariables,
} from '../graphql/graphql-types';
import ECFormItem from '../components/ECFormItem';
import { firebaseAuth } from '../utils/firebase';
import { logger } from '../utils/helpers';
import styles from './SignInScreen.module.scss';
import formStyles from '../forms/SignInForm.module.scss';
import { useApp } from '../context/AppContext';

/* set new password screen prop types */
type SetNewPasswordScreenPropType = {
  /* this set state method used to manage 'Set new password' Screen's visibility */
  setShowNewPasswordScreen: React.Dispatch<React.SetStateAction<boolean>>;
};

// modify current password form type
type ModifyPasswordFormType = {
  // new password to modify current one
  newPassword: string;
  // confirm password for cross-verifying new password
  confirmNewPassword: string;
};

/* ECFormComponent style props  */
const formItemStyleProps = {
  /* this prop used to store wrapper span */
  wrapperSpan: 20,
  /* custom style of ECFormItem component */
  customStyle: { width: '100%', justifyContent: 'center' } as React.CSSProperties,
};

/* loading set user first login mutation, using loader */
const setUserFirstLoginMutation = loader('../graphql/mutations/setUserFirstLoginMutation.graphql');

/* react functional component */
const SetNewPasswordScreen = ({
  setShowNewPasswordScreen,
}: SetNewPasswordScreenPropType): JSX.Element => {
  /* destructuring user data & setUser method from context */
  const { user, setUser } = useApp();

  // saving whether to show/hide confirm new password when suffix icon is clicked
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState<boolean>(true);

  // saving whether to show/hide save btn loading indicator
  const [showBtnLoader, setShowBtnLoader] = useState<boolean>(false);

  // form methods
  const methods = useForm<ModifyPasswordFormType>({
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  /* set user first login mutation */
  const [setUserFirstLogin] = useMutation<
    SetUserFirstLoginMutation,
    SetUserFirstLoginMutationVariables
  >(setUserFirstLoginMutation);

  // handling form submission
  const onSubmit = (formData: ModifyPasswordFormType, event: BaseSyntheticEvent | undefined) => {
    if (event) {
      event.preventDefault();
    }

    // accessing currentUser methods and props
    const { currentUser } = firebaseAuth;

    if (currentUser) {
      setShowBtnLoader(true);
      currentUser
        .updatePassword(formData.newPassword)
        .then(() => {
          if (user && user.id) {
            setUserFirstLogin({ variables: { id: user.id } })
              .then((res) => {
                /* const used to store first login time of the user */
                const firstLoginAt = res.data?.update_user_by_pk?.first_login_at;
                if (firstLoginAt) {
                  /* update that first login time into context */
                  setUser({ ...user, first_login_at: firstLoginAt });
                  setShowBtnLoader(false);
                  setShowNewPasswordScreen(false);
                }
                methods.reset();
              })
              .catch((err) => {
                logger(err);
                setShowBtnLoader(false);
              });
          }
        })
        .catch((error: FirebaseError) => {
          logger(error);
          setShowBtnLoader(false);
          if (error.code === 'auth/requires-recent-login') {
            firebaseAuth
              .signOut()
              .then((): void => {
                /* resetting user context */
                setUser(undefined);
                setShowNewPasswordScreen(false);
              })
              .catch((firebaseAuthError) => {
                logger(firebaseAuthError);
              });
          }
        });
    }
  };

  return (
    <div className={styles.signInContainer}>
      <div className={formStyles.formBorder}>
        <h1 className={formStyles.signInHeading}>Dairy Heifer Growth Tracker</h1>
        <h1 className={formStyles.signInHeading} style={{ paddingBottom: 15 }}>
          Update Password
        </h1>
        <h3
          style={{
            fontWeight: 400,
            textAlign: 'center',
            border: '1px solid black',
            backgroundColor: '#cccccc',
            marginBottom: 10,
          }}
        >
          This is your first login. Please set a new password.
        </h3>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            rowGap: 15,
          }}
        >
          <ECFormItem
            rules={{
              required: 'Please enter new password and try again',
              minLength: {
                value: 6,
                message: 'Password must be atleast 6 characters long',
              },
            }}
            name="newPassword"
            control={methods.control}
            {...formItemStyleProps}
            render={({ onChange, value }) => (
              <Input.Password
                value={value as string}
                onChange={(val) => {
                  onChange(val);
                }}
                prefix={<LockFilled style={{ color: '#c7bfbf', marginRight: 10 }} />}
                className={formStyles.settingsInputField}
                style={{ width: '100%' }}
                placeholder="Please enter your new password"
              />
            )}
            errors={
              methods.errors.newPassword ? (
                <span style={{ color: '#ce0e2d' }}>{methods.errors.newPassword.message}</span>
              ) : null
            }
          />
          <ECFormItem
            rules={{
              required: 'Please enter confirm new password and try again',
              validate: (value: string): string | boolean =>
                value !== methods.watch('newPassword')
                  ? 'Passwords do not match. Please try again'
                  : true,
            }}
            name="confirmNewPassword"
            control={methods.control}
            {...formItemStyleProps}
            render={({ onChange, value }) => (
              <Input
                type={showConfirmNewPassword ? 'text' : 'password'}
                placeholder="confirm your new password"
                prefix={<LockFilled style={{ color: '#c7bfbf', marginRight: 10 }} />}
                suffix={
                  <Button
                    type="link"
                    style={{ padding: 0, margin: 0, height: 'inherit' }}
                    onClick={(): void => {
                      setShowConfirmNewPassword(!showConfirmNewPassword);
                    }}
                  >
                    {showConfirmNewPassword ? (
                      <EyeOutlined className={formStyles.customSuffixClick} />
                    ) : (
                      <EyeInvisibleOutlined className={formStyles.customSuffixClick} />
                    )}
                  </Button>
                }
                value={value as string}
                onChange={(val): void => {
                  onChange(val);
                }}
                className={formStyles.settingsInputField}
                style={{ width: '100%' }}
              />
            )}
            errors={
              methods.errors.confirmNewPassword ? (
                <span style={{ color: '#ce0e2d' }}>
                  {methods.errors.confirmNewPassword.message}
                </span>
              ) : null
            }
          />
          <Button
            className={
              methods.watch('newPassword') === '' || methods.watch('confirmNewPassword') === ''
                ? formStyles.buttonColorDisabled
                : formStyles.updateBtn
            }
            loading={showBtnLoader}
            htmlType="submit"
            style={{
              width: 150,
              height: 50,
              fontSize: 20,
              marginTop: '30px',
              marginBottom: '20px',
            }}
            type="primary"
            disabled={
              methods.watch('newPassword') === '' || methods.watch('confirmNewPassword') === ''
            }
          >
            Update
          </Button>
        </form>
      </div>
    </div>
  );
};

export default SetNewPasswordScreen;
