import React, { useState } from 'react';
import { Button, Space, Table, message } from 'antd';
import moment from 'moment-timezone';
import { loader } from 'graphql.macro';
import { Reference, useMutation, useQuery } from '@apollo/client';
import InfoBar from './InfoBar';
import { BulkHeiferMeasurementDataSheetType } from '../utils/types';
import RequiredAlert from './RequiredAlert';
import heiferDataCalculations from '../utils/heiferDataCalculations';
import {
  BulkAddHeiferMeasurementsMutation,
  BulkAddHeiferMeasurementsMutationVariables,
  BulkAddHeifersAndMeasurementsMutation,
  BulkAddHeifersAndMeasurementsMutationVariables,
  Heifer_Insert_Input,
  Heifer_Measurements_Insert_Input,
  HeifersOfHerdQuery,
  HeifersOfHerdQueryVariables,
  PensOfFarmQuery,
  PensOfFarmQueryVariables,
  AddPensMutation,
  AddPensMutationVariables,
  Pen,
} from '../graphql/graphql-types';
import { checkIsDateValid, logger, renderRemarks, renderWarningMessage } from '../utils/helpers';
import { dashDateFormat, dateFormat, dateFormatForDisplay } from '../utils/globals';

const bulkAddHeiferMeasurementsMutation = loader(
  '../graphql/mutations/bulkAddHeiferMeasurementsMutation.graphql',
);
const bulkAddHeifersAndMeasurementsMutation = loader(
  '../graphql/mutations/bulkAddHeifersAndMeasurementsMutation.graphql',
);
const heifersOfSubHerdQuery = loader('../graphql/queries/heifersOfHerdQuery.graphql');
const pensOfHerdQuery = loader('../graphql/queries/pensOfFarmQuery.graphql');
const addPensMutation = loader('../graphql//mutations/addPensMutation.graphql');

// type def for component props
type BulkHeiferDataSheetPreviewProps = {
  // name of uploaded file
  uploadedFileName: string;
  // saving uploaded sheet data as json array to render as table data
  sheetDataToRender: BulkHeiferMeasurementDataSheetType[];
  // setState function to set sheet data
  setSheetDataToRender: React.Dispatch<React.SetStateAction<BulkHeiferMeasurementDataSheetType[]>>;
  // state to identify any sheet errors
  heiferSheetHasError: boolean;
  // setState fn to set any bulk heifer measurements error
  setHeiferSheetHasError: React.Dispatch<React.SetStateAction<boolean>>;
  // setState fn to set uploaded file name
  setUploadedFileName: React.Dispatch<React.SetStateAction<string>>;
  // getting average body weight of 3rd lactation cow
  avgBodyWeight?: number | null;
  // getting average height of 3rd lactation cow
  avgHeight?: number | null;
  // sub herd id of selected herd
  selectedSubHerdId: string;
  // selected herd id
  selectedHerdId: number;
  // setState fn to toggle visibility
  setShowBulkAddDataModal: React.Dispatch<React.SetStateAction<boolean>>;
};

// functional component
const BulkHeiferDataSheetPreview: React.FC<BulkHeiferDataSheetPreviewProps> = ({
  setSheetDataToRender,
  uploadedFileName,
  sheetDataToRender,
  heiferSheetHasError,
  setHeiferSheetHasError,
  setUploadedFileName,
  avgBodyWeight = null,
  avgHeight = null,
  selectedSubHerdId,
  selectedHerdId,
  setShowBulkAddDataModal,
}) => {
  // saving add btn loading state
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  // mutation to add bulk heifer measurements for existing heifers
  const [bulkAddHeiferMeasurements] = useMutation<
    BulkAddHeiferMeasurementsMutation,
    BulkAddHeiferMeasurementsMutationVariables
  >(bulkAddHeiferMeasurementsMutation);
  // mutation to add new heifers and respective measurements data
  const [bulkAddHeifersAndMeasurements] = useMutation<
    BulkAddHeifersAndMeasurementsMutation,
    BulkAddHeifersAndMeasurementsMutationVariables
  >(bulkAddHeifersAndMeasurementsMutation);

  // used to get the pens of farm data
  const result = useQuery<PensOfFarmQuery, PensOfFarmQueryVariables>(pensOfHerdQuery, {
    variables: {
      farm_id: selectedHerdId,
    },
    fetchPolicy: 'network-only',
  });

  // used to add new pens
  const [addPens] = useMutation<AddPensMutation, AddPensMutationVariables>(addPensMutation);

  // executing and fetching heifers of selected sub herd
  useQuery<HeifersOfHerdQuery, HeifersOfHerdQueryVariables>(heifersOfSubHerdQuery, {
    variables: {
      herd_id: selectedSubHerdId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // storing heifers data
      const heifersOfSubHerdData = data.heifer;

      // storing final bulk heifer sheet data with calculated entries
      const finalSheetData: BulkHeiferMeasurementDataSheetType[] = [];
      // storing calculated remarks value based on pens data and heifers data
      let remarksToRender: string;

      // iterating over each entry and storing calculated entries
      sheetDataToRender.forEach((item) => {
        // stores whether heiferDob format is separated by dash or not
        const isHeiferDobDashFormat = !!(
          item.heiferDob && (item.heiferDob as string).includes('-')
        );
        // stores whether measurement date format is separated by dash or not
        const isMeasurementDateDashFormat = !!(
          item.measurement_date && (item.measurement_date as string).includes('-')
        );
        // storing heifer's dob moment
        const sheetHeiferDob = moment(
          item.heiferDob,
          isHeiferDobDashFormat ? dashDateFormat : dateFormatForDisplay,
        );
        // heifer's date of measurement
        const sheetDoM = moment(
          item.measurement_date,
          isMeasurementDateDashFormat ? dashDateFormat : dateFormatForDisplay,
        );
        // checks if heifer dob  is valid date
        const isHeiferDobValid = item.heiferDob
          ? checkIsDateValid(item.heiferDob as string)
          : false;
        // checks if measurement date  is valid date
        const isMeasurementDateValid = item.measurement_date
          ? checkIsDateValid(item.measurement_date as string)
          : false;

        // whether measurement date is ahead of heifer's dob
        const isDoMAhead = sheetHeiferDob.isSameOrBefore(sheetDoM, 'day');

        // storing whether a heifer's name and dob is present in heifersData of a selected sub herd
        const isHeiferMatched =
          !Array.isArray(heifersOfSubHerdData) || heifersOfSubHerdData.length === 0
            ? false
            : heifersOfSubHerdData.some((heifer) => {
                return (
                  heifer.name === item.heiferName &&
                  moment(heifer.dob, dateFormat).isSame(sheetHeiferDob, 'day')
                );
              });
        if (!isHeiferDobValid) {
          remarksToRender = 'Invalid heifer dob';
        } else if (!isMeasurementDateValid) {
          remarksToRender = 'Invalid date of measurement';
        } else if (
          isHeiferMatched ||
          (isHeiferMatched && item.penName) ||
          (isHeiferMatched && item.body_weight && !item.height) ||
          (isHeiferMatched && !item.body_weight && item.height)
        ) {
          remarksToRender = 'Heifer found in system. New measurement will be added.';
        } else if (item.penName) {
          remarksToRender = 'New heifer and measurement will be added.';
        } else if (!item.penName) {
          remarksToRender = 'Error: Pen Id is required';
        } else if (!item.heiferName || !item.heiferDob || !item.measurement_date) {
          remarksToRender = '-';
        } else if (
          (isHeiferMatched && !isDoMAhead) ||
          (!isHeiferMatched && item.penName && !isDoMAhead)
        ) {
          remarksToRender = 'Error: DoM cannot be earlier than DoB';
        } else if (!item.body_weight && !item.height) {
          remarksToRender = 'Error: Enter at least 1 of Body Weight or Height';
        } else {
          remarksToRender = '-';
        }
        // checking whether heifer name in sheet matches with heifers present in db and storing that matched heifer data
        const matchedHeiferData = heifersOfSubHerdData.find((entry) => {
          return entry.name === item.heiferName;
        });
        // storing calculated heifer data for each heifer entry in sheet
        const {
          measurement_date_age,
          pc_mature_height,
          pc_mature_body_weight,
        } = heiferDataCalculations(
          {
            measurement_date: item.measurement_date ? sheetDoM : null,
            body_weight: item.body_weight || item.body_weight === 0 ? item.body_weight : null,
            height: item.height || item.height === 0 ? item.height : null,
          },
          item.heiferDob ? sheetHeiferDob.format(dateFormat) : '',
          avgBodyWeight,
          avgHeight,
        );
        finalSheetData.push({
          ...item,
          measurement_date_age: measurement_date_age.value,
          pcMatureBodyWeight: pc_mature_body_weight.value,
          pcMatureHeight: pc_mature_height.value,
          remarks: remarksToRender,
          heifer_id: matchedHeiferData ? matchedHeiferData.id : item.heiferName,
          pen_id: item.penName,
        });
      });
      setSheetDataToRender(finalSheetData);
    },
  });

  const handleAddBulkHeiferData = async () => {
    setIsBtnLoading(true);
    // values for adding measurements data for existing heifers
    const existingHeifersMutationValues: Heifer_Measurements_Insert_Input[] = [];
    // unique heifer values for adding new heifers and their measurements data
    const uniqueNewHeiferValues: Heifer_Insert_Input | Heifer_Insert_Input[] = [];
    // new heifer and its measurements other than unique new heifers
    const restNewHeiferValues: Array<
      {
        // heifer alias
        name: string | null;
        // heifer's date of birth
        dob: string | Date | null;
      } & Heifer_Measurements_Insert_Input
    > = [];
    // stores the value of new pen added in sheet
    const newPensAdded: string[] = [];

    // stores the value of existing pen data
    const existingPensData =
      result.data && Array.isArray(result.data.pen) && result.data.pen.length > 0
        ? result.data.pen
        : [];

    for (let i = 0; i < sheetDataToRender.length; i++) {
      const {
        heifer_id,
        heiferName,
        heiferDob,
        pen_id,
        body_weight,
        height,
        measurement_date,
        measurement_date_age,
        remarks,
        penName,
      } = sheetDataToRender[i];

      // common mutation values for new and existing heifers
      const commonMutationValues = {
        herd_id: selectedSubHerdId,
        body_weight: body_weight || body_weight === 0 ? body_weight : null,
        height: height || height === 0 ? height : null,
        measurement_date: moment(
          measurement_date,
          (measurement_date as string).includes('-') ? dashDateFormat : dateFormatForDisplay,
        ).format(dateFormat),
        measurement_date_age,
        mature_height: avgHeight,
        mature_body_weight: avgBodyWeight,
      };

      // checking whether pen already exist
      const matchedPenInDb = existingPensData.find((pen) =>
        penName ? pen.name.toLowerCase() === penName.toLowerCase() : undefined,
      );

      // if pen is not present in db its stores in newPensAdded
      if (!matchedPenInDb && penName) {
        // checks if pen is already present in array
        const isPenAlreadyPresent = newPensAdded.find(
          (pen) => pen.toLowerCase() === penName.toLowerCase(),
        );
        if (!isPenAlreadyPresent) {
          newPensAdded.push(penName);
        }
      }

      // checking for measurements for existing heifers and storing respective data
      if (remarks === 'Heifer found in system. New measurement will be added.') {
        existingHeifersMutationValues.push({
          heifer_id,
          ...commonMutationValues,
          pen_id,
        });
      }
      // stores the date format for heifer dob
      const dateFormatOfHeiferDob = (heiferDob as string).includes('-')
        ? dashDateFormat
        : dateFormatForDisplay;

      // checking if heifer is new and storing heifer and measurements data to add into system
      if (remarks === 'New heifer and measurement will be added.') {
        // checks whether multiple measurements of same unique new heifer is added
        const hasIdenticalHeiferCombo = uniqueNewHeiferValues.some(
          (item) =>
            item.name === heiferName &&
            moment(item.dob, dateFormat).isSame(moment(heiferDob, dateFormatOfHeiferDob), 'day'),
        );
        if (hasIdenticalHeiferCombo) {
          restNewHeiferValues.push({
            name: heiferName,
            dob: moment(heiferDob, dateFormatOfHeiferDob).format(dateFormat),
            ...commonMutationValues,
            pen_id,
          });
        } else {
          uniqueNewHeiferValues.push({
            name: heiferName,
            pen_id,
            dob: moment(heiferDob, dateFormatOfHeiferDob).format(dateFormat),
            herd_id: selectedSubHerdId,
            heifer_measurements: {
              data: [{ ...commonMutationValues, pen_id }],
            },
          });
        }
      }
    }
    // used to store new pen data
    let newPensData: Pick<Pen, 'farm_id' | 'id' | 'name'>[] | undefined;

    try {
      if (newPensAdded.length > 0) {
        // adds new pens
        const addPensData = await addPens({
          variables: {
            objects: newPensAdded.map((pen) => {
              return { name: pen, farm_id: selectedHerdId };
            }),
          },
          update: (cache, cacheData) => {
            // stores the value of data obtained from cache
            const dataToAdd = cacheData.data?.insert_pen;
            cache.modify({
              fields: {
                pen(existingPensRef: Array<Reference>) {
                  return [...existingPensRef, dataToAdd];
                },
              },
            });
          },
        });
        // stores the value obtained from mutation
        newPensData =
          addPensData.data && addPensData.data.insert_pen && addPensData.data.insert_pen.returning
            ? addPensData.data.insert_pen.returning
            : undefined;
      }

      // stores the values to pass in mutation for bulk add heifer measurements
      const existingHeifersValuesToPass = existingHeifersMutationValues.map((item) => {
        // stores the value of pen id
        const penId = item && item.pen_id;
        // checks if pen is present in new pen Data and obtains data
        const newPenData =
          Array.isArray(newPensData) && newPensData.length > 0
            ? newPensData.find((pen) => {
                return pen.name.toLowerCase() === item.pen_id && item.pen_id.toLowerCase();
              })
            : undefined;

        // checks if pen is present in existing pen Data and obtains data
        const existingPenData = penId
          ? existingPensData.find((pen) => pen.name.toLowerCase() === penId.toLowerCase())
          : undefined;

        // stores the value of pen id to pass in mutation
        let penIdToSet;

        if (existingPenData) {
          penIdToSet = existingPenData.id;
        } else if (newPenData) {
          penIdToSet = newPenData.id;
        }

        return {
          ...item,
          pen_id: penIdToSet,
        };
      });

      if (existingHeifersValuesToPass.length > 0) {
        // checking if measurements data is present to trigger mutation
        // calling mutation
        await bulkAddHeiferMeasurements({
          variables: {
            objects: existingHeifersValuesToPass,
          },
          update: (cache) => {
            // modifying cache to reflect entries without refresh
            cache.modify({
              fields: {
                heifer_measurements(existingHeiferMeasurementRef: Array<Reference>) {
                  if (
                    Array.isArray(existingHeifersValuesToPass) &&
                    existingHeifersValuesToPass.length > 0
                  ) {
                    return [...existingHeiferMeasurementRef, ...existingHeifersValuesToPass];
                  }
                  return existingHeiferMeasurementRef;
                },
              },
            });
          },
        });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Heifer measurements have been successfully added');
      }

      // adding unique new heifer and data
      if (uniqueNewHeiferValues.length > 0) {
        // stores the value of unqiue heifersValues to pass
        const uniqueHeifersValuesToPass = uniqueNewHeiferValues.map((item) => {
          // stores the value of pen id
          const penId = item && item.pen_id;
          // checks if pen is present in new pen Data and obtains data
          const newPenData =
            Array.isArray(newPensData) && newPensData.length > 0 && penId
              ? newPensData.find((pen) => {
                  return pen.name.toLowerCase() === penId.toLowerCase();
                })
              : undefined;

          // checks if pen is present in existing pen Data and obtains data
          const existingPenData = penId
            ? existingPensData.find((pen) => pen.name.toLowerCase() === penId.toLowerCase())
            : undefined;

          // stores the value of pen id to pass in mutation
          let penIdToSet: string | undefined;
          if (existingPenData) {
            penIdToSet = existingPenData.id;
          } else if (newPenData) {
            penIdToSet = newPenData.id;
          }

          return {
            ...item,
            pen_id: penIdToSet,
            heifer_measurements: {
              data:
                item.heifer_measurements &&
                Array.isArray(item.heifer_measurements.data) &&
                item.heifer_measurements.data.length > 0
                  ? item.heifer_measurements.data.map((measurementData) => {
                      return {
                        ...measurementData,
                        pen_id: penIdToSet,
                      };
                    })
                  : [],
            },
          };
        });

        // adds heifers and its measurement
        const data = await bulkAddHeifersAndMeasurements({
          variables: {
            objects: uniqueHeifersValuesToPass,
          },
        });

        // storing unique heifer response data
        const response = data.data?.insert_heifer?.returning;

        if (Array.isArray(response) && response.length > 0) {
          if (restNewHeiferValues.length > 0) {
            // multiple measurements for unique new heifers
            const mutationData = restNewHeiferValues.map((item) => {
              // stores the value of pen id
              const penId = item && item.pen_id;
              // finding equivalent id on matched heifer name, dob
              const heiferId = response.find(({ dob, name }) => {
                return (
                  name === item.name &&
                  moment(dob, dateFormat).isSame(moment(item.dob, dateFormat), 'day')
                );
              })?.id;

              // checks if pen is present in new pen Data and obtains data
              const newPenData =
                Array.isArray(newPensData) && newPensData.length > 0 && penId
                  ? newPensData.find((pen) => {
                      return pen.name.toLowerCase() === penId.toLowerCase();
                    })
                  : undefined;

              // checks if pen is present in existing pen Data and obtains data
              const existingPenData = penId
                ? existingPensData.find((pen) => {
                    return pen.name.toLowerCase() === penId.toLowerCase();
                  })
                : undefined;

              // stores the value of pen id to pass in mutation
              let penIdToSet: string | undefined;
              if (existingPenData) {
                penIdToSet = existingPenData.id;
              } else if (newPenData) {
                penIdToSet = newPenData.id;
              }

              return {
                heifer_id: !heiferId ? undefined : heiferId,
                herd_id: item.herd_id,
                body_weight:
                  item.body_weight || item.body_weight === 0 ? Number(item.body_weight) : null,
                height: item.height || item.height === 0 ? Number(item.height) : null,
                measurement_date: item.measurement_date,
                measurement_date_age: Number(item.measurement_date_age),
                mature_height: Number(item.mature_height),
                mature_body_weight: Number(item.mature_body_weight),
                pen_id: penIdToSet,
              };
            });

            await bulkAddHeiferMeasurements({
              variables: {
                objects: mutationData,
              },
              update: (cache) => {
                // modifying cache to reflect entries without refresh
                cache.modify({
                  fields: {
                    heifer_measurements(existingHeiferMeasurementRef: Array<Reference>) {
                      if (
                        Array.isArray(existingHeifersValuesToPass) &&
                        existingHeifersValuesToPass.length > 0
                      ) {
                        return [...existingHeiferMeasurementRef, ...existingHeifersValuesToPass];
                      }
                      return existingHeiferMeasurementRef;
                    },
                  },
                });
              },
            });
          }
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.success('Heifers and measurements data have been successfully added');
      setShowBulkAddDataModal(false);
      setSheetDataToRender([]);
      setIsBtnLoading(false);
    } catch (err) {
      logger(err);
      setSheetDataToRender([]);
      setIsBtnLoading(false);
      setShowBulkAddDataModal(false);
    }
  };

  return (
    <div style={{ padding: '20px 16px 0 16px' }}>
      <Space
        align="start"
        style={{ display: 'flex', alignItems: 'baseline', fontSize: 16, marginBottom: 10 }}
      >
        <span>Selected file name:</span>
        <Button type="link" color="#2B78E4">
          {uploadedFileName}
        </Button>
      </Space>
      {heiferSheetHasError ? (
        <div style={{ marginBottom: 16 }}>
          {renderWarningMessage(
            <span>Please fill the required fields and re-upload the sheet.</span>,
            { marginLeft: 0 },
          )}
        </div>
      ) : null}
      <InfoBar
        marginLeft={0}
        info={
          <span>
            Entries that have <b>no errors</b> will be uploaded.
          </span>
        }
      />
      <Table<BulkHeiferMeasurementDataSheetType>
        dataSource={sheetDataToRender}
        id="#heiferSheetTable"
        pagination={{
          defaultPageSize: 25,
          pageSizeOptions: ['25', '50', '75', '100'],
          total: sheetDataToRender.length,
          size: 'small',
          position: ['bottomLeft'],
        }}
        style={{ marginTop: 20 }}
        size="small"
        bordered
        rowKey={Math.random().toString(16).slice(2)}
        scroll={{ x: 'max-content' }}
      >
        <Table.Column<BulkHeiferMeasurementDataSheetType>
          title="Pen ID"
          dataIndex="penName"
          key="pen_id"
          render={(text, { penName }) => {
            return penName || '-';
          }}
        />
        <Table.Column<BulkHeiferMeasurementDataSheetType>
          title="Heifer ID"
          dataIndex="heiferName"
          key="heifer_id"
          render={(text, { heiferName }) => {
            return !heiferName ? <RequiredAlert /> : heiferName;
          }}
        />
        <Table.Column<BulkHeiferMeasurementDataSheetType>
          title="Heifer DOB"
          dataIndex="heiferDob"
          key="heiferDob"
          render={(text, { heiferDob }) => {
            return !heiferDob ? <RequiredAlert /> : heiferDob;
          }}
        />
        <Table.Column<BulkHeiferMeasurementDataSheetType>
          title="Date of Measurement"
          dataIndex="measurement_date"
          key="measurement_date"
          render={(text, { measurement_date }) => {
            return !measurement_date ? <RequiredAlert /> : measurement_date;
          }}
        />
        <Table.Column<BulkHeiferMeasurementDataSheetType>
          title="Body Weight (lbs)"
          dataIndex="body_weight"
          key="body_weight"
          render={(text, { body_weight, height }) => {
            if (!body_weight && height) {
              return '-';
            }
            return !body_weight ? <RequiredAlert /> : Number(body_weight).toFixed(2);
          }}
        />
        <Table.Column<BulkHeiferMeasurementDataSheetType>
          title="Height (inches)"
          dataIndex="height"
          key="height"
          render={(text, { height, body_weight }) => {
            if (!height && body_weight) {
              return '-';
            }
            return !height ? <RequiredAlert /> : Number(height).toFixed(2);
          }}
        />
        <Table.Column<BulkHeiferMeasurementDataSheetType>
          title="Age on DoM (months)"
          dataIndex="measurement_date_age"
          key="measurement_date_age"
          render={(text, { measurement_date_age }) => {
            return measurement_date_age && measurement_date_age !== 0
              ? measurement_date_age.toFixed(2)
              : '-';
          }}
        />
        <Table.Column<BulkHeiferMeasurementDataSheetType>
          title="Remarks"
          dataIndex="remarks"
          key="remarks"
          fixed="right"
          render={(text, { remarks, body_weight, height, pen_id }) => {
            if (!body_weight && !height) {
              return renderRemarks('Enter at least 1 of Body Weight or Height.');
            }
            if (remarks === 'Error: DoM cannot be earlier than DoB') {
              return renderRemarks('DoM cannot be earlier than DoB');
            }
            if (!pen_id) {
              return renderRemarks('Pen Id is required');
            }
            return remarks;
          }}
        />
      </Table>
      <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 20 }}>
        <Button
          loading={isBtnLoading}
          disabled={heiferSheetHasError || isBtnLoading}
          className={heiferSheetHasError ? '' : 'primaryBtn'}
          type="primary"
          style={{ padding: '16px 30px', display: 'flex', alignItems: 'center' }}
          onClick={handleAddBulkHeiferData}
        >
          Add
        </Button>
        <Button
          onClick={(): void => {
            setSheetDataToRender([]);
            setHeiferSheetHasError(false);
            setUploadedFileName('');
          }}
          className="secondaryBtn"
          style={{
            padding: '16px 24px',
            border: '1px solid #17A697',
            marginLeft: 60,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default BulkHeiferDataSheetPreview;
