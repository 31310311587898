import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment-timezone';
import {
  Row,
  Col,
  Space,
  DatePicker,
  Button,
  InputNumber,
  message,
  Input,
  Select,
  Radio,
  Divider,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ApolloError, Reference, useLazyQuery, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import ECFormItem from '../components/ECFormItem';
import commonStyles from '../screens/HeifersOfSubHerdScreen.module.scss';
import inputStyles from '../screens/AddSubHerdDataScreen.module.scss';
import {
  AddHeiferAndMeasurementMutation,
  AddHeiferAndMeasurementMutationVariables,
  AddHeiferMeasurementMutation,
  AddHeiferMeasurementMutationVariables,
  Heifer,
  HeifersOfHerdQuery,
  HeifersOfHerdQueryVariables,
  Herd_Data_Avg_Fields,
  PensOfFarmQuery,
  PensOfFarmQueryVariables,
  UpdateHeiferMeasurementMutation,
  UpdateHeiferMeasurementMutationVariables,
  AddPensMutation,
  AddPensMutationVariables,
} from '../graphql/graphql-types';
import { dateFormat, dateFormatForDisplay, validationRegex } from '../utils/globals';
import heiferDataCalculations from '../utils/heiferDataCalculations';
import { logger } from '../utils/helpers';
import { HeiferDataFormType, HeiferMeasurementDataType } from '../utils/types';
import InfoBar from '../components/InfoBar';

// using Select option component
const { Option } = Select;

const addHeiferMeasurementMutation = loader(
  '../graphql/mutations/addHeiferMeasurementMutation.graphql',
);
const updateHeiferMeasurementMutation = loader(
  '../graphql/mutations/updateHeiferMeasurementMutation.graphql',
);
const addHeiferAndMeasurementMutation = loader(
  '../graphql/mutations/addHeiferAndMeasurementMutation.graphql',
);
const pensOfHerdQuery = loader('../graphql/queries/pensOfFarmQuery.graphql');
// loading heifersOfSubHerd query
const heifersOfSubHerdQuery = loader('../graphql/queries/heifersOfHerdQuery.graphql');
// used to add new pen
const addPenMutation = loader('../graphql/mutations/addPensMutation.graphql');

// type definition for component props
type HeiferDataFormProps = {
  // heifer's dob obtained from selected heifer from parent screen
  heiferDobForEdit?: Date | string;
  // storing sub herd data form values
  subHerdDataFormValues?: Herd_Data_Avg_Fields;
  // accessing selected sub herdId from previous screen
  selectedSubHerdId?: string;
  // data of heifer which is going to be edited
  heiferDataToEdit?: HeiferMeasurementDataType;
  // mode to detect whether new heifer and data / existing heifer data / edit heifer is done
  mode: 'addNew' | 'edit' | 'addExisting';
  // This controls the close of drawer
  setShowEditDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
  // selected Herd ID
  selectedHerdId?: number;
  // type of heifer
  heiferType?: 'new' | 'existing';
  // setting type of heifer from parent state
  setHeiferType?: React.Dispatch<React.SetStateAction<'new' | 'existing'>>;
};

// form type for adding new heifer and data or existing heifer data based on heifer type
type FormDataType = HeiferDataFormType & Pick<Heifer, 'name' | 'pen_id'> & { dob?: moment.Moment };

// This is the main functional component
const HeiferDataForm: React.FC<HeiferDataFormProps> = ({
  subHerdDataFormValues = {},
  selectedSubHerdId = '',
  heiferDataToEdit,
  mode,
  setShowEditDrawer = () => {},
  selectedHerdId,
  heiferType,
  setHeiferType,
  heiferDobForEdit,
}) => {
  // This is the date of measurement of heifer when mode of form is edit
  const measurementDate = heiferDataToEdit?.measurement_date;
  // This is the body weight of  heifer when mode of form is edit
  const bodyWeight = heiferDataToEdit?.body_weight as number;
  // This is the height of heifer when mode of form is edit
  const height = heiferDataToEdit?.height as number;

  // accessing useForm methods
  const methods = useForm<FormDataType>({
    defaultValues: {
      measurement_date:
        mode === 'edit' && measurementDate ? moment(measurementDate, dateFormat) : moment(),
      body_weight: mode === 'edit' && (bodyWeight || bodyWeight === 0) ? bodyWeight : null,
      height: mode === 'edit' && (height || height === 0) ? height : null,
      name: '',
      pen_id: null,
      dob: mode === 'edit' ? undefined : '',
    },
    shouldUnregister: false,
  });

  // fetching all pens for selected herd
  const [
    getPensOfHerd,
    { data: pensOfHerd, loading: pensOfHerdLoading, error: pensOfHerdErr },
  ] = useLazyQuery<PensOfFarmQuery, PensOfFarmQueryVariables>(pensOfHerdQuery);

  // fetching data for heifers only when existing heifer radio is selected
  const [
    getLatestHeifersData,
    { data: heiferOfSubHerdData, loading: heiferOfSubHerdLoading, error: heiferOfSubHerdErr },
  ] = useLazyQuery<HeifersOfHerdQuery, HeifersOfHerdQueryVariables>(heifersOfSubHerdQuery, {
    fetchPolicy: 'network-only',
    variables: {
      herd_id: selectedSubHerdId,
    },
  });

  //  mutation for adding the pen data
  const [addPen] = useMutation<AddPensMutation, AddPensMutationVariables>(addPenMutation);

  // storing heifer's data fetched from query for a selected subHerd
  const heifersData = heiferOfSubHerdData?.heifer;
  // storing pens data of selected herd
  const pens = pensOfHerd?.pen;

  // boolean state to show or hide a loading indicator
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  // storing the selected heifer ID from the select options
  const [selectedHeiferId, setSelectedHeiferId] = useState<string | undefined>(undefined);
  // used to store value added in new pen input
  const [newPenInputValue, setNewPenInputValue] = useState<string>('');
  /* State to store new pen input err message */
  const [newPenInputErr, setNewPenNameInputErr] = useState<string>('');
  // used to indicate whether to show loader in add button
  const [showLoaderOnAddButton, setShowLoaderInAddButton] = useState<boolean>(false);
  // mutation function for adding a heifer's measurement
  const [addHeiferMeasurement] = useMutation<
    AddHeiferMeasurementMutation,
    AddHeiferMeasurementMutationVariables
  >(addHeiferMeasurementMutation);

  // mutation function for updating the heifer's measurement
  const [updateHeiferMeasurement] = useMutation<
    UpdateHeiferMeasurementMutation,
    UpdateHeiferMeasurementMutationVariables
  >(updateHeiferMeasurementMutation);

  //
  const [addHeiferAndMeasurement] = useMutation<
    AddHeiferAndMeasurementMutation,
    AddHeiferAndMeasurementMutationVariables
  >(addHeiferAndMeasurementMutation);

  // storing pen name for a selected heifer
  let selectedHeiferPenName: string | undefined = '';
  // storing pen id for a selected heifer
  let selectedHeiferPenId: string | undefined = '';
  // storing heifer's dob from a selected heifer
  let selectedHeiferDob: Date | string | undefined;

  // checking whether heifersData is present
  if (Array.isArray(heifersData) && heifersData.length > 0) {
    // data corresponding to a selected heifer
    const selectedHeiferData = heifersData.find((entry) => {
      return entry.id === selectedHeiferId;
    });

    selectedHeiferPenName = selectedHeiferData?.pen?.name;
    selectedHeiferPenId = selectedHeiferData?.pen?.id;
    selectedHeiferDob = selectedHeiferData?.dob;
  }

  // fetching all pens data when adding new heifer and data
  const getPens = useCallback(() => {
    if (mode === 'addNew') {
      getPensOfHerd({
        variables: {
          farm_id: selectedHerdId as number,
        },
      });
    }
  }, [selectedHerdId, getPensOfHerd, mode]);

  // componentDidMount
  useEffect(() => {
    // calling lazy query to fetch pens of herd
    getPens();
  }, [getPens]);

  // avgBw3rdLactCow is used for calculating heifer's data
  const avgBw3rdLactCow: number | null | undefined =
    mode !== 'edit'
      ? subHerdDataFormValues?.avg_bw_3rdlact_cow
      : (heiferDataToEdit?.mature_body_weight as number);

  // avgHeight3rdLactCow is used for calculating heifer's data
  const avgHeight3rdLactCow: number | null | undefined =
    mode !== 'edit'
      ? subHerdDataFormValues?.avg_height_3rdlact_cow
      : (heiferDataToEdit?.mature_height as number);

  // store current value for new heifer dob
  const watchNewHeiferDob = methods.watch('dob');
  // stores current form state of height field
  const watchHeight = methods.watch('height');

  // heifer's dob based on current mode to pass for data calculation
  const modeWiseHeiferDob = (): string | Date | undefined => {
    if (mode === 'edit') return heiferDobForEdit;
    return mode === 'addNew' && watchNewHeiferDob
      ? watchNewHeiferDob.format(dateFormat)
      : selectedHeiferDob;
  };

  // storing calculated data obtained from heiferDataForm
  const calculatedHeiferData = heiferDataCalculations(
    methods.watch(),
    modeWiseHeiferDob(),
    avgBw3rdLactCow,
    avgHeight3rdLactCow,
  );

  // storing calculated age for date of measurement
  const calculatedMeasurementDateAge = !calculatedHeiferData.measurement_date_age.value
    ? null
    : calculatedHeiferData.measurement_date_age.value.toFixed(2);
  // handling heifer data form submission
  const onSubmit = (formData: FormDataType): void => {
    setIsBtnLoading(true);
    // measurement data
    const values = {
      measurement_date: formData.measurement_date ? formData.measurement_date.toDate() : null,
      body_weight: formData.body_weight,
      height: formData.height,
      measurement_date_age: calculatedMeasurementDateAge,
    };
    // mature heifer's body weight and height values
    const matureHeiferData = {
      mature_body_weight: subHerdDataFormValues.avg_bw_3rdlact_cow,
      mature_height: subHerdDataFormValues.avg_height_3rdlact_cow,
    };

    // This code is run when form is in add mode
    if (mode === 'addExisting') {
      addHeiferMeasurement({
        variables: {
          object: {
            ...values,
            ...matureHeiferData,
            herd_id: selectedSubHerdId,
            heifer_id: selectedHeiferId,
            pen_id: selectedHeiferPenId,
          },
        },
        update: (cache, result) => {
          // This is the new data of heifer_measurements which is going to be added in the cache
          const dataToAdd = result.data?.insert_heifer_measurements_one;
          // This is the modify function for the cache
          cache.modify({
            // This is the input of the modify function by which we get access to the cache data here we access heifer_measurements field
            fields: {
              // existingHeiferMeasurementsRef by this we get all the data of heifer_measurements present the cache
              heifer_measurements(existingHeiferMeasurementsRef: Array<Reference>) {
                return [...existingHeiferMeasurementsRef, dataToAdd];
              },
            },
          });
        },
      })
        .then((): void => {
          if (setSelectedHeiferId) {
            setSelectedHeiferId(undefined);
          }
          methods.reset({
            measurement_date: moment(),
            body_weight: null,
            height: null,
          });
          setIsBtnLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Heifer measurement has been successfully added');
        })
        .catch((heiferMeasurementError: ApolloError): void => {
          setIsBtnLoading(false);
          logger(heiferMeasurementError);
        });
    }

    // this code is run when form is in edit mode
    if (mode === 'edit' && heiferDataToEdit && heiferDataToEdit.id) {
      updateHeiferMeasurement({
        variables: {
          id: heiferDataToEdit.id,
          _set: {
            ...values,
            mature_body_weight: heiferDataToEdit.mature_body_weight as number,
            mature_height: heiferDataToEdit.mature_height as number,
            herd_id: heiferDataToEdit.herd_id,
            pen_id: heiferDataToEdit.pen_id,
            heifer_id: heiferDataToEdit.heifer_id,
          },
        },
        update: (cache, { data }) => {
          // updated heifer measurement data in cache
          const updatedData = data?.update_heifer_measurements_by_pk;
          // modifying cache
          cache.modify({
            fields: {
              heifer_measurements(existingMeasurementRef: Array<Reference>) {
                if (updatedData) {
                  return [...existingMeasurementRef, updatedData];
                }
                return existingMeasurementRef;
              },
            },
          });
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Heifer measurement has been successfully updated.');
          setIsBtnLoading(false);
          if (setShowEditDrawer) {
            setShowEditDrawer(false);
          }
        })
        .catch((heiferMeasurementError: ApolloError) => {
          logger(heiferMeasurementError);
          setIsBtnLoading(false);
        });
    }
    // trigger mutation to add new heifer and its measurement when mode is 'addNew'
    if (mode === 'addNew' && formData.dob) {
      addHeiferAndMeasurement({
        variables: {
          object: {
            herd_id: selectedSubHerdId,
            name: formData.name,
            dob: formData.dob.format(dateFormat),
            pen_id: formData.pen_id,
            heifer_measurements: {
              data: [
                {
                  ...values,
                  ...matureHeiferData,
                  herd_id: selectedSubHerdId,
                  pen_id: formData.pen_id,
                },
              ],
            },
          },
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Heifer and measurement data has been successfully added');
          methods.reset({
            measurement_date: moment(),
            body_weight: null,
            height: null,
            name: '',
            dob: '',
            pen_id: methods.watch('pen_id'),
          });
          setIsBtnLoading(false);
        })
        .catch((err: ApolloError) => {
          logger(err);
          if (
            err.message ===
            `Uniqueness violation. duplicate key value violates unique constraint "heifer_dob_name_herd_id_key"`
          ) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error('Heifer already exists in system.');
            methods.reset();
          }
          setIsBtnLoading(false);
        });
    }
  };

  // function that renders calculated heifer data
  const renderCalcHeiferData = ({
    title,
    displayValue,
  }: {
    title: string;
    displayValue: string;
  }) => (
    <Row
      key={title}
      style={{
        alignItems: 'center',
        padding: '20px 0 8px 0',
        justifyContent: 'space-between',
      }}
    >
      <Col style={{ color: '#333' }}>{title}</Col>
      <Col style={{ color: '#666', paddingLeft: 50 }}>{displayValue}</Col>
    </Row>
  );

  // function to render form field error
  const renderFormErr = (errMsg: string | undefined | JSX.Element): JSX.Element | null =>
    errMsg ? (
      <span
        style={{
          display: 'flex',
        }}
        className={inputStyles.errorContainer}
      >
        {errMsg}
      </span>
    ) : null;

  // sets spacing size based on mode
  const setCalcSize = (): number => {
    if (mode === 'addNew') return 314;
    return mode === 'addExisting' ? 261 : 0;
  };

  // used to add new pen id
  const handleAddNewPen = () => {
    /* Variable to store pen data with existing pen name */
    if (!newPenInputValue) {
      setNewPenNameInputErr('Please enter pen id and try again');
      return;
    }
    const existingPen = pens ? pens.find((item) => item.name === newPenInputValue) : undefined;
    if (existingPen) {
      setNewPenNameInputErr(
        `Following Pen(s) already exist : ${
          newPenInputValue || ''
        }. Please enter unique pen names and try again`,
      );
      return;
    }
    setShowLoaderInAddButton(true);
    addPen({
      variables: {
        objects: [{ name: newPenInputValue, farm_id: selectedHerdId }],
      },
      // for updating the cache
      update: (cache, result) => {
        // This is the new data of pen which is going to be added in the cache
        const dataToAdd = result.data?.insert_pen;
        // This is the modify function for the cache update
        cache.modify({
          // This is the input of the modify function by which we get access to the cache data here we access pen field
          fields: {
            // existingPensRef consists all the data of pens present the cache
            pen(existingPensRef: Array<Reference>) {
              return [...existingPensRef, dataToAdd];
            },
          },
        });
      },
    })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Successfully added new pen.');
        // resets the value after adding new pen in input
        setNewPenInputValue('');
        setShowLoaderInAddButton(false);
      })
      .catch((error: ApolloError) => {
        setShowLoaderInAddButton(false);
        logger(error);
      });
  };

  return (
    <div>
      <Space
        size={mode === 'edit' ? 10 : 180}
        style={{
          display: 'flex',
          alignItems: mode === 'edit' ? 'baseline' : 'flex-end',
        }}
        align="start"
        direction={mode === 'edit' ? 'vertical' : 'horizontal'}
      >
        <form
          className={commonStyles.formContainer}
          style={mode === 'edit' ? { width: 500, paddingRight: 20, paddingLeft: 20 } : undefined}
        >
          <ECFormItem
            rules={{
              required: "Please enter heifer's current measurement date and try again",
              validate: {
                beforeDob: (val: moment.Moment) => {
                  if (mode === 'addNew' && !watchNewHeiferDob) {
                    return undefined;
                  }
                  if (mode === 'edit' && heiferDobForEdit) {
                    return (
                      moment(heiferDobForEdit, dateFormat).isSameOrBefore(val) ||
                      'DoM cannot be earlier than DoB'
                    );
                  }

                  /* Variable to store heifer dob */
                  const heiferDob =
                    mode === 'addNew' && watchNewHeiferDob
                      ? watchNewHeiferDob.format(dateFormat)
                      : selectedHeiferDob;

                  return (
                    moment(heiferDob, dateFormat).isSameOrBefore(val) ||
                    'DoM cannot be earlier than DoB'
                  );
                },
              },
            }}
            requiredMark
            labelSpan={10}
            fieldSpan={14}
            textAlign={mode === 'edit' ? 'left' : 'end'}
            control={methods.control}
            label="Date of Measurement"
            name="measurement_date"
            render={({ onChange, value }): JSX.Element => (
              <DatePicker
                placeholder="Please enter heifer's current measurement date"
                value={value as moment.Moment}
                format={dateFormatForDisplay}
                style={{ width: 320 }}
                onChange={(selectedDate) => {
                  onChange(selectedDate);
                }}
                disabledDate={(currentDate): boolean => moment().endOf('d') <= currentDate}
              />
            )}
            errors={
              methods.errors.measurement_date &&
              methods.errors.measurement_date.type !== 'beforeDob' ? (
                <span
                  style={{
                    display: 'flex',
                  }}
                  className={inputStyles.errorContainer}
                >
                  {methods.errors.measurement_date.message}
                </span>
              ) : null
            }
          />
          <div>
            {mode === 'addExisting' ? (
              <Space style={{ alignItems: 'center', padding: '20px 0 0 125px' }}>
                <span>Pen ID:</span>
                <span style={{ paddingLeft: 35 }}>
                  {!selectedHeiferPenName ? <b>-</b> : selectedHeiferPenName}
                </span>
              </Space>
            ) : null}
          </div>

          {mode === 'addNew' ? (
            <>
              <ECFormItem
                labelSpan={10}
                fieldSpan={14}
                name="pen_id"
                rules={{ required: 'Please enter pen id and try again' }}
                control={methods.control}
                requiredMark
                as={
                  <Select
                    loading={pensOfHerdLoading}
                    style={{ width: 320, border: '1px solid #d9d9d9' }}
                    className="selectBox"
                    placeholder="Please select Heifer's pen ID"
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input
                            style={{ flex: 'auto' }}
                            value={newPenInputValue}
                            onChange={(event) => {
                              setNewPenInputValue(event.target.value);
                              if (newPenInputErr) {
                                setNewPenNameInputErr('');
                              }
                            }}
                            placeholder="Please add Heifer's pen ID"
                          />
                          <Button
                            icon={<PlusOutlined />}
                            style={{ marginLeft: 15 }}
                            className="primaryBtn"
                            htmlType="submit"
                            onClick={() => {
                              handleAddNewPen();
                            }}
                            loading={showLoaderOnAddButton}
                          >
                            Add
                          </Button>
                        </div>
                        {newPenInputErr ? (
                          <p
                            style={{
                              padding: 10,
                              margin: 0,
                              marginTop: -10,
                              color: '#ce0e2d',
                              fontSize: 13,
                            }}
                          >
                            {newPenInputErr}
                          </p>
                        ) : null}
                      </>
                    )}
                  >
                    {Array.isArray(pens) && pens.length > 0
                      ? pens.map((pen) => (
                          <Option value={pen.id} key={pen.id}>
                            {pen.name}
                          </Option>
                        ))
                      : null}
                  </Select>
                }
                label="Pen ID"
                errors={methods.errors.pen_id ? renderFormErr(methods.errors.pen_id.message) : null}
              />
              {!Array.isArray(pens) || pens.length === 0 ? (
                <Row>
                  <Col style={{ fontStyle: 'italic', padding: 0 }} offset={10}>
                    No Pens added in this herd
                  </Col>
                </Row>
              ) : null}
              {pensOfHerdErr ? (
                <Row>
                  <Col style={{ fontStyle: 'italic', padding: 0 }} offset={10}>
                    {pensOfHerdErr.message}
                  </Col>
                </Row>
              ) : null}
            </>
          ) : null}

          {mode !== 'edit' ? (
            <>
              <InfoBar
                containerStyle={{ width: 546, marginTop: 10, paddingLeft: 35 }}
                info={
                  <div>
                    Pen IDs must be included for all heifers and measurements. Pen IDs can be
                    edited, added, or deleted at any time. Example Pen IDs may be “1”, “New Barn”,
                    “Purina”, “Control”, etc. For small farms Pen ID may be “All”, but must still be
                    entered. Data from heifers in separate Pens will appear on the same graphs.
                  </div>
                }
              />
              <Row>
                <Col offset={10}>
                  <Radio.Group
                    value={heiferType}
                    onChange={({ target: { value } }) => {
                      methods.reset();
                      if (setHeiferType) {
                        setHeiferType(value);
                      }
                      // when 'new heifer' option is selected, reset
                      if (value === 'new') {
                        if (setSelectedHeiferId) {
                          setSelectedHeiferId(undefined);
                        }
                      } else {
                        getLatestHeifersData();
                      }
                    }}
                    style={{ margin: '10px 0', padding: 0 }}
                  >
                    <Radio value="new">New Heifer</Radio>
                    <Radio value="existing">Existing Heifer</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </>
          ) : null}
          {mode === 'addExisting' ? (
            <Space
              size={140}
              style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 20 }}
              align="start"
            >
              <Row style={{ alignItems: 'center', padding: 0 }}>
                <Col span={10} style={{ textAlign: 'end', paddingRight: 24 }}>
                  Select Heifer ID:
                </Col>
                <Col span={14} style={{ paddingLeft: 18 }}>
                  {heiferOfSubHerdErr ? (
                    <p style={{ color: 'red' }}>{heiferOfSubHerdErr.message}</p>
                  ) : (
                    <Select
                      onSelect={(val) => {
                        if (setSelectedHeiferId) {
                          setSelectedHeiferId(val);
                        }
                      }}
                      className="selectBox"
                      value={selectedHeiferId}
                      showSearch
                      optionFilterProp="searchdata"
                      style={{ width: 320, border: '1px solid #d9d9d9' }}
                      placeholder="Start typing Heifer ID"
                      loading={heiferOfSubHerdLoading}
                    >
                      {Array.isArray(heifersData) && heifersData.length > 0
                        ? heifersData.map(
                            (heiferEntry): JSX.Element => (
                              <Select.Option
                                value={heiferEntry.id}
                                key={heiferEntry.id}
                                searchdata={heiferEntry.name}
                              >
                                <Space
                                  align="start"
                                  style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                  <span>{heiferEntry.name}</span>
                                  <span style={{ color: '#666' }}>
                                    {moment(heiferEntry.dob, dateFormat).format(
                                      dateFormatForDisplay,
                                    )}
                                  </span>
                                </Space>
                              </Select.Option>
                            ),
                          )
                        : null}
                    </Select>
                  )}
                </Col>
              </Row>
            </Space>
          ) : null}
          {mode === 'addNew' ? (
            <>
              <ECFormItem
                customStyle={{ margin: 0, padding: 0 }}
                name="name"
                rules={{ required: 'Please enter ID of heifer and try again' }}
                control={methods.control}
                as={<Input style={{ width: 320 }} placeholder="Please enter heifer's ID" />}
                labelSpan={10}
                fieldSpan={14}
                label="Heifer ID"
                requiredMark
                errors={methods.errors.name ? renderFormErr(methods.errors.name.message) : null}
              />
              <ECFormItem
                name="dob"
                requiredMark
                rules={{ required: true }}
                control={methods.control}
                render={({ onChange, value }) => (
                  <DatePicker
                    onChange={(momentDate) => {
                      onChange(momentDate);
                    }}
                    value={value as moment.Moment}
                    format="M/D/YYYY"
                    style={{ width: 320 }}
                    disabledDate={(currentDate): boolean => {
                      return moment().endOf('d') <= currentDate;
                    }}
                    placeholder="Please enter Heifer's DOB"
                  />
                )}
                labelSpan={10}
                fieldSpan={14}
                label="Date Of Birth"
                errors={
                  methods.errors.dob
                    ? renderFormErr(<span>Please enter date of birth and try again</span>)
                    : null
                }
              />
            </>
          ) : null}
          <ECFormItem
            rules={{
              pattern: {
                value: validationRegex.decimal.value,
                message: validationRegex.decimal.msg,
              },
              validate: {
                eitherErr: (val: number) =>
                  (val && !watchHeight) ||
                  (!val && watchHeight) ||
                  (val && watchHeight) ||
                  val === 0 ||
                  watchHeight === 0
                    ? undefined
                    : 'At least 1 of either Body Weight or Height is required.',
              },
            }}
            labelSpan={10}
            fieldSpan={14}
            customStyle={mode === 'addExisting' ? { margin: 0, padding: 0 } : undefined}
            textAlign={mode === 'edit' ? 'left' : 'end'}
            control={methods.control}
            label="Body Weight (lbs)"
            name="body_weight"
            as={
              <InputNumber
                placeholder="Please enter heifer's current body weight"
                precision={2}
                min={0}
                style={{ width: 320 }}
              />
            }
            errors={
              methods.errors.body_weight && methods.errors.body_weight.type !== 'eitherErr' ? (
                <span style={{ display: 'flex' }} className={inputStyles.errorContainer}>
                  {methods.errors.body_weight.message}
                </span>
              ) : null
            }
          />
          <ECFormItem
            rules={{
              pattern: {
                value: validationRegex.decimal.value,
                message: validationRegex.decimal.msg,
              },
            }}
            labelSpan={10}
            fieldSpan={14}
            textAlign={mode === 'edit' ? 'left' : 'end'}
            control={methods.control}
            label="Height (inches)"
            name="height"
            as={
              <InputNumber
                placeholder="Please enter heifer's current height"
                precision={2}
                min={0}
                style={{ width: 320 }}
              />
            }
            errors={
              methods.errors.height ? (
                <span style={{ display: 'flex' }} className={inputStyles.errorContainer}>
                  {methods.errors.height.message}
                </span>
              ) : null
            }
          />
        </form>

        <Space direction="vertical" style={{ display: 'flex' }} size={setCalcSize()}>
          <div style={mode === 'edit' ? { paddingRight: 20, paddingLeft: 20 } : undefined}>
            {renderCalcHeiferData({
              title: Object.values(calculatedHeiferData)[0].title,
              displayValue: Object.values(calculatedHeiferData)[0].displayValue,
            })}
          </div>
          <div
            style={mode === 'edit' ? { width: 550, paddingRight: 20, paddingLeft: 20 } : undefined}
          >
            {Object.values(calculatedHeiferData)
              .filter(({ title }) => title !== 'Age on date of measurement')
              .map(({ title, displayValue }) => renderCalcHeiferData({ title, displayValue }))}
          </div>
        </Space>
      </Space>
      <Space align="start" style={{ marginTop: 20, marginLeft: 210, display: 'flex' }}>
        <Button
          loading={isBtnLoading}
          disabled={isBtnLoading}
          className={commonStyles.editSaveBtn}
          type="primary"
          onClick={methods.handleSubmit(onSubmit, (errors) => {
            if (errors.body_weight && errors.body_weight.type === 'eitherErr') {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.error(errors.body_weight.message);
            }
            if (errors.measurement_date && errors.measurement_date.type === 'beforeDob') {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.error(errors.measurement_date.message);
            }
          })}
        >
          Save
        </Button>
        {mode === 'edit' ? (
          <Button
            style={{ marginLeft: 50, border: '1px solid #17A697' }}
            className="secondaryBtn"
            onClick={() => {
              methods.reset();
              if (setShowEditDrawer) setShowEditDrawer(false);
            }}
          >
            Cancel
          </Button>
        ) : null}
      </Space>
    </div>
  );
};

export default HeiferDataForm;
