import React, { CSSProperties, useEffect, useState } from 'react';
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import {
  Select,
  Button,
  Modal,
  Input,
  Divider,
  message,
  Popconfirm,
  Dropdown,
  Menu,
  Drawer,
} from 'antd';
import { Controller, useForm, UseFormMethods } from 'react-hook-form';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import {
  WarningFilled,
  PlusOutlined,
  DeleteOutlined,
  UploadOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { loader } from 'graphql.macro';
import styles from './DataManagementScreen.module.scss';
import panelStyles from './AddSubHerdDataScreen.module.scss';
import {
  DataFarmsHerdsQuery,
  DataFarmsHerdsQueryVariables,
  Herd,
  AddHerdMutation,
  AddHerdMutationVariables,
  DeleteHerdMutation,
  DeleteHerdMutationVariables,
  Herd_Data_Avg_Fields,
} from '../graphql/graphql-types';
import { logger, renderWarningMessage } from '../utils/helpers';
import HeifersOfSubHerdScreen from './HeifersOfSubHerdScreen';
import AddSubHerdDataScreen from './AddSubHerdDataScreen';
import AllSubHerdDataScreen from './AllSubHerdDataScreen';
import InfoBar from '../components/InfoBar';
import { ScreenToRenderOnDataMeasurementScreenType } from '../utils/types';
import commonBtnStyles from './HeifersOfSubHerdScreen.module.scss';
import ViewChartsScreen from './ViewChartsScreen';
import AddHeiferDataScreen from './AddHeiferDataScreen';
import HerdForm from '../forms/HerdForm';
import HerdDetailComponent from '../components/HerdDetailComponent';
import PenDetailsComponent from '../components/PenDetailsComponent';
import TransferScreen from './TransferScreen';

// This is the query to fetch all the dataHerdsSubHerds
const dataHerdsSubHerdsQuery = loader('../graphql/queries/dataFarmsHerdsQuery.graphql');
// This is the query to add new subHerd
const addSubHerdMutation = loader('../graphql/mutations/addHerdMutation.graphql');
// mutation for deleting a subHerd
const deleteSubHerdMutation = loader('../graphql/mutations/deleteHerdMutation.graphql');

// Destructuring Options from select
const { Option } = Select;

// This is the type of the subHerd data for form
type AddSubHerdFieldsType = Pick<Herd, 'name'>;

// justifying the content at the center of the screen
const centerFlexView: CSSProperties = {
  justifyContent: 'center',
  display: 'flex',
};

// This is the main functional component
const DataMeasurmentScreen: React.FC = () => {
  // This state handles the open and close of add subHerd modal
  const [showAddSubHerdModal, setShowAddSubHerdModal] = React.useState(false);
  // This state is used to store the id of selected herd
  const [selectedHerdId, setSelectedHerdId] = React.useState<number | undefined>(undefined);
  // This state is used to store the id of selected subHerd
  const [selectedSubHerdId, setSelectedSubHerdId] = React.useState<string>('');
  // storing the selected heifer ID from the select options
  const [selectedHeiferId, setSelectedHeiferId] = React.useState<string | undefined>(undefined);
  // This state handles loader of save button
  const [btnLoading, setBtnLoading] = React.useState<boolean>(false);
  // This state is for controlling tab panel
  const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(0);
  // used to store the name of the screen for rendering the screen component
  const [screenToRender, setScreenToRender] = useState<ScreenToRenderOnDataMeasurementScreenType>(
    'AddSubHerdDataScreen',
  );
  // used to toggle active/disable state of next btn
  const [nextBtnDisabled, setNextBtnDisabled] = useState<boolean>(false);
  // used to store subHerd formData (fetched or updated)
  const [subHerdDataFormValues, setSubHerdDataFormValues] = useState<Herd_Data_Avg_Fields>({});
  // state to store the visibility state of bulk add data modal
  const [showBulkAddDataModal, setShowBulkAddDataModal] = useState<boolean>(false);
  // indicates whether to show add herd drawer
  const [showAddHerdDrawer, setShowAddHerdDrawer] = useState<boolean>(false);
  // indicates whether to show view herd data drawer
  const [showViewHerdDataDrawer, setShowViewHerdDataDrawer] = useState<boolean>(false);
  // indicates whether to show edit herd drawer
  const [showEditHerdDrawer, setShowEditHerdDrawer] = useState<boolean>(false);
  // indicates whether to show view pen details drawer
  const [showViewPenDrawer, setShowViewPenDrawer] = useState<boolean>(false);

  // accessing form methods for adding a subHerd
  const allSubHerdFormMethods = useForm<AddSubHerdFieldsType>({
    defaultValues: {
      name: '',
    },
  });

  // accessing form methods for updating subHerd data statistics
  const subHerdDataFormMethods: UseFormMethods<Herd_Data_Avg_Fields> = useForm<Herd_Data_Avg_Fields>(
    {
      defaultValues: {
        avg_bw_3rdlact_cow: null,
        avg_height_3rdlact_cow: null,
        avg_birth_bw_calves: null,
        avg_birth_height_calves: null,
        avg_spc_heifers: null,
        current_age_1st_calving: null,
        goal_age_1st_calving: null,
      },
      shouldUnregister: false,
    },
  );

  // This is the query data fetching data of herd with subHerds
  const dataHerdsSubHerdsResult = useQuery<DataFarmsHerdsQuery, DataFarmsHerdsQueryVariables>(
    dataHerdsSubHerdsQuery,
    {
      fetchPolicy: 'network-only',
    },
  );

  // storing data of herd
  const herds = dataHerdsSubHerdsResult?.data?.farm;

  // stores the data of herd to be displayed in herd profile
  const herdDataToShow = herds
    ? herds.find((i) => {
        return i.id === selectedHerdId;
      })
    : null;

  // This is invoke when user select herd
  const handleChangeSelectHerd = (value: number) => {
    setSelectedSubHerdId('');
    setSelectedHerdId(value);
    setSelectedHeiferId(undefined);
  };

  // This is invoke when user select subHerd
  const handleChangeSelectSubHerds = (value: string) => {
    setSelectedHeiferId(undefined);
    setSelectedSubHerdId(value);
    // set to render SubHerdDataForm when subHerd selection changes
    setScreenToRender('AddSubHerdDataScreen');
    setSelectedTabIndex(0);
  };

  // This is to store the subHerds from the selected herd
  let subHerds: Pick<Herd, 'id' | 'name'>[] | undefined;
  // This is to store the name of selected subHerd
  let selectedSubHerdName: string | undefined = '';
  // This is to store the name of selected herd
  let selectedHerdName: string | undefined = '';
  // filtering subHerds data specific to selected herd
  if (selectedHerdId && Array.isArray(herds) && herds.length > 0) {
    // storing the object data of selected herd
    const selectedHerd = herds.find((herd) => selectedHerdId === herd.id);
    selectedHerdName = selectedHerd?.name;
    subHerds = selectedHerd?.herds;
    if (Array.isArray(subHerds) && subHerds.length > 0) {
      const selectedSubHerd = subHerds.find((subHerd) => selectedSubHerdId === subHerd.id);
      selectedSubHerdName = selectedSubHerd?.name;
    }
  }

  // This is used to add new subHerd in the herd
  const [addSubHerd] = useMutation<AddHerdMutation, AddHerdMutationVariables>(addSubHerdMutation);
  // mutation function to handle delete subHerd logic
  const [deleteSubHerd] = useMutation<DeleteHerdMutation, DeleteHerdMutationVariables>(
    deleteSubHerdMutation,
  );

  // componentDidMount
  useEffect(() => {
    // render add heifer data screen when sub herd data present
    if (
      subHerdDataFormMethods.formState.isSubmitSuccessful ||
      subHerdDataFormValues.avg_height_3rdlact_cow !== null
    ) {
      setScreenToRender('AddHeiferDataScreen');
    }
  }, [
    subHerdDataFormMethods.formState.isSubmitSuccessful,
    subHerdDataFormValues.avg_height_3rdlact_cow,
  ]);

  // This function invoke when user hit the cancel button of modal
  const handleCancel = () => {
    // resetting form data on closing the modal
    allSubHerdFormMethods.reset();
    setShowAddSubHerdModal(false);
  };

  // This function is invoke when user hit the save button
  const onSubmit = (data: AddSubHerdFieldsType) => {
    setBtnLoading(true);
    if (selectedHerdId) {
      addSubHerd({
        variables: {
          farm_id: selectedHerdId,
          name: data.name,
        },
        // update function update the data of subHerd in our app
        update: (cache, { data: cacheData }) => {
          const newSubHerd = cacheData?.insert_herd_one;
          if (newSubHerd && herds) {
            cache.writeQuery({
              query: dataHerdsSubHerdsQuery,
              data: {
                farm: herds.map((item) => {
                  if (item.id === selectedHerdId) {
                    return { ...item, herds: [...item.herds, newSubHerd] };
                  }
                  return item;
                }),
              },
            });
          }
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Sub-herd has been successfully added.');
          allSubHerdFormMethods.reset();
          setBtnLoading(false);
          setShowAddSubHerdModal(false);
        })
        .catch((err) => {
          allSubHerdFormMethods.reset();
          setBtnLoading(false);
          logger(err);
          setShowAddSubHerdModal(false);
        });
    }
  };

  // function to handle deletion of subHerd
  const handleDeleteSubHerd = (subHerdId: string) => {
    setBtnLoading(true);
    // triggering deleteSubHerd mutation
    deleteSubHerd({
      variables: {
        id: subHerdId,
      },
      update: (cache, { data }) => {
        const deletedSubHerdId: string | undefined = data?.delete_herd_by_pk?.id;
        if (herds && deletedSubHerdId) {
          cache.writeQuery({
            query: dataHerdsSubHerdsQuery,
            data: {
              farm: herds
                .map((herdData) => herdData.herds)
                .filter((subHerdData) =>
                  subHerdData.filter((subHerdItem) => {
                    return deletedSubHerdId !== subHerdItem.id;
                  }),
                ),
            },
          });
        }
      },
    })
      .then((): void => {
        setSelectedSubHerdId('');
        setBtnLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Sub-herd has been successfully deleted');
      })
      .catch((deleteSubHerdError: ApolloError) => {
        logger(deleteSubHerdError);
        setBtnLoading(false);
      });
  };

  // function to render a screen based on screenToRender state value
  const handleScreenToRender = (): JSX.Element => {
    // checking if current screen is AddSubHerdDataScreen
    if (screenToRender === 'AddSubHerdDataScreen') {
      return (
        <div className={panelStyles.tabPanel}>
          <AddSubHerdDataScreen
            selectedSubHerdId={selectedSubHerdId}
            nextBtnDisabled={nextBtnDisabled}
            setNextBtnDisabled={setNextBtnDisabled}
            setSubHerdDataFormValues={setSubHerdDataFormValues}
            subHerdDataFormMethods={subHerdDataFormMethods}
            setShowBulkAddDataModal={setShowBulkAddDataModal}
            showBulkAddDataModal={showBulkAddDataModal}
            selectedHerdId={selectedHerdId as number}
          />
        </div>
      );
    }
    // checking if current screen is AddHeiferDataScreen
    if (screenToRender === 'AddHeiferDataScreen') {
      return (
        <div className={panelStyles.tabPanel}>
          <AddHeiferDataScreen
            selectedHerdId={selectedHerdId}
            selectedSubHerdId={selectedSubHerdId}
            subHerdDataFormValues={subHerdDataFormValues}
            setSubHerdDataFormValues={setSubHerdDataFormValues}
            subHerdDataFormMethods={subHerdDataFormMethods}
            setShowBulkAddDataModal={setShowBulkAddDataModal}
            showBulkAddDataModal={showBulkAddDataModal}
          />
          <div style={{ ...centerFlexView, marginTop: 36 }}>
            <Button
              icon={<UploadOutlined />}
              className={commonBtnStyles.editSaveBtn}
              type="primary"
              style={{ width: 187 }}
              onClick={(): void => {
                // showing modal
                setShowBulkAddDataModal(true);
              }}
            >
              Bulk add heifer data
            </Button>
            <Button
              style={{ marginLeft: 30, width: 212 }}
              className={commonBtnStyles.addHeiferBtn}
              type="primary"
              onClick={(): void => {
                setScreenToRender('ViewChartsScreen');
              }}
            >
              View Today's Measurements
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className={panelStyles.tabPanel}>
        <ViewChartsScreen
          setScreenToRender={setScreenToRender}
          selectedSubHerdId={selectedSubHerdId}
          matureBodyWeight={subHerdDataFormMethods.watch('avg_bw_3rdlact_cow') as number}
          matureHipHeight={subHerdDataFormMethods.watch('avg_height_3rdlact_cow') as number}
        />
        <div style={centerFlexView}>
          <Button
            className={commonBtnStyles.editSaveBtn}
            type="primary"
            onClick={(): void => {
              setScreenToRender('AddHeiferDataScreen');
            }}
          >
            Previous
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="dataMeasurmentContainer">
      <Modal
        centered
        bodyStyle={{ borderRadius: 5 }}
        visible={showAddSubHerdModal}
        title={<div style={{ fontSize: 18 }}>Add Sub-herd</div>}
        footer={[
          <Button key="1" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="2"
            className="tintColoredButton"
            onClick={allSubHerdFormMethods.handleSubmit(onSubmit)}
            loading={btnLoading}
          >
            Save
          </Button>,
        ]}
        destroyOnClose
        onCancel={handleCancel}
      >
        <div style={{ margin: 20, marginBottom: 25 }}>
          <InfoBar
            info={
              <div>
                Sub-herds can be distinguished by breed (ex.: Holstein, Jersey, Crossbred, etc.) or
                by location or site (ex.: North Farm, South Farm, Purchased, etc.) <br />
                <b>
                  Sub-herds should not be separated by birth date or date of measurement. Data from
                  heifers in separate sub-herds will not appear on the same graphs.
                </b>
                <br />
                For small farms Sub-herd may be “All Heifers”
              </div>
            }
          />

          <div className={styles.formLabel} style={{ marginTop: 15 }}>
            Sub-herd ID
          </div>
          <Controller
            name="name"
            rules={{ required: 'Please enter ID of sub-herd and try again' }}
            control={allSubHerdFormMethods.control}
            as={<Input className={styles.inputField} placeholder="Please enter a sub-herd ID" />}
          />
          {allSubHerdFormMethods.errors.name ? (
            <p style={{ marginLeft: 5, color: '#17A697' }}>
              {allSubHerdFormMethods.errors.name.message}
            </p>
          ) : null}
        </div>
      </Modal>
      <Drawer
        title={<div style={{ fontSize: 22 }}>Add new Herd</div>}
        placement="right"
        closable
        onClose={() => {
          setShowAddHerdDrawer(false);
        }}
        visible={showAddHerdDrawer}
        width={650}
        className="drawerContainer"
        destroyOnClose
      >
        <HerdForm
          mode="add"
          inputsFlexDirection="column"
          paddingBottomInName={0}
          setVisibleDrawer={setShowAddHerdDrawer}
        />
      </Drawer>

      <Drawer
        title={<div style={{ fontSize: 22 }}>Herd Profile</div>}
        placement="right"
        closable
        onClose={() => {
          setShowViewHerdDataDrawer(false);
        }}
        visible={showViewHerdDataDrawer}
        width={450}
        className="drawerContainer"
      >
        {herdDataToShow && showViewHerdDataDrawer ? (
          <HerdDetailComponent
            herdDataToShow={herdDataToShow}
            setShowDrawer={setShowViewHerdDataDrawer}
            setSelectedHerdId={setSelectedHerdId}
            setSelectedSubHerdId={setSelectedSubHerdId}
          />
        ) : null}
      </Drawer>
      <Drawer
        title={<div style={{ fontSize: 22 }}>Edit herd profile</div>}
        placement="right"
        closable
        onClose={() => {
          setShowEditHerdDrawer(false);
        }}
        visible={showEditHerdDrawer}
        width={640}
        className="drawerContainer"
      >
        {showEditHerdDrawer && herdDataToShow ? (
          <HerdForm
            mode="edit"
            inputsFlexDirection="column"
            paddingBottomInName={0}
            dataToEdit={herdDataToShow}
            editHerdId={herdDataToShow.id}
            setVisibleDrawer={setShowEditHerdDrawer}
            setSelectedHerdId={setSelectedHerdId}
            setSelectedSubHerdId={setSelectedSubHerdId}
          />
        ) : null}
      </Drawer>
      <Drawer
        title={<div style={{ fontSize: 22 }}>Pen List</div>}
        placement="right"
        closable
        onClose={() => {
          setShowViewPenDrawer(false);
        }}
        visible={showViewPenDrawer}
        width={600}
        className="drawerContainer"
      >
        {selectedHerdId ? <PenDetailsComponent selectedHerdId={selectedHerdId} /> : null}
      </Drawer>

      <div
        style={{ marginLeft: 34, paddingTop: 20, marginBottom: 20 }}
        className="selectBoxContainer"
      >
        <span className="selectBoxLabel">Select a Herd:</span>
        <Select
          className="selectBox"
          showSearch
          style={{ width: 400, marginLeft: 50 }}
          placeholder="Select a herd to start collecting data"
          optionFilterProp="data"
          onChange={handleChangeSelectHerd}
          value={selectedHerdId}
          disabled={!Array.isArray(herds) || herds.length === 0}
        >
          {Array.isArray(herds) && herds.length > 0
            ? herds.map((item) => (
                <Option key={item.id} value={item.id} data={item.name}>
                  {`${item.name}`}
                </Option>
              ))
            : []}
        </Select>
        {selectedHerdId ? (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    setShowViewHerdDataDrawer(true);
                  }}
                >
                  <span>View Herd</span>
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    setShowEditHerdDrawer(true);
                  }}
                >
                  <span>Edit Herd</span>
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    setShowViewPenDrawer(true);
                  }}
                >
                  <span>View Pens</span>
                </Menu.Item>
              </Menu>
            }
          >
            <Button style={{ marginLeft: 60 }} className="primaryBtn">
              Options <CaretDownOutlined />
            </Button>
          </Dropdown>
        ) : null}
        {selectedHerdId ? (
          <Divider
            type="vertical"
            style={{
              marginLeft: 15,
              marginRight: 15,
              width: 2,
              height: 34,
              borderColor: 'black',
            }}
          />
        ) : null}
        <Button
          className="primaryBtn"
          style={{ marginLeft: selectedHerdId ? 0 : 60 }}
          icon={<PlusOutlined />}
          onClick={() => {
            setShowAddHerdDrawer(true);
          }}
        >
          Add Herd
        </Button>
        {!Array.isArray(herds) || herds.length === 0
          ? renderWarningMessage(
              <>
                <span>
                  Please add a herd using <b> + Add Herd</b> button to begin using the app.
                </span>
              </>,
              { marginTop: 50, marginLeft: 80 },
            )
          : null}
      </div>

      {selectedHerdId && (!Array.isArray(subHerds) || subHerds.length === 0) ? (
        <div style={{ marginLeft: 190 }}>
          <InfoBar
            marginLeft={0}
            info={
              <span>
                Quickly add sub-herds to this herd using the <b>Add Sub-herd</b> button.
              </span>
            }
          />
        </div>
      ) : null}
      {Array.isArray(subHerds) ? (
        <>
          <div style={{ paddingLeft: 34, marginTop: 20 }} className="selectBoxContainer">
            <span className="selectBoxLabel">Select a Sub-herd:</span>
            <Select
              className="selectBox"
              showSearch
              disabled={Array.isArray(subHerds) && subHerds.length === 0}
              style={{ width: 400 }}
              placeholder="Select a sub-herd to add heifers and heifers measurements"
              optionFilterProp="data"
              onChange={handleChangeSelectSubHerds}
              value={selectedSubHerdId === '' ? undefined : selectedSubHerdId}
            >
              {subHerds &&
                subHerds.map((item) => (
                  <Option key={item.id} value={item.id} data={item.name}>
                    {item.name}
                  </Option>
                ))}
            </Select>
            <Button
              className="primaryBtn"
              icon={<PlusOutlined />}
              style={{ marginLeft: 60, marginRight: 60 }}
              onClick={() => {
                setShowAddSubHerdModal(true);
              }}
            >
              Add Sub-herd
            </Button>
            {subHerds && subHerds.length !== 0 ? (
              <Popconfirm
                disabled={!selectedSubHerdId}
                title="Delete Sub-herd. Are you sure?"
                onConfirm={(): void => {
                  handleDeleteSubHerd(selectedSubHerdId);
                }}
                icon={<WarningFilled style={{ color: '#ce0e2d' }} />}
                cancelText="No"
                okText="Yes"
              >
                <Button
                  className={!selectedSubHerdId ? '' : 'secondaryBtn'}
                  icon={<DeleteOutlined />}
                  disabled={!selectedSubHerdId || btnLoading}
                  loading={btnLoading}
                >
                  Delete Sub-herd
                </Button>
              </Popconfirm>
            ) : null}
          </div>
          {selectedSubHerdId && selectedSubHerdId !== '' ? (
            <div className="dataScreenTab" style={{ marginTop: 30 }}>
              <Tabs
                selectedIndex={selectedTabIndex}
                onSelect={(value) => {
                  setSelectedTabIndex(value);
                  setSelectedHeiferId(undefined);
                  // when user clicks on Add Heifer Data tab and if sub herd form has a null value,
                  // render AddSubHerdDataScreen
                  if (value === 2 && subHerdDataFormValues.avg_height_3rdlact_cow === null) {
                    setScreenToRender('AddSubHerdDataScreen');
                  }
                }}
              >
                <TabList style={{ padding: '0px 35px', fontSize: '16px' }}>
                  <Tab>Add heifer data</Tab>
                  <Tab>Heifer Summary</Tab>
                  <Tab>Sub-herd information</Tab>
                  <Tab>Heifer Transfer</Tab>
                </TabList>
                <TabPanel>{handleScreenToRender()}</TabPanel>
                <TabPanel>
                  <HeifersOfSubHerdScreen
                    selectedHerdId={selectedHerdId}
                    herdData={herds}
                    idOfSubHerd={selectedSubHerdId}
                    selectedHerdName={selectedHerdName}
                    selectedSubHerdName={selectedSubHerdName}
                  />
                </TabPanel>
                <TabPanel>
                  <AllSubHerdDataScreen
                    selectedSubHerdId={selectedSubHerdId}
                    subHerdDataFormMethods={subHerdDataFormMethods}
                    setSubHerdDataFormValues={setSubHerdDataFormValues}
                  />
                </TabPanel>
                <TabPanel>
                  <TransferScreen
                    selectedHeiferId={selectedHeiferId}
                    setSelectedHeiferId={setSelectedHeiferId}
                    selectedSubHerdId={selectedSubHerdId}
                    selectedHerdId={selectedHerdId}
                  />
                </TabPanel>
              </Tabs>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default DataMeasurmentScreen;
