import React, { CSSProperties } from 'react';
import { Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import styles from './InfoBar.module.scss';

// component props
type InfoBarProps = {
  // info text
  info: JSX.Element | string;
  // assigning extra margin from LHS
  marginLeft?: number;
  /* container style */
  containerStyle?: CSSProperties;
};

// FC
const InfoBar: React.FC<InfoBarProps> = ({ info, marginLeft = 0, containerStyle }) => {
  return (
    <div style={{ display: 'inline-block', ...containerStyle }}>
      <Row className={styles.container} style={{ marginLeft }}>
        <Col>
          <FontAwesomeIcon icon={faInfo} className={styles.infoIcon} />
        </Col>
        <Col span={22}>
          <span style={{ color: '#333333' }}>{info}</span>
        </Col>
      </Row>
    </div>
  );
};

export default InfoBar;
