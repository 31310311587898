import React, { useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input, message, Radio } from 'antd';
import { useNavigate } from 'react-router-dom';
import { loader } from 'graphql.macro';
import {
  AddUserMutationVariables,
  AddUserMutation,
  Enum_User_Role_Enum,
  AllUsersQuery,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from '../graphql/graphql-types';
import styles from './UserForm.module.scss';
import { logger } from '../utils/helpers';

// This is the mutation schema for adding the user in the graphql
const addUserMutation = loader('../graphql/mutations/addUserMutation.graphql');
// This is the mutation schema for updating the user detail in the graphql
const updateUserMutation = loader('../graphql/mutations/updateUserMutation.graphql');
// This is query schema for fetching all the users from graphql
const allUsersQuery = loader('../graphql/queries/allUsersQuery.graphql');

// This is the type of the props which are coming form the parent component
type UserFormProps = {
  // This is the mode of the form whether it is in edit mode or in add mode
  mode: 'add' | 'edit';
  // This is the padding left side of form this is passed as a props because this form is open in drawer also
  padding_left: number;
  // This is the padding top side of form this is passed as a props because this form is open in drawer also
  padding_top: number;
  // This is the data which is going to be edited when form is in edit mode
  dataToEdit?: UpdateUserMutationVariables;
  // This is function for controlling the open and close of the drawer
  setVisibleDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
};

// This is the main react functional component
const UserForm: React.FC<UserFormProps> = (props) => {
  // Here destructuring of the props coming form the parent component
  const { mode, padding_left, padding_top, dataToEdit, setVisibleDrawer } = props;
  // This state controls the loader of the add button
  const [addButtonLoader, setAddButtonLoader] = useState<boolean>(false);
  // this variable is used to store the values of useForm
  const methods = useForm<AddUserMutationVariables>();

  // This navigate holds the useNavigation()
  const navigate = useNavigate();

  // This is the mutation function for adding the data in the graphql
  const [addUsersMutation] = useMutation<AddUserMutation, AddUserMutationVariables>(
    addUserMutation,
  );

  // This is the mutation function for updating the values of user details in the graphql
  const [updateUsersMutation] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    updateUserMutation,
  );

  // Inside this useEffect methods we set the value of our forms initial value when it is in edit mode
  React.useEffect(() => {
    if (
      mode === 'edit' &&
      dataToEdit &&
      dataToEdit.first_name &&
      dataToEdit.last_name &&
      dataToEdit.email &&
      dataToEdit.role
    ) {
      methods.setValue('first_name', dataToEdit.first_name);
      methods.setValue('last_name', dataToEdit.last_name);
      methods.setValue('email', dataToEdit.email);
      methods.setValue('role', dataToEdit.role);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToEdit, mode]);

  // This function is invoked when user hits the add or save button in the form
  const onSubmit = (data: AddUserMutationVariables) => {
    setAddButtonLoader(true);
    const { first_name, last_name, email, role } = data;
    if (mode === 'add') {
      // called mutation with variable
      addUsersMutation({
        variables: {
          first_name,
          last_name,
          email,
          role,
        },
        // updating the cache of user list
        update: (cache, { data: cacheData }) => {
          const allUsersDataCache = cache.readQuery<AllUsersQuery, null>({
            query: allUsersQuery,
          });
          const userCache = allUsersDataCache?.user;
          if (cacheData && cacheData.insert_user_one && userCache) {
            cache.writeQuery({
              query: allUsersQuery,
              data: {
                user:
                  Array.isArray(userCache) && userCache.length > 0
                    ? userCache.concat([{ id: cacheData.insert_user_one.id, ...data }])
                    : [userCache],
              },
            });
          }
        },
      })
        .then(() => {
          setAddButtonLoader(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('User added successfully');
          methods.reset();
          navigate('/users');
        })
        .catch((error: ApolloError) => {
          if (
            error &&
            error.message ===
              'Uniqueness violation. duplicate key value violates unique constraint "user_email_key"'
          ) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error('User already exists.');
          }
          setAddButtonLoader(false);
          logger(error);
        });
    }
    if (dataToEdit && mode === 'edit' && setVisibleDrawer) {
      // update mutation function takes all the variables according to the form fields and update the user detail in graphql
      updateUsersMutation({
        variables: {
          id: dataToEdit.id,
          first_name,
          last_name,
          email,
          role,
        },
      })
        .then(() => {
          setAddButtonLoader(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('User updated successfully');
          methods.reset();
          setVisibleDrawer(false);
          navigate('/users');
        })
        .catch((err: ApolloError) => {
          setAddButtonLoader(false);
          setVisibleDrawer(false);
          logger(err);
        });
    }
  };

  return (
    <form
      className={styles.userFormContainer}
      style={{ paddingLeft: padding_left, paddingTop: padding_top }}
      onSubmit={methods.handleSubmit(onSubmit)}
    >
      <div className={styles.inputContainer}>
        <div className={`${styles.labelName} requiredField`}>User first name:</div>
        <Controller
          className={styles.inputField}
          name="first_name"
          rules={{ required: 'Please enter user first Name and try again' }}
          control={methods.control}
          as={<Input placeholder="Please enter first name of user" />}
        />
      </div>
      {methods.errors.first_name ? (
        <p className={styles.errorText}>{methods.errors.first_name.message}</p>
      ) : null}
      <div className={styles.inputContainer}>
        <div className={`${styles.labelName} requiredField`}>User last name:</div>
        <Controller
          className={styles.inputField}
          name="last_name"
          rules={{ required: 'Please enter user last name and try again' }}
          control={methods.control}
          as={<Input placeholder="Please enter last name of user" />}
        />
      </div>
      {methods.errors.last_name ? (
        <p className={styles.errorText}>{methods.errors.last_name.message}</p>
      ) : null}
      <div className={styles.inputContainer}>
        <div className={`${styles.labelName} requiredField`}>Email ID:</div>
        <Controller
          className={styles.inputField}
          name="email"
          rules={{
            required: {
              value: true,
              message: 'Please enter email ID and try again',
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Please enter a valid email ID.',
            },
          }}
          control={methods.control}
          as={<Input placeholder="Please enter email ID of user" />}
        />
      </div>
      <p className={styles.belowEmailText}>This email will be used for login</p>
      {methods.errors.email ? (
        <p className={styles.errorText}>{methods.errors.email.message}</p>
      ) : null}

      <div className={styles.inputContainer} style={{ paddingTop: 20 }}>
        <div className={`${styles.labelName} requiredField`}>Role:</div>
        <Controller
          name="role"
          rules={{ required: 'Please select role and try again.' }}
          control={methods.control}
          render={({ onChange, value }) => (
            <Radio.Group
              style={{ marginLeft: 30 }}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              value={value as Enum_User_Role_Enum.AppAdmin | Enum_User_Role_Enum.User}
            >
              <Radio value={Enum_User_Role_Enum.AppAdmin}>Admin</Radio>
              <Radio value={Enum_User_Role_Enum.User}>User</Radio>
            </Radio.Group>
          )}
        />
      </div>
      {methods.errors.role ? (
        <p className={styles.errorText}>{methods.errors.role.message}</p>
      ) : null}
      <div className={styles.buttonContainer}>
        <Button
          type="primary"
          className="primaryBtn"
          htmlType="submit"
          style={{ width: 100 }}
          loading={addButtonLoader}
        >
          {mode === 'add' ? 'Add' : 'Save'}
        </Button>
        <Button
          className="secondaryBtn"
          style={{ width: 100, marginLeft: 20 }}
          onClick={() => {
            if (mode === 'add') {
              methods.reset();
              navigate('/users');
            }
            if (mode === 'edit' && setVisibleDrawer) {
              setVisibleDrawer(false);
              methods.setValue('first_name', '');
              methods.setValue('last_name', '');
              methods.setValue('email', '');
              methods.setValue('role', Enum_User_Role_Enum.AppAdmin);
            }
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default UserForm;
