import { ApolloError } from '@apollo/client';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import React, { CSSProperties } from 'react';
import validator from 'validator';
import { Row, Col } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { Surface, Symbols } from 'recharts';
import ScatterChartTriangleShape from '../components/ScatterChartTriangleShape';
import {
  ChartDataPoints,
  DchaDataRenderType,
  DchaDataSeries,
  HeiferChartCalculatedDataPoints,
  HeiferMeasurementDataType,
  SubHerdDataOfSubHerdType,
  LegendPayloadType,
  LineChartDataType,
  ScatterChartDataType,
  UserInputDataType,
  CustomLegendPropsType,
  CustomShapePropsType,
  PenIdsWithColorsType,
} from './types';
import { Herd_Data_Avg_Fields } from '../graphql/graphql-types';
import { colors, dateFormatForDisplay } from './globals';
import commonStyles from '../screens/TransferScreen.module.scss';

// This is a function by which we get the object of particular key
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj;
}

// used to log errors
export const logger = (error: Error | ApolloError): void => {
  console.log('message', error.message);
  console.log('error name', error.name);
  // If ApolloError
  if ((error as ApolloError).graphQLErrors) {
    const apolloError = error as ApolloError;
    console.log('ApolloError.extraInfo', apolloError.extraInfo);
    console.log('ApolloError.graphQLErrors', apolloError.graphQLErrors);
  }
  // any other type of error
  else {
    console.log(error.stack);
    console.log(error);
  }
};

// This addressFormat func is used for showing address
export const addressFormat = (addInfo: {
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zip: string;
}): string => {
  const address = ['addressLine1', 'addressLine2', 'city', 'state', 'zip'];
  const addressSend: string[] = [];
  address.forEach((add) => {
    if (addInfo && add && hasKey(addInfo, add)) {
      const addr: string | null = addInfo[add];
      if (addr) {
        addressSend.push(addr);
      }
    }
  });
  return addressSend.join(', ');
};

// function to render ticks on primary and secondary axes (X and Y axis)
export const renderTicks = (start: number, range: number, counter: number): number[] => {
  // ticks number array to render on respective axis
  const ticks: number[] = [start];
  for (let i = 0; i < range; i++) {
    ticks.push(ticks[i] + counter);
  }
  return ticks;
};

// function to render DCHA targets data points on x axis and DCHA standards data points on y-axis
export const renderDchaDataPoints = (
  dchaInputData: DchaDataSeries[],
  matureBodyWeight?: number,
  matureHipHeight?: number,
): DchaDataRenderType[] => {
  // output data to render DCHA data points
  const dchaOutputData: DchaDataRenderType[] = [];

  if (Array.isArray(dchaInputData) && dchaInputData.length > 0) {
    dchaInputData.forEach((item) => {
      if (item.percentStandardWeight && matureBodyWeight) {
        dchaOutputData.push({
          targetAge: item.targetAge,
          percentStandardWeight: matureBodyWeight * (item.percentStandardWeight / 100),
          displayValue: `${item.percentStandardWeight}%`,
        });
      }
      if (item.percentStandardHeight && matureHipHeight) {
        dchaOutputData.push({
          targetAge: item.targetAge,
          percentStandardHeight: matureHipHeight * (item.percentStandardHeight / 100),
          displayValue: `${item.percentStandardHeight}%`,
        });
      }
    });
  }
  return dchaOutputData;
};

// function to store sub herd data average fields values fetched from query
export const handleAvgSubHerdDataFields = (
  queryData: SubHerdDataOfSubHerdType,
): Herd_Data_Avg_Fields => ({
  avg_bw_3rdlact_cow: queryData.avg_bw_3rdlact_cow as number,
  avg_height_3rdlact_cow: queryData.avg_height_3rdlact_cow as number,
  avg_birth_bw_calves: queryData.avg_birth_bw_calves as number,
  avg_birth_height_calves: queryData.avg_birth_height_calves as number,
  avg_spc_heifers: queryData.avg_spc_heifers as number,
  current_age_1st_calving: queryData.current_age_1st_calving as number,
  goal_age_1st_calving: queryData.goal_age_1st_calving as number,
});

// function to return calculated sub herd data evaluated from user input data
export const handleCalculatedSubHerdData = (
  userInputData: Herd_Data_Avg_Fields,
): UserInputDataType | null => {
  // storing average sub herd data fields
  if (userInputData) {
    return {
      avg_bw_3rdlact_cow: {
        title: 'Avg. body weight of 3rd lactation cows',
        value: userInputData.avg_bw_3rdlact_cow,
        displayValue: `${userInputData.avg_bw_3rdlact_cow as number} lbs`,
      },
      avg_height_3rdlact_cow: {
        title: 'Avg. height of 3rd lactation cows',
        value: userInputData.avg_height_3rdlact_cow,
        displayValue: `${userInputData.avg_height_3rdlact_cow as number} inches`,
      },
      avg_birth_bw_calves: {
        title: 'Avg. birth body weight of calves',
        value: userInputData.avg_birth_bw_calves,
        displayValue: `${userInputData.avg_birth_bw_calves as number} lbs`,
      },
      avg_birth_height_calves: {
        title: 'Avg. birth height of calves',
        value: userInputData.avg_birth_height_calves,
        displayValue: `${userInputData.avg_birth_height_calves as number} inches`,
      },
      avg_spc_heifers: {
        title: 'Avg. services per conception for heifers',
        value: userInputData.avg_spc_heifers,
        displayValue: `${userInputData.avg_spc_heifers as number}`,
      },
      current_age_1st_calving: {
        title: 'Current age of first calving',
        value: userInputData.current_age_1st_calving,
        displayValue: `${userInputData.current_age_1st_calving as number} months`,
      },
      goal_age_1st_calving: {
        title: 'Goal for age of first calving',
        value: userInputData.goal_age_1st_calving,
        displayValue: `${userInputData.goal_age_1st_calving as number} months`,
      },
    };
  }
  return null;
};

/* function to render legend payload based on the data present
if data is present, particular legend will render else hides that legend
*/
export const handleLegendPayloadRender = (
  payload: LegendPayloadType,
  psuData: LineChartDataType[] | ChartDataPoints,
  purinaModelData: LineChartDataType[] | ChartDataPoints,
  subHerdHeiferData: ChartDataPoints | ScatterChartDataType[],
  historicalHeiferData: ChartDataPoints | ScatterChartDataType[],
): LegendPayloadType => {
  // storing filtered legend payload data based on data present
  let legendPayload: Payload<JSX.Element, string>[] = payload;

  // checking if psu2013 data is present
  if (!Array.isArray(psuData) || psuData.length === 0) {
    legendPayload = legendPayload.filter((item) => {
      return item.id !== 'psu' && item.id !== 'psuBw' && item.id !== 'psuHipHt';
    });
  }

  // checks if purina model is present
  if (!Array.isArray(purinaModelData) || purinaModelData.length === 0) {
    legendPayload = legendPayload.filter((item) => {
      return item.id !== 'purina' && item.id !== 'purinaModelBw' && item.id !== 'purinaModelHipHt';
    });
  }

  // checks if sub herd heifers data is present
  if (!Array.isArray(subHerdHeiferData) || subHerdHeiferData.length === 0) {
    legendPayload = legendPayload.filter((item) => {
      return (
        item.id !== 'subHerdHeifer' &&
        item.id !== 'subHerdHeifersHipHt' &&
        item.id !== 'subHerdHeifersBw'
      );
    });
  }
  // checks if historical heifer data is present
  if (!Array.isArray(historicalHeiferData) || historicalHeiferData.length === 0) {
    legendPayload = legendPayload.filter((item) => {
      return (
        item.id !== 'historicalHeifer' &&
        item.id !== 'historicalSubHerdBw' &&
        item.id !== 'historicalSubHerdHipHt'
      );
    });
  }
  return legendPayload;
};

// function to render error message while fetching a query
export const renderQueryErrorMsg = (error: ApolloError): JSX.Element => (
  <p style={{ color: 'red', textAlign: 'center' }}>{error.message}</p>
);

// function to calculate data points for all heifers charts
export const calcHeiferChartDataPoints = (
  heiferMeasurementsData: HeiferMeasurementDataType[] | undefined,
): HeiferChartCalculatedDataPoints => {
  // heifer's weight chart data points for either sub herd heifer data or historical data
  const weightChartDataPoints: ChartDataPoints = [];
  // heifer's height chart data points for either sub herd heifer data or historical data
  const heightChartDataPoints: ChartDataPoints = [];
  // heifer's percent mature weight height chart body weight data
  const percentChartBwDataPoints: ChartDataPoints = [];
  // heifer's percent mature weight height chart hip height data
  const percentChartHipHtDataPoints: ChartDataPoints = [];

  // checking if fetched query data is present
  if (Array.isArray(heiferMeasurementsData) && heiferMeasurementsData.length > 0) {
    heiferMeasurementsData.forEach((item) => {
      // storing measurement date age
      const measurementDateAge = item.measurement_date_age as number | null;
      /* Variable to store pen name */
      const penName = item.pen ? (item.pen as { name: string | undefined }).name : undefined;
      /* Variable to store pen name */
      const penId = item.pen_id || undefined;

      weightChartDataPoints.push({
        age: measurementDateAge,
        weight: item.body_weight as number | null,
        pen_id: penId,
        pen_name: penName,
      });
      heightChartDataPoints.push({
        age: measurementDateAge,
        height: item.height as number | null,
        pen_id: penId,
        pen_name: penName,
      });
      percentChartBwDataPoints.push({
        age: measurementDateAge,
        weight: item.pc_mature_body_weight as number,
        pen_id: penId,
        pen_name: penName,
      });
      percentChartHipHtDataPoints.push({
        age: measurementDateAge,
        height: item.pc_mature_height as number,
        pen_id: penId,
        pen_name: penName,
      });
    });
  }
  return {
    weightChartDataPoints,
    heightChartDataPoints,
    percentChartBwDataPoints,
    percentChartHipHtDataPoints,
  };
};

// function that takes max and min age interval values from form and returns range of x-axis ticks
export const getXAxisTicksRange = (xAxisMaxInterval: number, xAxisMinInterval: number): number => {
  // checking if difference between max age(upperLimit) interval and min age interval(lowerLimit) is an even number
  const isDiffEven = (xAxisMaxInterval - xAxisMinInterval) % 2 === 0;

  return !isDiffEven
    ? (xAxisMaxInterval - xAxisMinInterval - 1) / 2
    : (xAxisMaxInterval - xAxisMinInterval) / 2;
};

// method to render form field error
export const renderFormErr = (err: string | undefined): JSX.Element => (
  <p style={{ color: 'red', marginTop: 5 }}>{err}</p>
);

// reusable function to handle render of warning message
export const renderWarningMessage = (
  message: JSX.Element | string,
  containerStyle?: CSSProperties,
): JSX.Element => (
  <div>
    <div style={{ display: 'inline-block', ...containerStyle }}>
      <Row className={commonStyles.warningText}>
        <Col>
          <WarningFilled className={commonStyles.warningIcon} />
        </Col>
        <Col>
          <span style={{ color: '#333333' }}>{message}</span>
        </Col>
      </Row>
    </div>
  </div>
);

// common method to calculate error bar limit for X,Y axis of heifer charts
export const calErrBarLimit = (
  dataPointVal: number | null | undefined,
  errRatio: number,
): number => {
  // returning single value for rendering lowest and highest err values for input body weight
  return (!dataPointVal ? 0 : dataPointVal) * errRatio;
};

// function to render remarks message
export const renderRemarks = (msg: string): JSX.Element => (
  <p style={{ padding: 0, margin: 0 }}>
    <b style={{ color: 'red' }}>Error</b>: {msg}
  </p>
);

/* Function to render chart legend icon */
export const renderLegendIcon = (type: string, id: string): JSX.Element | null => {
  if (type === 'line') {
    return (
      <svg
        className="recharts-surface"
        width="12"
        height="12"
        viewBox="0 0 32 32"
        version="1.1"
        style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: 4, marginTop: 2 }}
      >
        <path
          strokeWidth="4"
          fill="none"
          stroke="#F20505"
          d="M0,16h10.666666666666666 A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16 H32M21.333333333333332,16 A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
          className="recharts-legend-icon"
        />
      </svg>
    );
  }
  if (type === 'plainline') {
    if (id === 'dchaStandards' || id === 'dchaStandardsHipHt' || id === 'dchaStandardsBw') {
      return (
        <svg
          className="recharts-surface"
          width="12"
          height="12"
          viewBox="0 0 32 32"
          version="1.1"
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginRight: 4,
            marginTop: 2,
          }}
        >
          <line
            strokeWidth="4"
            fill="none"
            stroke="#F20505"
            strokeDasharray="10 8"
            x1="0"
            y1="16"
            x2="32"
            y2="16"
            className="recharts-legend-icon"
          />
        </svg>
      );
    }

    if (id === 'purina' || id === 'purinaModelHipHt' || id === 'purinaModelBw') {
      return (
        <svg
          className="recharts-surface"
          width="12"
          height="12"
          viewBox="0 0 32 32"
          version="1.1"
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginRight: 4,
            marginTop: 2,
          }}
        >
          <line
            strokeWidth="4"
            fill="none"
            stroke="#BE0001"
            strokeDasharray="0"
            x1="0"
            y1="16"
            x2="32"
            y2="16"
            className="recharts-legend-icon"
          />
        </svg>
      );
    }

    if (id === 'psu' || id === 'psuHipHt' || id === 'psuBw') {
      return (
        <svg
          className="recharts-surface"
          width="12"
          height="12"
          viewBox="0 0 32 32"
          version="1.1"
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginRight: 4,
            marginTop: 2,
          }}
        >
          <line
            strokeWidth="4"
            fill="none"
            stroke="#4E5F72"
            strokeDasharray="0"
            x1="0"
            y1="16"
            x2="32"
            y2="16"
            className="recharts-legend-icon"
          />
        </svg>
      );
    }
  }

  return null;
};

/* Function to assign colors to unique pen Ids to show in charts */
export const assigningColorsToUniquePenIds = (data: ChartDataPoints): PenIdsWithColorsType => {
  const result = data
    .map((item) => item.pen_id as string)
    .filter((val, index, self) => self.indexOf(val) === index)
    .map((item, index) => ({
      pen_id: item,
      color: colors[index] || '#A9A9A9',
      pen_name: data.find((val) => val.pen_id === item)?.pen_name,
    }));

  return result;
};

/* Function to render custom chart legend */
export const renderChartLegend = (props: any): JSX.Element => {
  /* Props destructuring */
  const { payload, data } = props as CustomLegendPropsType & {
    data: PenIdsWithColorsType;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {payload.map((entry, index) => (
          <>
            {entry.type === 'line' || entry.type === 'plainline' ? (
              renderLegendIcon(entry.type, entry.id)
            ) : (
              <Surface
                width={10}
                height={10}
                style={{ marginRight: 4, marginTop: entry.type === 'triangle' ? 4 : 2 }}
              >
                <Symbols
                  cx={5}
                  cy={5}
                  type={entry.type === 'triangle' ? 'triangle' : 'circle'}
                  size={50}
                  fill="#000000"
                />
              </Surface>
            )}

            <span key={`item-${index.toString()}`} style={{ marginRight: 10 }}>
              {entry.value}
            </span>
          </>
        ))}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {data.map((entry, index) => (
          <>
            <Surface width={10} height={10} style={{ marginRight: 4, marginTop: -1 }}>
              <Symbols cx={5} cy={5} type="square" size={60} fill={entry.color} />
            </Surface>
            <span
              key={`item-${index.toString()}`}
              style={{ marginRight: 10, fontWeight: 'bold', fontSize: 12, color: '#585858' }}
            >
              {entry.pen_name || 'Unnamed'}
            </span>
          </>
        ))}
      </div>
    </div>
  );
};

/* Function to render scatter plot shapes */
export const CustomizedScatterPointShape = (props: any): JSX.Element => {
  /* Props destructuring */
  const { cx, cy, pen_id, type, data } = props as CustomShapePropsType & {
    data: PenIdsWithColorsType;
  };

  /* Variable to store shape color */
  const shapeColor = data.find((item) => item.pen_id === pen_id)?.color;

  return (
    <g>
      {type === 'historical' ? (
        <ScatterChartTriangleShape fillColor={shapeColor || '#000'} cx={cx} cy={cy} />
      ) : (
        <circle
          cx={cx}
          cy={cy}
          r={3}
          stroke={shapeColor || '#000'}
          strokeWidth={0.5}
          fill={shapeColor || '#000'}
        />
      )}
    </g>
  );
};

// function to render Y-axis label using svg <text> element
export const renderYAxisLabel = ({
  x,
  y,
  label,
}: Record<'x' | 'y', number | string> & {
  // y axis label
  label: string | JSX.Element;
}): JSX.Element => (
  <text offset={1} x={x} y={y} className="recharts-text recharts-label" textAnchor="middle">
    {label}
  </text>
);

// function used to check if entered date is valid or not
export const checkIsDateValid = (date: string): boolean => {
  // checks if entered date is valid date
  const isDateValid =
    validator.isDate(date, {
      format: dateFormatForDisplay,
      delimiters: ['/', '-'],
    }) ||
    validator.isDate(date, {
      format: 'M/D/YYYY',
      delimiters: ['/', '-'],
    }) ||
    validator.isDate(date, {
      format: 'MM/D/YYYY',
      delimiters: ['/', '-'],
    }) ||
    validator.isDate(date, {
      format: 'M/DD/YYYY',
      delimiters: ['/', '-'],
    });
  return isDateValid;
};

export default null;
