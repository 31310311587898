import React from 'react';
import moment from 'moment-timezone';
import styles from './ViewHeiferMeasurementData.module.scss';
import { HeiferMeasurementDataType } from '../utils/types';
import { dateFormat, dateFormatForDisplay } from '../utils/globals';

// This is the type of props coming from the parent component
type ViewHeiferMeasurementDataProps = {
  // This is the detail of the measurement which is going to be visible in the view measurement drawer
  measurementData: HeiferMeasurementDataType;
  // This is the herd name which is going to be visible in heifer info section
  herdName: string | undefined;
  // This is the sub herd name which is going to be visible in heifer info section
  subHerdName: string | undefined;
  // This is the pen name which is going to be visible in heifer info section
  penName: string | undefined;
};

// this is the main functional component
const ViewHeiferMeasurementData: React.FC<ViewHeiferMeasurementDataProps> = ({
  measurementData,
  herdName = '-',
  subHerdName = '-',
  penName = '-',
}) => {
  return (
    <div style={{ padding: '30px 15px' }}>
      <div className={styles.fieldContainer}>
        <span>Date of Measurement</span>
        <span className={styles.valueText}>
          {moment(measurementData.measurement_date, dateFormat).format(dateFormatForDisplay)}
        </span>
      </div>
      <div className={styles.fieldContainer}>
        <span>Body Weight (lbs)</span>
        <span className={styles.valueText}>
          {measurementData.body_weight || measurementData.body_weight === 0
            ? (measurementData.body_weight as number).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '-'}
        </span>
      </div>
      <div className={styles.fieldContainer}>
        <span>Height (inches)</span>
        <span className={styles.valueText}>
          {measurementData.height || measurementData.height === 0
            ? (measurementData.height as number).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : '-'}
        </span>
      </div>
      <div className={styles.fieldContainer}>
        <span>Age on date of measurement</span>
        <span className={styles.valueText}>
          {(measurementData.measurement_date_age as number).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{' '}
          months
        </span>
      </div>
      <div className={styles.fieldContainer}>
        <span>% Mature Body Weight</span>
        <span className={styles.valueText}>
          {measurementData.pc_mature_body_weight || measurementData.pc_mature_body_weight === 0
            ? `${(measurementData.pc_mature_body_weight as number).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} %`
            : '-'}
        </span>
      </div>
      <div className={styles.fieldContainer}>
        <span>% Mature Height</span>
        <span className={styles.valueText}>
          {measurementData.pc_mature_height || measurementData.pc_mature_height === 0
            ? `${(measurementData.pc_mature_height as number).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} %`
            : '-'}
        </span>
      </div>
      <h2 style={{ marginBottom: 0, marginTop: 40 }}>Heifer info</h2>
      <p style={{ fontStyle: 'italic', fontSize: 13 }}>
        This is heifer related information at the time of measurement
      </p>
      <div className={styles.fieldContainer}>
        <span>Herd ID</span>
        <span className={styles.valueText}>{herdName}</span>
      </div>
      <div className={styles.fieldContainer}>
        <span>Sub-herd ID</span>
        <span className={styles.valueText}>{subHerdName}</span>
      </div>
      <div className={styles.fieldContainer}>
        <span>Pen ID</span>
        <span className={styles.valueText}>{penName}</span>
      </div>
    </div>
  );
};

export default ViewHeiferMeasurementData;
