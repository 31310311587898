import React from 'react';
import SignInForm from '../forms/SignInForm';
import styles from './SignInScreen.module.scss';

// This is the main React functional component
const SignInScreen: React.FC = () => {
  return (
    <div className={styles.signInContainer}>
      <SignInForm />
    </div>
  );
};

export default SignInScreen;
