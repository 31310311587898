import React from 'react';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Scatter,
  ReferenceLine,
  Area,
  ReferenceDot,
} from 'recharts';
import '../App.scss';
import { ChartDataPoints, ChartDataType } from '../utils/types';
import {
  assigningColorsToUniquePenIds,
  calErrBarLimit,
  CustomizedScatterPointShape,
  getXAxisTicksRange,
  handleLegendPayloadRender,
  renderChartLegend,
  renderDchaDataPoints,
  renderTicks,
  renderYAxisLabel,
} from '../utils/helpers';
import {
  heiferHeightErrRatio,
  purinaErrorRangeColor,
  purinaErrorRangeStrokeColor,
  weightHeightChartLegendPayload,
} from '../utils/globals';
import styles from './Charts.module.scss';

// type def for component props
type HeiferHeightChartProps = ChartDataType & {
  // average height of 3rd lactation cow
  matureHipHeight: number;
};

// functional component
const HeiferHeightChart: React.FC<HeiferHeightChartProps> = ({
  psu2013Data,
  purinaModelData,
  subHerdHeiferData,
  historicalHeiferData,
  dchaData,
  xAxisMinInterval = 0,
  xAxisMaxInterval = 28,
  matureHipHeight,
}) => {
  // storing sub herd heifers data to display error bar for each data point having error limit
  const deviatedSubHerdHeiferData: ChartDataPoints = (subHerdHeiferData as ChartDataPoints).reduce(
    (filtered: ChartDataPoints, option) => {
      if (option.height && option.height <= 2000) {
        filtered.push({
          ...option,
          heightErrLimit: calErrBarLimit(option.height, heiferHeightErrRatio),
        });
      }
      return filtered;
    },
    [],
  );
  // storing historical heifer data with error range for each data point to plot
  const deviatedHistoricalData: ChartDataPoints = (historicalHeiferData as ChartDataPoints).map(
    (data) => ({ ...data, heightErrLimit: calErrBarLimit(data.height, heiferHeightErrRatio) }),
  );

  // calculates and stores the data of range for purina error area
  const purinaModelErrorRangeArea = (purinaModelData as ChartDataPoints)
    .map((data) => ({
      ...data,
      heightErrLimit: calErrBarLimit(data.height, heiferHeightErrRatio),
    }))
    .map((data) => {
      // stores the value of height
      const height = !data.height ? 0 : data.height;
      return {
        range: [height + data.heightErrLimit, height - data.heightErrLimit],
        age: data.age,
      };
    });

  /* Variable to store merged chart data (historical and sub herd) */
  const mergedChartData = [...subHerdHeiferData, ...historicalHeiferData] as ChartDataPoints;

  /* Variable to store data for pen IDs with there corresponding colors to be shown in graph */
  const dataForPenIdsWithColors = assigningColorsToUniquePenIds(mergedChartData);

  return (
    <div className={styles.chartWrapper} style={{ margin: '0 5% 40px 5%' }}>
      <div className={styles.chartTitle}>Heifer Height Chart</div>
      <ResponsiveContainer id="heiferHeightChartContainer" width={1180} height={500}>
        <ComposedChart
          margin={{
            top: 5,
            right: 25,
            left: 20,
            bottom: 20,
          }}
          data={purinaModelErrorRangeArea}
        >
          <CartesianGrid stroke="#767676" />
          <XAxis
            // eslint-disable-next-line
            // @ts-ignore
            stroke="#000"
            ticks={renderTicks(
              xAxisMinInterval,
              getXAxisTicksRange(xAxisMaxInterval, xAxisMinInterval),
              2,
            )}
            type="number"
            // eslint-disable-next-line
            domain={[xAxisMinInterval, xAxisMaxInterval]}
            allowDataOverflow
            interval={0}
            dataKey="age"
            orientation="bottom"
            label={{
              value: 'Age (months)',
              position: 'insideBottom',
              offset: -16,
              style: { fontWeight: 500, fontSize: 16, color: '#6E6E6E' },
            }}
            tick={{ fontSize: 12 }}
          />
          <YAxis
            // eslint-disable-next-line
            // @ts-ignore
            stroke="#000"
            yAxisId={0}
            interval={0}
            allowDataOverflow
            dataKey="height"
            tickFormatter={(value: number) => {
              // renders the tick value which is multiple of 5, else hides that tick value
              return value % 5 === 0 ? value.toString() : '';
            }}
            domain={[20, 65]}
            tick={{ fontSize: 12 }}
            ticks={renderTicks(20, 45, 1)}
            label={{
              value: 'Hip Height (inches)',
              angle: -90,
              position: 'insideLeft',
              offset: -10,
              style: { fontWeight: 700, fontSize: 16, color: '#6E6E6E' },
            }}
          />
          <YAxis yAxisId={1} tick={{ fontSize: 12 }} orientation="right" />
          <YAxis
            yAxisId={2}
            tickLine={false}
            axisLine={false}
            width={1}
            orientation="right"
            // label={{
            //   value: 'Percent of Mature Size',
            //   position: 'top',
            //   style: {
            //     fontSize: 16,
            //     fontWeight: 700,
            //     color: '#6e6e6e',
            //   },
            // }}
            label={renderYAxisLabel({
              x: 1166.5,
              y: 18,
              label: (
                <>
                  <tspan x={1130} dy={`${0}em`} style={{ fontSize: 12 }}>
                    Percent
                  </tspan>
                  <tspan style={{ fontSize: 12 }} x={1130} y={18 + 14}>
                    of Mature
                  </tspan>
                  <tspan style={{ fontSize: 12 }} x={1130} y={32 + 14}>
                    Size
                  </tspan>
                </>
              ),
            })}
          />
          <Legend
            wrapperStyle={{ alignItems: 'center', padding: '0 0 20px 0' }}
            margin={{ top: 20, bottom: 20 }}
            verticalAlign="top"
            iconSize={12}
            payload={handleLegendPayloadRender(
              weightHeightChartLegendPayload,
              psu2013Data,
              purinaModelData,
              subHerdHeiferData,
              historicalHeiferData,
            )}
            content={(props) => renderChartLegend({ ...props, data: dataForPenIdsWithColors })}
          />
          <Area
            type="monotone"
            dataKey="range"
            stroke={purinaErrorRangeStrokeColor}
            fill={purinaErrorRangeColor}
          />
          {renderDchaDataPoints(dchaData, undefined, matureHipHeight).map((entry) => (
            <Line
              // eslint-disable-next-line
              // @ts-ignore
              data={[
                { age: entry.targetAge, height: 0 },
                { age: entry.targetAge, height: entry.percentStandardHeight },
              ]}
              dataKey="height"
              name="DCHA Targets"
              type="monotone"
              stroke="#F20505"
              strokeWidth={2}
              legendType="none"
              key={`${entry.displayValue}-${entry.targetAge as number}`}
              isAnimationActive={false}
            />
          ))}
          {renderDchaDataPoints(dchaData, undefined, matureHipHeight).map((entry) => (
            <ReferenceLine
              // eslint-disable-next-line
              // @ts-ignore
              label={{ value: entry.displayValue, position: 'right', fontSize: 12 }}
              name="DCHA Standards"
              y={entry.percentStandardHeight}
              strokeWidth={1.6}
              stroke="#F20505"
              strokeDasharray="10 8"
              key={`${entry.targetAge as number}-${entry.displayValue}`}
            />
          ))}
          {renderDchaDataPoints(dchaData, undefined, matureHipHeight).map((entry) => (
            <ReferenceDot
              r={3.4}
              isFront
              y={entry.percentStandardHeight}
              fill="yellow"
              stroke="red"
              x={0}
              strokeWidth={1.6}
            />
          ))}
          {renderDchaDataPoints(dchaData, undefined, matureHipHeight).map((entry) => (
            <ReferenceDot
              r={3.4}
              isFront
              y={entry.percentStandardHeight}
              fill="yellow"
              stroke="red"
              x={xAxisMaxInterval}
              strokeWidth={1.6}
            />
          ))}

          <Line
            // eslint-disable-next-line
            // @ts-ignore
            data={purinaModelData}
            dataKey="height"
            name="Purina Model"
            legendType="plainline"
            dot={false}
            strokeWidth={2.8}
            type="monotone"
            stroke="#BE0001"
            activeDot={false}
            isAnimationActive={false}
          />
          <Line
            // eslint-disable-next-line
            // @ts-ignore
            data={psu2013Data}
            dataKey="height"
            name="PSU 2013"
            legendType="plainline"
            dot={false}
            strokeWidth={2}
            type="monotone"
            stroke="#4E5F72"
            activeDot={false}
            isAnimationActive={false}
          />
          <Scatter
            dataKey="height"
            className=""
            shape={<CustomizedScatterPointShape type="subHerd" data={dataForPenIdsWithColors} />}
            legendType="circle"
            data={deviatedSubHerdHeiferData}
            fill="#000"
            strokeWidth={0.4}
            // sub-herd is replaced with current
            name="Current Heifers"
          />
          <Scatter
            dataKey="height"
            className=""
            shape={<CustomizedScatterPointShape type="historical" data={dataForPenIdsWithColors} />}
            legendType="triangle"
            data={deviatedHistoricalData}
            fill="#1075E6"
            stroke="#1075E6"
            strokeWidth={1.2}
            // sub-herd information is replaced heifers
            name="Historical Heifers"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HeiferHeightChart;
