import React from 'react';
import { Space } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

const RequiredAlert: React.FC = () => (
  <Space align="start" style={{ color: '#17A697' }}>
    <InfoCircleFilled />
    <span>Required</span>
  </Space>
);

export default RequiredAlert;
