import React from 'react';
import { Button, InputNumber, Space } from 'antd';
import { UseFormMethods } from 'react-hook-form';
import ECFormItem from './ECFormItem';
import { renderFormErr } from '../utils/helpers';
import { AgeInterval, DataRangeFormType, HeiferChartFormType } from '../utils/types';

// component type definitions
type AgeIntervalOptionsProps = {
  // accessing react hook form methods for heifer charts in Analyze and Data section
  formMethods: UseFormMethods<DataRangeFormType> | UseFormMethods<HeiferChartFormType>;
  // function to set updated age interval options to pass under all charts to render x-axis range
  setAgeInterval: React.Dispatch<React.SetStateAction<AgeInterval>>;
};

const AgeIntervalOptions: React.FC<AgeIntervalOptionsProps> = ({ formMethods, setAgeInterval }) => (
  <div>
    <h3 style={{ marginTop: 30 }}>Age interval</h3>
    <p>Select age range to display:</p>
    <Space style={{ flex: 1, alignItems: 'baseline', marginTop: -10 }}>
      <p>From</p>
      <ECFormItem
        rules={{
          required: true,
          validate: {
            minAgeErr: (value: number) => {
              return (
                value <
                  (formMethods as UseFormMethods<DataRangeFormType>).getValues('maxAgeInterval') ||
                'Min. range cannot be greater than max. range'
              );
            },
          },
        }}
        name="minAgeInterval"
        control={formMethods.control}
        as={<InputNumber min={0} max={27} style={{ width: 55 }} />}
      />
      <p>months to</p>
      <ECFormItem
        rules={{
          required: true,
        }}
        name="maxAgeInterval"
        control={formMethods.control}
        as={<InputNumber min={1} max={28} style={{ width: 55 }} />}
      />
      <p>months</p>
    </Space>
    <Space size={35} direction="horizontal" style={{ marginTop: 20 }} align="start">
      <Button
        className="primaryBtn"
        size="small"
        style={{ padding: '0 20px' }}
        onClick={async () => {
          const resolved = await formMethods.trigger(['minAgeInterval', 'maxAgeInterval']);
          if (resolved) {
            setAgeInterval({
              minAgeInterval: (formMethods as UseFormMethods<DataRangeFormType>).getValues(
                'minAgeInterval',
              ),
              maxAgeInterval: (formMethods as UseFormMethods<DataRangeFormType>).getValues(
                'maxAgeInterval',
              ),
            });
          }
        }}
      >
        Apply
      </Button>
      <Button
        onClick={(): void => {
          formMethods.setValue('minAgeInterval', 0);
          formMethods.setValue('maxAgeInterval', 28);
          formMethods.clearErrors('minAgeInterval');
          formMethods.clearErrors('maxAgeInterval');
          setAgeInterval({
            minAgeInterval: 0,
            maxAgeInterval: 28,
          });
        }}
        className="secondaryBtn"
        size="small"
        style={{ border: '1px solid #000', padding: '0 20px' }}
      >
        Reset
      </Button>
    </Space>
    {(formMethods.errors.minAgeInterval && formMethods.errors.minAgeInterval.type === 'required') ||
    (formMethods.errors.maxAgeInterval && formMethods.errors.maxAgeInterval.type === 'required')
      ? renderFormErr('Enter values in both fields and try again.')
      : null}
    {formMethods.errors.minAgeInterval && formMethods.errors.minAgeInterval.type === 'minAgeErr'
      ? renderFormErr(formMethods.errors.minAgeInterval.message)
      : null}
  </div>
);

export default AgeIntervalOptions;
