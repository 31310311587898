import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ApolloError } from '@apollo/client';
import { FirebaseError } from 'firebase';
import client from '../graphql/apolloClient';
import styles from './Header.module.scss';
import logo from '../assests/images/appLogo.png';
import { Enum_User_Role_Enum } from '../graphql/graphql-types';
import { firebaseAuth } from '../utils/firebase';
import { logger } from '../utils/helpers';

// This is the type of props which is coming from the parent component
type HeaderProps = {
  // roleOfUser is whether admin or user will show user menu item to admin only
  roleOfUser: Enum_User_Role_Enum | undefined;
};

// This function less component is rendering the header of our app in which menus are present
const Header: React.FC<HeaderProps> = (props) => {
  // Destructuring props
  const { roleOfUser } = props;
  // location is object coming from useLocation hooks of react router dom
  const location = useLocation();
  // navigate variable contain useNavigate hooks
  const navigate = useNavigate();

  // When user click on the logo of the app then this function gets invoke
  const handleOnClickLogo = (): void => {
    navigate('/analyze');
  };

  return (
    <div className={styles.headerContainer}>
      <div
        style={{ cursor: 'pointer', backgroundColor: 'none' }}
        onClick={handleOnClickLogo}
        onKeyPress={handleOnClickLogo}
        role="button"
        tabIndex={0}
      >
        <img src={logo} alt="purinaEquine" height="50" width="50" />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          paddingLeft: 20,
        }}
      >
        <div className={styles.menuList}>
          <Link
            className={location.pathname.includes('data') ? styles.linkActive : styles.link}
            to="/data"
          >
            Data
          </Link>
          <Link
            className={location.pathname.includes('/analyze') ? styles.linkActive : styles.link}
            to="/analyze"
          >
            Analyze
          </Link>
          {roleOfUser === Enum_User_Role_Enum.AppAdmin ? (
            <Link
              className={location.pathname.includes('users') ? styles.linkActive : styles.link}
              to="/users"
            >
              Users
            </Link>
          ) : null}
          <Link
            className={location.pathname.includes('settings') ? styles.linkActive : styles.link}
            to="/settings"
          >
            Settings
          </Link>
        </div>
        <Button
          onClick={(): void => {
            // firestore logout
            firebaseAuth
              .signOut()
              .then((): void => {
                // reset apollo cache
                client.resetStore().catch((clientErr: ApolloError) => {
                  logger(clientErr);
                });
              })
              .catch((firebaseAuthError: FirebaseError) => {
                logger(firebaseAuthError);
              });
          }}
          tabIndex={0}
          className={styles.logoutContainer}
          type="text"
          style={{ alignItems: 'center', justifyContent: 'baseline' }}
        >
          <span>
            <LogoutOutlined />
          </span>
          <span style={{ fontSize: 11 }}>Logout</span>
        </Button>
      </div>
    </div>
  );
};

export default Header;
