import React, { ReactText, useState } from 'react';
import moment from 'moment-timezone';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import { Button, Input, message, Drawer, Modal, Table, Space, Popconfirm } from 'antd';
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  WarningFilled,
  DeleteOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { loader } from 'graphql.macro';
import { FilterDropdownProps } from 'antd/es/table/interface';
import {
  DeleteHeiferMutation,
  DeleteHeiferMutationVariables,
  HeifersOfHerdQuery,
  HeifersOfHerdQueryVariables,
} from '../graphql/graphql-types';
import Heading from '../components/Heading';
import styles from './HeifersOfSubHerdScreen.module.scss';
import { logger } from '../utils/helpers';
import HeiferForm from '../forms/HeiferForm';
import { HerdDataType, HeiferType } from '../utils/types';
import InfoBar from '../components/InfoBar';
import BulkAddHeiferComponent from '../components/BulkAddHeiferComponent';
import ViewHeiferDataScreen from './ViewHeiferDataScreen';
import { dateFormat, dateFormatForDisplay } from '../utils/globals';
// importing deleteHeifer mutation
const deleteHeiferMutation = loader('../graphql/mutations/deleteHeiferMutation.graphql');

// Table column component
const { Column } = Table;
// importing and loading heifersOfSubHerd query
const heifersOfSubHerdQuery = loader('../graphql/queries/heifersOfHerdQuery.graphql');

// FC props
type HeifersOfSubHerdScreenProps = {
  // subHerdId
  idOfSubHerd: string;
  // all herds data
  herdData?: HerdDataType;
  // selected herd id from herd select options
  selectedHerdId?: number;
  // subHerd name is used to show the name of subHerd in view measurement drawer as subHerd Id
  selectedSubHerdName: string | undefined;
  // herd name is used to show the name of herd in view measurement drawer as herd Id
  selectedHerdName: string | undefined;
};

// Functional Component
const HeifersOfSubHerdScreen: React.FC<HeifersOfSubHerdScreenProps> = (props) => {
  // DataMeasurmentScreen props
  const { idOfSubHerd, herdData, selectedHerdId, selectedSubHerdName, selectedHerdName } = props;
  // storing add heifer modal visibility state
  const [addHeiferModal, setAddHeiferModal] = useState<boolean>(false);
  // drawer visibility state in edit mode
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  // storing heifer id for editing and deleting the heifer
  const [heiferId, setHeiferId] = useState<string>('');
  // loading state for delete button
  const [btnLoader, setBtnLoader] = useState<boolean>(false);
  // This state holds the value to show bulk add heifer modal or not
  const [showBulkAddHeiferModal, setShowBulkAddHeiferModal] = useState<boolean>(false);
  // controls visibility state for selected heifer measurements
  const [showHeiferMeasurements, setShowHeiferMeasurements] = useState<boolean>(false);

  // delete heifer mutation
  const [deleteHeifer] = useMutation<DeleteHeiferMutation, DeleteHeiferMutationVariables>(
    deleteHeiferMutation,
  );

  // fetching data for heifers
  const { data: heifersOfSubHerdData } = useQuery<HeifersOfHerdQuery, HeifersOfHerdQueryVariables>(
    heifersOfSubHerdQuery,
    {
      variables: { herd_id: idOfSubHerd },
      fetchPolicy: 'network-only',
    },
  );

  // storing the search results after filtering heifer ID with dom ref
  let searchInput: Input | null;

  // heifer data fetched from query
  const heifersData = heifersOfSubHerdData?.heifer;
  // handling search btn logic
  const handleSearch = (selectedKeys: React.Key[], confirm: () => void) => {
    confirm();
  };
  // handling reset btn logic
  const handleReset = (clearFilters: (() => void) | undefined) => {
    if (clearFilters) {
      clearFilters();
    }
  };
  // using additional props for heifer ID column to apply search logic
  const heiferSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div className={styles.searchBox}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder="Search by Heifer ID"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={(): void => handleSearch(selectedKeys, confirm)}
          className={styles.searchInput}
        />
        <Space className={styles.searchBtnContainer}>
          <Button
            type="primary"
            onClick={(): void => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            className={styles.searchBtn}
          >
            Search
          </Button>
          <Button
            onClick={(): void => handleReset(clearFilters)}
            size="small"
            className={styles.resetBtn}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value: string | number | boolean, record: any) => {
      // eslint-disable-next-line
      return record[dataIndex]
        ? // eslint-disable-next-line
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : '';
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => {
          if (searchInput !== null) {
            searchInput.select();
          }
        }, 100);
      }
    },
    render: (text: ReactText) => {
      return text;
    },
  });

  // storing heifer data for respective heifer id
  let selectedHeiferData;
  if (Array.isArray(heifersData) && heifersData.length > 0) {
    selectedHeiferData = heifersData.find((data) => {
      return data.id === heiferId;
    });
  }

  // handling delete logic for a heifer
  const handleHeiferDelete = (heiferIdToDelete: string): void => {
    // triggering loading indicator
    setBtnLoader(true);
    deleteHeifer({
      variables: {
        id: heiferIdToDelete,
      },
      update: (cache) => {
        cache.modify({
          fields: {
            heifer(existingHeiferDataRef: Array<Reference>, { readField }) {
              if (heiferIdToDelete) {
                return existingHeiferDataRef.filter((heiferRef) => {
                  return heiferIdToDelete !== readField('id', heiferRef);
                });
              }
              return existingHeiferDataRef;
            },
          },
        });
      },
    })
      .then((): void => {
        setBtnLoader(false);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Heifer has been successfully deleted');
      })
      .catch((deleteHeiferError: ApolloError) => {
        setBtnLoader(false);
        logger(deleteHeiferError);
      });
  };

  return (
    <div style={{ padding: '15px 35px' }}>
      {showBulkAddHeiferModal && selectedHerdId ? (
        <BulkAddHeiferComponent
          showBulkAddHeiferModal={showBulkAddHeiferModal}
          setShowBulkAddHeiferModal={setShowBulkAddHeiferModal}
          idOfSubHerd={idOfSubHerd}
          selectedHerdId={selectedHerdId}
          heifersData={heifersData}
        />
      ) : null}
      <Drawer
        title={<div style={{ fontSize: 22 }}>Edit heifer profile</div>}
        closable
        className="drawerContainer"
        width={480}
        visible={drawerVisible}
        onClose={(): void => {
          setDrawerVisible(false);
        }}
        destroyOnClose
      >
        {selectedHerdId ? (
          <HeiferForm
            heiferId={heiferId}
            selectedHerdId={selectedHerdId}
            herdData={herdData}
            dataToEdit={selectedHeiferData}
            subHerdId={idOfSubHerd}
            mode="edit"
            setDrawerVisible={(prevState): void => {
              setDrawerVisible(prevState);
            }}
          />
        ) : null}
      </Drawer>
      <Modal
        centered
        title={<div style={{ fontSize: 18 }}>Add Heifer</div>}
        visible={addHeiferModal}
        footer={null}
        destroyOnClose
        onCancel={() => {
          setAddHeiferModal(false);
        }}
        bodyStyle={{ borderRadius: 5 }}
      >
        {selectedHerdId ? (
          <HeiferForm
            heiferId={heiferId}
            mode="add"
            subHerdId={idOfSubHerd}
            setHeiferModal={(e): void => {
              setAddHeiferModal(e);
            }}
            selectedHerdId={selectedHerdId}
          />
        ) : null}
      </Modal>
      <Heading headingName="Heifer Summary" />
      {/* {Array.isArray(heifersData) && heifersData.length === 0 ? (
        <div>
          <InfoBar
            marginLeft={0}
            info={
              <span>
                Quickly add heifers to this sub-herd using the <b>Add Heifer</b> button.
              </span>
            }
            containerStyle={{ width: 500 }}
          />
        </div>
      ) : null} */}
      {/* <Button
        className={styles.addHeiferBtn}
        type="default"
        icon={<PlusOutlined />}
        style={{ marginTop: 10, color: '#000' }}
        onClick={() => {
          setAddHeiferModal(true);
        }}
      >
        Add Heifer
      </Button>
      <Button
        icon={<UploadOutlined />}
        className={styles.addHeiferBtn}
        type="primary"
        style={{ marginLeft: 30 }}
        onClick={(): void => {
          // showing modal
          setShowBulkAddHeiferModal(true);
        }}
      >
        Bulk add heifers
      </Button> */}
      {(heifersData && Array.isArray(heifersData) && heifersData.length > 0) ||
      (showHeiferMeasurements && selectedHeiferData) ? (
        <Table<HeiferType>
          rowKey={(record): ReactText => {
            return showHeiferMeasurements ? 'id' : record.id;
          }}
          dataSource={showHeiferMeasurements ? [selectedHeiferData as HeiferType] : heifersData}
          bordered
          size="small"
          style={{ padding: '20px 0' }}
        >
          <Column<HeiferType>
            filterIcon={(filteredText: boolean) => (
              <SearchOutlined
                style={{
                  color: filteredText ? '#1890ff' : undefined,
                }}
              />
            )}
            key="name"
            dataIndex="name"
            title="Heifer ID"
            align="center"
            {...(showHeiferMeasurements ? undefined : heiferSearchProps('name'))}
          />
          <Column
            key="dob"
            dataIndex="dob"
            title="Date of Birth"
            align="center"
            render={(text) => {
              return moment(text, dateFormat).format(dateFormatForDisplay);
            }}
          />
          <Column<HeiferType>
            filterIcon={<SearchOutlined style={{ backgroundColor: 'red', width: 200 }} />}
            key="pen_id"
            dataIndex={['pen', 'name']}
            title="Pen"
            align="center"
            render={(text: string, record) =>
              record.pen && record.pen.name ? record.pen.name : '-'
            }
          />
          <Column<HeiferType>
            key="name"
            dataIndex="name"
            title="Options"
            align="center"
            width={400}
            render={(text, record) => {
              return (
                <div>
                  <Button
                    danger={showHeiferMeasurements}
                    className={showHeiferMeasurements ? '' : styles.addHeiferBtn}
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      setHeiferId(record.id);
                      setShowHeiferMeasurements(!showHeiferMeasurements);
                    }}
                  >
                    {showHeiferMeasurements ? 'Close' : 'View Measurements'}
                  </Button>
                  <Button
                    icon={<EditOutlined />}
                    className="primaryBtn"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      setDrawerVisible(true);
                      setHeiferId(record.id);
                    }}
                  >
                    Edit
                  </Button>
                  <Popconfirm
                    title="Delete heifer. Are you sure? All heifer data will also be deleted."
                    onConfirm={(): void => {
                      handleHeiferDelete(record.id);
                      setShowHeiferMeasurements(false);
                    }}
                    icon={<WarningFilled style={{ color: '#ce0e2d' }} />}
                    cancelText="No"
                    okText="Yes"
                  >
                    <Button
                      loading={btnLoader}
                      className="secondaryBtn"
                      style={{ border: '1px solid #17A697' }}
                      disabled={btnLoader}
                      icon={<DeleteOutlined />}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                </div>
              );
            }}
          />
        </Table>
      ) : null}

      {showHeiferMeasurements && selectedHeiferData ? (
        <ViewHeiferDataScreen
          herdName={selectedHerdName}
          subHerdName={selectedSubHerdName}
          selectedHeiferData={selectedHeiferData}
          setShowHeiferMeasurements={setShowHeiferMeasurements}
        />
      ) : null}
    </div>
  );
};

export default HeifersOfSubHerdScreen;
