import React from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';

// This is the maine react functional component
const UsersScreen: React.FC = () => {
  // location is object coming from useLocation hooks of react router dom
  const location = useLocation();
  // navigate variable contain useNavigate hooks
  const navigate = useNavigate();

  // selectedIndex is used for the active tab
  let selectedIndex: number;
  selectedIndex = 0;
  if (location.pathname.includes('create')) {
    selectedIndex = 1;
  }

  return (
    <div className="tabContainer">
      <Tabs
        selectedIndex={selectedIndex}
        onSelect={(value) => {
          if (value === 0) {
            navigate('');
          }
          if (value === 1) {
            navigate('create');
          }
        }}
      >
        <TabList style={{ padding: '0px 35px', fontSize: '16px' }}>
          <Tab>View All Users</Tab>
          <Tab>Add new User</Tab>
        </TabList>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default UsersScreen;
