import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './graphql/apolloClient';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import 'react-tabs/style/react-tabs.css';
import { AppProvider } from './context/AppContext';

// setting default moment timezone to CST (Central Standard Time)
moment.tz.setDefault('America/Chicago');

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <AppProvider>
        <App />
      </AppProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
