import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import { useLazyQuery, useQuery } from '@apollo/client';
import { CalendarOutlined } from '@ant-design/icons';
import { Select, Button, Drawer, Switch, Space, DatePicker, Radio, Divider } from 'antd';
import moment from 'moment-timezone';
import { useForm, UseFormMethods } from 'react-hook-form';
import HeiferWeightChart from '../charts/HeiferWeightChart';
import HeiferHeightChart from '../charts/HeiferHeightChart';
import PercentMatureWeightHeightChart from '../charts/PercentMatureWeightHeightChart';
import {
  DataFarmsHerdsQuery,
  DataFarmsHerdsQueryVariables,
  Herd,
  Herd_Data_Avg_Fields,
  HerdAllHeiferMeasurementsQuery,
  HerdAllHeiferMeasurementsQueryVariables,
  HerdDataOfHerdQuery,
  HerdDataOfHerdQueryVariables,
} from '../graphql/graphql-types';
import {
  handleAvgSubHerdDataFields,
  handleCalculatedSubHerdData,
  calcHeiferChartDataPoints,
  logger,
  renderQueryErrorMsg,
  renderWarningMessage,
} from '../utils/helpers';
import Heading from '../components/Heading';
import {
  AgeInterval,
  DataRangeFormType,
  HeiferMeasurementDataType,
  SubHerdSummaryAgeFilterOptions,
  SubHerdSummarySelectOptions,
  LineChartDataType,
  ScatterChartDataType,
  UserInputDataType,
} from '../utils/types';
import ViewLatestSubHerdDataSection from '../components/ViewLatestSubHerdDataSection';
import LoadingPage from './LoadingScreen';
import {
  heiferChartDataOptions,
  heiferHeightChartDchaData,
  heiferHeightChartPsuData,
  heiferHeightChartPurinaModelData,
  heiferWeightChartDchaData,
  heiferWeightChartPsuData,
  heiferWeightChartPurinaModelData,
  pcMatureWeightHeightChartSubHerdHeifersData,
  pcMatureWeightHeightChartHistoricalData,
  pcMatureWeightHeightChartPsuData,
  pcMatureWeightHeightChartPurinaData,
  pcMatureWeightHeightDchaData,
  dateFormat,
  dateFormatForDisplay,
} from '../utils/globals';
import ECFormItem from '../components/ECFormItem';
import AgeIntervalOptions from '../components/AgeIntervalOptions';
import SubHerdBasicSummaryTable from '../components/SubHerdBasicSummaryTable';
import subHerdSummaryCalculations from '../utils/subHerdSummaryCalculations';
import GenerateReportComponent from '../components/GenerateReportComponent';
import InfoBar from '../components/InfoBar';

// This is the query to fetch all the dataHerdsSubHerds
const dataHerdsSubHerdsQuery = loader('../graphql/queries/dataFarmsHerdsQuery.graphql');
// importing subHerdDataOfSubHerd query
const subHerdDataOfSubHerdQuery = loader('../graphql/queries/herdDataOfHerdQuery.graphql');
const subHerdAllHeiferMeasurementsQuery = loader(
  '../graphql/queries/herdAllHeiferMeasurements.graphql',
);

// subHerd summary select options data
const subHerdSummaryOptions = ['overall', 'current', 'historical'];

// functional component
const AnalyzeScreen: React.FC = () => {
  // storing id of a selected herd
  const [selectedHerdId, setSelectedHerdId] = useState<number | undefined>(undefined);
  // storing subHerdId for a selected herd
  const [selectedSubHerdId, setSelectedSubHerdId] = useState<string>('');
  // state to store visibility of the options drawer
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  // This state holds the boolean value to show generate report page or not
  const [showGenerateReportPage, setShowGenerateReportPage] = useState<boolean>(false);

  // storing filtered heifer's measurements for a selected subHerd to plot subHerd heifer's data points for all charts
  const [filteredHeiferMeasurements, setFilteredHeiferMeasurements] = useState<
    HeiferMeasurementDataType[]
  >([]);
  // state to store min and max age interval for x-axis ticks for all heifer charts
  const [ageInterval, setAgeInterval] = useState<AgeInterval>({
    minAgeInterval: 0,
    maxAgeInterval: 28,
  });
  /* State to store filtered historical heifer measurement data */
  const [filteredHistoricalHeiferMeasurements, setFilteredHistoricalHeiferMeasurements] = useState<
    HeiferMeasurementDataType[]
  >([]);

  // componentDidMount
  useEffect(() => {
    // scroll to top when generate report page hides
    if (!showGenerateReportPage) {
      window.scrollTo(0, 0);
    }
  }, [showGenerateReportPage]);

  /* state to store age and interval (error deviation) to
  calculate subHerd summary data based on age range (inclusive) */
  const [
    subHerdSummaryAgeFilterOptions,
    setSubHerdSummaryAgeFilterOptions,
  ] = useState<SubHerdSummaryAgeFilterOptions>({
    baseAge: 4,
    baseAgeErrDeviation: 1,
  });

  // This is the query data fetching data of herd with subHerds
  const {
    loading: dataHerdsSubHerdsLoading,
    error: dataHerdsSubHerdsError,
    data: dataHerdsSubHerdsResult,
  } = useQuery<DataFarmsHerdsQuery, DataFarmsHerdsQueryVariables>(dataHerdsSubHerdsQuery);
  // fetching subHerd data only when a subHerd is selected from the list options
  const [
    getSubHerdDataOfSubHerd,
    {
      loading: subHerdDataOfSubHerdLoading,
      error: subHerdDataOfSubHerdError,
      data: subHerdDataOfSubHerdResult,
    },
  ] = useLazyQuery<HerdDataOfHerdQuery, HerdDataOfHerdQueryVariables>(subHerdDataOfSubHerdQuery);

  // fetching selected subHerd's all heifer's measurements data
  const [
    getSubHerdAllHeifersMeasurements,
    {
      loading: subHerdAllHeifersLoading,
      error: subHerdAllHeifersError,
      data: subHerdAllHeifersMeasurementResult,
    },
  ] = useLazyQuery<HerdAllHeiferMeasurementsQuery, HerdAllHeiferMeasurementsQueryVariables>(
    subHerdAllHeiferMeasurementsQuery,
  );

  // useForm methods
  const methods: UseFormMethods<DataRangeFormType> = useForm<DataRangeFormType>({
    mode: 'onSubmit',
    defaultValues: {
      psu: true,
      purina: true,
      subHerdHeifer: true,
      historicalHeifer: true,
      fromDate: '',
      toDate: '',
      minAgeInterval: 0,
      maxAgeInterval: 28,
      subHerdSummaryOption: 'overall',
      baseAge: 4,
      baseAgeErrDeviation: 1,
      historicalDataFromDate: '',
      historicalDataToDate: '',
    },
    shouldUnregister: false,
  });

  // storing all heifer measurements for a selected subHerd
  const subHerdAllHeifersMeasurementData = subHerdAllHeifersMeasurementResult?.heifer_measurements;

  /*
  function that calculates heifer's historical or current measurement data
  based on age criteria passed with the help of a data key
   */

  const calcHeiferMeasurementsData = (
    dataKey: 'subHerdHeifer' | 'historical',
  ): HeiferMeasurementDataType[] => {
    // checks if heifer's measurement data is present
    if (
      Array.isArray(subHerdAllHeifersMeasurementData) &&
      subHerdAllHeifersMeasurementData.length > 0
    ) {
      return subHerdAllHeifersMeasurementData.filter((elem) => {
        // storing age of heifer since birth
        let currentHeiferAge = 0;
        if (elem) {
          // calculating difference (in months) between today's date and heifer's DOB
          currentHeiferAge = moment().diff(moment(elem.heifer.dob, dateFormat), 'months');
        }
        return dataKey === 'subHerdHeifer' ? currentHeiferAge <= 24 : currentHeiferAge > 24;
      }) as HeiferMeasurementDataType[];
    }
    return [];
  };

  // storing calculated current heifer measurement data
  const currentHeiferData = calcHeiferMeasurementsData('subHerdHeifer');
  // storing calculated historical heifer measurement data
  const historicalHeiferData = calcHeiferMeasurementsData('historical');

  // storing subHerd heifers data points for all heifer charts
  const subHerdHeifersDataPointsToPlot = calcHeiferChartDataPoints(
    methods.getValues('fromDate') && methods.getValues('toDate')
      ? filteredHeiferMeasurements
      : currentHeiferData,
  );

  // storing historical heifer data points for all heifer charts
  const historicalHeiferDataPointsToPlot = calcHeiferChartDataPoints(
    methods.getValues('historicalDataFromDate') && methods.getValues('historicalDataToDate')
      ? filteredHistoricalHeiferMeasurements
      : historicalHeiferData,
  );

  // storing data of herd
  const herds = dataHerdsSubHerdsResult?.farm;

  // storing subHerd data
  const subHerdData = subHerdDataOfSubHerdResult?.herd_data[0];

  // storing subHerds data for a selected herd
  let subHerds: Pick<Herd, 'id' | 'name'>[] | undefined;
  // storing name of selected herd
  let selectedHerdName: string | undefined;

  // checking if herd data is present with selected herd id
  if (selectedHerdId && Array.isArray(herds) && herds.length > 0) {
    // storing data for a selected herd
    const selectedHerd = herds.find((item) => {
      return selectedHerdId === item.id;
    });
    selectedHerdName = selectedHerd?.name;
    subHerds = selectedHerd?.herds;
  }

  // storing user input data for a selected row in the table and rendering data on drawer
  let userInputData: UserInputDataType = null;
  // storing subHerd average field's data for userInputData and calculated subHerd data
  let avgInputDataFields: Herd_Data_Avg_Fields | undefined;

  // checking and storing existing subHerd data for subHerd data calculations
  if (subHerdData) {
    avgInputDataFields = handleAvgSubHerdDataFields(subHerdData);
    userInputData = handleCalculatedSubHerdData(avgInputDataFields);
  }

  // storing current value of fromDate form item
  const watchFromDate = methods.watch('fromDate');
  // storing current value of ToDate form item
  const watchToDate = methods.watch('toDate');
  // storing current form value for psu switch component
  const watchPsuCheckedVal = methods.watch('psu');
  // storing current form value for purina switch component
  const watchPurinaCheckedVal = methods.watch('purina');
  // storing current form value for subHerd heifers switch component
  const watchSubHerdHeiferCheckedVal = methods.watch('subHerdHeifer');
  // storing current form value for historical heifer switch component
  const watchHistoricalHeiferCheckedVal = methods.watch('historicalHeifer');
  // getting currently selected radio option for calculating subHerd summary table data
  const selectedSubHerdSummaryOption = methods.watch('subHerdSummaryOption');
  /*  storing current value of historical data fromDate form item */
  const watchHistoricalDataFromDate = methods.watch('historicalDataFromDate');
  /*  storing current value of historical data toDate form item */
  const watchHistoricalDataToDate = methods.watch('historicalDataToDate');

  // storing calculated table data for subHerd summary
  const subHerdSummaryTableData =
    !subHerdAllHeifersLoading ||
    (subHerdHeifersDataPointsToPlot.weightChartDataPoints.length > 0 &&
      subHerdHeifersDataPointsToPlot.heightChartDataPoints.length > 0 &&
      historicalHeiferDataPointsToPlot.weightChartDataPoints.length > 0 &&
      historicalHeiferDataPointsToPlot.heightChartDataPoints.length > 0)
      ? subHerdSummaryCalculations({
          weightChartSubHerdHeiferData: subHerdHeifersDataPointsToPlot.weightChartDataPoints,
          heightChartSubHerdHeiferData: subHerdHeifersDataPointsToPlot.heightChartDataPoints,
          weightChartHistoricalData: historicalHeiferDataPointsToPlot.weightChartDataPoints,
          heightChartHistoricalData: historicalHeiferDataPointsToPlot.heightChartDataPoints,
          selectedOption: selectedSubHerdSummaryOption,
          baseAge: subHerdSummaryAgeFilterOptions.baseAge,
          baseAgeErrDeviation: subHerdSummaryAgeFilterOptions.baseAgeErrDeviation,
        })
      : [];

  // handling error state of query
  if (subHerdDataOfSubHerdError) {
    return renderQueryErrorMsg(subHerdDataOfSubHerdError);
  }
  if (subHerdAllHeifersError) {
    return renderQueryErrorMsg(subHerdAllHeifersError);
  }
  if (dataHerdsSubHerdsError) {
    return renderQueryErrorMsg(dataHerdsSubHerdsError);
  }

  // function to handle rendering of warning message when selectedSubHerdId is present, but has no subHerdData
  const handleNoSubHerdData = (): JSX.Element | null | string => {
    if (subHerdAllHeifersLoading) {
      return <LoadingPage />;
    }
    if (selectedHerdId && selectedSubHerdId && subHerdData === undefined) {
      return renderWarningMessage(
        <span>
          No sub-herd and heifer data is present for this sub-herd. Please add it in <b>Data</b>{' '}
          section to view charts.
        </span>,
        { margin: '20px 0 40px 0' },
      );
    }
    return null;
  };

  // historical body weight and hip height data for percent weight-height chart
  const pcWeightHeightChartHistoricalData: ScatterChartDataType[] = [
    {
      ...pcMatureWeightHeightChartHistoricalData[0],
      data: historicalHeiferDataPointsToPlot.percentChartBwDataPoints,
    },
    {
      ...pcMatureWeightHeightChartHistoricalData[1],
      data: historicalHeiferDataPointsToPlot.percentChartHipHtDataPoints,
    },
  ];

  // this function is invoke when user hit back button
  const onClickBackButton = () => {
    setShowGenerateReportPage(false);
  };

  /* function to render date range filter headers in drawer */
  const renderDrawerDateRangeFilterHeader = (title: string, description: string) => {
    return (
      <>
        <h3 style={{ marginTop: 20 }}>{title}</h3>
        <p>{description}</p>
        <Space
          style={{ alignItems: 'baseline', fontSize: 14, padding: 0 }}
          size={145}
          align="start"
        >
          <span>From</span>
          <span>To</span>
        </Space>
      </>
    );
  };

  /* Function to render date range filter apply and rest btn in drawer */
  const renderDrawerDateRangeFilterButtons = (calledFor: 'currentHeifer' | 'historicalHeifer') => {
    return (
      <Space align="start" style={{ marginTop: 20 }} size={35}>
        <Button
          onClick={(): void => {
            if (calledFor === 'currentHeifer') {
              methods.trigger(['fromDate', 'toDate']).catch((err) => {
                logger(err);
              });
            }
            if (calledFor === 'historicalHeifer') {
              methods.trigger(['historicalDataFromDate', 'historicalDataToDate']).catch((err) => {
                logger(err);
              });
            }
            if (subHerdAllHeifersMeasurementData) {
              if (calledFor === 'currentHeifer') {
                // storing heifer's measurements data when fromDate (inclusive) and toDate (inclusive) are present
                const filteredSubHerdAllHeifersData = currentHeiferData.filter((item) => {
                  if (watchFromDate && watchToDate) {
                    return moment(item.measurement_date, dateFormat).isBetween(
                      watchFromDate,
                      watchToDate,
                      'day',
                      '[]',
                    );
                  }
                  return false;
                });

                setFilteredHeiferMeasurements(filteredSubHerdAllHeifersData);
              }
              if (calledFor === 'historicalHeifer') {
                // storing heifer's measurements data when fromDate (inclusive) and toDate (inclusive) are present
                const filteredHistoricalSubHerdHeifersData = historicalHeiferData.filter((item) => {
                  if (watchHistoricalDataFromDate && watchHistoricalDataToDate) {
                    return moment(item.measurement_date, dateFormat).isBetween(
                      watchHistoricalDataFromDate,
                      watchHistoricalDataToDate,
                      'day',
                      '[]',
                    );
                  }
                  return false;
                });

                setFilteredHistoricalHeiferMeasurements(filteredHistoricalSubHerdHeifersData);
              }
            }
          }}
          className="primaryBtn"
          size="small"
          style={{ padding: '0 20px' }}
        >
          Apply
        </Button>
        <Button
          onClick={(): void => {
            if (calledFor === 'currentHeifer') {
              methods.setValue('fromDate', '');
              methods.setValue('toDate', '');
              methods.clearErrors(['fromDate', 'toDate']);
            }
            if (calledFor === 'historicalHeifer') {
              methods.setValue('historicalDataFromDate', '');
              methods.setValue('historicalDataToDate', '');
              methods.clearErrors(['historicalDataFromDate', 'historicalDataToDate']);
            }
          }}
          size="small"
          className="secondaryBtn"
          style={{ border: '1px solid #000', padding: '0 20px' }}
        >
          Reset
        </Button>
      </Space>
    );
  };

  if (showGenerateReportPage) {
    return (
      <div>
        {subHerdData ? (
          <GenerateReportComponent
            xAxisMinInterval={ageInterval.minAgeInterval}
            xAxisMaxInterval={ageInterval.maxAgeInterval}
            matureBodyWeight={subHerdData.avg_bw_3rdlact_cow as number}
            heiferWeightChartDchaData={heiferWeightChartDchaData}
            historicalHeiferDataWeightChartDataPoints={
              historicalHeiferDataPointsToPlot.weightChartDataPoints
            }
            historicalHeiferDataHeightChartDataPoints={
              historicalHeiferDataPointsToPlot.heightChartDataPoints
            }
            subHerdHeifersDataWeightChartDataPoints={
              subHerdHeifersDataPointsToPlot.weightChartDataPoints
            }
            subHerdHeifersDataHeightChartDataPoints={
              subHerdHeifersDataPointsToPlot.heightChartDataPoints
            }
            heiferWeightChartPurinaModelData={heiferWeightChartPurinaModelData}
            heiferWeightChartPsuData={heiferWeightChartPsuData}
            userInputData={userInputData}
            avgInputDataFields={avgInputDataFields}
            tableData={subHerdSummaryTableData}
            formMethods={methods}
            setSubHerdSummaryAgeFilterOptions={setSubHerdSummaryAgeFilterOptions}
            subHerdSummaryAgeFilterOptions={subHerdSummaryAgeFilterOptions}
            heiferHeightChartPsuData={heiferHeightChartPsuData}
            heiferHeightChartPurinaModelData={heiferHeightChartPurinaModelData}
            heiferHeightChartDchaData={heiferHeightChartDchaData}
            matureHipHeight={subHerdData.avg_height_3rdlact_cow as number}
            pcMatureWeightHeightChartPsuData={pcMatureWeightHeightChartPsuData}
            pcMatureWeightHeightChartPurinaData={
              pcMatureWeightHeightChartPurinaData as LineChartDataType[]
            }
            subHerdHeiferDataPcMature={[
              {
                ...pcMatureWeightHeightChartSubHerdHeifersData[0],
                data: subHerdHeifersDataPointsToPlot.percentChartBwDataPoints,
              },
              {
                ...pcMatureWeightHeightChartSubHerdHeifersData[1],
                data: subHerdHeifersDataPointsToPlot.percentChartHipHtDataPoints,
              },
            ]}
            pcWeightHeightChartHistoricalData={pcWeightHeightChartHistoricalData}
            pcMatureWeightHeightDchaData={pcMatureWeightHeightDchaData}
            onClickBackButton={onClickBackButton}
            selectedSubHerdId={selectedSubHerdId}
            selectedHerdName={selectedHerdName}
          />
        ) : null}
      </div>
    );
  }

  return (
    <>
      <Drawer
        title={<div style={{ fontSize: 22 }}>Options</div>}
        closable
        width={480}
        className="drawerContainer"
        visible={isDrawerVisible}
        onClose={(): void => {
          setIsDrawerVisible(false);
        }}
      >
        <form style={{ margin: '15px 24px' }}>
          <div>
            <h3 style={{ marginBottom: 10 }}>Data series</h3>
            {heiferChartDataOptions.map((entry) => (
              <Space
                key={entry.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 5,
                  alignItems: 'baseline',
                }}
              >
                <span>{entry.seriesTitle}</span>
                <span>
                  <ECFormItem
                    control={methods.control}
                    name={entry.id}
                    render={({ value, onChange }) => {
                      return (
                        <Switch
                          checked={value as boolean}
                          onChange={(checked) => {
                            onChange(checked);
                            methods.setValue(entry.id, checked);
                          }}
                        />
                      );
                    }}
                  />
                </span>
              </Space>
            ))}

            {renderDrawerDateRangeFilterHeader(
              'Current Heifer (select measurement date range)',
              'Filter data for which measurement date is',
            )}

            <Space style={{ display: 'flex', alignItems: 'baseline', marginTop: -10 }}>
              <CalendarOutlined style={{ fontSize: 20 }} />
              <ECFormItem
                rules={{ required: `Select both 'from' and 'to' dates.` }}
                control={methods.control}
                name="fromDate"
                render={({ value, onChange }): JSX.Element => (
                  <DatePicker
                    style={{ width: 130 }}
                    placeholder="mm/dd/yyyy"
                    format={dateFormatForDisplay}
                    value={value as moment.Moment}
                    onChange={(val) => {
                      onChange(val);
                    }}
                  />
                )}
              />
              <CalendarOutlined style={{ fontSize: 20, marginLeft: 20 }} />
              <ECFormItem
                rules={{ required: `Select both 'from' and 'to' dates.` }}
                control={methods.control}
                name="toDate"
                render={({ value, onChange }): JSX.Element => (
                  <DatePicker
                    style={{ width: 130 }}
                    placeholder="mm/dd/yyyy"
                    format={dateFormatForDisplay}
                    value={value as moment.Moment}
                    onChange={(val) => {
                      onChange(val);
                    }}
                  />
                )}
              />
            </Space>
            {renderDrawerDateRangeFilterButtons('currentHeifer')}

            {methods.errors.fromDate || methods.errors.toDate ? (
              <p style={{ color: 'red' }}>Select both 'from' and 'to' dates</p>
            ) : null}

            {/* Historical data date range filter */}
            {renderDrawerDateRangeFilterHeader(
              'Historical Heifers (select measurement date range)',
              'Filter data for which measurement date is',
            )}

            <Space style={{ display: 'flex', alignItems: 'baseline', marginTop: -10 }}>
              <CalendarOutlined style={{ fontSize: 20 }} />
              <ECFormItem
                rules={{ required: `Select both 'from' and 'to' dates.` }}
                control={methods.control}
                name="historicalDataFromDate"
                render={({ value, onChange }): JSX.Element => (
                  <DatePicker
                    style={{ width: 130 }}
                    placeholder="mm/dd/yyyy"
                    format={dateFormatForDisplay}
                    value={value as moment.Moment}
                    onChange={(val) => {
                      onChange(val);
                    }}
                  />
                )}
              />
              <CalendarOutlined style={{ fontSize: 20, marginLeft: 20 }} />
              <ECFormItem
                rules={{ required: `Select both 'from' and 'to' dates.` }}
                control={methods.control}
                name="historicalDataToDate"
                render={({ value, onChange }): JSX.Element => (
                  <DatePicker
                    style={{ width: 130 }}
                    placeholder="mm/dd/yyyy"
                    format={dateFormatForDisplay}
                    value={value as moment.Moment}
                    onChange={(val) => {
                      onChange(val);
                    }}
                  />
                )}
              />
            </Space>

            {renderDrawerDateRangeFilterButtons('historicalHeifer')}

            {methods.errors.historicalDataFromDate || methods.errors.historicalDataToDate ? (
              <p style={{ color: 'red' }}>Select both 'from' and 'to' dates</p>
            ) : null}
          </div>
          <AgeIntervalOptions formMethods={methods} setAgeInterval={setAgeInterval} />
        </form>
      </Drawer>
      <div
        style={{
          display: 'flex',
          marginTop: 20,
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {Array.isArray(herds) && herds.length > 0 ? (
          <>
            <div>
              <InfoBar
                marginLeft={34}
                containerStyle={{ width: 675, marginBottom: 20 }}
                info={
                  <span>
                    You can add herds, sub-herds, pens, heifers and heifer measurements in ‘Data’
                    section
                  </span>
                }
              />
            </div>
            <div className="selectBoxContainer" style={{ marginLeft: 34 }}>
              <span className="selectBoxLabel">Select a Herd:</span>
              <Select
                loading={dataHerdsSubHerdsLoading}
                className="selectBox"
                showSearch
                style={{ width: 400, marginLeft: 50 }}
                placeholder="Select a herd to see list of sub-herds"
                optionFilterProp="data"
                onChange={(value: number): void => {
                  setSelectedHerdId(value);
                  setSelectedSubHerdId('');
                }}
                value={selectedHerdId}
              >
                {herds.map((item) => (
                  <Select.Option key={item.id} value={item.id} data={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </>
        ) : (
          renderWarningMessage(
            <span>
              Please add a herd in <b> Data</b> section to begin using the app.
            </span>,
            { marginTop: 20, marginLeft: 80 },
          )
        )}

        {selectedHerdId ? (
          <div style={{ paddingLeft: 34, marginTop: 20 }} className="selectBoxContainer">
            <span className="selectBoxLabel">Select a Sub-herd:</span>
            <Select
              loading={subHerdDataOfSubHerdLoading}
              className="selectBox"
              showSearch
              disabled={!Array.isArray(subHerds) || subHerds.length === 0}
              style={{ width: 400 }}
              placeholder="Select a sub-herd to add heifers and heifers measurements"
              optionFilterProp="data"
              onChange={(val: string): void => {
                setSelectedSubHerdId(val);
                getSubHerdDataOfSubHerd({
                  variables: {
                    herd_id: val,
                  },
                });
                getSubHerdAllHeifersMeasurements({
                  variables: {
                    _eq: val,
                  },
                });
              }}
              value={selectedSubHerdId === '' ? undefined : selectedSubHerdId}
            >
              {Array.isArray(subHerds) && subHerds.length > 0
                ? subHerds.map((item) => (
                    <Select.Option key={item.id} value={item.id} data={item.name}>
                      {item.name}
                    </Select.Option>
                  ))
                : null}
            </Select>
          </div>
        ) : null}
        {selectedHerdId && (!Array.isArray(subHerds) || subHerds.length === 0) ? (
          <div style={{ marginLeft: 190 }}>
            {renderWarningMessage(
              <span>
                No sub-herd or heifer present in selected herd. Add sub-herd & heifer to herd in{' '}
                <b>Data</b> section.
              </span>,
              { margin: '20px 0 40px 0' },
            )}
          </div>
        ) : null}
        {subHerdData && selectedSubHerdId.length > 0 ? (
          <>
            <Button
              type="primary"
              className="primaryBtn"
              style={{ position: 'fixed', bottom: 20, marginLeft: '50%', left: -50, zIndex: 9999 }}
              onClick={() => setShowGenerateReportPage(true)}
            >
              Export PDF
            </Button>
            <div style={{ margin: '20px 34px' }}>
              <div>
                {/* Latest Sub-herd information is replaced with sub-herd information */}
                <div style={{ fontSize: 22, color: '#000' }}>Sub-herd information</div>
              </div>

              <Divider style={{ marginTop: 0, marginBottom: 0, backgroundColor: 'grey' }} />
              <ViewLatestSubHerdDataSection
                layoutSize={300}
                userInputData={userInputData}
                avgInputDataFields={avgInputDataFields}
                layoutDirection="horizontal"
              />
            </div>
            <div style={{ margin: '0 34px' }}>
              {/*  Sub-herd basic summary is replaced with group summary */}
              <Heading headingName="Group Summary" />
              <Space
                align="start"
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  marginTop: -30,
                }}
              >
                <b>Select:</b>
                <ECFormItem
                  name="subHerdSummaryOption"
                  control={methods.control}
                  render={({ onChange, value }) => {
                    return (
                      <Radio.Group
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        style={{ display: 'flex', flexDirection: 'row' }}
                        value={value as SubHerdSummarySelectOptions}
                      >
                        {subHerdSummaryOptions.map((val) => (
                          <Radio style={{ textTransform: 'capitalize' }} key={val} value={val}>
                            {val}
                          </Radio>
                        ))}
                      </Radio.Group>
                    );
                  }}
                />
              </Space>
              <SubHerdBasicSummaryTable
                tableData={subHerdSummaryTableData}
                formMethods={methods}
                setSubHerdSummaryAgeFilterOptions={setSubHerdSummaryAgeFilterOptions}
              />
            </div>
            <div style={{ margin: '0 34px' }}>
              <Heading
                headingName="Heifer Weight Chart"
                showOptions
                onOptionsClick={(): void => {
                  setIsDrawerVisible(true);
                }}
              />
            </div>
            <HeiferWeightChart
              psu2013Data={watchPsuCheckedVal ? heiferWeightChartPsuData : []}
              purinaModelData={watchPurinaCheckedVal ? heiferWeightChartPurinaModelData : []}
              subHerdHeiferData={
                watchSubHerdHeiferCheckedVal
                  ? subHerdHeifersDataPointsToPlot.weightChartDataPoints
                  : []
              }
              historicalHeiferData={
                watchHistoricalHeiferCheckedVal
                  ? historicalHeiferDataPointsToPlot.weightChartDataPoints
                  : []
              }
              dchaData={heiferWeightChartDchaData}
              xAxisMinInterval={ageInterval.minAgeInterval}
              xAxisMaxInterval={ageInterval.maxAgeInterval}
              matureBodyWeight={subHerdData.avg_bw_3rdlact_cow as number}
            />
            <div style={{ marginLeft: 34, marginRight: 34 }}>
              <Heading
                headingName="Heifer Height Chart"
                showOptions
                onOptionsClick={(): void => {
                  setIsDrawerVisible(true);
                }}
              />
            </div>
            <HeiferHeightChart
              psu2013Data={watchPsuCheckedVal ? heiferHeightChartPsuData : []}
              purinaModelData={watchPurinaCheckedVal ? heiferHeightChartPurinaModelData : []}
              subHerdHeiferData={
                watchSubHerdHeiferCheckedVal
                  ? subHerdHeifersDataPointsToPlot.heightChartDataPoints
                  : []
              }
              historicalHeiferData={
                watchHistoricalHeiferCheckedVal
                  ? historicalHeiferDataPointsToPlot.heightChartDataPoints
                  : []
              }
              dchaData={heiferHeightChartDchaData}
              xAxisMinInterval={ageInterval.minAgeInterval}
              xAxisMaxInterval={ageInterval.maxAgeInterval}
              matureHipHeight={subHerdData.avg_height_3rdlact_cow as number}
            />
            <div style={{ marginLeft: 34, marginRight: 34 }}>
              <Heading
                headingName="Heifer % of Mature Weight and Height Chart"
                showOptions
                onOptionsClick={(): void => {
                  setIsDrawerVisible(true);
                }}
              />
            </div>
            <PercentMatureWeightHeightChart
              psu2013Data={watchPsuCheckedVal ? pcMatureWeightHeightChartPsuData : []}
              purinaModelData={
                watchPurinaCheckedVal
                  ? (pcMatureWeightHeightChartPurinaData as LineChartDataType[])
                  : []
              }
              subHerdHeiferData={
                watchSubHerdHeiferCheckedVal
                  ? [
                      {
                        ...pcMatureWeightHeightChartSubHerdHeifersData[0],
                        data: subHerdHeifersDataPointsToPlot.percentChartBwDataPoints,
                      },
                      {
                        ...pcMatureWeightHeightChartSubHerdHeifersData[1],
                        data: subHerdHeifersDataPointsToPlot.percentChartHipHtDataPoints,
                      },
                    ]
                  : []
              }
              historicalHeiferData={
                watchHistoricalHeiferCheckedVal ? pcWeightHeightChartHistoricalData : []
              }
              dchaData={pcMatureWeightHeightDchaData}
              xAxisMinInterval={ageInterval.minAgeInterval}
              xAxisMaxInterval={ageInterval.maxAgeInterval}
            />
          </>
        ) : (
          <div style={{ marginLeft: 190 }}>{handleNoSubHerdData()}</div>
        )}
      </div>
    </>
  );
};

export default AnalyzeScreen;
