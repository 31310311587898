import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import { useApolloClient } from '@apollo/client';
import SignInScreen from './screens/SignInScreen';
import './App.scss';
import LoadingScreen from './screens/LoadingScreen';
import { firebaseAuth } from './utils/firebase';
import AppRoutes from './AppRoutes';
import { UserByIdQuery, UserByIdQueryVariables } from './graphql/graphql-types';
import { useApp } from './context/AppContext';
import SetNewPasswordScreen from './screens/SetNewPasswordScreen';
import { logger } from './utils/helpers';

// This is the query to get the role of the user who is logged in
const userByIdQuery = loader('./graphql/queries/userByIdQuery.graphql');

// This is main functional component
const App: React.FC = () => {
  /* destructing setUser method from context */
  const { setUser } = useApp();

  /* const used to store apolloClient */
  const apolloClient = useApolloClient();

  // This checks the user is logged in or not if user is logged in then in the onAuthStateChange() gives the data of user then we make this state true
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  // This is for showing the loader on our screen
  const [loading, setLoading] = useState(true);

  /* state used to manage 'set new password's screen visibility */
  const [showNewPasswordScreen, setShowNewPasswordScreen] = useState<boolean>(false);

  /* Timeout function that set loading state of application to false after 1 sec so that loading screen will get hide after it */
  const updateLoadingStateTimeoutFunc = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1200);
  };

  // In this function we identify the state of user when ever users state gets changed this function observe it
  useEffect(() => {
    const unregisterAuthStateChangedObserver = firebaseAuth.onAuthStateChanged((userData) => {
      if (userData) {
        /* execute user by id query, to set user data & to show 'set new password' screen after user first login */
        apolloClient
          .query<UserByIdQuery, UserByIdQueryVariables>({
            query: userByIdQuery,
            variables: {
              id: userData.uid,
            },
          })
          .then((res) => {
            if (res && res.data && res.data.user_by_pk) {
              setIsLoggedIn(true);

              /* set user data (or store user data into context) */
              setUser(res.data.user_by_pk);
              /* if user logged first time then show 'set new password' screen */
              if (!res.data.user_by_pk.first_login_at) {
                /* set state to 'true' to show 'Set new password' screen */
                setShowNewPasswordScreen(true);
              }
              updateLoadingStateTimeoutFunc();
            }
          })
          .catch((error) => {
            logger(error);
            updateLoadingStateTimeoutFunc();
          });
      } else {
        updateLoadingStateTimeoutFunc();
        setIsLoggedIn(false);
      }
    });
    return (): void => {
      unregisterAuthStateChangedObserver();
    };
  }, [apolloClient, setUser]);

  if (loading) return <LoadingScreen />;
  if (showNewPasswordScreen) {
    return <SetNewPasswordScreen setShowNewPasswordScreen={setShowNewPasswordScreen} />;
  }
  if (isLoggedIn) return <AppRoutes />;
  return <SignInScreen />;
};

export default App;
