import React, { useState } from 'react';
import { Button, Drawer, message, Modal, Upload } from 'antd';
import { UseFormMethods } from 'react-hook-form';
import XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { InboxOutlined } from '@ant-design/icons';
import InfoBar from '../components/InfoBar';
import { Herd_Data_Avg_Fields } from '../graphql/graphql-types';
import HeiferDataForm from '../forms/HeiferDataForm';
import Heading from '../components/Heading';
import commonStyles from './HeifersOfSubHerdScreen.module.scss';
import SubHerdDataForm from '../forms/SubHerdDataForm';
import BulkHeiferDataSheetPreview from '../components/BulkHeiferDataSheetPreview';
import { bulkAddHeiferMeasurementFileDownloadUrl } from '../utils/globals';
import { BulkHeiferMeasurementDataSheetType } from '../utils/types';

const { Dragger } = Upload;

// type definition for component props
type AddHeiferDataScreenProps = {
  // selected subHerdId from DataMeasurement screen
  selectedSubHerdId: string;
  // selected herdId from DataMeasurement screen
  selectedHerdId?: number;
  // accessing subHerd data form values (fetched or updated)
  subHerdDataFormValues: Herd_Data_Avg_Fields;
  // setState fn to set subHerd data form values
  setSubHerdDataFormValues: React.Dispatch<React.SetStateAction<Herd_Data_Avg_Fields>>;
  // useForm method
  subHerdDataFormMethods: UseFormMethods<Herd_Data_Avg_Fields>;
  // method to update visibility state of bulk upload modal
  setShowBulkAddDataModal: React.Dispatch<React.SetStateAction<boolean>>;
  // stores visibility state of bulk upload modal
  showBulkAddDataModal: boolean;
};

// FC
const AddHeiferDataScreen: React.FC<AddHeiferDataScreenProps> = ({
  selectedSubHerdId,
  selectedHerdId,
  subHerdDataFormValues,
  setSubHerdDataFormValues,
  subHerdDataFormMethods,
  setShowBulkAddDataModal,
  showBulkAddDataModal,
}) => {
  // stores type of heifer to determine whether new heifer and data or existing heifer data is being added
  const [heiferType, setHeiferType] = useState<'new' | 'existing'>('new');
  // stores state to either show or hide the drawer consisting SubHerdDataForm
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  // saving uploaded sheet data as json array to render as table data
  const [sheetDataToRender, setSheetDataToRender] = useState<BulkHeiferMeasurementDataSheetType[]>(
    [],
  );
  // saving file name of the uploaded excel data sheet
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  // setting errors for row entries in bulk heifer sheet that are required in data table
  const [heiferSheetHasError, setHeiferSheetHasError] = useState<boolean>(false);

  return (
    <div>
      <Heading headingName="Add Heifer Data" />
      <div style={{ marginBottom: 16 }}>
        <Button
          type="primary"
          className={commonStyles.addHeiferBtn}
          onClick={() => {
            setIsDrawerVisible(true);
          }}
        >
          Edit sub-herd information
        </Button>
      </div>
      {/* <InfoBar
        info={
          <span>
            Use <b>View Charts</b> to analyze data collected <b>today</b> for this sub-herd.
          </span>
        }
        marginLeft={0}
        containerStyle={{ width: 505 }}
      /> */}
      {heiferType === 'new' ? (
        <HeiferDataForm
          selectedSubHerdId={selectedSubHerdId}
          subHerdDataFormValues={subHerdDataFormValues}
          mode="addNew"
          selectedHerdId={selectedHerdId}
          setHeiferType={setHeiferType}
          heiferType={heiferType}
        />
      ) : (
        <HeiferDataForm
          selectedSubHerdId={selectedSubHerdId}
          subHerdDataFormValues={subHerdDataFormValues}
          mode="addExisting"
          heiferType={heiferType}
          setHeiferType={setHeiferType}
        />
      )}
      <Drawer
        bodyStyle={{ padding: '0 24px' }}
        title={<div style={{ fontSize: 22 }}>Update sub-herd information</div>}
        closable
        width={580}
        className="drawerContainer"
        visible={isDrawerVisible}
        onClose={(): void => {
          setIsDrawerVisible(false);
        }}
        destroyOnClose
      >
        <SubHerdDataForm
          textAlign="left"
          setSubHerdDataFormValues={setSubHerdDataFormValues}
          layoutDirection="vertical"
          selectedSubHerdId={selectedSubHerdId}
          subHerdDataFormMethods={subHerdDataFormMethods}
          setDrawerVisible={setIsDrawerVisible}
          btnMode="edit"
        />
      </Drawer>
      <Modal
        title="Bulk Add Heifer Data"
        closable
        destroyOnClose
        bodyStyle={{ margin: '16px 24px' }}
        visible={showBulkAddDataModal}
        width="98%"
        footer={null}
        onCancel={(): void => {
          setShowBulkAddDataModal(false);
          setSheetDataToRender([]);
          setHeiferSheetHasError(false);
          setUploadedFileName('');
        }}
      >
        {selectedHerdId && Array.isArray(sheetDataToRender) && sheetDataToRender.length > 0 ? (
          <BulkHeiferDataSheetPreview
            sheetDataToRender={sheetDataToRender}
            setSheetDataToRender={setSheetDataToRender}
            uploadedFileName={uploadedFileName}
            heiferSheetHasError={heiferSheetHasError}
            setHeiferSheetHasError={setHeiferSheetHasError}
            setUploadedFileName={setUploadedFileName}
            avgBodyWeight={subHerdDataFormValues.avg_bw_3rdlact_cow}
            avgHeight={subHerdDataFormValues.avg_height_3rdlact_cow}
            selectedSubHerdId={selectedSubHerdId}
            selectedHerdId={selectedHerdId}
            setShowBulkAddDataModal={setShowBulkAddDataModal}
          />
        ) : (
          <div>
            <Button
              href={bulkAddHeiferMeasurementFileDownloadUrl}
              style={{ marginTop: 20 }}
              type="primary"
              className="primaryBtn"
              icon={<FontAwesomeIcon icon={faDownload} style={{ marginRight: 10 }} />}
            >
              Download template file
            </Button>
            <div>
              <InfoBar
                containerStyle={{ marginTop: 10, width: '60%' }}
                info={
                  <div>
                    <b>
                      This template file must be used to upload heifer data. All measurements must
                      include a Pen ID. Pen ID must match an existing Herd Pen ID.
                    </b>
                    Do not alter the formatting, column headers, or column order in the template
                    file. Paste heifer data into columns of the template file, save, and upload.
                  </div>
                }
              />
            </div>
            <div style={{ margin: '10px 0' }}>
              <i>
                Supported file types are: <b>.xls</b> & <b>.xlsx</b>
              </i>
            </div>
            <Dragger
              beforeUpload={(file) => {
                // checking if uploaded file type is either of .xlsx or .xls file format
                if (
                  file.type !==
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
                  file.type !== 'application/vnd.ms-excel'
                ) {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  message.error('You can upload only .xls or .xlsx files');
                } else {
                  // setting file name of uploaded file
                  setUploadedFileName(file.name);
                  // js FileReader instance
                  const fileReader = new FileReader();
                  fileReader.onload = (event: ProgressEvent<FileReader>) => {
                    // storing read data for uploaded file
                    const rawData = event.target?.result;
                    // this stores an array of 8-bit unsigned integers for the uploaded file
                    // eslint-disable-next-line
                    const bufferData = new Uint8Array(rawData as ArrayBuffer);
                    // reading and storing excel data as xlsx workbook
                    const workBook = XLSX.read(bufferData, { type: 'array', cellDates: true });
                    // storing converted data from xlsx to json array format
                    const jsonSheetData: BulkHeiferMeasurementDataSheetType[] = XLSX.utils.sheet_to_json(
                      workBook.Sheets[workBook.SheetNames[0]],
                      {
                        // default value if a cell data is not present
                        defval: null,
                        header: [
                          'penName',
                          'heiferName',
                          'heiferDob',
                          'measurement_date',
                          'body_weight',
                          'height',
                        ],
                        raw: false,
                        // converting to en-US date format
                        dateNF: 'MM"/"DD"/"YYYY',
                      },
                    );

                    // whether uploaded sheet has required errors
                    const hasSheetError = jsonSheetData.some(
                      ({ heiferDob, heiferName, measurement_date, body_weight, height }) =>
                        !heiferName || !heiferDob || !measurement_date || (!body_weight && !height),
                    );
                    setHeiferSheetHasError(hasSheetError);
                    setSheetDataToRender(jsonSheetData.slice(1));
                  };
                  fileReader.readAsArrayBuffer(file);
                }
                return false;
              }}
              fileList={[]}
              style={{
                width: 500,
                maxHeight: 150,
                border: '1.8px dashed black',
                strokeDasharray: 5,
                borderRadius: 10,
                marginBottom: 40,
              }}
            >
              <div>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
              </div>
            </Dragger>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AddHeiferDataScreen;
