import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Table, Button, Popconfirm, message, Drawer } from 'antd';
import { DeleteOutlined, EditOutlined, WarningFilled } from '@ant-design/icons';
import { loader } from 'graphql.macro';
import {
  AllUsersQuery,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  UpdateUserMutationVariables,
  Enum_User_Role_Enum,
  User,
} from '../graphql/graphql-types';
import Heading from './Heading';
import UserForm from '../forms/UserForm';
import { logger } from '../utils/helpers';
import { firebaseAuth } from '../utils/firebase';

// This is the query to fetch all the user data
const allUsersQuery = loader('../graphql/queries/allUsersQuery.graphql');
// This is the mutation to delete user
const deleteUserMutation = loader('../graphql/mutations/deleteUserMutation.graphql');

// This is the type of table data
type TableRecordType = { __typename?: 'user' } & Pick<
  User,
  'id' | 'first_name' | 'last_name' | 'email' | 'role'
>;

// This is the Main functional component
const ListOfAllUsers: React.FC = () => {
  // This state holds the value for showing drawer or not
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  // This is the state in which id of the user is stored which it is going to be delete and by checking the id we control the loader of delete button
  const [deleteUserId, setDeleteUserId] = useState('');
  // This is the state for storing the value of user data which is going to be edited
  const [dataToEdit, setDataToEdit] = useState<UpdateUserMutationVariables>({
    id: '',
    first_name: '',
    last_name: '',
    role: Enum_User_Role_Enum.AppAdmin,
    email: '',
  });

  // This is the detail of the project fetched from the graphql by giving id
  const allUserQueryResult = useQuery<AllUsersQuery, null>(allUsersQuery);

  // This is the data of all users fetched from the graphql
  const allUsersData = allUserQueryResult.data;

  // This is the function to open the drawer
  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  // This is the function to close the drawer
  const onClose = () => {
    setVisibleDrawer(false);
  };

  // This is the mutation data for deleting the users data from graphql
  const [deleteUsersMutation] = useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    deleteUserMutation,
  );

  // handleDelete is invoked when user hits the ok button from the pop of delete button
  const handleDelete = (record: UpdateUserMutationVariables) => {
    /*
    This is the information of current loggedIn user and it is used to check the user which is going to be delete
    is current user or not, if record.id is similar to current user id then will show error msg
     */
    const { currentUser } = firebaseAuth;
    if (currentUser && currentUser.uid === record.id) {
      setDeleteUserId('');
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error('Cannot delete logged in user.');
    } else {
      // this is the mutation in which we pass the id of the user who is going to be delete
      deleteUsersMutation({
        variables: {
          id: record.id,
        },
        // update function is used here to see the updated list immediately after deleting the user
        update: (cache, { data: mutationResult }) => {
          const allUsersCache = cache.readQuery<AllUsersQuery, null>({
            query: allUsersQuery,
          });
          const idOfUser = mutationResult?.delete_user_by_pk?.id;
          if (idOfUser && allUsersCache && Array.isArray(allUsersCache.user)) {
            cache.writeQuery({
              query: allUsersQuery,
              data: {
                user: allUsersCache.user.filter((item) => {
                  return item.id !== idOfUser;
                }),
              },
            });
          }
        },
      })
        .then(() => {
          setDeleteUserId('');
          const firstname = record?.first_name;
          const lastname = record?.last_name;
          if (firstname && lastname) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success(`${firstname} ${lastname} is successfully deleted`);
          }
        })
        .catch((error) => {
          setDeleteUserId('');
          logger(error);
        });
    }
  };

  return (
    <>
      <Drawer
        title={<div style={{ fontSize: 22 }}>Edit user profile</div>}
        placement="right"
        closable
        onClose={onClose}
        visible={visibleDrawer}
        width={640}
        className="drawerContainer"
      >
        {visibleDrawer ? (
          <UserForm
            mode="edit"
            padding_left={0}
            padding_top={0}
            dataToEdit={dataToEdit}
            setVisibleDrawer={setVisibleDrawer}
          />
        ) : null}
      </Drawer>
      <div style={{ padding: 40, paddingTop: 10 }}>
        <Heading headingName="Users Summary" />
        {allUsersData && Array.isArray(allUsersData.user) && allUsersData.user.length > 0 ? (
          <Table<TableRecordType>
            dataSource={allUsersData.user}
            bordered
            pagination={false}
            style={{ padding: '40px', paddingTop: 10 }}
            size="middle"
          >
            <Table.Column<TableRecordType>
              key="name"
              title="Name"
              align="center"
              dataIndex="name"
              render={(text, record): string => {
                const nameOfUser = `${record.first_name} ${record.last_name}`;
                return nameOfUser;
              }}
            />
            <Table.Column<TableRecordType>
              title="Email"
              dataIndex="email"
              key="email"
              align="center"
              render={(text, record): string => {
                return record.email;
              }}
            />
            <Table.Column<TableRecordType>
              title="Role"
              dataIndex="role"
              key="role"
              align="center"
              render={(text, record): string => {
                return record.role === Enum_User_Role_Enum.AppAdmin ? 'Admin' : 'User';
              }}
            />
            <Table.Column<TableRecordType>
              key="option"
              title="Options"
              align="center"
              dataIndex="option"
              render={(text, record) => {
                return (
                  <div>
                    <Button
                      icon={<EditOutlined />}
                      className="primaryBtn"
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        showDrawer();
                        setDataToEdit({
                          id: record.id,
                          first_name: record.first_name,
                          last_name: record.last_name,
                          role: record.role,
                          email: record.email,
                        });
                      }}
                    >
                      Edit
                    </Button>
                    <Popconfirm
                      title="Delete User. Are you sure?"
                      onConfirm={(): void => {
                        setDeleteUserId(record.id);
                        handleDelete(record);
                      }}
                      icon={<WarningFilled style={{ color: '#ce0e2d' }} />}
                      cancelText="No"
                      okText="Yes"
                    >
                      <Button
                        className="secondaryBtn"
                        icon={<DeleteOutlined />}
                        loading={record.id === deleteUserId}
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </div>
                );
              }}
            />
          </Table>
        ) : null}
      </div>
    </>
  );
};

export default ListOfAllUsers;
