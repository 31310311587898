import React from 'react';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Scatter,
  ReferenceLine,
  Area,
  Surface,
  Symbols,
} from 'recharts';
import styles from './Charts.module.scss';
import '../App.scss';
import {
  ChartDataPoints,
  ChartDataType,
  CustomLegendPropsType,
  LineChartDataType,
  ScatterChartDataType,
} from '../utils/types';
import {
  assigningColorsToUniquePenIds,
  calErrBarLimit,
  CustomizedScatterPointShape,
  getXAxisTicksRange,
  handleLegendPayloadRender,
  renderLegendIcon,
  renderTicks,
} from '../utils/helpers';
import {
  heiferHeightErrRatio,
  heiferWeightErrRatio,
  pcMatureWeightHeightChartLegendPayload,
  purinaErrorRangeColor,
  purinaErrorRangeStrokeColor,
} from '../utils/globals';

// functional component
const PercentMatureWeightHeightChart: React.FC<ChartDataType> = ({
  psu2013Data,
  purinaModelData,
  subHerdHeiferData,
  historicalHeiferData,
  dchaData,
  xAxisMinInterval = 0,
  xAxisMaxInterval = 28,
}) => {
  // storing whether historical sub herd body weight or hip height data is present
  const isHistoricalHeiferData: boolean = (historicalHeiferData as ScatterChartDataType[]).some(
    (item) => {
      return (
        item.legendName === 'Historical Sub-herd Information BW' ||
        'Historical Sub-herd Information Hip Ht'
      );
    },
  );

  // function to calculate deviated chart data with error bar range for each data point
  const calcDeviatedChartData = (data: ScatterChartDataType[]) => {
    return data.map((item) => ({
      ...item,
      data: item.data
        ? item.data.map((elem) => ({
            ...elem,
            weightErrLimit: calErrBarLimit(elem.weight, heiferWeightErrRatio),
            heightErrLimit: calErrBarLimit(elem.height, heiferHeightErrRatio),
          }))
        : [],
    }));
  };

  // storing sub herd heifers body weight and hip height data to display error bar for each data point having error limit
  const deviatedSubHerdHeiferData = calcDeviatedChartData(
    subHerdHeiferData as ScatterChartDataType[],
  );

  // storing historical heifer's body weight and hip height data to show error bars for each data point
  const deviatedHistoricalData = calcDeviatedChartData(
    historicalHeiferData as ScatterChartDataType[],
  );

  // calculates the error limit for each data
  const purinaRangeErrorDataWithErrorLimits = (purinaModelData as LineChartDataType[]).map(
    (item) => ({
      ...item,
      data: item.data
        ? item.data.map((i) => ({
            ...i,
            weightErrLimit: calErrBarLimit(i.weight, heiferWeightErrRatio),
            heightErrLimit: calErrBarLimit(i.height, heiferHeightErrRatio),
          }))
        : [],
    }),
  );
  // calculates the range data for height
  const purinaRangeErrorDataWithHeightRange =
    purinaRangeErrorDataWithErrorLimits.length > 0
      ? purinaRangeErrorDataWithErrorLimits[1].data.map((i) => {
          // stores the value of height
          const height = !i.height ? 0 : i.height;
          return {
            age: i.age,
            heightRange: [height + i.heightErrLimit, height - i.heightErrLimit],
          };
        })
      : [];
  // calculates the range data for weight
  const purinaRangeErrorDataWithWeightRange =
    purinaRangeErrorDataWithErrorLimits.length > 0
      ? purinaRangeErrorDataWithErrorLimits[0].data.map((i) => {
          // stores the value of weight
          const weight = !i.weight ? 0 : i.weight;
          return {
            age: i.age,
            weightRange: [weight + i.weightErrLimit, weight - i.weightErrLimit],
          };
        })
      : [];

  // stores the value of error range data for purina model weight and height
  const purinaModelErrorRangeData: { age: number; height: number[]; weight: number[] }[] = [];
  purinaRangeErrorDataWithWeightRange.forEach((a) => {
    purinaRangeErrorDataWithHeightRange.forEach((b) => {
      if (a.age === b.age) {
        purinaModelErrorRangeData.push({
          age: a.age as number,
          weight: a.weightRange,
          height: b.heightRange,
        });
      }
    });
  });

  /* Variable to store merged sub herd heifer and historical data */
  const mergedData = [...subHerdHeiferData, ...historicalHeiferData] as ScatterChartDataType[];

  /* Variable to store merged chart data points */
  const mergeChartDataPoints: ChartDataPoints = [];

  mergedData.forEach((item) => {
    const { data } = item;
    if (data) {
      data.forEach((val) => {
        mergeChartDataPoints.push(val);
      });
    }
  });

  /* Variable to store data with unique pen IDs (removed duplicate IDs) */
  const dataForPenIdsWithColors = assigningColorsToUniquePenIds(mergeChartDataPoints);

  /* Function to render custom chart legend */
  const renderChartLegend = (props: any) => {
    /* Props destructuring */
    const { payload } = props as CustomLegendPropsType;

    /* Variable to store sub herd data payload */
    const subHerdPayload = payload.filter((item) => item.id.includes('Bw'));
    /* Variable to store historical herd data payload */
    const historicalPayload = payload.filter((item) => item.id.includes('Ht'));

    return (
      <div className={styles.legendContainer}>
        <div className={styles.legendInnerContainer}>
          {subHerdPayload.map((entry, index) => (
            <>
              {entry.type === 'line' || entry.type === 'plainline' ? (
                renderLegendIcon(entry.type, entry.id)
              ) : (
                <Surface
                  width={10}
                  height={10}
                  style={{ marginRight: 4, marginTop: entry.type === 'triangle' ? 4 : 2 }}
                >
                  <Symbols
                    cx={5}
                    cy={5}
                    type={entry.type === 'triangle' ? 'triangle' : 'circle'}
                    size={50}
                    fill="#000000"
                  />
                </Surface>
              )}

              <span key={`item-${index.toString()}`} style={{ marginRight: 10 }}>
                {entry.value}
              </span>
            </>
          ))}
        </div>
        <div className={styles.legendInnerContainer}>
          {historicalPayload.map((entry, index) => (
            <>
              {entry.type === 'line' || entry.type === 'plainline' ? (
                renderLegendIcon(entry.type, entry.id)
              ) : (
                <Surface
                  width={10}
                  height={10}
                  style={{ marginRight: 4, marginTop: entry.type === 'triangle' ? 4 : 2 }}
                >
                  <Symbols
                    cx={5}
                    cy={5}
                    type={entry.type === 'triangle' ? 'triangle' : 'circle'}
                    size={50}
                    fill="#000000"
                  />
                </Surface>
              )}

              <span key={`item-${index.toString()}`} style={{ marginRight: 10 }}>
                {entry.value}
              </span>
            </>
          ))}
        </div>
        <div className={styles.legendInnerContainer}>
          {dataForPenIdsWithColors.map((entry, index) => (
            <>
              <Surface width={10} height={10} style={{ marginRight: 4, marginTop: -2 }}>
                <Symbols cx={5} cy={5} type="square" size={60} fill={entry.color} />
              </Surface>
              <span key={`item-${index.toString()}`} className={styles.legendPenName}>
                {entry.pen_name || 'Unnamed'}
              </span>
            </>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.chartWrapper} style={{ margin: '0 5% 40px 5%' }}>
      <div className={styles.chartTitle}>Percent of Mature Weight and Height Chart</div>
      <ResponsiveContainer id="matureWeightHeightChartContainer" width={1180} height={500}>
        <ComposedChart
          margin={{
            top: 5,
            right: 20,
            left: 20,
            bottom: 20,
          }}
          data={purinaModelErrorRangeData}
        >
          <CartesianGrid stroke="#767676" />
          <XAxis
            // eslint-disable-next-line
            // @ts-ignore
            stroke="#000"
            ticks={renderTicks(
              xAxisMinInterval,
              getXAxisTicksRange(xAxisMaxInterval, xAxisMinInterval),
              2,
            )}
            type="number"
            dataKey="age"
            domain={[xAxisMinInterval, xAxisMaxInterval]}
            allowDataOverflow
            interval={0}
            orientation="bottom"
            label={{
              value: 'Age (months)',
              position: 'insideBottom',
              offset: -16,
              style: { fontWeight: 500, fontSize: 16, color: '#6E6E6E' },
            }}
            tick={{ fontSize: 12 }}
          />
          <YAxis
            // eslint-disable-next-line
            // @ts-ignore
            stroke="#000"
            domain={['dataMin', 'dataMax - 10']}
            yAxisId={0}
            interval={0}
            tickLine
            tick={{ fontSize: 12 }}
            ticks={renderTicks(0, 55, 2)}
            tickFormatter={(value: number) => {
              // renders the tick value which is multiple of 5, else hides that tick value
              return value % 5 === 0 ? value.toString() : '';
            }}
            label={{
              value: 'Percent of Mature Body Weight',
              angle: -90,
              position: 'insideBottomLeft',
              offset: 20,
              style: {
                fontWeight: 700,
                fontSize: 16,
                color: '#6E6E6E',
                margin: 20,
              },
            }}
          />
          <YAxis
            // eslint-disable-next-line
            // @ts-ignore
            stroke="#000"
            yAxisId={1}
            tick={{ fontSize: 12 }}
            interval={0}
            orientation="right"
            domain={['dataMin', 'dataMax - 10']}
            ticks={renderTicks(0, 55, 2)}
            tickFormatter={(value: number) => {
              return value % 5 === 0 ? value.toString() : '';
            }}
            label={{
              value: 'Percent of Mature Hip Height',
              position: 'insideTopRight',
              angle: -90,
              offset: 20,
              style: {
                fontWeight: 700,
                fontSize: 16,
                color: '#6E6E6E',
              },
            }}
          />
          <Legend
            wrapperStyle={{
              alignItems: 'center',
              padding: '0 0 20px 0',
              width: isHistoricalHeiferData ? '800px' : '600px',
              left: 0,
              right: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center',
            }}
            margin={{ top: 20, bottom: 20 }}
            verticalAlign="top"
            iconSize={12}
            payload={handleLegendPayloadRender(
              pcMatureWeightHeightChartLegendPayload,
              psu2013Data,
              purinaModelData,
              subHerdHeiferData,
              historicalHeiferData,
            )}
            content={renderChartLegend}
          />
          <Area
            type="monotone"
            dataKey="weight"
            stroke={purinaErrorRangeStrokeColor}
            fill={purinaErrorRangeColor}
          />
          <Area
            type="monotone"
            dataKey="height"
            stroke={purinaErrorRangeStrokeColor}
            fill={purinaErrorRangeColor}
          />
          {dchaData.map((item) => (
            <ReferenceLine
              key={Math.random().toString(16).slice(2)}
              name="DCHA Standards BW"
              y={item.percentStandardWeight}
              strokeWidth={1.8}
              stroke="#F20505"
              strokeDasharray="10 8"
              // eslint-disable-next-line
              // @ts-ignore
              legendType="circle"
            />
          ))}
          {dchaData.map((item) => (
            <ReferenceLine
              key={Math.random().toString(16).slice(2)}
              name="DCHA Standards Hip Ht"
              yAxisId={1}
              y={item.percentStandardHeight}
              strokeWidth={1.6}
              stroke="#F20505"
              strokeDasharray="4 4"
            />
          ))}
          {(psu2013Data as LineChartDataType[]).map((item) => (
            <Line
              key={item.name}
              yAxisId={item.yAxisId}
              // eslint-disable-next-line
              // @ts-ignore
              data={item.data}
              dataKey={item.dataKey}
              name={item.name}
              legendType="plainline"
              dot={false}
              strokeWidth={item.strokeWidth}
              type="monotone"
              stroke={item.strokeColor}
              strokeDasharray={item.strokeDashArray}
              isAnimationActive={false}
            />
          ))}
          {(purinaModelData as LineChartDataType[]).map((item) => (
            <Line
              key={item.name}
              yAxisId={item.yAxisId}
              // eslint-disable-next-line
              // @ts-ignore
              data={item.data}
              dataKey={item.dataKey}
              name={item.name}
              legendType="plainline"
              dot={false}
              strokeWidth={item.strokeWidth}
              type="monotone"
              stroke={item.strokeColor}
              strokeDasharray={item.strokeDashArray}
              isAnimationActive={false}
            />
          ))}
          {deviatedSubHerdHeiferData.map(
            ({ dataKey, legendName, legendType, data, fillColor, strokeColor }) => (
              <Scatter
                key={legendName}
                dataKey={dataKey}
                className=""
                shape={
                  <CustomizedScatterPointShape type="subHerd" data={dataForPenIdsWithColors} />
                }
                legendType={legendType}
                data={data.filter((i) => {
                  return dataKey === 'weight'
                    ? i.weight && i.weight <= 110
                    : i.height && i.height <= 110;
                })}
                fill={fillColor}
                strokeWidth={0.4}
                stroke={strokeColor}
                name={legendName}
              />
            ),
          )}
          {(deviatedHistoricalData as ScatterChartDataType[]).map(
            ({ dataKey, legendName, legendType, data, fillColor, strokeColor }) => (
              <Scatter
                key={legendName}
                dataKey={dataKey}
                className=""
                shape={
                  <CustomizedScatterPointShape type="historical" data={dataForPenIdsWithColors} />
                }
                legendType={legendType}
                data={data}
                fill={fillColor}
                strokeWidth={0.5}
                stroke={strokeColor}
                name={legendName}
              />
            ),
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PercentMatureWeightHeightChart;
