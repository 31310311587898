import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Table, message, Drawer } from 'antd';
import { WarningFilled, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import moment from 'moment-timezone';
import { UseFormMethods } from 'react-hook-form';
import styles from './AddSubHerdDataScreen.module.scss';
import btnStyles from './HeifersOfSubHerdScreen.module.scss';
import Heading from '../components/Heading';
import InfoBar from '../components/InfoBar';
import {
  AllHerdDataOfHerdQuery,
  AllHerdDataOfHerdQueryVariables,
  DeleteHerdDataMutation,
  DeleteHerdDataMutationVariables,
  Herd_Data,
  Herd_Data_Avg_Fields,
  Maybe,
  User,
} from '../graphql/graphql-types';
import { handleAvgSubHerdDataFields, handleCalculatedSubHerdData, logger } from '../utils/helpers';
import SubHerdDataForm from '../forms/SubHerdDataForm';
import ViewLatestSubHerdDataSection from '../components/ViewLatestSubHerdDataSection';
import { UserInputDataType } from '../utils/types';

// importing allSubHerdDataOfSubHerd query
const allSubHerdDataOfSubHerdQuery = loader('../graphql/queries/allHerdDataOfHerdQuery.graphql');
// importing deleteSubHerdData mutation
const deleteSubHerdDataMutation = loader('../graphql/mutations/deleteHerdDataMutation.graphql');

// destructuring Column component
const { Column } = Table;

// allSubHerdDataOfSubHerd object type
type SubHerdDataTableType = { __typename?: 'herd_data' } & Pick<
  Herd_Data,
  | 'adg_birth_56days'
  | 'avg_birth_bw_calves'
  | 'avg_birth_height_calves'
  | 'avg_bw_3rdlact_cow'
  | 'avg_height_3rdlact_cow'
  | 'avg_spc_heifers'
  | 'bw_heifer_1st_conception'
  | 'bw_heifer_1week_precalving'
  | 'bw_heifer_calving'
  | 'current_age_1st_calving'
  | 'goal_age_1st_calving'
  | 'height_heifer_1st_conception'
  | 'height_heifer_calving'
  | 'mintarget_calf_bw_56days'
  | 'mintarget_calf_height_56days'
  | 'id'
  | 'updated_at'
> & { updated_by?: Maybe<{ __typename?: 'user' } & Pick<User, 'first_name' | 'last_name'>> };

// component props
type AllSubHerdDataScreenProps = {
  // selected subHerdId from DataMeasurement screen
  selectedSubHerdId: string;
  // subHerdDataForm methods
  subHerdDataFormMethods: UseFormMethods<Herd_Data_Avg_Fields>;
  // updates sub herd data form values
  setSubHerdDataFormValues: React.Dispatch<React.SetStateAction<Herd_Data_Avg_Fields>>;
};

// FC
const AllSubHerdDataScreen: React.FC<AllSubHerdDataScreenProps> = ({
  selectedSubHerdId,
  subHerdDataFormMethods,
  setSubHerdDataFormValues,
}) => {
  // toggling visibility of drawer for viewing subHerd data
  const [viewDrawerVisible, setViewDrawerVisible] = useState<boolean>(false);
  // toggling visibility of drawer for editing subHerd data
  const [editDrawerVisible, setEditDrawerVisible] = useState<boolean>(false);
  // used to set subHerdId for selected row in the table to render respective subHerd statistics
  const [subHerdDataId, setSubHerdDataId] = useState<string | null>(null);

  // fetching data for all subHerds
  const allSubHerdDataQueryResult = useQuery<
    AllHerdDataOfHerdQuery,
    AllHerdDataOfHerdQueryVariables
  >(allSubHerdDataOfSubHerdQuery, {
    variables: {
      herd_id: selectedSubHerdId,
    },
  });

  // mutation function to delete a specific subHerd from the subHerds table
  const [deleteSubHerd] = useMutation<DeleteHerdDataMutation, DeleteHerdDataMutationVariables>(
    deleteSubHerdDataMutation,
  );

  // storing allSubHerdData fetched from query (if present)
  const allSubHerdData = allSubHerdDataQueryResult.data?.herd_data;

  // componentDidMount
  useEffect(() => {
    // when sub herd data for a selected herd is not present,
    // then set sub herd form with null value to render AddSubHerdDataScreen on Add heifer data tab
    if (!Array.isArray(allSubHerdData) || allSubHerdData.length === 0) {
      setSubHerdDataFormValues({ avg_height_3rdlact_cow: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSubHerdData]);

  // handling delete of a subHerd from subHerd table
  const handleDeleteSubHerd = (subHerdId: string) => {
    // triggering deleteSubHerd mutation
    deleteSubHerd({
      variables: {
        id: subHerdId,
      },
      update: (cache) => {
        cache.modify({
          fields: {
            herd_data(existingSubHerdDataRef: Array<Reference>, { readField }) {
              return existingSubHerdDataRef.filter((refItem) => {
                return subHerdId !== readField('id', refItem);
              });
            },
          },
        });
      },
    })
      .then((): void => {
        setSubHerdDataId(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Sub-herd information has been successfully deleted');
      })
      .catch((deleteSubHerdMutationError: ApolloError): void => {
        setSubHerdDataId(null);
        logger(deleteSubHerdMutationError);
      });
  };

  // finding subHerd data for the selected subHerdDataId
  const selectedSubHerdDataSummary =
    Array.isArray(allSubHerdData) && allSubHerdData.length > 0
      ? allSubHerdData.find((item) => {
          return item.id === subHerdDataId;
        })
      : undefined;

  // storing user input data for a selected row in the table and rendering data on drawer
  let userInputData: UserInputDataType = null;
  // storing subHerd average field's data for userInputData and calculated herd data
  let avgInputDataFields: Herd_Data_Avg_Fields | undefined;

  if (selectedSubHerdDataSummary) {
    avgInputDataFields = handleAvgSubHerdDataFields(selectedSubHerdDataSummary);
    userInputData = handleCalculatedSubHerdData(avgInputDataFields);
  }

  return (
    <div className={styles.tabPanel}>
      <Drawer
        title={<div style={{ fontSize: 22 }}>View sub-herd information</div>}
        closable
        className="drawerContainer"
        width={540}
        visible={viewDrawerVisible}
        onClose={(): void => {
          setViewDrawerVisible(false);
          setSubHerdDataId(null);
        }}
        destroyOnClose
      >
        {selectedSubHerdDataSummary ? (
          <div style={{ margin: '15px 24px' }}>
            <p>
              Data input on{' '}
              <b>{moment(selectedSubHerdDataSummary.updated_at).format('DD MMM YYYY')}</b> at{' '}
              <b>{moment(selectedSubHerdDataSummary.updated_at).format('HH:mm A')} CST</b>
            </p>

            <ViewLatestSubHerdDataSection
              layoutSize={0}
              avgInputDataFields={avgInputDataFields}
              userInputData={userInputData}
              layoutDirection="vertical"
            />
          </div>
        ) : null}
      </Drawer>
      <Drawer
        title={<div style={{ fontSize: 22 }}>Edit sub-herd information</div>}
        closable
        className="drawerContainer"
        width={560}
        visible={editDrawerVisible}
        onClose={(): void => {
          setEditDrawerVisible(false);
        }}
        destroyOnClose
        bodyStyle={{ padding: '0px 24px' }}
      >
        <SubHerdDataForm
          setDrawerVisible={(prevState): void => {
            setEditDrawerVisible(prevState);
          }}
          textAlign="start"
          selectedSubHerdId={selectedSubHerdId}
          layoutDirection="vertical"
          subHerdDataFormMethods={subHerdDataFormMethods}
        />
      </Drawer>
      <Heading headingName="Sub-herd Information Summary" />
      {Array.isArray(allSubHerdData) && allSubHerdData.length === 0 ? (
        <InfoBar
          marginLeft={0}
          info={
            <span>
              Sub-herd information has not been added yet for this sub-herd. Use{' '}
              <b>Add Heifer Data</b> tab to start recording Sub-herd Information.
            </span>
          }
        />
      ) : (
        <div>
          <Button
            className={btnStyles.addHeiferBtn}
            onClick={(): void => {
              setEditDrawerVisible(true);
            }}
          >
            Edit sub-herd information
          </Button>
          <Table<SubHerdDataTableType>
            rowKey={(record): string => {
              return record.id;
            }}
            dataSource={allSubHerdData}
            style={{ padding: '20px 0' }}
            size="middle"
            bordered
          >
            <Column<SubHerdDataTableType>
              render={(text: string) => {
                // formatting dateTime with (CST) timezone
                return `${moment(text).format('DD MMM YYYY; HH:mm A')} CST`;
              }}
              title="Updated On"
              align="center"
              dataIndex="updated_at"
              key="updated_at"
            />
            <Column<SubHerdDataTableType>
              title="Updated By"
              align="center"
              dataIndex="updated_by"
              render={(obj: Pick<User, 'first_name' | 'last_name'>) => {
                return `${obj.first_name} ${obj.last_name}`;
              }}
              key="id"
            />
            <Column<SubHerdDataTableType>
              title="Options"
              dataIndex="id"
              key="id"
              align="center"
              render={(text: string, record): JSX.Element => (
                <div>
                  <Button
                    style={{ marginRight: 10 }}
                    className="primaryBtn"
                    icon={<EyeOutlined />}
                    onClick={(): void => {
                      setViewDrawerVisible(true);
                      setSubHerdDataId(record.id);
                    }}
                  >
                    View
                  </Button>
                  <Popconfirm
                    title="Delete sub-herd information. Are you sure ?"
                    onConfirm={(): void => {
                      handleDeleteSubHerd(record.id);
                      setSubHerdDataId(record.id);
                    }}
                    icon={<WarningFilled style={{ color: '#ce0e2d' }} />}
                    cancelText="No"
                    okText="Yes"
                  >
                    <Button
                      className="secondaryBtn"
                      disabled={record.id === subHerdDataId}
                      loading={record.id === subHerdDataId}
                      type="default"
                      icon={<DeleteOutlined />}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                </div>
              )}
            />
          </Table>
        </div>
      )}
    </div>
  );
};

export default AllSubHerdDataScreen;
