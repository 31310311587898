import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { LeftOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { Button, Drawer, Space, Switch } from 'antd';
import Heading from '../components/Heading';
import {
  TodayHeiferMeasurementsQuery,
  TodayHeiferMeasurementsQueryVariables,
} from '../graphql/graphql-types';
import HeiferWeightChart from '../charts/HeiferWeightChart';
import {
  heiferHeightChartDchaData,
  heiferWeightChartDchaData,
  pcMatureWeightHeightDchaData,
  heiferWeightChartPsuData,
  heiferWeightChartPurinaModelData,
  heiferHeightChartPsuData,
  heiferHeightChartPurinaModelData,
  pcMatureWeightHeightChartPsuData,
  pcMatureWeightHeightChartPurinaData,
  pcMatureWeightHeightChartSubHerdHeifersData,
  heiferChartDataOptions,
} from '../utils/globals';
import {
  AgeInterval,
  HeiferChartFormType,
  SubHerdSummaryAgeFilterOptions,
  LineChartDataType,
  ScreenToRenderOnDataMeasurementScreenType,
} from '../utils/types';
import HeiferHeightChart from '../charts/HeiferHeightChart';
import PercentMatureWeightHeightChart from '../charts/PercentMatureWeightHeightChart';
import { calcHeiferChartDataPoints } from '../utils/helpers';
import InfoBar from '../components/InfoBar';
import ECFormItem from '../components/ECFormItem';
import AgeIntervalOptions from '../components/AgeIntervalOptions';
import SubHerdBasicSummaryTable from '../components/SubHerdBasicSummaryTable';
import subHerdSummaryCalculations, {
  SubHerdSummaryCalcType,
} from '../utils/subHerdSummaryCalculations';

const todayHeiferMeasurementsQuery = loader('../graphql/queries/todayHeiferMeasurements.graphql');

// type def for component props
type ViewChartsScreenProps = {
  // selected sub herdId from DataMeasurement screen
  selectedSubHerdId: string;
  // function to set screen name for rendering screen component
  setScreenToRender: React.Dispatch<
    React.SetStateAction<ScreenToRenderOnDataMeasurementScreenType>
  >;
  // mature body weight obtained from herdDataForm
  matureBodyWeight: number;
  // mature hip height obtained from herdDataForm
  matureHipHeight: number;
};

// functional component
const ViewChartsScreen: React.FC<ViewChartsScreenProps> = ({
  selectedSubHerdId,
  setScreenToRender,
  matureBodyWeight,
  matureHipHeight,
}) => {
  // parsing today's date with 0 time offset
  const currentDate: string = moment().format('YYYY-MM-DDT00:00:00+00:00');

  // fetching heifer measurements data taken today
  const todayHeiferMeasurementsQueryResult = useQuery<
    TodayHeiferMeasurementsQuery,
    TodayHeiferMeasurementsQueryVariables
  >(todayHeiferMeasurementsQuery, {
    variables: {
      _herd_id: selectedSubHerdId,
      _updated_at: currentDate,
    },
  });

  // accessing hook form internal methods
  const methods = useForm<HeiferChartFormType>({
    defaultValues: {
      psu: true,
      purina: true,
      subHerdHeifer: true,
      minAgeInterval: 0,
      maxAgeInterval: 28,
      baseAge: 4,
      baseAgeErrDeviation: 1,
    },
  });
  // state to show/hide options drawer
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  // state to maintain updated age interval options to render x-axis range for all heifer charts
  const [ageIntervalOptions, setAgeIntervalOptions] = useState<AgeInterval>({
    minAgeInterval: 0,
    maxAgeInterval: 28,
  });
  /* state to store age and interval (error deviation) to
  calculate herd summary data based on age range (inclusive) */
  const [
    herdSummaryAgeFilterOptions,
    setHerdSummaryAgeFilterOptions,
  ] = useState<SubHerdSummaryAgeFilterOptions>({
    baseAge: 4,
    baseAgeErrDeviation: 1,
  });

  // all heifer measurement data for today's date
  const todayHeiferMeasurementsData = todayHeiferMeasurementsQueryResult?.data?.heifer_measurements;

  // storing herd heifers calculated data points to plot for all heifers charts
  const herdHeifersDataToPlot = calcHeiferChartDataPoints(todayHeiferMeasurementsData);

  // stores the upper limit for x-axis max range
  // const xUpperLimit: number = setXAxisMaxInterval(methods.watch('ageInterval'));
  // storing current form value for psu switch component
  const watchPsuChecked = methods.watch('psu');
  // storing current form value for purina switch component
  const watchPurinaChecked = methods.watch('purina');
  // storing current form value for herd heifer switch component
  const watchHerdHeiferChecked = methods.watch('herdHeifer');

  // storing herd summary table data (empty by default)
  let herdSummaryTableData: SubHerdSummaryCalcType[] | [] = [];
  // checking if current heifer measurements (weight and height data points) are present
  if (
    herdHeifersDataToPlot.weightChartDataPoints.length !== 0 &&
    herdHeifersDataToPlot.heightChartDataPoints.length !== 0
  ) {
    herdSummaryTableData = subHerdSummaryCalculations({
      weightChartSubHerdHeiferData: herdHeifersDataToPlot.weightChartDataPoints,
      heightChartSubHerdHeiferData: herdHeifersDataToPlot.heightChartDataPoints,
      selectedOption: 'current',
      baseAge: herdSummaryAgeFilterOptions.baseAge,
      baseAgeErrDeviation: herdSummaryAgeFilterOptions.baseAgeErrDeviation,
    });
  }

  return (
    <div>
      <Drawer
        title={<div style={{ fontSize: 22 }}>Options</div>}
        closable
        width={480}
        onClose={(): void => {
          setShowDrawer(false);
        }}
        className="drawerContainer"
        visible={showDrawer}
      >
        <form style={{ margin: '15px 24px' }}>
          <h3>Data Series</h3>
          {heiferChartDataOptions.slice(0, 3).map(({ id, seriesTitle }) => (
            <Space
              key={id}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 5,
                alignItems: 'baseline',
              }}
            >
              <span>{seriesTitle}</span>
              <ECFormItem
                name={id}
                control={methods.control}
                render={({ onChange, value }) => (
                  <Switch
                    checked={value as boolean}
                    onChange={(val) => {
                      onChange(val);
                    }}
                  />
                )}
              />
            </Space>
          ))}
          <AgeIntervalOptions formMethods={methods} setAgeInterval={setAgeIntervalOptions} />
        </form>
      </Drawer>
      <Heading headingName="Analysis Charts" />
      <Button
        type="link"
        style={{ padding: 0, margin: 0, color: '#000' }}
        onClick={(): void => {
          setScreenToRender('AddHeiferDataScreen');
        }}
      >
        <LeftOutlined />
        Back
      </Button>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: -20 }}>
        <InfoBar
          marginLeft={0}
          info={
            <span>
              These charts have <b>Heifer Data</b> that was added today in system. For detailed
              charts use <b>Analyze</b> section.
            </span>
          }
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <Heading headingName="Sub-herd Basic Summary" />
        <SubHerdBasicSummaryTable
          tableData={herdSummaryTableData}
          formMethods={methods}
          setSubHerdSummaryAgeFilterOptions={setHerdSummaryAgeFilterOptions}
        />
      </div>
      <div style={{ marginTop: 40 }}>
        <Heading
          headingName="Heifer Weight Chart"
          showOptions
          onOptionsClick={(): void => {
            setShowDrawer(true);
          }}
        />
        <HeiferWeightChart
          psu2013Data={watchPsuChecked ? heiferWeightChartPsuData : []}
          purinaModelData={watchPurinaChecked ? heiferWeightChartPurinaModelData : []}
          subHerdHeiferData={
            watchHerdHeiferChecked ? herdHeifersDataToPlot.weightChartDataPoints : []
          }
          historicalHeiferData={[]}
          dchaData={heiferWeightChartDchaData}
          matureBodyWeight={matureBodyWeight}
          xAxisMinInterval={ageIntervalOptions.minAgeInterval}
          xAxisMaxInterval={ageIntervalOptions.maxAgeInterval}
        />
      </div>
      <div style={{ marginTop: 40 }}>
        <Heading
          headingName="Heifer Height Chart"
          showOptions
          onOptionsClick={(): void => {
            setShowDrawer(true);
          }}
        />
        <HeiferHeightChart
          psu2013Data={watchPsuChecked ? heiferHeightChartPsuData : []}
          purinaModelData={watchPurinaChecked ? heiferHeightChartPurinaModelData : []}
          subHerdHeiferData={
            watchHerdHeiferChecked ? herdHeifersDataToPlot.heightChartDataPoints : []
          }
          historicalHeiferData={[]}
          dchaData={heiferHeightChartDchaData}
          matureHipHeight={matureHipHeight}
          xAxisMinInterval={ageIntervalOptions.minAgeInterval}
          xAxisMaxInterval={ageIntervalOptions.maxAgeInterval}
        />
      </div>
      <div style={{ marginTop: 40 }}>
        <Heading
          headingName="Heifer % of Mature Weight and Height Chart"
          showOptions
          onOptionsClick={(): void => {
            setShowDrawer(true);
          }}
        />
        <PercentMatureWeightHeightChart
          psu2013Data={watchPsuChecked ? pcMatureWeightHeightChartPsuData : []}
          purinaModelData={
            watchPurinaChecked ? (pcMatureWeightHeightChartPurinaData as LineChartDataType[]) : []
          }
          subHerdHeiferData={
            watchHerdHeiferChecked
              ? [
                  {
                    ...pcMatureWeightHeightChartSubHerdHeifersData[0],
                    data: herdHeifersDataToPlot.percentChartBwDataPoints,
                  },
                  {
                    ...pcMatureWeightHeightChartSubHerdHeifersData[1],
                    data: herdHeifersDataToPlot.percentChartHipHtDataPoints,
                  },
                ]
              : []
          }
          historicalHeiferData={[]}
          dchaData={pcMatureWeightHeightDchaData}
          xAxisMinInterval={ageIntervalOptions.minAgeInterval}
          xAxisMaxInterval={ageIntervalOptions.maxAgeInterval}
        />
      </div>
    </div>
  );
};

export default ViewChartsScreen;
