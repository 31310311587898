import React from 'react';
import { loader } from 'graphql.macro';
import { DeleteOutlined, WarningFilled, EditOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { Table, Button, Popconfirm, Drawer, message } from 'antd';
import { Reference, useMutation, useQuery } from '@apollo/client';
import {
  DeleteHeiferMeasurementMutation,
  DeleteHeiferMeasurementMutationVariables,
  HeiferAllMeasurementsQuery,
  HeiferAllMeasurementsQueryVariables,
} from '../graphql/graphql-types';
import ViewHeiferMeasurementData from '../components/ViewHeiferMeasurementData';
import { logger, renderWarningMessage } from '../utils/helpers';
import { HeiferMeasurementDataType, HeiferType } from '../utils/types';
import HeiferDataForm from '../forms/HeiferDataForm';
import Heading from '../components/Heading';
import { dateFormat, dateFormatForDisplay } from '../utils/globals';

const heiferAllMeasurementsQuery = loader('../graphql/queries/heiferAllMeasurementsQuery.graphql');
const deleteHeiferMeasurementMutation = loader(
  '../graphql/mutations/deleteHeiferMeasurmentMutation.graphql',
);

// This is the types of props coming from parent component
type ViewHeiferDataScreenProps = {
  // subHerd name is used to show the name of subHerd in view measurement drawer as subHerd Id
  subHerdName: string | undefined;
  // herd name is used to show the name of herd in view measurement drawer as herd Id
  herdName: string | undefined;
  // updates whether to show/hide heifer measurements table
  setShowHeiferMeasurements: React.Dispatch<React.SetStateAction<boolean>>;
  // data for selected heifer when view measurement btn is clicked
  selectedHeiferData: HeiferType;
};

// This is the main functional component
const ViewHeiferDataScreen: React.FC<ViewHeiferDataScreenProps> = ({
  subHerdName,
  herdName,
  setShowHeiferMeasurements,
  selectedHeiferData,
}) => {
  // This state handle the opening and closing of the view measurement drawer if this state is true then drawer is open
  const [showViewDrawer, setShowViewDrawer] = React.useState<boolean>(false);
  // This state is used for showing the loader of delete button when this state is empty string then no loading
  const [deleteMeasurementId, setDeleteMeasurementId] = React.useState<string>('');
  // This state handle the opening and closing of the edit measurement drawer if this state is true then drawer is open
  const [showEditDrawer, setShowEditDrawer] = React.useState<boolean>(false);
  // This stores the detail of heifer measurement which is selected for editing
  const [heiferDataToEdit, setHeiferDataToEdit] = React.useState<HeiferMeasurementDataType | null>(
    null,
  );
  // This stores the detail of heifer measurement which is selected for showing data in view drawer
  const [
    measurementDataToView,
    setMeasurementDataToView,
  ] = React.useState<HeiferMeasurementDataType | null>(null);

  /*  Fetching the data of all measurement of selected heifer
     if you want to execute a query in response to a different event then we use  useLazyQuery,
     so, Here we are using useLazyQuery for fetching the query after selection of heifer in select box */
  const {
    data: heiferAllMeasurements,
    loading: heiferAllMeasurementsLoading,
    error: heiferAllMeasurementsErr,
  } = useQuery<HeiferAllMeasurementsQuery, HeiferAllMeasurementsQueryVariables>(
    heiferAllMeasurementsQuery,
    { variables: { _heifer_id: selectedHeiferData.id }, fetchPolicy: 'network-only' },
  );

  // This is the mutation data for deleting the measurement data of heifer from graphql
  const [deleteHeiferMeasurement] = useMutation<
    DeleteHeiferMeasurementMutation,
    DeleteHeiferMeasurementMutationVariables
  >(deleteHeiferMeasurementMutation);

  // storing the data of measurement of selected heifer
  const heiferMeasurements = heiferAllMeasurements?.heifer_measurements;

  // This function is invoke when user hit the delete button of the measurement table
  const handleDeleteMeasurement = (measurementId: string) => {
    // this is the mutation in which we pass the id of the measurement which is going to be delete
    deleteHeiferMeasurement({
      variables: {
        id: measurementId,
      },
      // update function is used here to see the updated list immediately after deleting the measurement
      update: (cache, { data: deletedData }) => {
        const idToDelete = deletedData?.delete_heifer_measurements_by_pk?.id;
        // This is the modify function in which fields is one input where we get all the data of our cache and according to our need we apply field name and get ref of field
        cache.modify({
          fields: {
            /* inside fields we get all our fields of cache here we need to update measurement field
               existingMeasurementRefs is the all measurement present in our cache */
            heifer_measurements(existingMeasurementRefs: Array<Reference>, { readField }) {
              if (idToDelete) {
                return existingMeasurementRefs.filter((measurement) => {
                  // inside readField first parameter is key and the second parameter is object by this we get id of object
                  return idToDelete !== readField('id', measurement);
                });
              }
              return existingMeasurementRefs;
            },
          },
        });
      },
    })
      .then(() => {
        setDeleteMeasurementId('');
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Heifer measurement has been successfully deleted.');
      })
      .catch((error) => {
        setDeleteMeasurementId('');
        logger(error);
      });
  };

  return (
    <>
      {measurementDataToView ? (
        <Drawer
          title={<div style={{ fontSize: 22 }}>View measurement data </div>}
          closable
          className="drawerContainer"
          width={480}
          visible={showViewDrawer}
          onClose={(): void => {
            setShowViewDrawer(false);
          }}
          destroyOnClose
        >
          <ViewHeiferMeasurementData
            measurementData={measurementDataToView}
            penName={selectedHeiferData.pen?.name}
            subHerdName={subHerdName}
            herdName={herdName}
          />
        </Drawer>
      ) : null}
      {heiferDataToEdit ? (
        <Drawer
          title={<div style={{ fontSize: 22 }}>Edit measurement data </div>}
          closable
          className="drawerContainer"
          width={550}
          visible={showEditDrawer}
          onClose={(): void => {
            setShowEditDrawer(false);
            setHeiferDataToEdit(null);
          }}
          destroyOnClose
        >
          <p
            style={{
              fontStyle: 'italic',
              fontSize: 14,
              marginTop: 14,
              marginBottom: 0,
              marginLeft: 20,
            }}
          >
            All fields are compulsory
          </p>
          {selectedHeiferData && heiferDataToEdit ? (
            <HeiferDataForm
              heiferDobForEdit={selectedHeiferData.dob}
              mode="edit"
              heiferDataToEdit={heiferDataToEdit}
              setShowEditDrawer={setShowEditDrawer}
            />
          ) : null}
        </Drawer>
      ) : null}

      <div>
        {Array.isArray(heiferMeasurements) && heiferMeasurements.length > 0 ? (
          <div>
            <Heading
              headingName="Heifer Measurement Data"
              showCrossIcon
              handleCrossClick={() => {
                setShowHeiferMeasurements(false);
              }}
            />
            {heiferAllMeasurementsErr ? (
              <p style={{ color: 'red' }}>{heiferAllMeasurementsErr.message}</p>
            ) : (
              <Table<HeiferMeasurementDataType>
                dataSource={heiferMeasurements}
                bordered
                pagination={false}
                style={{ padding: '40px 0px', paddingTop: 20 }}
                size="small"
                rowKey={({ id }) => id}
                loading={heiferAllMeasurementsLoading}
              >
                <Table.Column<HeiferMeasurementDataType>
                  title="Date of Measurement"
                  dataIndex="measurement_date"
                  key="measurement_date"
                  align="center"
                  render={(text) => {
                    return moment(text, dateFormat).format(dateFormatForDisplay);
                  }}
                />
                <Table.Column<HeiferMeasurementDataType>
                  title="Age on Measurement Date (months)"
                  dataIndex="measurement_date_age"
                  key="measurement_date_age"
                  align="center"
                  render={(text: number) => {
                    return text.toFixed(2);
                  }}
                />
                <Table.Column<HeiferMeasurementDataType>
                  title="Body Weight (lbs)"
                  dataIndex="body_weight"
                  key="body_weight"
                  align="center"
                  render={(text, { body_weight }): string => {
                    if (body_weight === 0) {
                      return '0';
                    }
                    return !body_weight
                      ? '-'
                      : (body_weight as number).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        });
                  }}
                />
                <Table.Column<HeiferMeasurementDataType>
                  title="Height (inches)"
                  dataIndex="height"
                  key="height"
                  align="center"
                  render={(text, { height }) => {
                    if (height === 0) {
                      return '0';
                    }
                    return !height
                      ? '-'
                      : (height as number).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        });
                  }}
                />
                <Table.Column<HeiferMeasurementDataType>
                  title="% Mature Body Weight"
                  dataIndex="pc_mature_body_weight"
                  key="pc_mature_body_weight"
                  align="center"
                  render={(text, { pc_mature_body_weight }) => {
                    if (pc_mature_body_weight === 0) {
                      return '0';
                    }
                    return !pc_mature_body_weight
                      ? '-'
                      : (pc_mature_body_weight as number).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        });
                  }}
                />
                <Table.Column<HeiferMeasurementDataType>
                  title="% Mature Height"
                  dataIndex="pc_mature_height"
                  key="pc_mature_height"
                  align="center"
                  render={(text, { pc_mature_height }) => {
                    if (pc_mature_height === 0) {
                      return '0';
                    }
                    return !pc_mature_height
                      ? '-'
                      : (pc_mature_height as number).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        });
                  }}
                />
                <Table.Column<HeiferMeasurementDataType>
                  key="option"
                  title="Options"
                  align="center"
                  dataIndex="option"
                  render={(text, record) => {
                    return (
                      <div>
                        <Button
                          icon={<EyeOutlined />}
                          className="primaryBtn"
                          style={{
                            marginRight: 10,
                            color: '#000',
                          }}
                          onClick={() => {
                            setMeasurementDataToView(record);
                            setShowViewDrawer(true);
                          }}
                        >
                          View
                        </Button>
                        <Button
                          icon={<EditOutlined />}
                          className="primaryBtn"
                          style={{ marginRight: 10 }}
                          onClick={() => {
                            setHeiferDataToEdit(record);
                            setShowEditDrawer(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Popconfirm
                          title="Delete Measurement. Are you sure?"
                          onConfirm={(): void => {
                            if (record.id) {
                              setDeleteMeasurementId(record.id);
                              handleDeleteMeasurement(record.id);
                            }
                          }}
                          icon={<WarningFilled style={{ color: '#ce0e2d' }} />}
                          cancelText="No"
                          okText="Yes"
                        >
                          <Button
                            className="secondaryBtn"
                            icon={<DeleteOutlined />}
                            loading={record.id === deleteMeasurementId}
                          >
                            Delete
                          </Button>
                        </Popconfirm>
                      </div>
                    );
                  }}
                />
              </Table>
            )}
          </div>
        ) : (
          renderWarningMessage(
            <span>
              No measurement data present for this Heifer. Add data in
              <b> Add heifer data</b> tab.
            </span>,
            { marginLeft: 0, marginRight: 0 },
          )
        )}
      </div>
    </>
  );
};

export default ViewHeiferDataScreen;
