import React from 'react';
import './LoadingScreen.css';

// This is a loading screen
const LoadingPage: React.FC = () => {
  return (
    <div
      style={{
        width: '100%',
        paddingTop: 150,
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div className="spinner">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    </div>
  );
};

export default LoadingPage;
