export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: Date | string;
  json: any;
  jsonb: any;
  numeric: any;
  timestamptz: string;
  uuid: string;
};

export type GeneratePdfReportOutput = {
  __typename?: 'GeneratePdfReportOutput';
  s3Key: Scalars['String'];
  url: Scalars['String'];
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type SignInWithMicrosoftOutput = {
  __typename?: 'SignInWithMicrosoftOutput';
  accessToken: Scalars['String'];
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};


/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/**
 * ENUM table for user's role
 * 
 * 
 * columns and relationships of "enum_user_role"
 */
export type Enum_User_Role = {
  __typename?: 'enum_user_role';
  value: Scalars['String'];
};

/** aggregated selection of "enum_user_role" */
export type Enum_User_Role_Aggregate = {
  __typename?: 'enum_user_role_aggregate';
  aggregate?: Maybe<Enum_User_Role_Aggregate_Fields>;
  nodes: Array<Enum_User_Role>;
};

/** aggregate fields of "enum_user_role" */
export type Enum_User_Role_Aggregate_Fields = {
  __typename?: 'enum_user_role_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Enum_User_Role_Max_Fields>;
  min?: Maybe<Enum_User_Role_Min_Fields>;
};


/** aggregate fields of "enum_user_role" */
export type Enum_User_Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Enum_User_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "enum_user_role" */
export type Enum_User_Role_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Enum_User_Role_Max_Order_By>;
  min?: Maybe<Enum_User_Role_Min_Order_By>;
};

/** input type for inserting array relation for remote table "enum_user_role" */
export type Enum_User_Role_Arr_Rel_Insert_Input = {
  data: Array<Enum_User_Role_Insert_Input>;
  on_conflict?: Maybe<Enum_User_Role_On_Conflict>;
};

/** Boolean expression to filter rows from the table "enum_user_role". All fields are combined with a logical 'AND'. */
export type Enum_User_Role_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Enum_User_Role_Bool_Exp>>>;
  _not?: Maybe<Enum_User_Role_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Enum_User_Role_Bool_Exp>>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_user_role" */
export enum Enum_User_Role_Constraint {
  /** unique or primary key constraint */
  EnumUserRolePkey = 'enum_user_role_pkey'
}

export enum Enum_User_Role_Enum {
  AppAdmin = 'app_admin',
  User = 'user'
}

/** expression to compare columns of type enum_user_role_enum. All fields are combined with logical 'AND'. */
export type Enum_User_Role_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_User_Role_Enum>;
  _in?: Maybe<Array<Enum_User_Role_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_User_Role_Enum>;
  _nin?: Maybe<Array<Enum_User_Role_Enum>>;
};

/** input type for inserting data into table "enum_user_role" */
export type Enum_User_Role_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Enum_User_Role_Max_Fields = {
  __typename?: 'enum_user_role_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "enum_user_role" */
export type Enum_User_Role_Max_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Enum_User_Role_Min_Fields = {
  __typename?: 'enum_user_role_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "enum_user_role" */
export type Enum_User_Role_Min_Order_By = {
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "enum_user_role" */
export type Enum_User_Role_Mutation_Response = {
  __typename?: 'enum_user_role_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Enum_User_Role>;
};

/** input type for inserting object relation for remote table "enum_user_role" */
export type Enum_User_Role_Obj_Rel_Insert_Input = {
  data: Enum_User_Role_Insert_Input;
  on_conflict?: Maybe<Enum_User_Role_On_Conflict>;
};

/** on conflict condition type for table "enum_user_role" */
export type Enum_User_Role_On_Conflict = {
  constraint: Enum_User_Role_Constraint;
  update_columns: Array<Enum_User_Role_Update_Column>;
  where?: Maybe<Enum_User_Role_Bool_Exp>;
};

/** ordering options when selecting data from "enum_user_role" */
export type Enum_User_Role_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "enum_user_role" */
export type Enum_User_Role_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "enum_user_role" */
export enum Enum_User_Role_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_user_role" */
export type Enum_User_Role_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "enum_user_role" */
export enum Enum_User_Role_Update_Column {
  /** column name */
  Value = 'value'
}

/**
 * Table to store farm data
 * 
 * 
 * columns and relationships of "farm"
 */
export type Farm = {
  __typename?: 'farm';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by?: Maybe<User>;
  created_by_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  herds: Array<Herd>;
  /** An aggregated array relationship */
  herds_aggregate: Herd_Aggregate;
  id: Scalars['Int'];
  manager_email?: Maybe<Scalars['String']>;
  manager_name?: Maybe<Scalars['String']>;
  manager_phone?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  pens: Array<Pen>;
  /** An aggregated array relationship */
  pens_aggregate: Pen_Aggregate;
  state?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  updated_by?: Maybe<User>;
  updated_by_id?: Maybe<Scalars['uuid']>;
  zipcode: Scalars['String'];
};


/**
 * Table to store farm data
 * 
 * 
 * columns and relationships of "farm"
 */
export type FarmHerdsArgs = {
  distinct_on?: Maybe<Array<Herd_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Herd_Order_By>>;
  where?: Maybe<Herd_Bool_Exp>;
};


/**
 * Table to store farm data
 * 
 * 
 * columns and relationships of "farm"
 */
export type FarmHerds_AggregateArgs = {
  distinct_on?: Maybe<Array<Herd_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Herd_Order_By>>;
  where?: Maybe<Herd_Bool_Exp>;
};


/**
 * Table to store farm data
 * 
 * 
 * columns and relationships of "farm"
 */
export type FarmPensArgs = {
  distinct_on?: Maybe<Array<Pen_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pen_Order_By>>;
  where?: Maybe<Pen_Bool_Exp>;
};


/**
 * Table to store farm data
 * 
 * 
 * columns and relationships of "farm"
 */
export type FarmPens_AggregateArgs = {
  distinct_on?: Maybe<Array<Pen_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pen_Order_By>>;
  where?: Maybe<Pen_Bool_Exp>;
};

/** aggregated selection of "farm" */
export type Farm_Aggregate = {
  __typename?: 'farm_aggregate';
  aggregate?: Maybe<Farm_Aggregate_Fields>;
  nodes: Array<Farm>;
};

/** aggregate fields of "farm" */
export type Farm_Aggregate_Fields = {
  __typename?: 'farm_aggregate_fields';
  avg?: Maybe<Farm_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Farm_Max_Fields>;
  min?: Maybe<Farm_Min_Fields>;
  stddev?: Maybe<Farm_Stddev_Fields>;
  stddev_pop?: Maybe<Farm_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Farm_Stddev_Samp_Fields>;
  sum?: Maybe<Farm_Sum_Fields>;
  var_pop?: Maybe<Farm_Var_Pop_Fields>;
  var_samp?: Maybe<Farm_Var_Samp_Fields>;
  variance?: Maybe<Farm_Variance_Fields>;
};


/** aggregate fields of "farm" */
export type Farm_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Farm_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "farm" */
export type Farm_Aggregate_Order_By = {
  avg?: Maybe<Farm_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Farm_Max_Order_By>;
  min?: Maybe<Farm_Min_Order_By>;
  stddev?: Maybe<Farm_Stddev_Order_By>;
  stddev_pop?: Maybe<Farm_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Farm_Stddev_Samp_Order_By>;
  sum?: Maybe<Farm_Sum_Order_By>;
  var_pop?: Maybe<Farm_Var_Pop_Order_By>;
  var_samp?: Maybe<Farm_Var_Samp_Order_By>;
  variance?: Maybe<Farm_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "farm" */
export type Farm_Arr_Rel_Insert_Input = {
  data: Array<Farm_Insert_Input>;
  on_conflict?: Maybe<Farm_On_Conflict>;
};

/** aggregate avg on columns */
export type Farm_Avg_Fields = {
  __typename?: 'farm_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "farm" */
export type Farm_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "farm". All fields are combined with a logical 'AND'. */
export type Farm_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Farm_Bool_Exp>>>;
  _not?: Maybe<Farm_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Farm_Bool_Exp>>>;
  address_line_1?: Maybe<String_Comparison_Exp>;
  address_line_2?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<User_Bool_Exp>;
  created_by_id?: Maybe<Uuid_Comparison_Exp>;
  herds?: Maybe<Herd_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  manager_email?: Maybe<String_Comparison_Exp>;
  manager_name?: Maybe<String_Comparison_Exp>;
  manager_phone?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  pens?: Maybe<Pen_Bool_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<User_Bool_Exp>;
  updated_by_id?: Maybe<Uuid_Comparison_Exp>;
  zipcode?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "farm" */
export enum Farm_Constraint {
  /** unique or primary key constraint */
  FarmPkey = 'farm_pkey'
}

/** input type for incrementing integer column in table "farm" */
export type Farm_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "farm" */
export type Farm_Insert_Input = {
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<User_Obj_Rel_Insert_Input>;
  created_by_id?: Maybe<Scalars['uuid']>;
  herds?: Maybe<Herd_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['Int']>;
  manager_email?: Maybe<Scalars['String']>;
  manager_name?: Maybe<Scalars['String']>;
  manager_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pens?: Maybe<Pen_Arr_Rel_Insert_Input>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<User_Obj_Rel_Insert_Input>;
  updated_by_id?: Maybe<Scalars['uuid']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Farm_Max_Fields = {
  __typename?: 'farm_max_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  manager_email?: Maybe<Scalars['String']>;
  manager_name?: Maybe<Scalars['String']>;
  manager_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "farm" */
export type Farm_Max_Order_By = {
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  manager_email?: Maybe<Order_By>;
  manager_name?: Maybe<Order_By>;
  manager_phone?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by_id?: Maybe<Order_By>;
  zipcode?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Farm_Min_Fields = {
  __typename?: 'farm_min_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  manager_email?: Maybe<Scalars['String']>;
  manager_name?: Maybe<Scalars['String']>;
  manager_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "farm" */
export type Farm_Min_Order_By = {
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  manager_email?: Maybe<Order_By>;
  manager_name?: Maybe<Order_By>;
  manager_phone?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by_id?: Maybe<Order_By>;
  zipcode?: Maybe<Order_By>;
};

/** response of any mutation on the table "farm" */
export type Farm_Mutation_Response = {
  __typename?: 'farm_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Farm>;
};

/** input type for inserting object relation for remote table "farm" */
export type Farm_Obj_Rel_Insert_Input = {
  data: Farm_Insert_Input;
  on_conflict?: Maybe<Farm_On_Conflict>;
};

/** on conflict condition type for table "farm" */
export type Farm_On_Conflict = {
  constraint: Farm_Constraint;
  update_columns: Array<Farm_Update_Column>;
  where?: Maybe<Farm_Bool_Exp>;
};

/** ordering options when selecting data from "farm" */
export type Farm_Order_By = {
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<User_Order_By>;
  created_by_id?: Maybe<Order_By>;
  herds_aggregate?: Maybe<Herd_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  manager_email?: Maybe<Order_By>;
  manager_name?: Maybe<Order_By>;
  manager_phone?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pens_aggregate?: Maybe<Pen_Aggregate_Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<User_Order_By>;
  updated_by_id?: Maybe<Order_By>;
  zipcode?: Maybe<Order_By>;
};

/** primary key columns input for table: "farm" */
export type Farm_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "farm" */
export enum Farm_Select_Column {
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerEmail = 'manager_email',
  /** column name */
  ManagerName = 'manager_name',
  /** column name */
  ManagerPhone = 'manager_phone',
  /** column name */
  Name = 'name',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id',
  /** column name */
  Zipcode = 'zipcode'
}

/** input type for updating data in table "farm" */
export type Farm_Set_Input = {
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  manager_email?: Maybe<Scalars['String']>;
  manager_name?: Maybe<Scalars['String']>;
  manager_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Farm_Stddev_Fields = {
  __typename?: 'farm_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "farm" */
export type Farm_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Farm_Stddev_Pop_Fields = {
  __typename?: 'farm_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "farm" */
export type Farm_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Farm_Stddev_Samp_Fields = {
  __typename?: 'farm_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "farm" */
export type Farm_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Farm_Sum_Fields = {
  __typename?: 'farm_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "farm" */
export type Farm_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "farm" */
export enum Farm_Update_Column {
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Id = 'id',
  /** column name */
  ManagerEmail = 'manager_email',
  /** column name */
  ManagerName = 'manager_name',
  /** column name */
  ManagerPhone = 'manager_phone',
  /** column name */
  Name = 'name',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id',
  /** column name */
  Zipcode = 'zipcode'
}

/** aggregate var_pop on columns */
export type Farm_Var_Pop_Fields = {
  __typename?: 'farm_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "farm" */
export type Farm_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Farm_Var_Samp_Fields = {
  __typename?: 'farm_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "farm" */
export type Farm_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Farm_Variance_Fields = {
  __typename?: 'farm_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "farm" */
export type Farm_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/**
 * table for heifers
 * 
 * 
 * columns and relationships of "heifer"
 */
export type Heifer = {
  __typename?: 'heifer';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by?: Maybe<User>;
  created_by_id?: Maybe<Scalars['uuid']>;
  dob: Scalars['date'];
  /** An array relationship */
  heifer_measurements: Array<Heifer_Measurements>;
  /** An aggregated array relationship */
  heifer_measurements_aggregate: Heifer_Measurements_Aggregate;
  /** An object relationship */
  herd: Herd;
  herd_id: Scalars['uuid'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  pen?: Maybe<Pen>;
  pen_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  updated_by?: Maybe<User>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};


/**
 * table for heifers
 * 
 * 
 * columns and relationships of "heifer"
 */
export type HeiferHeifer_MeasurementsArgs = {
  distinct_on?: Maybe<Array<Heifer_Measurements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Measurements_Order_By>>;
  where?: Maybe<Heifer_Measurements_Bool_Exp>;
};


/**
 * table for heifers
 * 
 * 
 * columns and relationships of "heifer"
 */
export type HeiferHeifer_Measurements_AggregateArgs = {
  distinct_on?: Maybe<Array<Heifer_Measurements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Measurements_Order_By>>;
  where?: Maybe<Heifer_Measurements_Bool_Exp>;
};

/** aggregated selection of "heifer" */
export type Heifer_Aggregate = {
  __typename?: 'heifer_aggregate';
  aggregate?: Maybe<Heifer_Aggregate_Fields>;
  nodes: Array<Heifer>;
};

/** aggregate fields of "heifer" */
export type Heifer_Aggregate_Fields = {
  __typename?: 'heifer_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Heifer_Max_Fields>;
  min?: Maybe<Heifer_Min_Fields>;
};


/** aggregate fields of "heifer" */
export type Heifer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Heifer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "heifer" */
export type Heifer_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Heifer_Max_Order_By>;
  min?: Maybe<Heifer_Min_Order_By>;
};

/** input type for inserting array relation for remote table "heifer" */
export type Heifer_Arr_Rel_Insert_Input = {
  data: Array<Heifer_Insert_Input>;
  on_conflict?: Maybe<Heifer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "heifer". All fields are combined with a logical 'AND'. */
export type Heifer_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Heifer_Bool_Exp>>>;
  _not?: Maybe<Heifer_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Heifer_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<User_Bool_Exp>;
  created_by_id?: Maybe<Uuid_Comparison_Exp>;
  dob?: Maybe<Date_Comparison_Exp>;
  heifer_measurements?: Maybe<Heifer_Measurements_Bool_Exp>;
  herd?: Maybe<Herd_Bool_Exp>;
  herd_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  pen?: Maybe<Pen_Bool_Exp>;
  pen_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<User_Bool_Exp>;
  updated_by_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "heifer" */
export enum Heifer_Constraint {
  /** unique or primary key constraint */
  HeiferDobNameHerdIdKey = 'heifer_dob_name_herd_id_key',
  /** unique or primary key constraint */
  HeiferPkey = 'heifer_pkey'
}

/** input type for inserting data into table "heifer" */
export type Heifer_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<User_Obj_Rel_Insert_Input>;
  created_by_id?: Maybe<Scalars['uuid']>;
  dob?: Maybe<Scalars['date']>;
  heifer_measurements?: Maybe<Heifer_Measurements_Arr_Rel_Insert_Input>;
  herd?: Maybe<Herd_Obj_Rel_Insert_Input>;
  herd_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  pen?: Maybe<Pen_Obj_Rel_Insert_Input>;
  pen_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<User_Obj_Rel_Insert_Input>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Heifer_Max_Fields = {
  __typename?: 'heifer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  dob?: Maybe<Scalars['date']>;
  herd_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  pen_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "heifer" */
export type Heifer_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  dob?: Maybe<Order_By>;
  herd_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pen_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by_id?: Maybe<Order_By>;
};

/**
 * table to store heifer measurements
 * 
 * 
 * columns and relationships of "heifer_measurements"
 */
export type Heifer_Measurements = {
  __typename?: 'heifer_measurements';
  body_weight?: Maybe<Scalars['numeric']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by?: Maybe<User>;
  created_by_id?: Maybe<Scalars['uuid']>;
  group_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  heifer: Heifer;
  heifer_id: Scalars['uuid'];
  height?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  herd: Herd;
  herd_id: Scalars['uuid'];
  id: Scalars['uuid'];
  mature_body_weight?: Maybe<Scalars['numeric']>;
  mature_height?: Maybe<Scalars['numeric']>;
  measurement_date: Scalars['date'];
  measurement_date_age: Scalars['numeric'];
  /** A computed field, executes function "heifer_pc_mature_body_weight" */
  pc_mature_body_weight?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "heifer_pc_mature_height" */
  pc_mature_height?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  pen?: Maybe<Pen>;
  pen_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  updated_by?: Maybe<User>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "heifer_measurements" */
export type Heifer_Measurements_Aggregate = {
  __typename?: 'heifer_measurements_aggregate';
  aggregate?: Maybe<Heifer_Measurements_Aggregate_Fields>;
  nodes: Array<Heifer_Measurements>;
};

/** aggregate fields of "heifer_measurements" */
export type Heifer_Measurements_Aggregate_Fields = {
  __typename?: 'heifer_measurements_aggregate_fields';
  avg?: Maybe<Heifer_Measurements_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Heifer_Measurements_Max_Fields>;
  min?: Maybe<Heifer_Measurements_Min_Fields>;
  stddev?: Maybe<Heifer_Measurements_Stddev_Fields>;
  stddev_pop?: Maybe<Heifer_Measurements_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Heifer_Measurements_Stddev_Samp_Fields>;
  sum?: Maybe<Heifer_Measurements_Sum_Fields>;
  var_pop?: Maybe<Heifer_Measurements_Var_Pop_Fields>;
  var_samp?: Maybe<Heifer_Measurements_Var_Samp_Fields>;
  variance?: Maybe<Heifer_Measurements_Variance_Fields>;
};


/** aggregate fields of "heifer_measurements" */
export type Heifer_Measurements_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Heifer_Measurements_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "heifer_measurements" */
export type Heifer_Measurements_Aggregate_Order_By = {
  avg?: Maybe<Heifer_Measurements_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Heifer_Measurements_Max_Order_By>;
  min?: Maybe<Heifer_Measurements_Min_Order_By>;
  stddev?: Maybe<Heifer_Measurements_Stddev_Order_By>;
  stddev_pop?: Maybe<Heifer_Measurements_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Heifer_Measurements_Stddev_Samp_Order_By>;
  sum?: Maybe<Heifer_Measurements_Sum_Order_By>;
  var_pop?: Maybe<Heifer_Measurements_Var_Pop_Order_By>;
  var_samp?: Maybe<Heifer_Measurements_Var_Samp_Order_By>;
  variance?: Maybe<Heifer_Measurements_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "heifer_measurements" */
export type Heifer_Measurements_Arr_Rel_Insert_Input = {
  data: Array<Heifer_Measurements_Insert_Input>;
  on_conflict?: Maybe<Heifer_Measurements_On_Conflict>;
};

/** aggregate avg on columns */
export type Heifer_Measurements_Avg_Fields = {
  __typename?: 'heifer_measurements_avg_fields';
  body_weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  mature_body_weight?: Maybe<Scalars['Float']>;
  mature_height?: Maybe<Scalars['Float']>;
  measurement_date_age?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "heifer_measurements" */
export type Heifer_Measurements_Avg_Order_By = {
  body_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  mature_body_weight?: Maybe<Order_By>;
  mature_height?: Maybe<Order_By>;
  measurement_date_age?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "heifer_measurements". All fields are combined with a logical 'AND'. */
export type Heifer_Measurements_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Heifer_Measurements_Bool_Exp>>>;
  _not?: Maybe<Heifer_Measurements_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Heifer_Measurements_Bool_Exp>>>;
  body_weight?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<User_Bool_Exp>;
  created_by_id?: Maybe<Uuid_Comparison_Exp>;
  group_name?: Maybe<String_Comparison_Exp>;
  heifer?: Maybe<Heifer_Bool_Exp>;
  heifer_id?: Maybe<Uuid_Comparison_Exp>;
  height?: Maybe<Numeric_Comparison_Exp>;
  herd?: Maybe<Herd_Bool_Exp>;
  herd_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  mature_body_weight?: Maybe<Numeric_Comparison_Exp>;
  mature_height?: Maybe<Numeric_Comparison_Exp>;
  measurement_date?: Maybe<Date_Comparison_Exp>;
  measurement_date_age?: Maybe<Numeric_Comparison_Exp>;
  pen?: Maybe<Pen_Bool_Exp>;
  pen_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<User_Bool_Exp>;
  updated_by_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "heifer_measurements" */
export enum Heifer_Measurements_Constraint {
  /** unique or primary key constraint */
  HeiferMeasurementsPkey = 'heifer_measurements_pkey'
}

/** input type for incrementing integer column in table "heifer_measurements" */
export type Heifer_Measurements_Inc_Input = {
  body_weight?: Maybe<Scalars['numeric']>;
  height?: Maybe<Scalars['numeric']>;
  mature_body_weight?: Maybe<Scalars['numeric']>;
  mature_height?: Maybe<Scalars['numeric']>;
  measurement_date_age?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "heifer_measurements" */
export type Heifer_Measurements_Insert_Input = {
  body_weight?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<User_Obj_Rel_Insert_Input>;
  created_by_id?: Maybe<Scalars['uuid']>;
  group_name?: Maybe<Scalars['String']>;
  heifer?: Maybe<Heifer_Obj_Rel_Insert_Input>;
  heifer_id?: Maybe<Scalars['uuid']>;
  height?: Maybe<Scalars['numeric']>;
  herd?: Maybe<Herd_Obj_Rel_Insert_Input>;
  herd_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  mature_body_weight?: Maybe<Scalars['numeric']>;
  mature_height?: Maybe<Scalars['numeric']>;
  measurement_date?: Maybe<Scalars['date']>;
  measurement_date_age?: Maybe<Scalars['numeric']>;
  pen?: Maybe<Pen_Obj_Rel_Insert_Input>;
  pen_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<User_Obj_Rel_Insert_Input>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Heifer_Measurements_Max_Fields = {
  __typename?: 'heifer_measurements_max_fields';
  body_weight?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  group_name?: Maybe<Scalars['String']>;
  heifer_id?: Maybe<Scalars['uuid']>;
  height?: Maybe<Scalars['numeric']>;
  herd_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  mature_body_weight?: Maybe<Scalars['numeric']>;
  mature_height?: Maybe<Scalars['numeric']>;
  measurement_date?: Maybe<Scalars['date']>;
  measurement_date_age?: Maybe<Scalars['numeric']>;
  pen_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "heifer_measurements" */
export type Heifer_Measurements_Max_Order_By = {
  body_weight?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  group_name?: Maybe<Order_By>;
  heifer_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  herd_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mature_body_weight?: Maybe<Order_By>;
  mature_height?: Maybe<Order_By>;
  measurement_date?: Maybe<Order_By>;
  measurement_date_age?: Maybe<Order_By>;
  pen_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Heifer_Measurements_Min_Fields = {
  __typename?: 'heifer_measurements_min_fields';
  body_weight?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  group_name?: Maybe<Scalars['String']>;
  heifer_id?: Maybe<Scalars['uuid']>;
  height?: Maybe<Scalars['numeric']>;
  herd_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  mature_body_weight?: Maybe<Scalars['numeric']>;
  mature_height?: Maybe<Scalars['numeric']>;
  measurement_date?: Maybe<Scalars['date']>;
  measurement_date_age?: Maybe<Scalars['numeric']>;
  pen_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "heifer_measurements" */
export type Heifer_Measurements_Min_Order_By = {
  body_weight?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  group_name?: Maybe<Order_By>;
  heifer_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  herd_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mature_body_weight?: Maybe<Order_By>;
  mature_height?: Maybe<Order_By>;
  measurement_date?: Maybe<Order_By>;
  measurement_date_age?: Maybe<Order_By>;
  pen_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "heifer_measurements" */
export type Heifer_Measurements_Mutation_Response = {
  __typename?: 'heifer_measurements_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Heifer_Measurements>;
};

/** input type for inserting object relation for remote table "heifer_measurements" */
export type Heifer_Measurements_Obj_Rel_Insert_Input = {
  data: Heifer_Measurements_Insert_Input;
  on_conflict?: Maybe<Heifer_Measurements_On_Conflict>;
};

/** on conflict condition type for table "heifer_measurements" */
export type Heifer_Measurements_On_Conflict = {
  constraint: Heifer_Measurements_Constraint;
  update_columns: Array<Heifer_Measurements_Update_Column>;
  where?: Maybe<Heifer_Measurements_Bool_Exp>;
};

/** ordering options when selecting data from "heifer_measurements" */
export type Heifer_Measurements_Order_By = {
  body_weight?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<User_Order_By>;
  created_by_id?: Maybe<Order_By>;
  group_name?: Maybe<Order_By>;
  heifer?: Maybe<Heifer_Order_By>;
  heifer_id?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  herd?: Maybe<Herd_Order_By>;
  herd_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mature_body_weight?: Maybe<Order_By>;
  mature_height?: Maybe<Order_By>;
  measurement_date?: Maybe<Order_By>;
  measurement_date_age?: Maybe<Order_By>;
  pen?: Maybe<Pen_Order_By>;
  pen_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<User_Order_By>;
  updated_by_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "heifer_measurements" */
export type Heifer_Measurements_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "heifer_measurements" */
export enum Heifer_Measurements_Select_Column {
  /** column name */
  BodyWeight = 'body_weight',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  GroupName = 'group_name',
  /** column name */
  HeiferId = 'heifer_id',
  /** column name */
  Height = 'height',
  /** column name */
  HerdId = 'herd_id',
  /** column name */
  Id = 'id',
  /** column name */
  MatureBodyWeight = 'mature_body_weight',
  /** column name */
  MatureHeight = 'mature_height',
  /** column name */
  MeasurementDate = 'measurement_date',
  /** column name */
  MeasurementDateAge = 'measurement_date_age',
  /** column name */
  PenId = 'pen_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id'
}

/** input type for updating data in table "heifer_measurements" */
export type Heifer_Measurements_Set_Input = {
  body_weight?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  group_name?: Maybe<Scalars['String']>;
  heifer_id?: Maybe<Scalars['uuid']>;
  height?: Maybe<Scalars['numeric']>;
  herd_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  mature_body_weight?: Maybe<Scalars['numeric']>;
  mature_height?: Maybe<Scalars['numeric']>;
  measurement_date?: Maybe<Scalars['date']>;
  measurement_date_age?: Maybe<Scalars['numeric']>;
  pen_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Heifer_Measurements_Stddev_Fields = {
  __typename?: 'heifer_measurements_stddev_fields';
  body_weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  mature_body_weight?: Maybe<Scalars['Float']>;
  mature_height?: Maybe<Scalars['Float']>;
  measurement_date_age?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "heifer_measurements" */
export type Heifer_Measurements_Stddev_Order_By = {
  body_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  mature_body_weight?: Maybe<Order_By>;
  mature_height?: Maybe<Order_By>;
  measurement_date_age?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Heifer_Measurements_Stddev_Pop_Fields = {
  __typename?: 'heifer_measurements_stddev_pop_fields';
  body_weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  mature_body_weight?: Maybe<Scalars['Float']>;
  mature_height?: Maybe<Scalars['Float']>;
  measurement_date_age?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "heifer_measurements" */
export type Heifer_Measurements_Stddev_Pop_Order_By = {
  body_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  mature_body_weight?: Maybe<Order_By>;
  mature_height?: Maybe<Order_By>;
  measurement_date_age?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Heifer_Measurements_Stddev_Samp_Fields = {
  __typename?: 'heifer_measurements_stddev_samp_fields';
  body_weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  mature_body_weight?: Maybe<Scalars['Float']>;
  mature_height?: Maybe<Scalars['Float']>;
  measurement_date_age?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "heifer_measurements" */
export type Heifer_Measurements_Stddev_Samp_Order_By = {
  body_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  mature_body_weight?: Maybe<Order_By>;
  mature_height?: Maybe<Order_By>;
  measurement_date_age?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Heifer_Measurements_Sum_Fields = {
  __typename?: 'heifer_measurements_sum_fields';
  body_weight?: Maybe<Scalars['numeric']>;
  height?: Maybe<Scalars['numeric']>;
  mature_body_weight?: Maybe<Scalars['numeric']>;
  mature_height?: Maybe<Scalars['numeric']>;
  measurement_date_age?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "heifer_measurements" */
export type Heifer_Measurements_Sum_Order_By = {
  body_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  mature_body_weight?: Maybe<Order_By>;
  mature_height?: Maybe<Order_By>;
  measurement_date_age?: Maybe<Order_By>;
};

/** update columns of table "heifer_measurements" */
export enum Heifer_Measurements_Update_Column {
  /** column name */
  BodyWeight = 'body_weight',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  GroupName = 'group_name',
  /** column name */
  HeiferId = 'heifer_id',
  /** column name */
  Height = 'height',
  /** column name */
  HerdId = 'herd_id',
  /** column name */
  Id = 'id',
  /** column name */
  MatureBodyWeight = 'mature_body_weight',
  /** column name */
  MatureHeight = 'mature_height',
  /** column name */
  MeasurementDate = 'measurement_date',
  /** column name */
  MeasurementDateAge = 'measurement_date_age',
  /** column name */
  PenId = 'pen_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id'
}

/** aggregate var_pop on columns */
export type Heifer_Measurements_Var_Pop_Fields = {
  __typename?: 'heifer_measurements_var_pop_fields';
  body_weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  mature_body_weight?: Maybe<Scalars['Float']>;
  mature_height?: Maybe<Scalars['Float']>;
  measurement_date_age?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "heifer_measurements" */
export type Heifer_Measurements_Var_Pop_Order_By = {
  body_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  mature_body_weight?: Maybe<Order_By>;
  mature_height?: Maybe<Order_By>;
  measurement_date_age?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Heifer_Measurements_Var_Samp_Fields = {
  __typename?: 'heifer_measurements_var_samp_fields';
  body_weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  mature_body_weight?: Maybe<Scalars['Float']>;
  mature_height?: Maybe<Scalars['Float']>;
  measurement_date_age?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "heifer_measurements" */
export type Heifer_Measurements_Var_Samp_Order_By = {
  body_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  mature_body_weight?: Maybe<Order_By>;
  mature_height?: Maybe<Order_By>;
  measurement_date_age?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Heifer_Measurements_Variance_Fields = {
  __typename?: 'heifer_measurements_variance_fields';
  body_weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  mature_body_weight?: Maybe<Scalars['Float']>;
  mature_height?: Maybe<Scalars['Float']>;
  measurement_date_age?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "heifer_measurements" */
export type Heifer_Measurements_Variance_Order_By = {
  body_weight?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  mature_body_weight?: Maybe<Order_By>;
  mature_height?: Maybe<Order_By>;
  measurement_date_age?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Heifer_Min_Fields = {
  __typename?: 'heifer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  dob?: Maybe<Scalars['date']>;
  herd_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  pen_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "heifer" */
export type Heifer_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  dob?: Maybe<Order_By>;
  herd_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pen_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "heifer" */
export type Heifer_Mutation_Response = {
  __typename?: 'heifer_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Heifer>;
};

/** input type for inserting object relation for remote table "heifer" */
export type Heifer_Obj_Rel_Insert_Input = {
  data: Heifer_Insert_Input;
  on_conflict?: Maybe<Heifer_On_Conflict>;
};

/** on conflict condition type for table "heifer" */
export type Heifer_On_Conflict = {
  constraint: Heifer_Constraint;
  update_columns: Array<Heifer_Update_Column>;
  where?: Maybe<Heifer_Bool_Exp>;
};

/** ordering options when selecting data from "heifer" */
export type Heifer_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<User_Order_By>;
  created_by_id?: Maybe<Order_By>;
  dob?: Maybe<Order_By>;
  heifer_measurements_aggregate?: Maybe<Heifer_Measurements_Aggregate_Order_By>;
  herd?: Maybe<Herd_Order_By>;
  herd_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pen?: Maybe<Pen_Order_By>;
  pen_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<User_Order_By>;
  updated_by_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "heifer" */
export type Heifer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "heifer" */
export enum Heifer_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Dob = 'dob',
  /** column name */
  HerdId = 'herd_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PenId = 'pen_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id'
}

/** input type for updating data in table "heifer" */
export type Heifer_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  dob?: Maybe<Scalars['date']>;
  herd_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  pen_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "heifer" */
export enum Heifer_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Dob = 'dob',
  /** column name */
  HerdId = 'herd_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PenId = 'pen_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id'
}

/**
 * table for storing herds
 * 
 * 
 * columns and relationships of "herd"
 */
export type Herd = {
  __typename?: 'herd';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by?: Maybe<User>;
  created_by_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  farm: Farm;
  farm_id: Scalars['Int'];
  /** An array relationship */
  heifer_measurements: Array<Heifer_Measurements>;
  /** An aggregated array relationship */
  heifer_measurements_aggregate: Heifer_Measurements_Aggregate;
  /** An array relationship */
  heifers: Array<Heifer>;
  /** An aggregated array relationship */
  heifers_aggregate: Heifer_Aggregate;
  /** An array relationship */
  herd_data: Array<Herd_Data>;
  /** An aggregated array relationship */
  herd_data_aggregate: Herd_Data_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  updated_by?: Maybe<User>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};


/**
 * table for storing herds
 * 
 * 
 * columns and relationships of "herd"
 */
export type HerdHeifer_MeasurementsArgs = {
  distinct_on?: Maybe<Array<Heifer_Measurements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Measurements_Order_By>>;
  where?: Maybe<Heifer_Measurements_Bool_Exp>;
};


/**
 * table for storing herds
 * 
 * 
 * columns and relationships of "herd"
 */
export type HerdHeifer_Measurements_AggregateArgs = {
  distinct_on?: Maybe<Array<Heifer_Measurements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Measurements_Order_By>>;
  where?: Maybe<Heifer_Measurements_Bool_Exp>;
};


/**
 * table for storing herds
 * 
 * 
 * columns and relationships of "herd"
 */
export type HerdHeifersArgs = {
  distinct_on?: Maybe<Array<Heifer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Order_By>>;
  where?: Maybe<Heifer_Bool_Exp>;
};


/**
 * table for storing herds
 * 
 * 
 * columns and relationships of "herd"
 */
export type HerdHeifers_AggregateArgs = {
  distinct_on?: Maybe<Array<Heifer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Order_By>>;
  where?: Maybe<Heifer_Bool_Exp>;
};


/**
 * table for storing herds
 * 
 * 
 * columns and relationships of "herd"
 */
export type HerdHerd_DataArgs = {
  distinct_on?: Maybe<Array<Herd_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Herd_Data_Order_By>>;
  where?: Maybe<Herd_Data_Bool_Exp>;
};


/**
 * table for storing herds
 * 
 * 
 * columns and relationships of "herd"
 */
export type HerdHerd_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Herd_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Herd_Data_Order_By>>;
  where?: Maybe<Herd_Data_Bool_Exp>;
};

/** aggregated selection of "herd" */
export type Herd_Aggregate = {
  __typename?: 'herd_aggregate';
  aggregate?: Maybe<Herd_Aggregate_Fields>;
  nodes: Array<Herd>;
};

/** aggregate fields of "herd" */
export type Herd_Aggregate_Fields = {
  __typename?: 'herd_aggregate_fields';
  avg?: Maybe<Herd_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Herd_Max_Fields>;
  min?: Maybe<Herd_Min_Fields>;
  stddev?: Maybe<Herd_Stddev_Fields>;
  stddev_pop?: Maybe<Herd_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Herd_Stddev_Samp_Fields>;
  sum?: Maybe<Herd_Sum_Fields>;
  var_pop?: Maybe<Herd_Var_Pop_Fields>;
  var_samp?: Maybe<Herd_Var_Samp_Fields>;
  variance?: Maybe<Herd_Variance_Fields>;
};


/** aggregate fields of "herd" */
export type Herd_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Herd_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "herd" */
export type Herd_Aggregate_Order_By = {
  avg?: Maybe<Herd_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Herd_Max_Order_By>;
  min?: Maybe<Herd_Min_Order_By>;
  stddev?: Maybe<Herd_Stddev_Order_By>;
  stddev_pop?: Maybe<Herd_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Herd_Stddev_Samp_Order_By>;
  sum?: Maybe<Herd_Sum_Order_By>;
  var_pop?: Maybe<Herd_Var_Pop_Order_By>;
  var_samp?: Maybe<Herd_Var_Samp_Order_By>;
  variance?: Maybe<Herd_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "herd" */
export type Herd_Arr_Rel_Insert_Input = {
  data: Array<Herd_Insert_Input>;
  on_conflict?: Maybe<Herd_On_Conflict>;
};

/** aggregate avg on columns */
export type Herd_Avg_Fields = {
  __typename?: 'herd_avg_fields';
  farm_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "herd" */
export type Herd_Avg_Order_By = {
  farm_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "herd". All fields are combined with a logical 'AND'. */
export type Herd_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Herd_Bool_Exp>>>;
  _not?: Maybe<Herd_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Herd_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<User_Bool_Exp>;
  created_by_id?: Maybe<Uuid_Comparison_Exp>;
  farm?: Maybe<Farm_Bool_Exp>;
  farm_id?: Maybe<Int_Comparison_Exp>;
  heifer_measurements?: Maybe<Heifer_Measurements_Bool_Exp>;
  heifers?: Maybe<Heifer_Bool_Exp>;
  herd_data?: Maybe<Herd_Data_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<User_Bool_Exp>;
  updated_by_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "herd" */
export enum Herd_Constraint {
  /** unique or primary key constraint */
  HerdPkey = 'herd_pkey'
}

/**
 * table to store herd data
 * 
 * 
 * columns and relationships of "herd_data"
 */
export type Herd_Data = {
  __typename?: 'herd_data';
  /** A computed field, executes function "herd_adg_birth_56days" */
  adg_birth_56days?: Maybe<Scalars['numeric']>;
  avg_birth_bw_calves: Scalars['numeric'];
  avg_birth_height_calves: Scalars['numeric'];
  avg_bw_3rdlact_cow: Scalars['numeric'];
  avg_height_3rdlact_cow: Scalars['numeric'];
  avg_spc_heifers: Scalars['numeric'];
  /** A computed field, executes function "herd_bw_heifer_1st_conception" */
  bw_heifer_1st_conception?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "herd_bw_heifer_1week_precalving" */
  bw_heifer_1week_precalving?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "herd_bw_heifer_calving" */
  bw_heifer_calving?: Maybe<Scalars['numeric']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by?: Maybe<User>;
  created_by_id?: Maybe<Scalars['uuid']>;
  current_age_1st_calving: Scalars['numeric'];
  goal_age_1st_calving: Scalars['numeric'];
  /** A computed field, executes function "herd_height_heifer_1st_conception" */
  height_heifer_1st_conception?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "herd_height_heifer_calving" */
  height_heifer_calving?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  herd: Herd;
  herd_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** A computed field, executes function "herd_mintarget_calf_bw_56days" */
  mintarget_calf_bw_56days?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "herd_mintarget_calf_height_56days" */
  mintarget_calf_height_56days?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  updated_by?: Maybe<User>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "herd_data" */
export type Herd_Data_Aggregate = {
  __typename?: 'herd_data_aggregate';
  aggregate?: Maybe<Herd_Data_Aggregate_Fields>;
  nodes: Array<Herd_Data>;
};

/** aggregate fields of "herd_data" */
export type Herd_Data_Aggregate_Fields = {
  __typename?: 'herd_data_aggregate_fields';
  avg?: Maybe<Herd_Data_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Herd_Data_Max_Fields>;
  min?: Maybe<Herd_Data_Min_Fields>;
  stddev?: Maybe<Herd_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Herd_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Herd_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Herd_Data_Sum_Fields>;
  var_pop?: Maybe<Herd_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Herd_Data_Var_Samp_Fields>;
  variance?: Maybe<Herd_Data_Variance_Fields>;
};


/** aggregate fields of "herd_data" */
export type Herd_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Herd_Data_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "herd_data" */
export type Herd_Data_Aggregate_Order_By = {
  avg?: Maybe<Herd_Data_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Herd_Data_Max_Order_By>;
  min?: Maybe<Herd_Data_Min_Order_By>;
  stddev?: Maybe<Herd_Data_Stddev_Order_By>;
  stddev_pop?: Maybe<Herd_Data_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Herd_Data_Stddev_Samp_Order_By>;
  sum?: Maybe<Herd_Data_Sum_Order_By>;
  var_pop?: Maybe<Herd_Data_Var_Pop_Order_By>;
  var_samp?: Maybe<Herd_Data_Var_Samp_Order_By>;
  variance?: Maybe<Herd_Data_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "herd_data" */
export type Herd_Data_Arr_Rel_Insert_Input = {
  data: Array<Herd_Data_Insert_Input>;
  on_conflict?: Maybe<Herd_Data_On_Conflict>;
};

/** aggregate avg on columns */
export type Herd_Data_Avg_Fields = {
  __typename?: 'herd_data_avg_fields';
  avg_birth_bw_calves?: Maybe<Scalars['Float']>;
  avg_birth_height_calves?: Maybe<Scalars['Float']>;
  avg_bw_3rdlact_cow?: Maybe<Scalars['Float']>;
  avg_height_3rdlact_cow?: Maybe<Scalars['Float']>;
  avg_spc_heifers?: Maybe<Scalars['Float']>;
  current_age_1st_calving?: Maybe<Scalars['Float']>;
  goal_age_1st_calving?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "herd_data" */
export type Herd_Data_Avg_Order_By = {
  avg_birth_bw_calves?: Maybe<Order_By>;
  avg_birth_height_calves?: Maybe<Order_By>;
  avg_bw_3rdlact_cow?: Maybe<Order_By>;
  avg_height_3rdlact_cow?: Maybe<Order_By>;
  avg_spc_heifers?: Maybe<Order_By>;
  current_age_1st_calving?: Maybe<Order_By>;
  goal_age_1st_calving?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "herd_data". All fields are combined with a logical 'AND'. */
export type Herd_Data_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Herd_Data_Bool_Exp>>>;
  _not?: Maybe<Herd_Data_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Herd_Data_Bool_Exp>>>;
  avg_birth_bw_calves?: Maybe<Numeric_Comparison_Exp>;
  avg_birth_height_calves?: Maybe<Numeric_Comparison_Exp>;
  avg_bw_3rdlact_cow?: Maybe<Numeric_Comparison_Exp>;
  avg_height_3rdlact_cow?: Maybe<Numeric_Comparison_Exp>;
  avg_spc_heifers?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<User_Bool_Exp>;
  created_by_id?: Maybe<Uuid_Comparison_Exp>;
  current_age_1st_calving?: Maybe<Numeric_Comparison_Exp>;
  goal_age_1st_calving?: Maybe<Numeric_Comparison_Exp>;
  herd?: Maybe<Herd_Bool_Exp>;
  herd_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<User_Bool_Exp>;
  updated_by_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "herd_data" */
export enum Herd_Data_Constraint {
  /** unique or primary key constraint */
  HerdDataPkey = 'herd_data_pkey'
}

/** input type for incrementing integer column in table "herd_data" */
export type Herd_Data_Inc_Input = {
  avg_birth_bw_calves?: Maybe<Scalars['numeric']>;
  avg_birth_height_calves?: Maybe<Scalars['numeric']>;
  avg_bw_3rdlact_cow?: Maybe<Scalars['numeric']>;
  avg_height_3rdlact_cow?: Maybe<Scalars['numeric']>;
  avg_spc_heifers?: Maybe<Scalars['numeric']>;
  current_age_1st_calving?: Maybe<Scalars['numeric']>;
  goal_age_1st_calving?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "herd_data" */
export type Herd_Data_Insert_Input = {
  avg_birth_bw_calves?: Maybe<Scalars['numeric']>;
  avg_birth_height_calves?: Maybe<Scalars['numeric']>;
  avg_bw_3rdlact_cow?: Maybe<Scalars['numeric']>;
  avg_height_3rdlact_cow?: Maybe<Scalars['numeric']>;
  avg_spc_heifers?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<User_Obj_Rel_Insert_Input>;
  created_by_id?: Maybe<Scalars['uuid']>;
  current_age_1st_calving?: Maybe<Scalars['numeric']>;
  goal_age_1st_calving?: Maybe<Scalars['numeric']>;
  herd?: Maybe<Herd_Obj_Rel_Insert_Input>;
  herd_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<User_Obj_Rel_Insert_Input>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Herd_Data_Max_Fields = {
  __typename?: 'herd_data_max_fields';
  avg_birth_bw_calves?: Maybe<Scalars['numeric']>;
  avg_birth_height_calves?: Maybe<Scalars['numeric']>;
  avg_bw_3rdlact_cow?: Maybe<Scalars['numeric']>;
  avg_height_3rdlact_cow?: Maybe<Scalars['numeric']>;
  avg_spc_heifers?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  current_age_1st_calving?: Maybe<Scalars['numeric']>;
  goal_age_1st_calving?: Maybe<Scalars['numeric']>;
  herd_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "herd_data" */
export type Herd_Data_Max_Order_By = {
  avg_birth_bw_calves?: Maybe<Order_By>;
  avg_birth_height_calves?: Maybe<Order_By>;
  avg_bw_3rdlact_cow?: Maybe<Order_By>;
  avg_height_3rdlact_cow?: Maybe<Order_By>;
  avg_spc_heifers?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  current_age_1st_calving?: Maybe<Order_By>;
  goal_age_1st_calving?: Maybe<Order_By>;
  herd_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Herd_Data_Min_Fields = {
  __typename?: 'herd_data_min_fields';
  avg_birth_bw_calves?: Maybe<Scalars['numeric']>;
  avg_birth_height_calves?: Maybe<Scalars['numeric']>;
  avg_bw_3rdlact_cow?: Maybe<Scalars['numeric']>;
  avg_height_3rdlact_cow?: Maybe<Scalars['numeric']>;
  avg_spc_heifers?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  current_age_1st_calving?: Maybe<Scalars['numeric']>;
  goal_age_1st_calving?: Maybe<Scalars['numeric']>;
  herd_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "herd_data" */
export type Herd_Data_Min_Order_By = {
  avg_birth_bw_calves?: Maybe<Order_By>;
  avg_birth_height_calves?: Maybe<Order_By>;
  avg_bw_3rdlact_cow?: Maybe<Order_By>;
  avg_height_3rdlact_cow?: Maybe<Order_By>;
  avg_spc_heifers?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  current_age_1st_calving?: Maybe<Order_By>;
  goal_age_1st_calving?: Maybe<Order_By>;
  herd_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "herd_data" */
export type Herd_Data_Mutation_Response = {
  __typename?: 'herd_data_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Herd_Data>;
};

/** input type for inserting object relation for remote table "herd_data" */
export type Herd_Data_Obj_Rel_Insert_Input = {
  data: Herd_Data_Insert_Input;
  on_conflict?: Maybe<Herd_Data_On_Conflict>;
};

/** on conflict condition type for table "herd_data" */
export type Herd_Data_On_Conflict = {
  constraint: Herd_Data_Constraint;
  update_columns: Array<Herd_Data_Update_Column>;
  where?: Maybe<Herd_Data_Bool_Exp>;
};

/** ordering options when selecting data from "herd_data" */
export type Herd_Data_Order_By = {
  avg_birth_bw_calves?: Maybe<Order_By>;
  avg_birth_height_calves?: Maybe<Order_By>;
  avg_bw_3rdlact_cow?: Maybe<Order_By>;
  avg_height_3rdlact_cow?: Maybe<Order_By>;
  avg_spc_heifers?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<User_Order_By>;
  created_by_id?: Maybe<Order_By>;
  current_age_1st_calving?: Maybe<Order_By>;
  goal_age_1st_calving?: Maybe<Order_By>;
  herd?: Maybe<Herd_Order_By>;
  herd_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<User_Order_By>;
  updated_by_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "herd_data" */
export type Herd_Data_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "herd_data" */
export enum Herd_Data_Select_Column {
  /** column name */
  AvgBirthBwCalves = 'avg_birth_bw_calves',
  /** column name */
  AvgBirthHeightCalves = 'avg_birth_height_calves',
  /** column name */
  AvgBw_3rdlactCow = 'avg_bw_3rdlact_cow',
  /** column name */
  AvgHeight_3rdlactCow = 'avg_height_3rdlact_cow',
  /** column name */
  AvgSpcHeifers = 'avg_spc_heifers',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  CurrentAge_1stCalving = 'current_age_1st_calving',
  /** column name */
  GoalAge_1stCalving = 'goal_age_1st_calving',
  /** column name */
  HerdId = 'herd_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id'
}

/** input type for updating data in table "herd_data" */
export type Herd_Data_Set_Input = {
  avg_birth_bw_calves?: Maybe<Scalars['numeric']>;
  avg_birth_height_calves?: Maybe<Scalars['numeric']>;
  avg_bw_3rdlact_cow?: Maybe<Scalars['numeric']>;
  avg_height_3rdlact_cow?: Maybe<Scalars['numeric']>;
  avg_spc_heifers?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  current_age_1st_calving?: Maybe<Scalars['numeric']>;
  goal_age_1st_calving?: Maybe<Scalars['numeric']>;
  herd_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Herd_Data_Stddev_Fields = {
  __typename?: 'herd_data_stddev_fields';
  avg_birth_bw_calves?: Maybe<Scalars['Float']>;
  avg_birth_height_calves?: Maybe<Scalars['Float']>;
  avg_bw_3rdlact_cow?: Maybe<Scalars['Float']>;
  avg_height_3rdlact_cow?: Maybe<Scalars['Float']>;
  avg_spc_heifers?: Maybe<Scalars['Float']>;
  current_age_1st_calving?: Maybe<Scalars['Float']>;
  goal_age_1st_calving?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "herd_data" */
export type Herd_Data_Stddev_Order_By = {
  avg_birth_bw_calves?: Maybe<Order_By>;
  avg_birth_height_calves?: Maybe<Order_By>;
  avg_bw_3rdlact_cow?: Maybe<Order_By>;
  avg_height_3rdlact_cow?: Maybe<Order_By>;
  avg_spc_heifers?: Maybe<Order_By>;
  current_age_1st_calving?: Maybe<Order_By>;
  goal_age_1st_calving?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Herd_Data_Stddev_Pop_Fields = {
  __typename?: 'herd_data_stddev_pop_fields';
  avg_birth_bw_calves?: Maybe<Scalars['Float']>;
  avg_birth_height_calves?: Maybe<Scalars['Float']>;
  avg_bw_3rdlact_cow?: Maybe<Scalars['Float']>;
  avg_height_3rdlact_cow?: Maybe<Scalars['Float']>;
  avg_spc_heifers?: Maybe<Scalars['Float']>;
  current_age_1st_calving?: Maybe<Scalars['Float']>;
  goal_age_1st_calving?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "herd_data" */
export type Herd_Data_Stddev_Pop_Order_By = {
  avg_birth_bw_calves?: Maybe<Order_By>;
  avg_birth_height_calves?: Maybe<Order_By>;
  avg_bw_3rdlact_cow?: Maybe<Order_By>;
  avg_height_3rdlact_cow?: Maybe<Order_By>;
  avg_spc_heifers?: Maybe<Order_By>;
  current_age_1st_calving?: Maybe<Order_By>;
  goal_age_1st_calving?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Herd_Data_Stddev_Samp_Fields = {
  __typename?: 'herd_data_stddev_samp_fields';
  avg_birth_bw_calves?: Maybe<Scalars['Float']>;
  avg_birth_height_calves?: Maybe<Scalars['Float']>;
  avg_bw_3rdlact_cow?: Maybe<Scalars['Float']>;
  avg_height_3rdlact_cow?: Maybe<Scalars['Float']>;
  avg_spc_heifers?: Maybe<Scalars['Float']>;
  current_age_1st_calving?: Maybe<Scalars['Float']>;
  goal_age_1st_calving?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "herd_data" */
export type Herd_Data_Stddev_Samp_Order_By = {
  avg_birth_bw_calves?: Maybe<Order_By>;
  avg_birth_height_calves?: Maybe<Order_By>;
  avg_bw_3rdlact_cow?: Maybe<Order_By>;
  avg_height_3rdlact_cow?: Maybe<Order_By>;
  avg_spc_heifers?: Maybe<Order_By>;
  current_age_1st_calving?: Maybe<Order_By>;
  goal_age_1st_calving?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Herd_Data_Sum_Fields = {
  __typename?: 'herd_data_sum_fields';
  avg_birth_bw_calves?: Maybe<Scalars['numeric']>;
  avg_birth_height_calves?: Maybe<Scalars['numeric']>;
  avg_bw_3rdlact_cow?: Maybe<Scalars['numeric']>;
  avg_height_3rdlact_cow?: Maybe<Scalars['numeric']>;
  avg_spc_heifers?: Maybe<Scalars['numeric']>;
  current_age_1st_calving?: Maybe<Scalars['numeric']>;
  goal_age_1st_calving?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "herd_data" */
export type Herd_Data_Sum_Order_By = {
  avg_birth_bw_calves?: Maybe<Order_By>;
  avg_birth_height_calves?: Maybe<Order_By>;
  avg_bw_3rdlact_cow?: Maybe<Order_By>;
  avg_height_3rdlact_cow?: Maybe<Order_By>;
  avg_spc_heifers?: Maybe<Order_By>;
  current_age_1st_calving?: Maybe<Order_By>;
  goal_age_1st_calving?: Maybe<Order_By>;
};

/** update columns of table "herd_data" */
export enum Herd_Data_Update_Column {
  /** column name */
  AvgBirthBwCalves = 'avg_birth_bw_calves',
  /** column name */
  AvgBirthHeightCalves = 'avg_birth_height_calves',
  /** column name */
  AvgBw_3rdlactCow = 'avg_bw_3rdlact_cow',
  /** column name */
  AvgHeight_3rdlactCow = 'avg_height_3rdlact_cow',
  /** column name */
  AvgSpcHeifers = 'avg_spc_heifers',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  CurrentAge_1stCalving = 'current_age_1st_calving',
  /** column name */
  GoalAge_1stCalving = 'goal_age_1st_calving',
  /** column name */
  HerdId = 'herd_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id'
}

/** aggregate var_pop on columns */
export type Herd_Data_Var_Pop_Fields = {
  __typename?: 'herd_data_var_pop_fields';
  avg_birth_bw_calves?: Maybe<Scalars['Float']>;
  avg_birth_height_calves?: Maybe<Scalars['Float']>;
  avg_bw_3rdlact_cow?: Maybe<Scalars['Float']>;
  avg_height_3rdlact_cow?: Maybe<Scalars['Float']>;
  avg_spc_heifers?: Maybe<Scalars['Float']>;
  current_age_1st_calving?: Maybe<Scalars['Float']>;
  goal_age_1st_calving?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "herd_data" */
export type Herd_Data_Var_Pop_Order_By = {
  avg_birth_bw_calves?: Maybe<Order_By>;
  avg_birth_height_calves?: Maybe<Order_By>;
  avg_bw_3rdlact_cow?: Maybe<Order_By>;
  avg_height_3rdlact_cow?: Maybe<Order_By>;
  avg_spc_heifers?: Maybe<Order_By>;
  current_age_1st_calving?: Maybe<Order_By>;
  goal_age_1st_calving?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Herd_Data_Var_Samp_Fields = {
  __typename?: 'herd_data_var_samp_fields';
  avg_birth_bw_calves?: Maybe<Scalars['Float']>;
  avg_birth_height_calves?: Maybe<Scalars['Float']>;
  avg_bw_3rdlact_cow?: Maybe<Scalars['Float']>;
  avg_height_3rdlact_cow?: Maybe<Scalars['Float']>;
  avg_spc_heifers?: Maybe<Scalars['Float']>;
  current_age_1st_calving?: Maybe<Scalars['Float']>;
  goal_age_1st_calving?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "herd_data" */
export type Herd_Data_Var_Samp_Order_By = {
  avg_birth_bw_calves?: Maybe<Order_By>;
  avg_birth_height_calves?: Maybe<Order_By>;
  avg_bw_3rdlact_cow?: Maybe<Order_By>;
  avg_height_3rdlact_cow?: Maybe<Order_By>;
  avg_spc_heifers?: Maybe<Order_By>;
  current_age_1st_calving?: Maybe<Order_By>;
  goal_age_1st_calving?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Herd_Data_Variance_Fields = {
  __typename?: 'herd_data_variance_fields';
  avg_birth_bw_calves?: Maybe<Scalars['Float']>;
  avg_birth_height_calves?: Maybe<Scalars['Float']>;
  avg_bw_3rdlact_cow?: Maybe<Scalars['Float']>;
  avg_height_3rdlact_cow?: Maybe<Scalars['Float']>;
  avg_spc_heifers?: Maybe<Scalars['Float']>;
  current_age_1st_calving?: Maybe<Scalars['Float']>;
  goal_age_1st_calving?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "herd_data" */
export type Herd_Data_Variance_Order_By = {
  avg_birth_bw_calves?: Maybe<Order_By>;
  avg_birth_height_calves?: Maybe<Order_By>;
  avg_bw_3rdlact_cow?: Maybe<Order_By>;
  avg_height_3rdlact_cow?: Maybe<Order_By>;
  avg_spc_heifers?: Maybe<Order_By>;
  current_age_1st_calving?: Maybe<Order_By>;
  goal_age_1st_calving?: Maybe<Order_By>;
};

/** input type for incrementing integer column in table "herd" */
export type Herd_Inc_Input = {
  farm_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "herd" */
export type Herd_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<User_Obj_Rel_Insert_Input>;
  created_by_id?: Maybe<Scalars['uuid']>;
  farm?: Maybe<Farm_Obj_Rel_Insert_Input>;
  farm_id?: Maybe<Scalars['Int']>;
  heifer_measurements?: Maybe<Heifer_Measurements_Arr_Rel_Insert_Input>;
  heifers?: Maybe<Heifer_Arr_Rel_Insert_Input>;
  herd_data?: Maybe<Herd_Data_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<User_Obj_Rel_Insert_Input>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Herd_Max_Fields = {
  __typename?: 'herd_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  farm_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "herd" */
export type Herd_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  farm_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Herd_Min_Fields = {
  __typename?: 'herd_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  farm_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "herd" */
export type Herd_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  farm_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "herd" */
export type Herd_Mutation_Response = {
  __typename?: 'herd_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Herd>;
};

/** input type for inserting object relation for remote table "herd" */
export type Herd_Obj_Rel_Insert_Input = {
  data: Herd_Insert_Input;
  on_conflict?: Maybe<Herd_On_Conflict>;
};

/** on conflict condition type for table "herd" */
export type Herd_On_Conflict = {
  constraint: Herd_Constraint;
  update_columns: Array<Herd_Update_Column>;
  where?: Maybe<Herd_Bool_Exp>;
};

/** ordering options when selecting data from "herd" */
export type Herd_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<User_Order_By>;
  created_by_id?: Maybe<Order_By>;
  farm?: Maybe<Farm_Order_By>;
  farm_id?: Maybe<Order_By>;
  heifer_measurements_aggregate?: Maybe<Heifer_Measurements_Aggregate_Order_By>;
  heifers_aggregate?: Maybe<Heifer_Aggregate_Order_By>;
  herd_data_aggregate?: Maybe<Herd_Data_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<User_Order_By>;
  updated_by_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "herd" */
export type Herd_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "herd" */
export enum Herd_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  FarmId = 'farm_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id'
}

/** input type for updating data in table "herd" */
export type Herd_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  farm_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Herd_Stddev_Fields = {
  __typename?: 'herd_stddev_fields';
  farm_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "herd" */
export type Herd_Stddev_Order_By = {
  farm_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Herd_Stddev_Pop_Fields = {
  __typename?: 'herd_stddev_pop_fields';
  farm_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "herd" */
export type Herd_Stddev_Pop_Order_By = {
  farm_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Herd_Stddev_Samp_Fields = {
  __typename?: 'herd_stddev_samp_fields';
  farm_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "herd" */
export type Herd_Stddev_Samp_Order_By = {
  farm_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Herd_Sum_Fields = {
  __typename?: 'herd_sum_fields';
  farm_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "herd" */
export type Herd_Sum_Order_By = {
  farm_id?: Maybe<Order_By>;
};

/** update columns of table "herd" */
export enum Herd_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  FarmId = 'farm_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id'
}

/** aggregate var_pop on columns */
export type Herd_Var_Pop_Fields = {
  __typename?: 'herd_var_pop_fields';
  farm_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "herd" */
export type Herd_Var_Pop_Order_By = {
  farm_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Herd_Var_Samp_Fields = {
  __typename?: 'herd_var_samp_fields';
  farm_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "herd" */
export type Herd_Var_Samp_Order_By = {
  farm_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Herd_Variance_Fields = {
  __typename?: 'herd_variance_fields';
  farm_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "herd" */
export type Herd_Variance_Order_By = {
  farm_id?: Maybe<Order_By>;
};


/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "enum_user_role" */
  delete_enum_user_role?: Maybe<Enum_User_Role_Mutation_Response>;
  /** delete single row from the table: "enum_user_role" */
  delete_enum_user_role_by_pk?: Maybe<Enum_User_Role>;
  /** delete data from the table: "farm" */
  delete_farm?: Maybe<Farm_Mutation_Response>;
  /** delete single row from the table: "farm" */
  delete_farm_by_pk?: Maybe<Farm>;
  /** delete data from the table: "heifer" */
  delete_heifer?: Maybe<Heifer_Mutation_Response>;
  /** delete single row from the table: "heifer" */
  delete_heifer_by_pk?: Maybe<Heifer>;
  /** delete data from the table: "heifer_measurements" */
  delete_heifer_measurements?: Maybe<Heifer_Measurements_Mutation_Response>;
  /** delete single row from the table: "heifer_measurements" */
  delete_heifer_measurements_by_pk?: Maybe<Heifer_Measurements>;
  /** delete data from the table: "herd" */
  delete_herd?: Maybe<Herd_Mutation_Response>;
  /** delete single row from the table: "herd" */
  delete_herd_by_pk?: Maybe<Herd>;
  /** delete data from the table: "herd_data" */
  delete_herd_data?: Maybe<Herd_Data_Mutation_Response>;
  /** delete single row from the table: "herd_data" */
  delete_herd_data_by_pk?: Maybe<Herd_Data>;
  /** delete data from the table: "pen" */
  delete_pen?: Maybe<Pen_Mutation_Response>;
  /** delete single row from the table: "pen" */
  delete_pen_by_pk?: Maybe<Pen>;
  /** delete data from the table: "report" */
  delete_report?: Maybe<Report_Mutation_Response>;
  /** delete single row from the table: "report" */
  delete_report_by_pk?: Maybe<Report>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** perform the action: "generatePdfReport" */
  generatePdfReport?: Maybe<GeneratePdfReportOutput>;
  /** insert data into the table: "enum_user_role" */
  insert_enum_user_role?: Maybe<Enum_User_Role_Mutation_Response>;
  /** insert a single row into the table: "enum_user_role" */
  insert_enum_user_role_one?: Maybe<Enum_User_Role>;
  /** insert data into the table: "farm" */
  insert_farm?: Maybe<Farm_Mutation_Response>;
  /** insert a single row into the table: "farm" */
  insert_farm_one?: Maybe<Farm>;
  /** insert data into the table: "heifer" */
  insert_heifer?: Maybe<Heifer_Mutation_Response>;
  /** insert data into the table: "heifer_measurements" */
  insert_heifer_measurements?: Maybe<Heifer_Measurements_Mutation_Response>;
  /** insert a single row into the table: "heifer_measurements" */
  insert_heifer_measurements_one?: Maybe<Heifer_Measurements>;
  /** insert a single row into the table: "heifer" */
  insert_heifer_one?: Maybe<Heifer>;
  /** insert data into the table: "herd" */
  insert_herd?: Maybe<Herd_Mutation_Response>;
  /** insert data into the table: "herd_data" */
  insert_herd_data?: Maybe<Herd_Data_Mutation_Response>;
  /** insert a single row into the table: "herd_data" */
  insert_herd_data_one?: Maybe<Herd_Data>;
  /** insert a single row into the table: "herd" */
  insert_herd_one?: Maybe<Herd>;
  /** insert data into the table: "pen" */
  insert_pen?: Maybe<Pen_Mutation_Response>;
  /** insert a single row into the table: "pen" */
  insert_pen_one?: Maybe<Pen>;
  /** insert data into the table: "report" */
  insert_report?: Maybe<Report_Mutation_Response>;
  /** insert a single row into the table: "report" */
  insert_report_one?: Maybe<Report>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** update data of the table: "enum_user_role" */
  update_enum_user_role?: Maybe<Enum_User_Role_Mutation_Response>;
  /** update single row of the table: "enum_user_role" */
  update_enum_user_role_by_pk?: Maybe<Enum_User_Role>;
  /** update data of the table: "farm" */
  update_farm?: Maybe<Farm_Mutation_Response>;
  /** update single row of the table: "farm" */
  update_farm_by_pk?: Maybe<Farm>;
  /** update data of the table: "heifer" */
  update_heifer?: Maybe<Heifer_Mutation_Response>;
  /** update single row of the table: "heifer" */
  update_heifer_by_pk?: Maybe<Heifer>;
  /** update data of the table: "heifer_measurements" */
  update_heifer_measurements?: Maybe<Heifer_Measurements_Mutation_Response>;
  /** update single row of the table: "heifer_measurements" */
  update_heifer_measurements_by_pk?: Maybe<Heifer_Measurements>;
  /** update data of the table: "herd" */
  update_herd?: Maybe<Herd_Mutation_Response>;
  /** update single row of the table: "herd" */
  update_herd_by_pk?: Maybe<Herd>;
  /** update data of the table: "herd_data" */
  update_herd_data?: Maybe<Herd_Data_Mutation_Response>;
  /** update single row of the table: "herd_data" */
  update_herd_data_by_pk?: Maybe<Herd_Data>;
  /** update data of the table: "pen" */
  update_pen?: Maybe<Pen_Mutation_Response>;
  /** update single row of the table: "pen" */
  update_pen_by_pk?: Maybe<Pen>;
  /** update data of the table: "report" */
  update_report?: Maybe<Report_Mutation_Response>;
  /** update single row of the table: "report" */
  update_report_by_pk?: Maybe<Report>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
};


/** mutation root */
export type Mutation_RootDelete_Enum_User_RoleArgs = {
  where: Enum_User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_User_Role_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_FarmArgs = {
  where: Farm_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Farm_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_HeiferArgs = {
  where: Heifer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Heifer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Heifer_MeasurementsArgs = {
  where: Heifer_Measurements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Heifer_Measurements_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_HerdArgs = {
  where: Herd_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Herd_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Herd_DataArgs = {
  where: Herd_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Herd_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PenArgs = {
  where: Pen_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pen_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ReportArgs = {
  where: Report_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Report_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootGeneratePdfReportArgs = {
  farmName: Scalars['String'];
  reportId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Enum_User_RoleArgs = {
  objects: Array<Enum_User_Role_Insert_Input>;
  on_conflict?: Maybe<Enum_User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_User_Role_OneArgs = {
  object: Enum_User_Role_Insert_Input;
  on_conflict?: Maybe<Enum_User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FarmArgs = {
  objects: Array<Farm_Insert_Input>;
  on_conflict?: Maybe<Farm_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farm_OneArgs = {
  object: Farm_Insert_Input;
  on_conflict?: Maybe<Farm_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HeiferArgs = {
  objects: Array<Heifer_Insert_Input>;
  on_conflict?: Maybe<Heifer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Heifer_MeasurementsArgs = {
  objects: Array<Heifer_Measurements_Insert_Input>;
  on_conflict?: Maybe<Heifer_Measurements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Heifer_Measurements_OneArgs = {
  object: Heifer_Measurements_Insert_Input;
  on_conflict?: Maybe<Heifer_Measurements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Heifer_OneArgs = {
  object: Heifer_Insert_Input;
  on_conflict?: Maybe<Heifer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HerdArgs = {
  objects: Array<Herd_Insert_Input>;
  on_conflict?: Maybe<Herd_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Herd_DataArgs = {
  objects: Array<Herd_Data_Insert_Input>;
  on_conflict?: Maybe<Herd_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Herd_Data_OneArgs = {
  object: Herd_Data_Insert_Input;
  on_conflict?: Maybe<Herd_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Herd_OneArgs = {
  object: Herd_Insert_Input;
  on_conflict?: Maybe<Herd_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PenArgs = {
  objects: Array<Pen_Insert_Input>;
  on_conflict?: Maybe<Pen_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pen_OneArgs = {
  object: Pen_Insert_Input;
  on_conflict?: Maybe<Pen_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReportArgs = {
  objects: Array<Report_Insert_Input>;
  on_conflict?: Maybe<Report_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Report_OneArgs = {
  object: Report_Insert_Input;
  on_conflict?: Maybe<Report_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_User_RoleArgs = {
  _set?: Maybe<Enum_User_Role_Set_Input>;
  where: Enum_User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_User_Role_By_PkArgs = {
  _set?: Maybe<Enum_User_Role_Set_Input>;
  pk_columns: Enum_User_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FarmArgs = {
  _inc?: Maybe<Farm_Inc_Input>;
  _set?: Maybe<Farm_Set_Input>;
  where: Farm_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Farm_By_PkArgs = {
  _inc?: Maybe<Farm_Inc_Input>;
  _set?: Maybe<Farm_Set_Input>;
  pk_columns: Farm_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_HeiferArgs = {
  _set?: Maybe<Heifer_Set_Input>;
  where: Heifer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Heifer_By_PkArgs = {
  _set?: Maybe<Heifer_Set_Input>;
  pk_columns: Heifer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Heifer_MeasurementsArgs = {
  _inc?: Maybe<Heifer_Measurements_Inc_Input>;
  _set?: Maybe<Heifer_Measurements_Set_Input>;
  where: Heifer_Measurements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Heifer_Measurements_By_PkArgs = {
  _inc?: Maybe<Heifer_Measurements_Inc_Input>;
  _set?: Maybe<Heifer_Measurements_Set_Input>;
  pk_columns: Heifer_Measurements_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_HerdArgs = {
  _inc?: Maybe<Herd_Inc_Input>;
  _set?: Maybe<Herd_Set_Input>;
  where: Herd_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Herd_By_PkArgs = {
  _inc?: Maybe<Herd_Inc_Input>;
  _set?: Maybe<Herd_Set_Input>;
  pk_columns: Herd_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Herd_DataArgs = {
  _inc?: Maybe<Herd_Data_Inc_Input>;
  _set?: Maybe<Herd_Data_Set_Input>;
  where: Herd_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Herd_Data_By_PkArgs = {
  _inc?: Maybe<Herd_Data_Inc_Input>;
  _set?: Maybe<Herd_Data_Set_Input>;
  pk_columns: Herd_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PenArgs = {
  _inc?: Maybe<Pen_Inc_Input>;
  _set?: Maybe<Pen_Set_Input>;
  where: Pen_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pen_By_PkArgs = {
  _inc?: Maybe<Pen_Inc_Input>;
  _set?: Maybe<Pen_Set_Input>;
  pk_columns: Pen_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReportArgs = {
  _append?: Maybe<Report_Append_Input>;
  _delete_at_path?: Maybe<Report_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Report_Delete_Elem_Input>;
  _delete_key?: Maybe<Report_Delete_Key_Input>;
  _prepend?: Maybe<Report_Prepend_Input>;
  _set?: Maybe<Report_Set_Input>;
  where: Report_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Report_By_PkArgs = {
  _append?: Maybe<Report_Append_Input>;
  _delete_at_path?: Maybe<Report_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Report_Delete_Elem_Input>;
  _delete_key?: Maybe<Report_Delete_Key_Input>;
  _prepend?: Maybe<Report_Prepend_Input>;
  _set?: Maybe<Report_Set_Input>;
  pk_columns: Report_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/**
 * table for storing pens of farms
 * 
 * 
 * columns and relationships of "pen"
 */
export type Pen = {
  __typename?: 'pen';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by?: Maybe<User>;
  created_by_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  farm: Farm;
  farm_id: Scalars['Int'];
  /** An array relationship */
  heifer_measurements: Array<Heifer_Measurements>;
  /** An aggregated array relationship */
  heifer_measurements_aggregate: Heifer_Measurements_Aggregate;
  /** An array relationship */
  heifers: Array<Heifer>;
  /** An aggregated array relationship */
  heifers_aggregate: Heifer_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  updated_by?: Maybe<User>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};


/**
 * table for storing pens of farms
 * 
 * 
 * columns and relationships of "pen"
 */
export type PenHeifer_MeasurementsArgs = {
  distinct_on?: Maybe<Array<Heifer_Measurements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Measurements_Order_By>>;
  where?: Maybe<Heifer_Measurements_Bool_Exp>;
};


/**
 * table for storing pens of farms
 * 
 * 
 * columns and relationships of "pen"
 */
export type PenHeifer_Measurements_AggregateArgs = {
  distinct_on?: Maybe<Array<Heifer_Measurements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Measurements_Order_By>>;
  where?: Maybe<Heifer_Measurements_Bool_Exp>;
};


/**
 * table for storing pens of farms
 * 
 * 
 * columns and relationships of "pen"
 */
export type PenHeifersArgs = {
  distinct_on?: Maybe<Array<Heifer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Order_By>>;
  where?: Maybe<Heifer_Bool_Exp>;
};


/**
 * table for storing pens of farms
 * 
 * 
 * columns and relationships of "pen"
 */
export type PenHeifers_AggregateArgs = {
  distinct_on?: Maybe<Array<Heifer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Order_By>>;
  where?: Maybe<Heifer_Bool_Exp>;
};

/** aggregated selection of "pen" */
export type Pen_Aggregate = {
  __typename?: 'pen_aggregate';
  aggregate?: Maybe<Pen_Aggregate_Fields>;
  nodes: Array<Pen>;
};

/** aggregate fields of "pen" */
export type Pen_Aggregate_Fields = {
  __typename?: 'pen_aggregate_fields';
  avg?: Maybe<Pen_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Pen_Max_Fields>;
  min?: Maybe<Pen_Min_Fields>;
  stddev?: Maybe<Pen_Stddev_Fields>;
  stddev_pop?: Maybe<Pen_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pen_Stddev_Samp_Fields>;
  sum?: Maybe<Pen_Sum_Fields>;
  var_pop?: Maybe<Pen_Var_Pop_Fields>;
  var_samp?: Maybe<Pen_Var_Samp_Fields>;
  variance?: Maybe<Pen_Variance_Fields>;
};


/** aggregate fields of "pen" */
export type Pen_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pen_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pen" */
export type Pen_Aggregate_Order_By = {
  avg?: Maybe<Pen_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Pen_Max_Order_By>;
  min?: Maybe<Pen_Min_Order_By>;
  stddev?: Maybe<Pen_Stddev_Order_By>;
  stddev_pop?: Maybe<Pen_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Pen_Stddev_Samp_Order_By>;
  sum?: Maybe<Pen_Sum_Order_By>;
  var_pop?: Maybe<Pen_Var_Pop_Order_By>;
  var_samp?: Maybe<Pen_Var_Samp_Order_By>;
  variance?: Maybe<Pen_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "pen" */
export type Pen_Arr_Rel_Insert_Input = {
  data: Array<Pen_Insert_Input>;
  on_conflict?: Maybe<Pen_On_Conflict>;
};

/** aggregate avg on columns */
export type Pen_Avg_Fields = {
  __typename?: 'pen_avg_fields';
  farm_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pen" */
export type Pen_Avg_Order_By = {
  farm_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "pen". All fields are combined with a logical 'AND'. */
export type Pen_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Pen_Bool_Exp>>>;
  _not?: Maybe<Pen_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Pen_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<User_Bool_Exp>;
  created_by_id?: Maybe<Uuid_Comparison_Exp>;
  farm?: Maybe<Farm_Bool_Exp>;
  farm_id?: Maybe<Int_Comparison_Exp>;
  heifer_measurements?: Maybe<Heifer_Measurements_Bool_Exp>;
  heifers?: Maybe<Heifer_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<User_Bool_Exp>;
  updated_by_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "pen" */
export enum Pen_Constraint {
  /** unique or primary key constraint */
  PenPkey = 'pen_pkey'
}

/** input type for incrementing integer column in table "pen" */
export type Pen_Inc_Input = {
  farm_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pen" */
export type Pen_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<User_Obj_Rel_Insert_Input>;
  created_by_id?: Maybe<Scalars['uuid']>;
  farm?: Maybe<Farm_Obj_Rel_Insert_Input>;
  farm_id?: Maybe<Scalars['Int']>;
  heifer_measurements?: Maybe<Heifer_Measurements_Arr_Rel_Insert_Input>;
  heifers?: Maybe<Heifer_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<User_Obj_Rel_Insert_Input>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Pen_Max_Fields = {
  __typename?: 'pen_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  farm_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "pen" */
export type Pen_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  farm_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Pen_Min_Fields = {
  __typename?: 'pen_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  farm_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "pen" */
export type Pen_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  farm_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "pen" */
export type Pen_Mutation_Response = {
  __typename?: 'pen_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Pen>;
};

/** input type for inserting object relation for remote table "pen" */
export type Pen_Obj_Rel_Insert_Input = {
  data: Pen_Insert_Input;
  on_conflict?: Maybe<Pen_On_Conflict>;
};

/** on conflict condition type for table "pen" */
export type Pen_On_Conflict = {
  constraint: Pen_Constraint;
  update_columns: Array<Pen_Update_Column>;
  where?: Maybe<Pen_Bool_Exp>;
};

/** ordering options when selecting data from "pen" */
export type Pen_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<User_Order_By>;
  created_by_id?: Maybe<Order_By>;
  farm?: Maybe<Farm_Order_By>;
  farm_id?: Maybe<Order_By>;
  heifer_measurements_aggregate?: Maybe<Heifer_Measurements_Aggregate_Order_By>;
  heifers_aggregate?: Maybe<Heifer_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<User_Order_By>;
  updated_by_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "pen" */
export type Pen_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "pen" */
export enum Pen_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  FarmId = 'farm_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id'
}

/** input type for updating data in table "pen" */
export type Pen_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  farm_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Pen_Stddev_Fields = {
  __typename?: 'pen_stddev_fields';
  farm_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pen" */
export type Pen_Stddev_Order_By = {
  farm_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Pen_Stddev_Pop_Fields = {
  __typename?: 'pen_stddev_pop_fields';
  farm_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "pen" */
export type Pen_Stddev_Pop_Order_By = {
  farm_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Pen_Stddev_Samp_Fields = {
  __typename?: 'pen_stddev_samp_fields';
  farm_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "pen" */
export type Pen_Stddev_Samp_Order_By = {
  farm_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Pen_Sum_Fields = {
  __typename?: 'pen_sum_fields';
  farm_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pen" */
export type Pen_Sum_Order_By = {
  farm_id?: Maybe<Order_By>;
};

/** update columns of table "pen" */
export enum Pen_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  FarmId = 'farm_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id'
}

/** aggregate var_pop on columns */
export type Pen_Var_Pop_Fields = {
  __typename?: 'pen_var_pop_fields';
  farm_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "pen" */
export type Pen_Var_Pop_Order_By = {
  farm_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Pen_Var_Samp_Fields = {
  __typename?: 'pen_var_samp_fields';
  farm_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "pen" */
export type Pen_Var_Samp_Order_By = {
  farm_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Pen_Variance_Fields = {
  __typename?: 'pen_variance_fields';
  farm_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pen" */
export type Pen_Variance_Order_By = {
  farm_id?: Maybe<Order_By>;
};

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "enum_user_role" */
  enum_user_role: Array<Enum_User_Role>;
  /** fetch aggregated fields from the table: "enum_user_role" */
  enum_user_role_aggregate: Enum_User_Role_Aggregate;
  /** fetch data from the table: "enum_user_role" using primary key columns */
  enum_user_role_by_pk?: Maybe<Enum_User_Role>;
  /** fetch data from the table: "farm" */
  farm: Array<Farm>;
  /** fetch aggregated fields from the table: "farm" */
  farm_aggregate: Farm_Aggregate;
  /** fetch data from the table: "farm" using primary key columns */
  farm_by_pk?: Maybe<Farm>;
  /** fetch data from the table: "heifer" */
  heifer: Array<Heifer>;
  /** fetch aggregated fields from the table: "heifer" */
  heifer_aggregate: Heifer_Aggregate;
  /** fetch data from the table: "heifer" using primary key columns */
  heifer_by_pk?: Maybe<Heifer>;
  /** fetch data from the table: "heifer_measurements" */
  heifer_measurements: Array<Heifer_Measurements>;
  /** fetch aggregated fields from the table: "heifer_measurements" */
  heifer_measurements_aggregate: Heifer_Measurements_Aggregate;
  /** fetch data from the table: "heifer_measurements" using primary key columns */
  heifer_measurements_by_pk?: Maybe<Heifer_Measurements>;
  /** fetch data from the table: "herd" */
  herd: Array<Herd>;
  /** fetch aggregated fields from the table: "herd" */
  herd_aggregate: Herd_Aggregate;
  /** fetch data from the table: "herd" using primary key columns */
  herd_by_pk?: Maybe<Herd>;
  /** fetch data from the table: "herd_data" */
  herd_data: Array<Herd_Data>;
  /** fetch aggregated fields from the table: "herd_data" */
  herd_data_aggregate: Herd_Data_Aggregate;
  /** fetch data from the table: "herd_data" using primary key columns */
  herd_data_by_pk?: Maybe<Herd_Data>;
  /** fetch data from the table: "pen" */
  pen: Array<Pen>;
  /** fetch aggregated fields from the table: "pen" */
  pen_aggregate: Pen_Aggregate;
  /** fetch data from the table: "pen" using primary key columns */
  pen_by_pk?: Maybe<Pen>;
  /** fetch data from the table: "report" */
  report: Array<Report>;
  /** fetch aggregated fields from the table: "report" */
  report_aggregate: Report_Aggregate;
  /** fetch data from the table: "report" using primary key columns */
  report_by_pk?: Maybe<Report>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
};


/** query root */
export type Query_RootEnum_User_RoleArgs = {
  distinct_on?: Maybe<Array<Enum_User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_User_Role_Order_By>>;
  where?: Maybe<Enum_User_Role_Bool_Exp>;
};


/** query root */
export type Query_RootEnum_User_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_User_Role_Order_By>>;
  where?: Maybe<Enum_User_Role_Bool_Exp>;
};


/** query root */
export type Query_RootEnum_User_Role_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootFarmArgs = {
  distinct_on?: Maybe<Array<Farm_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Farm_Order_By>>;
  where?: Maybe<Farm_Bool_Exp>;
};


/** query root */
export type Query_RootFarm_AggregateArgs = {
  distinct_on?: Maybe<Array<Farm_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Farm_Order_By>>;
  where?: Maybe<Farm_Bool_Exp>;
};


/** query root */
export type Query_RootFarm_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootHeiferArgs = {
  distinct_on?: Maybe<Array<Heifer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Order_By>>;
  where?: Maybe<Heifer_Bool_Exp>;
};


/** query root */
export type Query_RootHeifer_AggregateArgs = {
  distinct_on?: Maybe<Array<Heifer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Order_By>>;
  where?: Maybe<Heifer_Bool_Exp>;
};


/** query root */
export type Query_RootHeifer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootHeifer_MeasurementsArgs = {
  distinct_on?: Maybe<Array<Heifer_Measurements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Measurements_Order_By>>;
  where?: Maybe<Heifer_Measurements_Bool_Exp>;
};


/** query root */
export type Query_RootHeifer_Measurements_AggregateArgs = {
  distinct_on?: Maybe<Array<Heifer_Measurements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Measurements_Order_By>>;
  where?: Maybe<Heifer_Measurements_Bool_Exp>;
};


/** query root */
export type Query_RootHeifer_Measurements_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootHerdArgs = {
  distinct_on?: Maybe<Array<Herd_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Herd_Order_By>>;
  where?: Maybe<Herd_Bool_Exp>;
};


/** query root */
export type Query_RootHerd_AggregateArgs = {
  distinct_on?: Maybe<Array<Herd_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Herd_Order_By>>;
  where?: Maybe<Herd_Bool_Exp>;
};


/** query root */
export type Query_RootHerd_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootHerd_DataArgs = {
  distinct_on?: Maybe<Array<Herd_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Herd_Data_Order_By>>;
  where?: Maybe<Herd_Data_Bool_Exp>;
};


/** query root */
export type Query_RootHerd_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Herd_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Herd_Data_Order_By>>;
  where?: Maybe<Herd_Data_Bool_Exp>;
};


/** query root */
export type Query_RootHerd_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootPenArgs = {
  distinct_on?: Maybe<Array<Pen_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pen_Order_By>>;
  where?: Maybe<Pen_Bool_Exp>;
};


/** query root */
export type Query_RootPen_AggregateArgs = {
  distinct_on?: Maybe<Array<Pen_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pen_Order_By>>;
  where?: Maybe<Pen_Bool_Exp>;
};


/** query root */
export type Query_RootPen_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootReportArgs = {
  distinct_on?: Maybe<Array<Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Order_By>>;
  where?: Maybe<Report_Bool_Exp>;
};


/** query root */
export type Query_RootReport_AggregateArgs = {
  distinct_on?: Maybe<Array<Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Order_By>>;
  where?: Maybe<Report_Bool_Exp>;
};


/** query root */
export type Query_RootReport_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** query root */
export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** query root */
export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/**
 * Table to temporarily store report data for PDF generation
 * 
 * 
 * columns and relationships of "report"
 */
export type Report = {
  __typename?: 'report';
  id: Scalars['uuid'];
  input_data: Scalars['jsonb'];
};


/**
 * Table to temporarily store report data for PDF generation
 * 
 * 
 * columns and relationships of "report"
 */
export type ReportInput_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "report" */
export type Report_Aggregate = {
  __typename?: 'report_aggregate';
  aggregate?: Maybe<Report_Aggregate_Fields>;
  nodes: Array<Report>;
};

/** aggregate fields of "report" */
export type Report_Aggregate_Fields = {
  __typename?: 'report_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Report_Max_Fields>;
  min?: Maybe<Report_Min_Fields>;
};


/** aggregate fields of "report" */
export type Report_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Report_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "report" */
export type Report_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Report_Max_Order_By>;
  min?: Maybe<Report_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Report_Append_Input = {
  input_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "report" */
export type Report_Arr_Rel_Insert_Input = {
  data: Array<Report_Insert_Input>;
  on_conflict?: Maybe<Report_On_Conflict>;
};

/** Boolean expression to filter rows from the table "report". All fields are combined with a logical 'AND'. */
export type Report_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Report_Bool_Exp>>>;
  _not?: Maybe<Report_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Report_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  input_data?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "report" */
export enum Report_Constraint {
  /** unique or primary key constraint */
  ReportPkey = 'report_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Report_Delete_At_Path_Input = {
  input_data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Report_Delete_Elem_Input = {
  input_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Report_Delete_Key_Input = {
  input_data?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "report" */
export type Report_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  input_data?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Report_Max_Fields = {
  __typename?: 'report_max_fields';
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "report" */
export type Report_Max_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Report_Min_Fields = {
  __typename?: 'report_min_fields';
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "report" */
export type Report_Min_Order_By = {
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "report" */
export type Report_Mutation_Response = {
  __typename?: 'report_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Report>;
};

/** input type for inserting object relation for remote table "report" */
export type Report_Obj_Rel_Insert_Input = {
  data: Report_Insert_Input;
  on_conflict?: Maybe<Report_On_Conflict>;
};

/** on conflict condition type for table "report" */
export type Report_On_Conflict = {
  constraint: Report_Constraint;
  update_columns: Array<Report_Update_Column>;
  where?: Maybe<Report_Bool_Exp>;
};

/** ordering options when selecting data from "report" */
export type Report_Order_By = {
  id?: Maybe<Order_By>;
  input_data?: Maybe<Order_By>;
};

/** primary key columns input for table: "report" */
export type Report_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Report_Prepend_Input = {
  input_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "report" */
export enum Report_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InputData = 'input_data'
}

/** input type for updating data in table "report" */
export type Report_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  input_data?: Maybe<Scalars['jsonb']>;
};

/** update columns of table "report" */
export enum Report_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InputData = 'input_data'
}

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "enum_user_role" */
  enum_user_role: Array<Enum_User_Role>;
  /** fetch aggregated fields from the table: "enum_user_role" */
  enum_user_role_aggregate: Enum_User_Role_Aggregate;
  /** fetch data from the table: "enum_user_role" using primary key columns */
  enum_user_role_by_pk?: Maybe<Enum_User_Role>;
  /** fetch data from the table: "farm" */
  farm: Array<Farm>;
  /** fetch aggregated fields from the table: "farm" */
  farm_aggregate: Farm_Aggregate;
  /** fetch data from the table: "farm" using primary key columns */
  farm_by_pk?: Maybe<Farm>;
  /** fetch data from the table: "heifer" */
  heifer: Array<Heifer>;
  /** fetch aggregated fields from the table: "heifer" */
  heifer_aggregate: Heifer_Aggregate;
  /** fetch data from the table: "heifer" using primary key columns */
  heifer_by_pk?: Maybe<Heifer>;
  /** fetch data from the table: "heifer_measurements" */
  heifer_measurements: Array<Heifer_Measurements>;
  /** fetch aggregated fields from the table: "heifer_measurements" */
  heifer_measurements_aggregate: Heifer_Measurements_Aggregate;
  /** fetch data from the table: "heifer_measurements" using primary key columns */
  heifer_measurements_by_pk?: Maybe<Heifer_Measurements>;
  /** fetch data from the table: "herd" */
  herd: Array<Herd>;
  /** fetch aggregated fields from the table: "herd" */
  herd_aggregate: Herd_Aggregate;
  /** fetch data from the table: "herd" using primary key columns */
  herd_by_pk?: Maybe<Herd>;
  /** fetch data from the table: "herd_data" */
  herd_data: Array<Herd_Data>;
  /** fetch aggregated fields from the table: "herd_data" */
  herd_data_aggregate: Herd_Data_Aggregate;
  /** fetch data from the table: "herd_data" using primary key columns */
  herd_data_by_pk?: Maybe<Herd_Data>;
  /** fetch data from the table: "pen" */
  pen: Array<Pen>;
  /** fetch aggregated fields from the table: "pen" */
  pen_aggregate: Pen_Aggregate;
  /** fetch data from the table: "pen" using primary key columns */
  pen_by_pk?: Maybe<Pen>;
  /** fetch data from the table: "report" */
  report: Array<Report>;
  /** fetch aggregated fields from the table: "report" */
  report_aggregate: Report_Aggregate;
  /** fetch data from the table: "report" using primary key columns */
  report_by_pk?: Maybe<Report>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
};


/** subscription root */
export type Subscription_RootEnum_User_RoleArgs = {
  distinct_on?: Maybe<Array<Enum_User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_User_Role_Order_By>>;
  where?: Maybe<Enum_User_Role_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEnum_User_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Enum_User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Enum_User_Role_Order_By>>;
  where?: Maybe<Enum_User_Role_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEnum_User_Role_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootFarmArgs = {
  distinct_on?: Maybe<Array<Farm_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Farm_Order_By>>;
  where?: Maybe<Farm_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFarm_AggregateArgs = {
  distinct_on?: Maybe<Array<Farm_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Farm_Order_By>>;
  where?: Maybe<Farm_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFarm_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootHeiferArgs = {
  distinct_on?: Maybe<Array<Heifer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Order_By>>;
  where?: Maybe<Heifer_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHeifer_AggregateArgs = {
  distinct_on?: Maybe<Array<Heifer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Order_By>>;
  where?: Maybe<Heifer_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHeifer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootHeifer_MeasurementsArgs = {
  distinct_on?: Maybe<Array<Heifer_Measurements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Measurements_Order_By>>;
  where?: Maybe<Heifer_Measurements_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHeifer_Measurements_AggregateArgs = {
  distinct_on?: Maybe<Array<Heifer_Measurements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Heifer_Measurements_Order_By>>;
  where?: Maybe<Heifer_Measurements_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHeifer_Measurements_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootHerdArgs = {
  distinct_on?: Maybe<Array<Herd_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Herd_Order_By>>;
  where?: Maybe<Herd_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHerd_AggregateArgs = {
  distinct_on?: Maybe<Array<Herd_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Herd_Order_By>>;
  where?: Maybe<Herd_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHerd_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootHerd_DataArgs = {
  distinct_on?: Maybe<Array<Herd_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Herd_Data_Order_By>>;
  where?: Maybe<Herd_Data_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHerd_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Herd_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Herd_Data_Order_By>>;
  where?: Maybe<Herd_Data_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHerd_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootPenArgs = {
  distinct_on?: Maybe<Array<Pen_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pen_Order_By>>;
  where?: Maybe<Pen_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPen_AggregateArgs = {
  distinct_on?: Maybe<Array<Pen_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pen_Order_By>>;
  where?: Maybe<Pen_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPen_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootReportArgs = {
  distinct_on?: Maybe<Array<Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Order_By>>;
  where?: Maybe<Report_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReport_AggregateArgs = {
  distinct_on?: Maybe<Array<Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Report_Order_By>>;
  where?: Maybe<Report_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReport_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/**
 * Users table
 * 
 * 
 * columns and relationships of "user"
 */
export type User = {
  __typename?: 'user';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  /** An array relationship */
  farms: Array<Farm>;
  /** An aggregated array relationship */
  farms_aggregate: Farm_Aggregate;
  first_login_at?: Maybe<Scalars['timestamptz']>;
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  last_name: Scalars['String'];
  role: Enum_User_Role_Enum;
};


/**
 * Users table
 * 
 * 
 * columns and relationships of "user"
 */
export type UserFarmsArgs = {
  distinct_on?: Maybe<Array<Farm_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Farm_Order_By>>;
  where?: Maybe<Farm_Bool_Exp>;
};


/**
 * Users table
 * 
 * 
 * columns and relationships of "user"
 */
export type UserFarms_AggregateArgs = {
  distinct_on?: Maybe<Array<Farm_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Farm_Order_By>>;
  where?: Maybe<Farm_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Max_Order_By>;
  min?: Maybe<User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  farms?: Maybe<Farm_Bool_Exp>;
  first_login_at?: Maybe<Timestamptz_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  role?: Maybe<Enum_User_Role_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  farms?: Maybe<Farm_Arr_Rel_Insert_Input>;
  first_login_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  role?: Maybe<Enum_User_Role_Enum>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_login_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_login_at?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_login_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_login_at?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** on conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

/** ordering options when selecting data from "user" */
export type User_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  farms_aggregate?: Maybe<Farm_Aggregate_Order_By>;
  first_login_at?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** primary key columns input for table: "user" */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstLoginAt = 'first_login_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_login_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  role?: Maybe<Enum_User_Role_Enum>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstLoginAt = 'first_login_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Role = 'role'
}


/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type AddFarmMutationVariables = Exact<{
  object: Farm_Insert_Input;
}>;


export type AddFarmMutation = (
  { __typename?: 'mutation_root' }
  & { insert_farm_one?: Maybe<(
    { __typename?: 'farm' }
    & Pick<Farm, 'id' | 'address_line_1' | 'address_line_2' | 'city' | 'created_at' | 'manager_email' | 'manager_name' | 'manager_phone' | 'name' | 'state' | 'updated_at' | 'zipcode'>
    & { pens: Array<(
      { __typename?: 'pen' }
      & Pick<Pen, 'farm_id' | 'name' | 'id'>
    )> }
  )> }
);

export type AddHeiferAndMeasurementMutationVariables = Exact<{
  object: Heifer_Insert_Input;
}>;


export type AddHeiferAndMeasurementMutation = (
  { __typename?: 'mutation_root' }
  & { insert_heifer_one?: Maybe<(
    { __typename?: 'heifer' }
    & Pick<Heifer, 'id' | 'dob' | 'herd_id' | 'name' | 'pen_id'>
    & { heifer_measurements: Array<(
      { __typename?: 'heifer_measurements' }
      & Pick<Heifer_Measurements, 'body_weight' | 'created_at' | 'heifer_id' | 'height' | 'herd_id' | 'id' | 'mature_body_weight' | 'mature_height' | 'measurement_date' | 'measurement_date_age' | 'pc_mature_body_weight' | 'pc_mature_height' | 'pen_id' | 'updated_at'>
    )> }
  )> }
);

export type AddHeiferMeasurementMutationVariables = Exact<{
  object: Heifer_Measurements_Insert_Input;
}>;


export type AddHeiferMeasurementMutation = (
  { __typename?: 'mutation_root' }
  & { insert_heifer_measurements_one?: Maybe<(
    { __typename?: 'heifer_measurements' }
    & Pick<Heifer_Measurements, 'body_weight' | 'created_at' | 'heifer_id' | 'height' | 'herd_id' | 'id' | 'mature_body_weight' | 'mature_height' | 'measurement_date' | 'measurement_date_age' | 'pc_mature_body_weight' | 'pc_mature_height' | 'pen_id' | 'updated_at'>
  )> }
);

export type AddHeiferMutationVariables = Exact<{
  object: Heifer_Insert_Input;
}>;


export type AddHeiferMutation = (
  { __typename?: 'mutation_root' }
  & { insert_heifer_one?: Maybe<(
    { __typename?: 'heifer' }
    & Pick<Heifer, 'id' | 'dob' | 'herd_id' | 'name' | 'pen_id'>
  )> }
);

export type AddHerdDataMutationVariables = Exact<{
  object: Herd_Data_Insert_Input;
}>;


export type AddHerdDataMutation = (
  { __typename?: 'mutation_root' }
  & { insert_herd_data_one?: Maybe<(
    { __typename?: 'herd_data' }
    & Pick<Herd_Data, 'avg_bw_3rdlact_cow' | 'avg_height_3rdlact_cow' | 'avg_birth_bw_calves' | 'avg_birth_height_calves' | 'avg_spc_heifers' | 'current_age_1st_calving' | 'goal_age_1st_calving' | 'herd_id'>
  )> }
);

export type AddHerdMutationVariables = Exact<{
  farm_id: Scalars['Int'];
  name: Scalars['String'];
}>;


export type AddHerdMutation = (
  { __typename?: 'mutation_root' }
  & { insert_herd_one?: Maybe<(
    { __typename?: 'herd' }
    & Pick<Herd, 'id' | 'name'>
  )> }
);

export type AddPensMutationVariables = Exact<{
  objects: Array<Pen_Insert_Input> | Pen_Insert_Input;
}>;


export type AddPensMutation = (
  { __typename?: 'mutation_root' }
  & { insert_pen?: Maybe<(
    { __typename?: 'pen_mutation_response' }
    & Pick<Pen_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'pen' }
      & Pick<Pen, 'id' | 'name' | 'farm_id'>
    )> }
  )> }
);

export type AddReportMutationVariables = Exact<{
  input_data: Scalars['jsonb'];
}>;


export type AddReportMutation = (
  { __typename?: 'mutation_root' }
  & { insert_report_one?: Maybe<(
    { __typename?: 'report' }
    & Pick<Report, 'id'>
  )> }
);

export type AddUserMutationVariables = Exact<{
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  role: Enum_User_Role_Enum;
}>;


export type AddUserMutation = (
  { __typename?: 'mutation_root' }
  & { insert_user_one?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, 'id' | 'last_name' | 'role' | 'first_name' | 'email'>
  )> }
);

export type BulkAddHeiferMeasurementsMutationVariables = Exact<{
  objects: Array<Heifer_Measurements_Insert_Input> | Heifer_Measurements_Insert_Input;
}>;


export type BulkAddHeiferMeasurementsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_heifer_measurements?: Maybe<(
    { __typename?: 'heifer_measurements_mutation_response' }
    & Pick<Heifer_Measurements_Mutation_Response, 'affected_rows'>
  )> }
);

export type BulkAddHeifersAndMeasurementsMutationVariables = Exact<{
  objects: Array<Heifer_Insert_Input> | Heifer_Insert_Input;
}>;


export type BulkAddHeifersAndMeasurementsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_heifer?: Maybe<(
    { __typename?: 'heifer_mutation_response' }
    & Pick<Heifer_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'heifer' }
      & Pick<Heifer, 'dob' | 'herd_id' | 'id' | 'name' | 'pen_id'>
      & { heifer_measurements: Array<(
        { __typename?: 'heifer_measurements' }
        & Pick<Heifer_Measurements, 'body_weight' | 'id' | 'heifer_id' | 'height' | 'herd_id' | 'mature_height' | 'mature_body_weight' | 'measurement_date' | 'pen_id'>
      )> }
    )> }
  )> }
);

export type BulkAddHeifersMutationVariables = Exact<{
  objects: Array<Heifer_Insert_Input> | Heifer_Insert_Input;
}>;


export type BulkAddHeifersMutation = (
  { __typename?: 'mutation_root' }
  & { insert_heifer?: Maybe<(
    { __typename?: 'heifer_mutation_response' }
    & Pick<Heifer_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'heifer' }
      & Pick<Heifer, 'dob' | 'herd_id' | 'id' | 'name' | 'pen_id'>
    )> }
  )> }
);

export type DeleteFarmMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteFarmMutation = (
  { __typename?: 'mutation_root' }
  & { delete_farm_by_pk?: Maybe<(
    { __typename?: 'farm' }
    & Pick<Farm, 'id'>
  )> }
);

export type DeleteHeiferMeasurementMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteHeiferMeasurementMutation = (
  { __typename?: 'mutation_root' }
  & { delete_heifer_measurements_by_pk?: Maybe<(
    { __typename?: 'heifer_measurements' }
    & Pick<Heifer_Measurements, 'id'>
  )> }
);

export type DeleteHeiferMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteHeiferMutation = (
  { __typename?: 'mutation_root' }
  & { delete_heifer_by_pk?: Maybe<(
    { __typename?: 'heifer' }
    & Pick<Heifer, 'id'>
  )> }
);

export type DeleteHerdDataMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteHerdDataMutation = (
  { __typename?: 'mutation_root' }
  & { delete_herd_data_by_pk?: Maybe<(
    { __typename?: 'herd_data' }
    & Pick<Herd_Data, 'id'>
  )> }
);

export type DeleteHerdMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteHerdMutation = (
  { __typename?: 'mutation_root' }
  & { delete_herd_by_pk?: Maybe<(
    { __typename?: 'herd' }
    & Pick<Herd, 'id'>
  )> }
);

export type DeletePenMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeletePenMutation = (
  { __typename?: 'mutation_root' }
  & { delete_pen_by_pk?: Maybe<(
    { __typename?: 'pen' }
    & Pick<Pen, 'id'>
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'mutation_root' }
  & { delete_user_by_pk?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, 'id'>
  )> }
);

export type GeneratePdfReportMutationVariables = Exact<{
  reportId: Scalars['String'];
  farmName: Scalars['String'];
}>;


export type GeneratePdfReportMutation = (
  { __typename?: 'mutation_root' }
  & { generatePdfReport?: Maybe<(
    { __typename?: 'GeneratePdfReportOutput' }
    & Pick<GeneratePdfReportOutput, 'url' | 's3Key'>
  )> }
);

export type SetUserFirstLoginMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type SetUserFirstLoginMutation = (
  { __typename?: 'mutation_root' }
  & { update_user_by_pk?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, 'id' | 'first_login_at'>
  )> }
);

export type UpdateFarmMutationVariables = Exact<{
  id: Scalars['Int'];
  _set: Farm_Set_Input;
}>;


export type UpdateFarmMutation = (
  { __typename?: 'mutation_root' }
  & { update_farm_by_pk?: Maybe<(
    { __typename?: 'farm' }
    & Pick<Farm, 'id' | 'address_line_1' | 'address_line_2' | 'city' | 'created_at' | 'manager_email' | 'manager_phone' | 'manager_name' | 'name' | 'state' | 'updated_at' | 'zipcode'>
  )> }
);

export type UpdateHeiferMeasurementDateAgeMutationVariables = Exact<{
  id: Scalars['uuid'];
  measurement_date_age: Scalars['numeric'];
}>;


export type UpdateHeiferMeasurementDateAgeMutation = (
  { __typename?: 'mutation_root' }
  & { update_heifer_measurements_by_pk?: Maybe<(
    { __typename?: 'heifer_measurements' }
    & Pick<Heifer_Measurements, 'body_weight' | 'created_at' | 'height' | 'id' | 'mature_body_weight' | 'mature_height' | 'measurement_date' | 'measurement_date_age' | 'pc_mature_body_weight' | 'pc_mature_height' | 'updated_at' | 'heifer_id' | 'herd_id' | 'pen_id'>
  )> }
);

export type UpdateHeiferMeasurementMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set: Heifer_Measurements_Set_Input;
}>;


export type UpdateHeiferMeasurementMutation = (
  { __typename?: 'mutation_root' }
  & { update_heifer_measurements_by_pk?: Maybe<(
    { __typename?: 'heifer_measurements' }
    & Pick<Heifer_Measurements, 'body_weight' | 'created_at' | 'heifer_id' | 'height' | 'herd_id' | 'id' | 'mature_body_weight' | 'mature_height' | 'measurement_date' | 'measurement_date_age' | 'pc_mature_body_weight' | 'pc_mature_height' | 'pen_id' | 'updated_at'>
  )> }
);

export type UpdateHeiferMutationVariables = Exact<{
  _set: Heifer_Set_Input;
  id: Scalars['uuid'];
}>;


export type UpdateHeiferMutation = (
  { __typename?: 'mutation_root' }
  & { update_heifer_by_pk?: Maybe<(
    { __typename?: 'heifer' }
    & Pick<Heifer, 'created_at' | 'dob' | 'herd_id' | 'id' | 'name' | 'updated_at' | 'pen_id'>
    & { heifer_measurements: Array<(
      { __typename?: 'heifer_measurements' }
      & Pick<Heifer_Measurements, 'measurement_date' | 'id'>
    )> }
  )> }
);

export type UpdatePenMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
}>;


export type UpdatePenMutation = (
  { __typename?: 'mutation_root' }
  & { update_pen_by_pk?: Maybe<(
    { __typename?: 'pen' }
    & Pick<Pen, 'name' | 'id'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['uuid'];
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  role?: Maybe<Enum_User_Role_Enum>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'mutation_root' }
  & { update_user_by_pk?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, 'id' | 'last_name' | 'role' | 'first_name' | 'email'>
  )> }
);

export type AllFarmsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllFarmsQuery = (
  { __typename?: 'query_root' }
  & { farms: Array<(
    { __typename?: 'farm' }
    & Pick<Farm, 'address_line_1' | 'address_line_2' | 'city' | 'id' | 'manager_email' | 'manager_name' | 'manager_phone' | 'name' | 'state' | 'updated_at' | 'zipcode' | 'created_at'>
    & { created_by?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, 'last_name' | 'first_name' | 'id'>
    )> }
  )> }
);

export type AllHerdDataOfHerdQueryVariables = Exact<{
  herd_id: Scalars['uuid'];
}>;


export type AllHerdDataOfHerdQuery = (
  { __typename?: 'query_root' }
  & { herd_data: Array<(
    { __typename?: 'herd_data' }
    & Pick<Herd_Data, 'adg_birth_56days' | 'avg_birth_bw_calves' | 'avg_birth_height_calves' | 'avg_bw_3rdlact_cow' | 'avg_height_3rdlact_cow' | 'avg_spc_heifers' | 'bw_heifer_1st_conception' | 'bw_heifer_1week_precalving' | 'bw_heifer_calving' | 'current_age_1st_calving' | 'goal_age_1st_calving' | 'height_heifer_1st_conception' | 'height_heifer_calving' | 'mintarget_calf_bw_56days' | 'mintarget_calf_height_56days' | 'id' | 'updated_at'>
    & { updated_by?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, 'first_name' | 'last_name'>
    )> }
  )> }
);

export type AllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllUsersQuery = (
  { __typename?: 'query_root' }
  & { user: Array<(
    { __typename?: 'user' }
    & Pick<User, 'id' | 'first_name' | 'last_name' | 'email' | 'role'>
  )> }
);

export type DataFarmsHerdsQueryVariables = Exact<{ [key: string]: never; }>;


export type DataFarmsHerdsQuery = (
  { __typename?: 'query_root' }
  & { farm: Array<(
    { __typename?: 'farm' }
    & Pick<Farm, 'id' | 'name' | 'manager_email' | 'manager_name' | 'manager_phone' | 'address_line_1' | 'address_line_2' | 'city' | 'state' | 'zipcode'>
    & { herds: Array<(
      { __typename?: 'herd' }
      & Pick<Herd, 'id' | 'name'>
    )> }
  )> }
);

export type HeiferAllMeasurementsQueryVariables = Exact<{
  _heifer_id: Scalars['uuid'];
}>;


export type HeiferAllMeasurementsQuery = (
  { __typename?: 'query_root' }
  & { heifer_measurements: Array<(
    { __typename?: 'heifer_measurements' }
    & Pick<Heifer_Measurements, 'body_weight' | 'created_at' | 'height' | 'id' | 'mature_body_weight' | 'mature_height' | 'measurement_date' | 'measurement_date_age' | 'pc_mature_body_weight' | 'pc_mature_height' | 'updated_at' | 'heifer_id' | 'herd_id' | 'pen_id'>
  )> }
);

export type HeifersOfHerdQueryVariables = Exact<{
  herd_id: Scalars['uuid'];
}>;


export type HeifersOfHerdQuery = (
  { __typename?: 'query_root' }
  & { heifer: Array<(
    { __typename?: 'heifer' }
    & Pick<Heifer, 'id' | 'name' | 'dob'>
    & { pen?: Maybe<(
      { __typename?: 'pen' }
      & Pick<Pen, 'id' | 'name'>
    )> }
  )> }
);

export type HerdAllHeiferMeasurementsQueryVariables = Exact<{
  _eq: Scalars['uuid'];
}>;


export type HerdAllHeiferMeasurementsQuery = (
  { __typename?: 'query_root' }
  & { heifer_measurements: Array<(
    { __typename?: 'heifer_measurements' }
    & Pick<Heifer_Measurements, 'body_weight' | 'created_at' | 'created_by_id' | 'heifer_id' | 'height' | 'herd_id' | 'id' | 'mature_body_weight' | 'mature_height' | 'measurement_date' | 'measurement_date_age' | 'pc_mature_body_weight' | 'pc_mature_height' | 'pen_id' | 'updated_at' | 'updated_by_id' | 'group_name'>
    & { heifer: (
      { __typename?: 'heifer' }
      & Pick<Heifer, 'id' | 'dob'>
    ), pen?: Maybe<(
      { __typename?: 'pen' }
      & Pick<Pen, 'id' | 'name'>
    )> }
  )> }
);

export type HerdDataOfHerdQueryVariables = Exact<{
  herd_id: Scalars['uuid'];
}>;


export type HerdDataOfHerdQuery = (
  { __typename?: 'query_root' }
  & { herd_data: Array<(
    { __typename?: 'herd_data' }
    & Pick<Herd_Data, 'adg_birth_56days' | 'avg_birth_bw_calves' | 'avg_birth_height_calves' | 'avg_bw_3rdlact_cow' | 'avg_height_3rdlact_cow' | 'avg_spc_heifers' | 'bw_heifer_1st_conception' | 'bw_heifer_1week_precalving' | 'bw_heifer_calving' | 'current_age_1st_calving' | 'goal_age_1st_calving' | 'height_heifer_1st_conception' | 'height_heifer_calving' | 'mintarget_calf_bw_56days' | 'mintarget_calf_height_56days' | 'id' | 'updated_at'>
  )> }
);

export type PensOfFarmQueryVariables = Exact<{
  farm_id: Scalars['Int'];
}>;


export type PensOfFarmQuery = (
  { __typename?: 'query_root' }
  & { pen: Array<(
    { __typename?: 'pen' }
    & Pick<Pen, 'id' | 'name'>
  )> }
);

export type TodayHeiferMeasurementsQueryVariables = Exact<{
  _herd_id: Scalars['uuid'];
  _updated_at: Scalars['timestamptz'];
}>;


export type TodayHeiferMeasurementsQuery = (
  { __typename?: 'query_root' }
  & { heifer_measurements: Array<(
    { __typename?: 'heifer_measurements' }
    & Pick<Heifer_Measurements, 'body_weight' | 'group_name' | 'created_by_id' | 'created_at' | 'heifer_id' | 'height' | 'herd_id' | 'id' | 'mature_body_weight' | 'mature_height' | 'measurement_date' | 'measurement_date_age' | 'pc_mature_body_weight' | 'pc_mature_height' | 'pen_id' | 'updated_at' | 'updated_by_id'>
  )> }
);

export type UserByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UserByIdQuery = (
  { __typename?: 'query_root' }
  & { user_by_pk?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, 'id' | 'role' | 'first_login_at' | 'first_name'>
  )> }
);
