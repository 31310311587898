import React, { useState } from 'react';
import { useQuery, useMutation, Reference } from '@apollo/client';
import { Button, message, Popconfirm } from 'antd';
import { DeleteOutlined, WarningFilled } from '@ant-design/icons';
import { loader } from 'graphql.macro';
import {
  Farm,
  PensOfFarmQuery,
  PensOfFarmQueryVariables,
  DeleteFarmMutation,
  DeleteFarmMutationVariables,
} from '../graphql/graphql-types';
import styles from './HerdDetailComponent.module.scss';
import { addressFormat, logger } from '../utils/helpers';

// This is the query for fetching all the pen id of selected herd
const pensOfHerdQuery = loader('../graphql/queries/pensOfFarmQuery.graphql');
// query used to delete herd
const deleteHerdMutation = loader('../graphql/mutations/deleteFarmMutation.graphql');

// This is the type of the props coming from the parent component
type HerdDetailComponentProps = {
  // this is the data of herd which is  to be shown
  herdDataToShow: {
    __typename?: 'farm' | undefined;
  } & Pick<
    Farm,
    | 'address_line_1'
    | 'address_line_2'
    | 'city'
    | 'id'
    | 'manager_email'
    | 'manager_name'
    | 'manager_phone'
    | 'name'
    | 'state'
    | 'zipcode'
  >;
  // used to update the state value of show drawer
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  // used to update the state value of selected herd id
  setSelectedHerdId: React.Dispatch<React.SetStateAction<number | undefined>>;
  // used to update the value of selected sub herd id
  setSelectedSubHerdId: React.Dispatch<React.SetStateAction<string>>;
};

// This is the main functional component
const HerdDetailComponent: React.FC<HerdDetailComponentProps> = (props) => {
  // destructuring props
  const { herdDataToShow, setShowDrawer, setSelectedSubHerdId, setSelectedHerdId } = props;
  // indicates whether to show loader on delete button
  const [showLoaderWhileDelete, setShowLoaderWhileDelete] = useState<boolean>(false);

  // fetching data of pens of selected herd
  const { data: pensOfHerd } = useQuery<PensOfFarmQuery, PensOfFarmQueryVariables>(
    pensOfHerdQuery,
    {
      variables: { farm_id: herdDataToShow.id },
    },
  );

  // This is the mutation data for deleting the herd data from graphql
  const [deleteHerd] = useMutation<DeleteFarmMutation, DeleteFarmMutationVariables>(
    deleteHerdMutation,
  );
  // This is the pens array of herd
  const penData = pensOfHerd?.pen;

  // separating pen Name from array of pens with ';' to display the names of pen
  let penNames = '';
  if (Array.isArray(penData) && penData.length > 0) {
    penData.forEach((pen, index) => {
      if (index === 0) {
        penNames = penNames.concat(pen.name);
      } else {
        penNames = penNames.concat('; ', pen.name);
      }
    });
  }

  // handleDelete is invoked when user hits the yes button from the pop confirm of delete button
  const handleDelete = () => {
    setShowLoaderWhileDelete(true);
    // this is the mutation in which we pass the id of the herd which is going to be delete
    deleteHerd({
      variables: {
        id: herdDataToShow.id,
      },
      // update function is used here to see the updated list immediately after deleting the herd
      update: (cache, { data: deletedData }) => {
        const idToDelete: number | undefined = deletedData?.delete_farm_by_pk?.id;
        // This is the modify function in which fields is one input where we get all the data of our cache and according to our need we apply field name and get ref of field
        cache.modify({
          fields: {
            /* inside fields we get all our fields of cache here we need to update farm field
               existingHerdRefs is the all farm present in our cache */
            farm(existingHerdRefs: Array<Reference>, { readField }) {
              if (idToDelete) {
                return existingHerdRefs.filter((herdRef) => {
                  // inside readField first parameter is key and the second parameter is object by this we get id of object
                  return idToDelete !== readField('id', herdRef);
                });
              }
              return existingHerdRefs;
            },
          },
        });
      },
    })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success(`${herdDataToShow.name} is successfully deleted`);
        setShowLoaderWhileDelete(false);
        setShowDrawer(false);
        setSelectedSubHerdId('');
        setSelectedHerdId(undefined);
      })
      .catch((error) => {
        logger(error);
        setShowLoaderWhileDelete(false);
        setShowDrawer(false);
      });
  };

  return (
    <div style={{ padding: 25 }}>
      {herdDataToShow.name ? (
        <div className={styles.individualDetail}>
          <span className={styles.label}>Herd Name:</span>
          <p className={styles.value}>{herdDataToShow.name}</p>
        </div>
      ) : null}
      {herdDataToShow.manager_name ? (
        <div className={styles.individualDetail}>
          <span className={styles.label}>Manager Name:</span>
          <p className={styles.value}>{herdDataToShow.manager_name}</p>
        </div>
      ) : null}
      {herdDataToShow.manager_email ? (
        <div className={styles.individualDetail}>
          <span className={styles.label}>Manager Email:</span>
          <p className={styles.value}>{herdDataToShow.manager_email}</p>
        </div>
      ) : null}
      {herdDataToShow.manager_phone ? (
        <div className={styles.individualDetail}>
          <span className={styles.label}>Manager Phone:</span>
          <p className={styles.value}>{herdDataToShow.manager_phone}</p>
        </div>
      ) : null}
      {herdDataToShow.address_line_1 ||
      herdDataToShow.address_line_2 ||
      herdDataToShow.city ||
      herdDataToShow.state ||
      herdDataToShow.zipcode ? (
        <div className={styles.individualDetail}>
          <span className={styles.label}>Address:</span>
          <p className={styles.value}>
            {addressFormat({
              addressLine1: herdDataToShow.address_line_1 ? herdDataToShow.address_line_1 : null,
              addressLine2: herdDataToShow.address_line_2 ? herdDataToShow.address_line_2 : null,
              city: herdDataToShow.city ? herdDataToShow.city : null,
              state: herdDataToShow.state ? herdDataToShow.state : null,
              zip: herdDataToShow.zipcode,
            })}
          </p>
        </div>
      ) : null}
      {penNames ? (
        <div className={styles.individualDetail}>
          <span className={styles.label}>Pen IDs:</span>
          <p className={styles.value}>{penNames}</p>
        </div>
      ) : null}
      <Popconfirm
        title="Delete Herd. Are you sure?"
        onConfirm={(): void => {
          handleDelete();
        }}
        icon={<WarningFilled style={{ color: '#ce0e2d' }} />}
        cancelText="No"
        okText="Yes"
      >
        <Button
          className="primary"
          icon={<DeleteOutlined />}
          style={{ marginLeft: 140, marginTop: 10, width: 120 }}
          loading={showLoaderWhileDelete}
        >
          Delete Herd
        </Button>
      </Popconfirm>
    </div>
  );
};

export default HerdDetailComponent;
