import React from 'react';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Scatter,
  Label,
  ReferenceLine,
  ReferenceDot,
  Area,
} from 'recharts';
import '../App.scss';
import { ChartDataPoints, ChartDataType } from '../utils/types';
import {
  assigningColorsToUniquePenIds,
  calErrBarLimit,
  CustomizedScatterPointShape,
  getXAxisTicksRange,
  handleLegendPayloadRender,
  renderChartLegend,
  renderDchaDataPoints,
  renderTicks,
  renderYAxisLabel,
} from '../utils/helpers';
import {
  heiferWeightErrRatio,
  purinaErrorRangeColor,
  purinaErrorRangeStrokeColor,
  weightHeightChartLegendPayload,
} from '../utils/globals';
import styles from './Charts.module.scss';

// type definition for component props
type HeiferWeightChartProps = ChartDataType & {
  // average body weight of 3rd lactation cow
  matureBodyWeight?: number;
};

// functional component
const HeiferWeightChart: React.FC<HeiferWeightChartProps> = ({
  psu2013Data,
  purinaModelData,
  subHerdHeiferData,
  historicalHeiferData,
  dchaData,
  xAxisMinInterval = 0,
  xAxisMaxInterval = 28,
  matureBodyWeight = 0,
}) => {
  // storing sub herd heifers data to display error bar for each data point having error limit
  const deviatedSubHerdHeiferData: ChartDataPoints = (subHerdHeiferData as ChartDataPoints).reduce(
    (filtered: ChartDataPoints, option) => {
      if (option.weight && option.weight <= 2000) {
        filtered.push({
          ...option,
          weightErrLimit: calErrBarLimit(option.weight, heiferWeightErrRatio),
        });
      }
      return filtered;
    },
    [],
  );

  // storing historical heifer data with error range for each data point to plot
  const deviatedHistoricalData: ChartDataPoints = (historicalHeiferData as ChartDataPoints).map(
    (data) => ({ ...data, weightErrLimit: calErrBarLimit(data.weight, heiferWeightErrRatio) }),
  );

  // calculates and stores the data of range for purina error area
  const purinaModelErrorRangeArea = (purinaModelData as ChartDataPoints)
    .map((data) => ({
      ...data,
      weightErrLimit: calErrBarLimit(data.weight, heiferWeightErrRatio),
    }))
    .map((data) => {
      // stores the value of weight
      const weight = !data.weight ? 0 : data.weight;
      return {
        range: [weight + data.weightErrLimit, weight - data.weightErrLimit],
        age: data.age,
      };
    });

  /* Variable to store merged chart data (historical and sub herd) */
  const mergedChartData = [...subHerdHeiferData, ...historicalHeiferData] as ChartDataPoints;

  /* Variable to store data for pen IDs with there corresponding colors to be shown in graph */
  const dataForPenIdsWithColors = assigningColorsToUniquePenIds(mergedChartData);

  return (
    <div className={styles.chartWrapper} style={{ margin: '16px 5% 40px 5%' }}>
      <div className={styles.chartTitle}>Heifer Weight Chart</div>
      <ResponsiveContainer id="heiferWeightChartContainer" width={1180} height={500}>
        <ComposedChart
          margin={{
            top: 5,
            right: 25,
            left: 20,
            bottom: 20,
          }}
          data={purinaModelErrorRangeArea}
        >
          <CartesianGrid stroke="#767676" />
          <XAxis
            // eslint-disable-next-line
            // @ts-ignore
            stroke="#000"
            ticks={renderTicks(
              xAxisMinInterval,
              getXAxisTicksRange(xAxisMaxInterval, xAxisMinInterval),
              2,
            )}
            type="number"
            allowDataOverflow
            domain={[xAxisMinInterval, xAxisMaxInterval]}
            interval={0}
            dataKey="age"
            orientation="bottom"
            label={{
              value: 'Age (months)',
              position: 'insideBottom',
              offset: -16,
              style: { fontWeight: 500, fontSize: 16, color: '#6E6E6E' },
            }}
            tick={{ fontSize: 12 }}
          />
          <YAxis
            // eslint-disable-next-line
            // @ts-ignore
            stroke="#000"
            yAxisId={0}
            interval={0}
            dataKey="weight"
            domain={['dataMin', 2000]}
            tickFormatter={(value: number) => {
              // renders the tick value which is multiple of 2, else hides that tick value
              return (value / 100) % 2 === 0 ? value.toString() : '';
            }}
            tick={{ fontSize: 12 }}
            ticks={renderTicks(0, 20, 100)}
            label={{
              value: 'Body Weight (lbs)',
              angle: -90,
              position: 'insideLeft',
              offset: -10,
              style: { fontWeight: 700, fontSize: 16, color: '#6E6E6E', margin: 40 },
            }}
          />
          <YAxis
            yAxisId={1}
            tick={{ fontSize: 12 }}
            orientation="right"
            // ** commented for future use
            // label={{
            //   value: 'Target for Breeding',
            //   position: 'center',
            //   style: { fontSize: 11, padding: 10, letterSpacing: 1.4 },
            // }}
            label={renderYAxisLabel({
              x: 1150,
              y: 275.5,
              label: (
                <>
                  <tspan x={1150} dy="0.355em" style={{ fontSize: 12 }}>
                    Target for
                  </tspan>
                  <tspan style={{ fontSize: 12 }} x={1150} y={275.5 + 20}>
                    Breeding
                  </tspan>
                </>
              ),
            })}
          />
          {/* <YAxis */}
          {/*  yAxisId={2} */}
          {/*  tickLine={false} */}
          {/*  axisLine={false} */}
          {/*  width={1} */}
          {/*  orientation="right" */}
          {/*  label={{ */}
          {/*    value: 'Percent of Mature Size', */}
          {/*    position: 'center', */}
          {/*    style: { */}
          {/*      fontSize: 16, */}
          {/*      fontWeight: 700, */}
          {/*      color: '#6e6e6e', */}
          {/*    }, */}
          {/*    angle: -90, */}
          {/*  }} */}
          {/* /> */}
          <YAxis
            yAxisId={2}
            tickLine={false}
            axisLine={false}
            width={1}
            orientation="right"
            // ** for future reference
            // label={renderYAxisLabel({
            //   x: 1100,
            //   y: 32,
            //   dy: 0,
            //   label: (
            //     <>
            //       <tspan x={1130} dy={`${0}em`} style={{ fontSize: 12 }}>
            //         Percent
            //       </tspan>
            //       <tspan style={{ fontSize: 12 }} x={1130} y={32 + 20}>
            //         of Mature
            //       </tspan>
            //       <tspan style={{ fontSize: 12 }} x={1130} y={52 + 20}>
            //         Size
            //       </tspan>
            //     </>
            //   ),
            // })}
            label={renderYAxisLabel({
              x: 0,
              y: 0,
              label: (
                <>
                  <tspan style={{ fontSize: 12 }} x={1130} y={22}>
                    Percent
                  </tspan>
                  <tspan style={{ fontSize: 12 }} x={1130} y={22 + 14}>
                    of Mature
                  </tspan>
                  <tspan style={{ fontSize: 12 }} x={1130} y={38 + 14}>
                    Size
                  </tspan>
                </>
              ),
            })}
          />
          <Legend
            wrapperStyle={{ alignItems: 'center', padding: '0 0 20px 0' }}
            margin={{ top: 20, bottom: 20 }}
            verticalAlign="top"
            iconSize={12}
            payload={handleLegendPayloadRender(
              weightHeightChartLegendPayload,
              psu2013Data,
              purinaModelData,
              subHerdHeiferData,
              historicalHeiferData,
            )}
            content={(props) => renderChartLegend({ ...props, data: dataForPenIdsWithColors })}
          />
          <Area
            type="monotone"
            dataKey="range"
            stroke={purinaErrorRangeStrokeColor}
            fill={purinaErrorRangeColor}
          />
          {renderDchaDataPoints(dchaData, matureBodyWeight).map((entry) => (
            <Line
              key={`${entry.percentStandardWeight as number}-${entry.displayValue}`}
              // eslint-disable-next-line
              // @ts-ignore
              data={[
                { age: entry.targetAge, weight: 0 },
                { age: entry.targetAge, weight: entry.percentStandardWeight },
              ]}
              dataKey="weight"
              name="DCHA Targets"
              type="monotone"
              stroke="#F20505"
              strokeWidth={2}
              legendType="none"
              isAnimationActive={false}
            />
          ))}
          {renderDchaDataPoints(dchaData, matureBodyWeight).map((entry) => (
            <ReferenceLine
              key={entry.displayValue}
              // eslint-disable-next-line
              // @ts-ignore
              label={{
                value: entry.displayValue,
                position: 'right',
                fontSize: 12,
              }}
              name="DCHA Standards"
              y={entry.percentStandardWeight}
              strokeWidth={1.6}
              stroke="#F20505"
              strokeDasharray="10 8"
            >
              <Label style={{ backgroundColor: 'red' }} />
            </ReferenceLine>
          ))}
          {renderDchaDataPoints(dchaData, matureBodyWeight).map((entry) => (
            <ReferenceDot
              r={3.4}
              isFront
              y={entry.percentStandardWeight}
              fill="yellow"
              stroke="red"
              x={0}
              strokeWidth={1.6}
            />
          ))}
          {renderDchaDataPoints(dchaData, matureBodyWeight).map((entry) => (
            <ReferenceDot
              isFront
              r={3.4}
              y={entry.percentStandardWeight}
              fill="yellow"
              stroke="red"
              x={xAxisMaxInterval}
              strokeWidth={1.6}
            />
          ))}
          <Line
            // eslint-disable-next-line
            // @ts-ignore
            data={psu2013Data}
            dataKey="weight"
            name="PSU 2013"
            legendType="plainline"
            dot={false}
            strokeWidth={2}
            type="monotone"
            stroke="#4E5F72"
            activeDot={false}
            isAnimationActive={false}
          />
          <Line
            // eslint-disable-next-line
            // @ts-ignore
            data={purinaModelData}
            dataKey="weight"
            name="Purina Model"
            legendType="plainline"
            dot={false}
            strokeWidth={2.8}
            type="monotone"
            stroke="#BE0001"
            activeDot={false}
            isAnimationActive={false}
          />

          <Scatter
            dataKey="weight"
            className=""
            shape={<CustomizedScatterPointShape type="subHerd" data={dataForPenIdsWithColors} />}
            legendType="circle"
            data={deviatedSubHerdHeiferData}
            fill="#000"
            strokeWidth={0.4}
            // sub-herd is replaced with current
            name="Current Heifers"
          />

          <Scatter
            dataKey="weight"
            className=""
            shape={<CustomizedScatterPointShape type="historical" data={dataForPenIdsWithColors} />}
            legendType="triangle"
            data={deviatedHistoricalData}
            fill="#1075e6"
            strokeWidth={0.4}
            // sub-herd information is replaced heifers
            name="Historical Heifers"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HeiferWeightChart;
