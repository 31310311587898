import * as firebase from 'firebase/app';
import 'firebase/auth';

// Typescript type for firebase config
type FirebaseConfig = {
  [key: string]: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId?: string;
  };
};

// Firebase config
const firebaseConfig: FirebaseConfig = {
  development: {
    apiKey: 'AIzaSyAOFMlHKndmex4nCC04DabRTVTbTZ0VYWU',
    authDomain: 'ecp30-development.firebaseapp.com',
    databaseURL: 'https://ecp30-development.firebaseio.com',
    projectId: 'ecp30-development',
    storageBucket: 'ecp30-development.appspot.com',
    messagingSenderId: '1099257941793',
    appId: '1:1099257941793:web:79118ec9e4ad95019a7860',
    measurementId: 'G-G89YP6H59J',
  },
  // staging: {},
  production: {
    apiKey: 'AIzaSyClM8lmMyFQ1TLJfqoRB4gxeUkf6eOK9Qo',
    authDomain: 'ecp30-production.firebaseapp.com',
    databaseURL: '',
    projectId: 'ecp30-production',
    storageBucket: 'ecp30-production.appspot.com',
    messagingSenderId: '266178463140',
    appId: '1:266178463140:web:56a10de526faeec804ed31',
  },
};

export const app = firebase.initializeApp(
  firebaseConfig[process.env.REACT_APP_ENVIRONMENT || 'development'],
);

export const firebaseAuth = firebase.auth();

export default firebase;
