import React, { CSSProperties } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { Row, Col } from 'antd';

// component props
type ECFormItemProps = {
  // form field label
  label?: string | JSX.Element;
  // rendering errors (if any)
  errors?: JSX.Element | null;
  // alignment of text label
  textAlign?: 'center' | 'end' | 'justify' | 'left' | 'match-parent' | 'right' | 'start';
  // adjusting span of label col
  labelSpan?: number;
  // adjusting span of wrapper col
  wrapperSpan?: number;
  // whether to render required asterisk mark on the left of the label
  requiredMark?: boolean;
  // custom css properties
  customStyle?: CSSProperties;
  /*  style which we pass from parent component */
  labelStyle?: React.CSSProperties;
} & ControllerProps<JSX.Element>;

// FC
const ECFormItem: React.FC<ECFormItemProps> = ({
  label,
  errors = null,
  textAlign = 'end',
  labelSpan = 18,
  wrapperSpan = 6,
  requiredMark = false,
  customStyle,
  labelStyle,
  ...controllerProps
}): JSX.Element => (
  <>
    <Row
      style={{ alignItems: 'center', padding: `${errors ? '10px' : '20px'} 0 0 0`, ...customStyle }}
    >
      {label ? (
        <Col
          className={requiredMark ? 'requiredField' : ''}
          span={labelSpan}
          style={labelStyle ? { textAlign, ...labelStyle } : { textAlign, paddingRight: 40 }}
        >
          {label}:
        </Col>
      ) : null}
      <Col span={wrapperSpan}>
        <Controller {...controllerProps} />
      </Col>
    </Row>
    {errors ? (
      <Row>
        <Col span={24} offset={label ? labelSpan : undefined}>
          {errors}
        </Col>
      </Row>
    ) : null}
  </>
);

export default ECFormItem;
