/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { firebaseAuth } from '../utils/firebase';

const authLink = setContext(async (_, { headers }) => {
  // return the headers to the context so httpLink can read them
  const newHeaders = { ...headers };

  if (firebaseAuth.currentUser) {
    const token = await firebaseAuth.currentUser.getIdToken();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    newHeaders.authorization = `Bearer ${token}`;
  }

  return {
    headers: newHeaders,
  };
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

export default client;
