import React, { useState } from 'react';
import { Button, Table, Popconfirm, Divider, message } from 'antd';
import { loader } from 'graphql.macro';
import { EditOutlined, DeleteOutlined, WarningFilled } from '@ant-design/icons';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import {
  PensOfFarmQuery,
  PensOfFarmQueryVariables,
  DeletePenMutation,
  DeletePenMutationVariables,
} from '../graphql/graphql-types';
import { TypeOfPenData } from '../utils/types';
import AddPensForm from '../forms/AddPensForm';
import { logger, renderWarningMessage } from '../utils/helpers';
import RenamePenForm from '../forms/RenamePenForm';

const pensOfFarmQuery = loader('../graphql/queries/pensOfFarmQuery.graphql');
const deletePenMutation = loader('../graphql/mutations/deletePenMutation.graphql');

// type definition for props of component
type PenDetailsComponentProps = {
  // indicates the value of selected herd id
  selectedHerdId: number;
};

const PenDetailsComponent: React.FC<PenDetailsComponentProps> = ({ selectedHerdId }) => {
  // This state holds whether to show rename pen modal or not
  const [showRenamePenModal, setShowRenamePenModal] = useState<boolean>(false);
  // This state holds the data of the pen when user hit the rename button this state updates their value
  const [penDataToEdit, setPenDataToEdit] = useState<TypeOfPenData | null>(null);
  // This is the state in which id of the pen is stored which is going to be delete and by checking the id we control the loader of delete button
  const [deletePenId, setDeletePenId] = useState<string>('');

  // obtains the data related to pens of herd
  const { data } = useQuery<PensOfFarmQuery, PensOfFarmQueryVariables>(pensOfFarmQuery, {
    variables: { farm_id: selectedHerdId },
  });

  // This is the mutation for deleting the pen data from graphql
  const [deletePen] = useMutation<DeletePenMutation, DeletePenMutationVariables>(deletePenMutation);

  // This function invoke when user hit the yes button of pop up confirm from pen table
  const handlePenDelete = (record: TypeOfPenData) => {
    // this is the mutation in which we pass the id of the pen which is going to be delete
    deletePen({
      variables: {
        id: record.id,
      },
      // update function is used here to see the updated list immediately after deleting the pen
      update: (cache, { data: deletedData }) => {
        const idOfPenToDelete: string | undefined = deletedData?.delete_pen_by_pk?.id;
        // This is the modify function in which fields is one input where we get all the data of our cache and according to our need we apply field name and get ref of field
        cache.modify({
          fields: {
            /* inside fields we get all our fields of cache, here we need to update pen field
             existingPenRefs consists all pens present in our cache */
            pen(existingPenRefs: Array<Reference>, { readField }) {
              if (idOfPenToDelete) {
                return existingPenRefs.filter((penRef) => {
                  // inside readField first parameter is key and the second parameter is object by this we get id of object
                  return idOfPenToDelete !== readField('id', penRef);
                });
              }
              return existingPenRefs;
            },
          },
        });
      },
    })
      .then(() => {
        const { name } = record;
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success(`${name} is successfully deleted`);
        setDeletePenId('');
      })
      .catch((error: ApolloError) => {
        logger(error);
        setDeletePenId('');
      });
  };

  // This is the data of pen
  const pens = data?.pen;

  return (
    <div>
      {showRenamePenModal && penDataToEdit ? (
        <RenamePenForm
          showRenamePenModal={showRenamePenModal}
          setShowRenamePenModal={setShowRenamePenModal}
          penDataToEdit={penDataToEdit}
          existingPens={pens}
        />
      ) : null}
      <AddPensForm herdId={selectedHerdId} existingPens={pens} />
      <Divider />
      {Array.isArray(pens) && pens.length > 0 ? (
        <Table<TypeOfPenData>
          dataSource={pens}
          bordered
          style={{ padding: '40px 0px', paddingTop: 10, width: 500, paddingLeft: 20 }}
          size="small"
          pagination={{
            defaultPageSize: 20,
            total: pens.length,
            size: 'small',
            position: ['bottomRight'],
          }}
        >
          <Table.Column<TypeOfPenData> title="Pen ID" dataIndex="name" key="name" align="center" />
          <Table.Column<TypeOfPenData>
            key="option"
            title="Options"
            align="center"
            dataIndex="option"
            render={(text, record) => {
              return (
                <div>
                  <Button
                    icon={<EditOutlined />}
                    className="primaryBtn"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      setPenDataToEdit(record);
                      setShowRenamePenModal(true);
                    }}
                  >
                    Rename
                  </Button>
                  <Popconfirm
                    title="Delete Pen. Are you sure?"
                    onConfirm={(): void => {
                      setDeletePenId(record.id);
                      handlePenDelete(record);
                    }}
                    icon={<WarningFilled style={{ color: '#ce0e2d' }} />}
                    cancelText="No"
                    okText="Yes"
                  >
                    <Button
                      className="secondaryBtn"
                      icon={<DeleteOutlined />}
                      loading={record.id === deletePenId}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                </div>
              );
            }}
          />
        </Table>
      ) : null}
      {!Array.isArray(pens) || pens.length === 0
        ? renderWarningMessage('No Pen is present in selected herd.', {
            marginLeft: 90,
          })
        : null}
    </div>
  );
};

export default PenDetailsComponent;
