import React from 'react';
import { Space, Table, InputNumber, Button } from 'antd';
import { UseFormMethods } from 'react-hook-form';
import { SubHerdSummaryCalcType } from '../utils/subHerdSummaryCalculations';
import ECFormItem from './ECFormItem';
import {
  DataRangeFormType,
  HeiferChartFormType,
  SubHerdSummaryAgeFilterOptions,
} from '../utils/types';
import { renderFormErr } from '../utils/helpers';

const { ColumnGroup, Column } = Table;

// component prop type
type SubHerdBasicSummaryTableProps = {
  // table data for sub herd summary
  tableData: SubHerdSummaryCalcType[];
  // form methods for analyze and data section
  formMethods: UseFormMethods<DataRangeFormType> | UseFormMethods<HeiferChartFormType>;
  // setState function to set applied baseAge & baseAgeErrDeviation input values
  setSubHerdSummaryAgeFilterOptions: React.Dispatch<
    React.SetStateAction<SubHerdSummaryAgeFilterOptions>
  >;
  // This prop is for hiding the age and month input field
  hideInputFields?: boolean;
};

const SubHerdBasicSummaryTable: React.FC<SubHerdBasicSummaryTableProps> = ({
  tableData,
  formMethods,
  setSubHerdSummaryAgeFilterOptions,
  hideInputFields = false,
}) => (
  <div>
    {hideInputFields ? null : (
      <Space
        align="start"
        style={{
          display: 'flex',
          alignItems: 'baseline',
          marginTop: 10,
        }}
      >
        <b>Age:</b>
        <ECFormItem
          rules={{
            required: true,
            validate: {
              baseAgeErr: (val: number) => {
                return (
                  val >=
                    (formMethods as UseFormMethods<DataRangeFormType>).getValues(
                      'baseAgeErrDeviation',
                    ) || 'Age cannot be less than age interval'
                );
              },
            },
          }}
          name="baseAge"
          control={formMethods.control}
          as={<InputNumber style={{ width: 55 }} min={0} max={28} />}
        />
        <div style={{ fontSize: 18 }}>±</div>
        <ECFormItem
          rules={{ required: true }}
          name="baseAgeErrDeviation"
          control={formMethods.control}
          render={({ onChange, value }) => (
            <InputNumber
              style={{ width: 55 }}
              value={value as number}
              min={0}
              max={14}
              onChange={(val) => {
                onChange(val);
              }}
            />
          )}
        />
        <div>months</div>
        <Button
          style={{ marginLeft: 10, padding: '0 20px' }}
          className="primaryBtn"
          onClick={async (): Promise<void> => {
            const isResolved = await formMethods.trigger(['baseAge', 'baseAgeErrDeviation']);
            if (isResolved) {
              setSubHerdSummaryAgeFilterOptions({
                baseAge: (formMethods as UseFormMethods<DataRangeFormType>).getValues('baseAge'),
                baseAgeErrDeviation: (formMethods as UseFormMethods<DataRangeFormType>).getValues(
                  'baseAgeErrDeviation',
                ),
              });
            }
          }}
        >
          Apply
        </Button>
        <Button
          onClick={(): void => {
            formMethods.setValue('baseAge', 4);
            formMethods.setValue('baseAgeErrDeviation', 1);
            formMethods.clearErrors(['baseAge', 'baseAgeErrDeviation']);
            setSubHerdSummaryAgeFilterOptions({
              baseAge: 4,
              baseAgeErrDeviation: 1,
            });
          }}
          className="secondaryBtn"
          style={{ border: '1px solid #000', padding: '0 20px', marginLeft: 20 }}
        >
          Reset
        </Button>
      </Space>
    )}

    {(formMethods.errors.baseAge && formMethods.errors.baseAge.type === 'required') ||
    (formMethods.errors.baseAgeErrDeviation &&
      formMethods.errors.baseAgeErrDeviation.type === 'required')
      ? renderFormErr('Enter values in both fields and try again.')
      : null}
    {formMethods.errors.baseAge && formMethods.errors.baseAge.type === 'baseAgeErr'
      ? renderFormErr(formMethods.errors.baseAge.message)
      : null}
    <Table<SubHerdSummaryCalcType>
      style={{ margin: '20px 0' }}
      bordered
      size="small"
      pagination={false}
      dataSource={tableData}
      rowKey={Math.random().toString(16).slice(2)}
    >
      <Column<SubHerdSummaryCalcType>
        title="No. of heifer measurements"
        align="center"
        dataIndex="measurementsCount"
        width={200}
      />
      <ColumnGroup title="Body Weight (lbs)">
        <Column<SubHerdSummaryCalcType>
          title="Average"
          align="center"
          dataIndex="avgWeight"
          render={(text, record) => {
            return record.avgWeight || record.avgWeight === 0 ? record.avgWeight.toFixed(0) : '-';
          }}
        />
        <Column<SubHerdSummaryCalcType>
          title="Min"
          align="center"
          dataIndex="minWeight"
          render={(text, record) => {
            return record.minWeight || record.minWeight === 0 ? record.minWeight.toFixed(0) : '-';
          }}
        />
        <Column<SubHerdSummaryCalcType>
          title="Max"
          align="center"
          dataIndex="maxWeight"
          render={(text, record) => {
            return record.maxWeight || record.maxWeight === 0 ? record.maxWeight.toFixed(0) : '-';
          }}
        />
        <Column<SubHerdSummaryCalcType>
          title="Median"
          align="center"
          dataIndex="medianWeight"
          render={(text, record) => {
            return record.medianWeight || record.medianWeight === 0
              ? record.medianWeight.toFixed(0)
              : '-';
          }}
        />
      </ColumnGroup>
      <ColumnGroup title="Height (inches)">
        <Column<SubHerdSummaryCalcType>
          title="Average"
          align="center"
          dataIndex="avgHeight"
          render={(text, record) => {
            return record.avgHeight || record.avgHeight === 0 ? record.avgHeight.toFixed(0) : '-';
          }}
        />
        <Column<SubHerdSummaryCalcType>
          title="Min"
          align="center"
          dataIndex="minHeight"
          render={(text, record) => {
            return record.minHeight || record.minHeight === 0 ? record.minHeight.toFixed(0) : '-';
          }}
        />
        <Column<SubHerdSummaryCalcType>
          title="Max"
          align="center"
          dataIndex="maxHeight"
          render={(text, record) => {
            return record.maxHeight || record.maxHeight === 0 ? record.maxHeight.toFixed(0) : '-';
          }}
        />
        <Column<SubHerdSummaryCalcType>
          title="Median"
          align="center"
          dataIndex="medianHeight"
          render={(text, record) => {
            return record.medianHeight || record.medianHeight === 0
              ? record.medianHeight.toFixed(0)
              : '-';
          }}
        />
      </ColumnGroup>
    </Table>
  </div>
);

export default SubHerdBasicSummaryTable;
