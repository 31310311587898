import moment from 'moment-timezone';
import { dateFormat } from './globals';
import { CalculatedHeifersDataType, HeiferDataFormType } from './types';

// helper function to calculate heifer's measurement statistics
const heiferDataCalculations = (
  // input form data for calculations
  formData: HeiferDataFormType,
  // heifer's dob from selected heifer
  heiferDob: Date | string | undefined,
  // average body weight of 3rd lactation cow
  avg_bw_3rdlact_cow: number | null | undefined,
  // average height for 3rd lactation cow
  avg_height_3rdlact_cow: number | null | undefined,
): CalculatedHeifersDataType => {
  // calculating precise age for date of measurement based on whether measurement date is ahead of heifer's dob
  const domAgeInMonths =
    formData.measurement_date && heiferDob
      ? (formData.measurement_date.diff(moment(heiferDob, dateFormat), 'days', true) + 1) / 30
      : undefined;
  // calculating percentage of mature body weight of heifer
  const percentMatureBodyWeight: number | null =
    (formData.body_weight && avg_bw_3rdlact_cow) ||
    (avg_bw_3rdlact_cow && formData.body_weight === 0)
      ? (formData.body_weight * 100) / avg_bw_3rdlact_cow
      : null;
  // calculating percentage of mature height of heifer
  const percentMatureHeight: number | null =
    formData.height || formData.height === 0
      ? (formData.height * 100) / (avg_height_3rdlact_cow as number)
      : null;

  return {
    measurement_date_age: {
      title: 'Age on date of measurement',
      value: domAgeInMonths,
      displayValue: domAgeInMonths ? `${domAgeInMonths.toFixed(2)} months` : '-',
    },
    pc_mature_body_weight: {
      title: '% Mature Body Weight',
      value: percentMatureBodyWeight,
      displayValue:
        percentMatureBodyWeight || percentMatureBodyWeight === 0
          ? `${percentMatureBodyWeight.toFixed(2)} %`
          : '-',
    },
    pc_mature_height: {
      title: '% Mature Height',
      value: percentMatureHeight,
      displayValue:
        percentMatureHeight || percentMatureHeight === 0
          ? `${percentMatureHeight.toFixed(2)} %`
          : '-',
    },
  };
};

export default heiferDataCalculations;
