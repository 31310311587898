import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ApolloError, useMutation } from '@apollo/client';
import { Button, Input, message, Modal } from 'antd';
import { loader } from 'graphql.macro';
import { Pen, UpdatePenMutation, UpdatePenMutationVariables } from '../graphql/graphql-types';
import styles from './UserForm.module.scss';
import { ExistingPensType, TypeOfPenData } from '../utils/types';
import { logger } from '../utils/helpers';

const updatePenMutation = loader('../graphql/mutations/updatePenMutation.graphql');

// This is the type of props coming from the parent component
type RenamePenFormProps = {
  // showRenamePenModal holds the value of visibility of modal
  showRenamePenModal: boolean;
  // This prop updates and handles the visibility of modal
  setShowRenamePenModal: React.Dispatch<React.SetStateAction<boolean>>;
  // data of pen which is going to be edit
  penDataToEdit: TypeOfPenData;
  /* Existing pens data */
  existingPens: ExistingPensType;
};

// This is the type of form to update pen
type RenamePenFormType = Pick<Pen, 'name'>;

// This is the main functional component
const RenamePenForm: React.FC<RenamePenFormProps> = ({
  showRenamePenModal,
  setShowRenamePenModal,
  penDataToEdit,
  existingPens,
}) => {
  // In this methods variable all the function of use form is contain
  const methods = useForm<RenamePenFormType>();
  // This state holds the loading indication of the save button
  const [isSaveButtonLoading, setIsSaveButtonLoading] = React.useState<boolean>(false);

  // This mutation is used to update the name of the pen
  const [updatePen] = useMutation<UpdatePenMutation, UpdatePenMutationVariables>(updatePenMutation);

  // This function invokes when user hit the save button
  const onSubmit = (data: RenamePenFormType) => {
    /* Variable to check if pen with the given name exists or not */
    const isPenExists = existingPens
      ? existingPens.find((item) => item.name === data.name)
      : undefined;

    if (isPenExists) {
      methods.setError('name', {
        message: `Following Pen(s) already exist : ${data.name}. Please enter unique pen names and try again`,
      });
      return;
    }

    setIsSaveButtonLoading(true);
    // Here we are updating the name of the pen
    updatePen({
      variables: {
        id: penDataToEdit.id,
        name: data.name,
      },
    })
      .then(() => {
        setIsSaveButtonLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Pen is successfully updated.');
        setShowRenamePenModal(false);
      })
      .catch((error: ApolloError) => {
        logger(error);
        setIsSaveButtonLoading(false);
        setShowRenamePenModal(false);
      });
  };

  return (
    <Modal
      centered
      title={<div style={{ fontSize: 18 }}>Rename Pen</div>}
      visible={showRenamePenModal}
      footer={[
        <Button
          key="1"
          onClick={(): void => {
            setShowRenamePenModal(false);
          }}
        >
          Cancel
        </Button>,
        <Button
          key="2"
          onClick={methods.handleSubmit(onSubmit)}
          className="tintColoredButton"
          loading={isSaveButtonLoading}
        >
          Save
        </Button>,
      ]}
      bodyStyle={{ borderRadius: 5 }}
      onCancel={() => {
        setShowRenamePenModal(false);
        methods.reset();
      }}
      destroyOnClose
    >
      <div style={{ paddingBottom: 20 }}>
        <div className={styles.inputContainer} style={{ paddingTop: 25 }}>
          <div className="requiredField">Pen ID:</div>
          <Controller
            className={styles.inputField}
            name="name"
            defaultValue={penDataToEdit.name}
            rules={{ required: 'Please enter name of pen and try again' }}
            control={methods.control}
            as={<Input placeholder="Please enter name of pen" />}
          />
        </div>
        {methods.errors.name ? (
          <p className={styles.errorText} style={{ paddingLeft: 175, margin: 0 }}>
            {methods.errors.name.message}
          </p>
        ) : null}
      </div>
    </Modal>
  );
};

export default RenamePenForm;
