import React, { useEffect, useState } from 'react';
import { Button, Col, InputNumber, message, Row, Space } from 'antd';
import { UseFormMethods } from 'react-hook-form';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import commonStyles from '../screens/HeifersOfSubHerdScreen.module.scss';
import ECFormItem from '../components/ECFormItem';
import styles from '../screens/AddSubHerdDataScreen.module.scss';
import { logger } from '../utils/helpers';
import {
  AddHerdDataMutation,
  AddHerdDataMutationVariables,
  Herd_Data_Avg_Fields,
  HerdDataOfHerdQuery,
  HerdDataOfHerdQueryVariables,
} from '../graphql/graphql-types';
import subHerdDataCalculations, {
  CalculatedSubHerdDataType,
} from '../utils/subHerdDataCalculations';
import { validationRegex } from '../utils/globals';

// importing addSubHerdData mutation
const addSubHerdDataMutation = loader('../graphql/mutations/addHerdDataMutation.graphql');
// importing sub herdDataOfHerd query
const subHerdDataOfSubHerdQuery = loader('../graphql/queries/herdDataOfHerdQuery.graphql');

// component props
type SubHerdDataFormProps = {
  // selected sub herdId for a specific selected herd
  selectedSubHerdId: string;
  // layout direction for sub herdDataForm and calculated fields columns
  layoutDirection: 'horizontal' | 'vertical';
  // setState function for toggling next btn as active or disabled (update sub herd data)
  setNextBtnDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  // using the current state's value for checking if next btn is disabled
  nextBtnDisabled?: boolean;
  // setState function to close the edit sub herd data drawer (edit sub herd data)
  setDrawerVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  // align text for form item label (edit sub herd data)
  textAlign?: 'center' | 'end' | 'justify' | 'left' | 'match-parent' | 'right' | 'start';
  // setState function to set sub herd data form values (fetched or updated)
  setSubHerdDataFormValues?: React.Dispatch<React.SetStateAction<Herd_Data_Avg_Fields>>;
  // sub herdDataForm methods
  subHerdDataFormMethods: UseFormMethods<Herd_Data_Avg_Fields>;
  // mode of add / edit btn to render specific content
  btnMode?: 'add' | 'edit' | null;
};

// FC
const SubHerdDataForm: React.FC<SubHerdDataFormProps> = ({
  textAlign,
  setDrawerVisible,
  setNextBtnDisabled,
  nextBtnDisabled,
  selectedSubHerdId,
  layoutDirection = 'horizontal',
  setSubHerdDataFormValues,
  subHerdDataFormMethods,
  btnMode = null,
}): JSX.Element => {
  // storing calculated sub herd data
  const calculatedSubHerdData: CalculatedSubHerdDataType = subHerdDataCalculations(
    subHerdDataFormMethods.watch(),
  );

  // storing the loading state of 'Add sub herd data' or 'Update sub herd data' btn when form is submit
  const [submitBtnLoading, setSubmitBtnLoading] = useState<boolean>(false);

  // mutation function for updating edited sub herd data
  const [addSubHerdData] = useMutation<AddHerdDataMutation, AddHerdDataMutationVariables>(
    addSubHerdDataMutation,
  );

  // fetching latest sub herds statistics for a selected sub herd using selectedSubHerdId
  useQuery<HerdDataOfHerdQuery, HerdDataOfHerdQueryVariables>(subHerdDataOfSubHerdQuery, {
    variables: {
      herd_id: selectedSubHerdId,
    },
    fetchPolicy: 'network-only',
    // callback when query executes success
    onCompleted: ({ herd_data: subHerdData }) => {
      // storing sub herd statistics with the most recent timestamp
      const latestSubHerdData = subHerdData[0];
      // checking if existing data is present to pre-fill form fields
      if (latestSubHerdData) {
        if (setSubHerdDataFormValues) {
          // setting this state when pre-fetched information is available
          setSubHerdDataFormValues({
            avg_bw_3rdlact_cow: latestSubHerdData.avg_bw_3rdlact_cow as number,
            avg_height_3rdlact_cow: latestSubHerdData.avg_height_3rdlact_cow as number,
            avg_birth_bw_calves: latestSubHerdData.avg_birth_bw_calves as number,
            avg_birth_height_calves: latestSubHerdData.avg_birth_height_calves as number,
            avg_spc_heifers: latestSubHerdData.avg_spc_heifers as number,
            current_age_1st_calving: latestSubHerdData.current_age_1st_calving as number,
            goal_age_1st_calving: latestSubHerdData.goal_age_1st_calving as number,
          });
        }
        subHerdDataFormMethods.setValue('avg_bw_3rdlact_cow', latestSubHerdData.avg_bw_3rdlact_cow);
        subHerdDataFormMethods.setValue(
          'avg_height_3rdlact_cow',
          latestSubHerdData.avg_height_3rdlact_cow,
        );
        subHerdDataFormMethods.setValue(
          'avg_birth_bw_calves',
          latestSubHerdData.avg_birth_bw_calves,
        );
        subHerdDataFormMethods.setValue(
          'avg_birth_height_calves',
          latestSubHerdData.avg_birth_height_calves,
        );
        subHerdDataFormMethods.setValue('avg_spc_heifers', latestSubHerdData.avg_spc_heifers);
        subHerdDataFormMethods.setValue(
          'current_age_1st_calving',
          latestSubHerdData.current_age_1st_calving,
        );
        subHerdDataFormMethods.setValue(
          'goal_age_1st_calving',
          latestSubHerdData.goal_age_1st_calving,
        );
      } else {
        // resetting form data when existing data is not present
        subHerdDataFormMethods.reset();
      }
    },
  });

  // componentDidMount (setting next btn state on every render)
  useEffect((): void => {
    if (setNextBtnDisabled) {
      // checking whether to disable next btn when a form field in sub herd data form is empty or null
      const isFormFieldEmpty: boolean = Object.values(subHerdDataFormMethods.watch()).some(
        (value: number | null | undefined | 'herd_data_avg_fields' | string) =>
          value === null || value === '',
      );
      // checking whether the current state of next btn with form field's values
      if (nextBtnDisabled !== isFormFieldEmpty) {
        setNextBtnDisabled(isFormFieldEmpty);
      }
    }
  });

  // handling form submission
  const onSubmit = (formData: Herd_Data_Avg_Fields): void => {
    setSubmitBtnLoading(true);
    // setting obtained form values to pass to AddHeiferDataScreen
    if (setSubHerdDataFormValues) {
      setSubHerdDataFormValues(formData);
    }
    // triggering mutation
    addSubHerdData({
      variables: {
        object: { ...formData, herd_id: selectedSubHerdId },
      },
      update: (cache, { data: cachedData }): void => {
        // syncing updated sub herd data with apollo cache
        const updatedSubHerdData = cachedData?.insert_herd_data_one;
        cache.modify({
          fields: {
            herd_data(subHerdDataRef: Array<Reference>) {
              return [...subHerdDataRef, updatedSubHerdData];
            },
          },
        });
      },
    })
      .then((): void => {
        setSubmitBtnLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success(
          `Sub-herd information has been successfully ${btnMode === 'add' ? 'added' : 'updated'}`,
        );
        if (layoutDirection === 'vertical' && setDrawerVisible) {
          setDrawerVisible(false);
        }
      })
      .catch((addSubHerdDataError: ApolloError): void => {
        logger(addSubHerdDataError);
        setSubmitBtnLoading(false);
      });
  };

  return (
    <div>
      <Space
        direction={layoutDirection}
        style={{ marginTop: 20 }}
        size={layoutDirection === 'horizontal' ? 280 : 30}
      >
        <div>
          <span style={{ fontSize: 22 }}>
            <b style={{ color: '#292929' }}>Herd Information</b>{' '}
            <i style={{ fontSize: 16 }}>(all fields are compulsory)</i>
          </span>
          <form
            className={commonStyles.formContainer}
            onSubmit={subHerdDataFormMethods.handleSubmit(onSubmit)}
          >
            <ECFormItem
              textAlign={textAlign}
              rules={{
                required: 'Please enter avg. body weight of 3rd lactation cows and try again',
                min: {
                  value: 700,
                  message: 'Min possible value for mature body weight is 700',
                },
                max: {
                  value: 2300,
                  message: 'Max possible value for mature body weight is 2300',
                },
              }}
              control={subHerdDataFormMethods.control}
              label="Avg. body weight of 3rd lactation cows (lbs)"
              name="avg_bw_3rdlact_cow"
              as={<InputNumber precision={0} step={1} className={styles.subHerdFormField} />}
              errors={
                subHerdDataFormMethods.errors.avg_bw_3rdlact_cow ? (
                  <span
                    style={{
                      display: 'flex',
                      width: layoutDirection === 'vertical' ? 150 : 'inherit',
                    }}
                    className={styles.errorContainer}
                  >
                    {subHerdDataFormMethods.errors.avg_bw_3rdlact_cow.message}
                  </span>
                ) : null
              }
            />
            <ECFormItem
              textAlign={textAlign}
              rules={{
                required: 'Please enter avg. height of 3rd lactation cows and try again',
                pattern: {
                  value: validationRegex.decimal.value,
                  message: validationRegex.decimal.msg,
                },
                min: {
                  value: 45,
                  message: 'Min possible value for mature height is 45',
                },
                max: {
                  value: 65,
                  message: 'Max possible value for mature height is 65',
                },
              }}
              label="Avg. height of 3rd lactation cows (inches)"
              name="avg_height_3rdlact_cow"
              control={subHerdDataFormMethods.control}
              as={<InputNumber precision={2} step={0.01} className={styles.subHerdFormField} />}
              errors={
                subHerdDataFormMethods.errors.avg_height_3rdlact_cow ? (
                  <span
                    className={styles.errorContainer}
                    style={{
                      display: 'flex',
                      width: layoutDirection === 'vertical' ? 150 : 'inherit',
                    }}
                  >
                    {subHerdDataFormMethods.errors.avg_height_3rdlact_cow.message}
                  </span>
                ) : null
              }
            />
            <ECFormItem
              textAlign={textAlign}
              rules={{
                required: 'Please enter avg. birth body weight of calves and try again',
                min: {
                  value: 30,
                  message: 'Min possible value for birth body weight is 30',
                },
                max: {
                  value: 150,
                  message: 'Max possible value for birth body weight is 150',
                },
              }}
              label="Avg. birth body weight of calves (lbs)"
              name="avg_birth_bw_calves"
              control={subHerdDataFormMethods.control}
              as={<InputNumber precision={0} step={1} className={styles.subHerdFormField} />}
              errors={
                subHerdDataFormMethods.errors.avg_birth_bw_calves ? (
                  <span
                    style={{
                      display: 'flex',
                      width: layoutDirection === 'vertical' ? 150 : 'inherit',
                    }}
                    className={styles.errorContainer}
                  >
                    {subHerdDataFormMethods.errors.avg_birth_bw_calves.message}
                  </span>
                ) : null
              }
            />
            <ECFormItem
              textAlign={textAlign}
              control={subHerdDataFormMethods.control}
              rules={{
                required: 'Please enter avg. birth height of calves and try again',
                pattern: {
                  value: validationRegex.decimal.value,
                  message: validationRegex.decimal.msg,
                },
                min: {
                  value: 24,
                  message: 'Min possible value for birth height is 24',
                },
                max: {
                  value: 36,
                  message: 'Max possible value for birth height is 36',
                },
              }}
              label="Avg. birth height of calves (inches)"
              name="avg_birth_height_calves"
              as={<InputNumber precision={2} step={0.01} className={styles.subHerdFormField} />}
              errors={
                subHerdDataFormMethods.errors.avg_birth_height_calves ? (
                  <span
                    style={{
                      display: 'flex',
                      width: layoutDirection === 'vertical' ? 150 : 'inherit',
                    }}
                    className={styles.errorContainer}
                  >
                    {subHerdDataFormMethods.errors.avg_birth_height_calves.message}
                  </span>
                ) : null
              }
            />
            <ECFormItem
              textAlign={textAlign}
              control={subHerdDataFormMethods.control}
              rules={{
                required: 'Please enter avg. services per conception for heifers and try again',
                pattern: {
                  value: validationRegex.decimal.value,
                  message: validationRegex.decimal.msg,
                },
                min: {
                  value: 1,
                  message: 'Min possible value for services/conception is 1',
                },
                max: {
                  value: 4,
                  message: 'Max possible value for services/conception is 4',
                },
              }}
              label="Avg. services per conception for heifers"
              name="avg_spc_heifers"
              as={<InputNumber precision={1} step={0.1} className={styles.subHerdFormField} />}
              errors={
                subHerdDataFormMethods.errors.avg_spc_heifers ? (
                  <span
                    style={{
                      display: 'flex',
                      width: layoutDirection === 'vertical' ? 150 : 'inherit',
                    }}
                    className={styles.errorContainer}
                  >
                    {subHerdDataFormMethods.errors.avg_spc_heifers.message}
                  </span>
                ) : null
              }
            />
            <ECFormItem
              textAlign={textAlign}
              control={subHerdDataFormMethods.control}
              rules={{
                required: 'Please enter current age of first calving and try again',
                pattern: {
                  value: validationRegex.decimal.value,
                  message: validationRegex.decimal.msg,
                },
                min: {
                  value: 18,
                  message: 'Min possible value for current calving age is 18',
                },
                max: {
                  value: 32,
                  message: 'Max possible value for current calving age is 32',
                },
              }}
              label="Current age of first calving (months)"
              name="current_age_1st_calving"
              as={<InputNumber precision={1} step={0.1} className={styles.subHerdFormField} />}
              errors={
                subHerdDataFormMethods.errors.current_age_1st_calving ? (
                  <span
                    style={{
                      display: 'flex',
                      width: layoutDirection === 'vertical' ? 150 : 'inherit',
                    }}
                    className={styles.errorContainer}
                  >
                    {subHerdDataFormMethods.errors.current_age_1st_calving.message}
                  </span>
                ) : null
              }
            />
            <ECFormItem
              textAlign={textAlign}
              control={subHerdDataFormMethods.control}
              rules={{
                required: 'Please enter goal for age of first calving and try again',
                pattern: {
                  value: validationRegex.decimal.value,
                  message: validationRegex.decimal.msg,
                },
                min: {
                  value: 18,
                  message: 'Min possible value for goal calving age is 18',
                },
                max: {
                  value: 30,
                  message: 'Max possible value for goal calving age is 30',
                },
              }}
              label="Goal for age of first calving (months)"
              name="goal_age_1st_calving"
              as={
                <InputNumber
                  autoFocus
                  step={0.1}
                  precision={1}
                  className={styles.subHerdFormField}
                />
              }
              errors={
                subHerdDataFormMethods.errors.goal_age_1st_calving ? (
                  <span
                    style={{
                      display: 'flex',
                      width: layoutDirection === 'vertical' ? 150 : 'inherit',
                    }}
                    className={styles.errorContainer}
                  >
                    {subHerdDataFormMethods.errors.goal_age_1st_calving.message}
                  </span>
                ) : null
              }
            />
          </form>
        </div>
        <div>
          <span style={{ fontSize: 22 }}>
            <b style={{ color: '#292929' }}>Targets based on DCHA recommendations</b>
          </span>
          {calculatedSubHerdData
            ? Object.values(calculatedSubHerdData).map((item) => (
                <Row
                  key={item.title}
                  style={{ padding: `20px 0 3px 0` }}
                  className={styles.calculatedDataContainer}
                >
                  <Col span={19} style={{ paddingRight: 40, textAlign: 'left', color: '#333' }}>
                    {item.title}
                  </Col>
                  <Col style={{ color: '#666' }} span={5}>
                    {item.displayValue}
                  </Col>
                </Row>
              ))
            : null}
        </div>
      </Space>
      <Row
        className={styles.btnContainer}
        style={{
          display: layoutDirection === 'vertical' ? 'flex' : 'inherit',
          justifyContent: layoutDirection === 'vertical' ? 'center' : 'inherit',
        }}
      >
        <Col
          style={{ paddingLeft: layoutDirection === 'horizontal' ? 25 : '' }}
          offset={layoutDirection === 'horizontal' ? 6 : 0}
        >
          <Button
            type="primary"
            onClick={subHerdDataFormMethods.handleSubmit(onSubmit)}
            className={commonStyles.addHeiferBtn}
            disabled={submitBtnLoading}
            loading={submitBtnLoading}
          >
            {btnMode === 'add' ? 'Add' : 'Update'} Sub-herd Information
          </Button>
        </Col>
        {layoutDirection === 'vertical' ? (
          <Col style={{ paddingLeft: 20 }}>
            <Button
              className="secondaryBtn"
              style={{ width: 220 }}
              onClick={() => {
                // closing the edit sub herd data drawer
                if (setDrawerVisible) {
                  setDrawerVisible(false);
                  if (btnMode === 'add') {
                    subHerdDataFormMethods.reset();
                  }
                }
              }}
            >
              Cancel
            </Button>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default SubHerdDataForm;
