import { Affix, Button, Input, Collapse } from 'antd';
import React, { useEffect, useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { loader } from 'graphql.macro';
import { ApolloError, useMutation } from '@apollo/client';
import { UseFormMethods, Controller, useForm } from 'react-hook-form';
import { saveAs } from 'file-saver';
import {
  AddReportMutation,
  AddReportMutationVariables,
  GeneratePdfReportMutation,
  GeneratePdfReportMutationVariables,
  Herd_Data_Avg_Fields,
} from '../graphql/graphql-types';
import { SubHerdSummaryCalcType } from '../utils/subHerdSummaryCalculations';
import {
  ChartDataPoints,
  DataRangeFormType,
  DchaDataSeries,
  HeiferChartFormType,
  SubHerdSummaryAgeFilterOptions,
  LineChartDataType,
  ScatterChartDataType,
  UserInputDataType,
  GenerateReportForm,
} from '../utils/types';
import styles from '../forms/UserForm.module.scss';
import ViewLatestSubHerdDataSection from './ViewLatestSubHerdDataSection';
import SubHerdBasicSummaryTable from './SubHerdBasicSummaryTable';
import HeiferWeightChart from '../charts/HeiferWeightChart';
import HeiferHeightChart from '../charts/HeiferHeightChart';
import PercentMatureWeightHeightChart from '../charts/PercentMatureWeightHeightChart';
import { firebaseAuth } from '../utils/firebase';
import { logger } from '../utils/helpers';
import ReportSectionHeader from './ReportSectionHeader';
import { dateFormat } from '../utils/globals';

const addReportMutation = loader('../graphql/mutations/addReportMutation.graphql');
const generatePdfReportMutation = loader('../graphql/mutations/generatePdfReportMutation.graphql');

// This is the types of props coming from parent component
type GenerateReportComponentProps = {
  // This is the type of data which is entered by user for sub herd
  userInputData: UserInputDataType;
  // This is the data of sub herd average field
  avgInputDataFields: Herd_Data_Avg_Fields | undefined;
  // table data for sub herd summary table
  tableData: SubHerdSummaryCalcType[];
  // This is the methods of form
  formMethods: UseFormMethods<DataRangeFormType> | UseFormMethods<HeiferChartFormType>;
  // This is for updating the value of age and age error range
  setSubHerdSummaryAgeFilterOptions: React.Dispatch<
    React.SetStateAction<SubHerdSummaryAgeFilterOptions>
  >;
  // sub herd summary table age filter data
  subHerdSummaryAgeFilterOptions: SubHerdSummaryAgeFilterOptions;
  // PSU 2013 line weight chart data
  heiferWeightChartPsuData: ChartDataPoints;
  // sub herdHeifer scatter weight chart data
  subHerdHeifersDataWeightChartDataPoints: ChartDataPoints;
  // purina model line weight chart data
  heiferWeightChartPurinaModelData: {
    // heifer's age
    age: number;
    // heifer's weight
    weight: number;
  }[];
  // historical heifer scatter weight chart data
  historicalHeiferDataWeightChartDataPoints: ChartDataPoints;
  // DCHA data for DCHA target plots and DCHA standard reference lines
  heiferWeightChartDchaData: DchaDataSeries[];
  // x-axis minimum age interval for all charts
  xAxisMinInterval: number | undefined;
  // x-axis maximum age interval for all charts
  xAxisMaxInterval: number | undefined;
  // This is the mature body weight value
  matureBodyWeight: number | undefined;
  // PSU 2013 line height chart data
  heiferHeightChartPsuData: ChartDataPoints;
  // purina model line height chart data
  heiferHeightChartPurinaModelData: ChartDataPoints;
  // sub herdHeifer scatter height chart data
  subHerdHeifersDataHeightChartDataPoints: ChartDataPoints;
  // historical heifer scatter height chart data
  historicalHeiferDataHeightChartDataPoints: ChartDataPoints;
  // DCHA data for DCHA target plots and DCHA standard reference lines
  heiferHeightChartDchaData: DchaDataSeries[];
  // This is the mature height value
  matureHipHeight: number;
  // PSU 2013 line pc mature weight height chart data
  pcMatureWeightHeightChartPsuData: LineChartDataType[];
  // purina model line pc mature weight height chart data
  pcMatureWeightHeightChartPurinaData: LineChartDataType[];
  // sub herdHeifer scatter pc mature weight height chart data
  subHerdHeiferDataPcMature: ChartDataPoints | ScatterChartDataType[];
  // historical heifer scatter  pc mature weight height chart data
  pcWeightHeightChartHistoricalData: ScatterChartDataType[];
  // DCHA data for DCHA target plots and DCHA standard reference lines
  pcMatureWeightHeightDchaData: (
    | {
        // percentage of DCHA standard weight
        percentStandardWeight: number;
        // percentage of DCHA standard height
        percentStandardHeight: number;
      }
    | {
        // percentage of DCHA standard weight
        percentStandardWeight: number;
        // percentage of DCHA standard height
        percentStandardHeight: undefined;
      }
  )[];
  // This function is invoke when user hit the back button
  onClickBackButton: () => void;
  // uuid of selected sub herd
  selectedSubHerdId: string;
  // name of selected herd
  selectedHerdName?: string;
};

// This is the main functional component
const GenerateReportComponent: React.FC<GenerateReportComponentProps> = (props) => {
  // destructuring of props
  const {
    userInputData,
    avgInputDataFields,
    tableData,
    formMethods,
    setSubHerdSummaryAgeFilterOptions,
    heiferWeightChartPsuData,
    subHerdHeifersDataWeightChartDataPoints,
    heiferWeightChartPurinaModelData,
    historicalHeiferDataWeightChartDataPoints,
    heiferWeightChartDchaData,
    xAxisMinInterval,
    xAxisMaxInterval,
    matureBodyWeight,
    heiferHeightChartPsuData,
    heiferHeightChartPurinaModelData,
    subHerdHeifersDataHeightChartDataPoints,
    historicalHeiferDataHeightChartDataPoints,
    heiferHeightChartDchaData,
    matureHipHeight,
    pcMatureWeightHeightChartPsuData,
    pcMatureWeightHeightChartPurinaData,
    subHerdHeiferDataPcMature,
    pcWeightHeightChartHistoricalData,
    pcMatureWeightHeightDchaData,
    onClickBackButton,
    subHerdSummaryAgeFilterOptions,
    selectedSubHerdId,
    selectedHerdName = '',
  } = props;

  // accessing data of logged user
  const { currentUser } = firebaseAuth;

  // maintains visibility state of download report btn loader
  const [showBtnLoader, setShowBtnLoader] = useState<boolean>(false);
  // obtaining values from analyze screen
  const {
    fromDate,
    toDate,
    subHerdSummaryOption,
    historicalDataFromDate,
    historicalDataToDate,
  } = (formMethods as UseFormMethods<DataRangeFormType>).watch([
    'fromDate',
    'toDate',
    'subHerdSummaryOption',
    'historicalDataFromDate',
    'historicalDataToDate',
  ]);
  // this variable is used to store the values of useForm
  const methods = useForm<GenerateReportForm>({
    defaultValues: {
      reportTitle: '',
      latestSubHerdData: true,
      subHerdBasicSummary: true,
      heiferWeightChart: true,
      heiferHeightChart: true,
      heiferPcMatureChart: true,
      generalComments: true,
      commentsInput: '',
    },
  });

  // mutation to add analyzed report data to db
  const [addReport] = useMutation<AddReportMutation, AddReportMutationVariables>(addReportMutation);
  // mutation to generate PDF report URL from addReport mutation
  const [generatePdf] = useMutation<GeneratePdfReportMutation, GeneratePdfReportMutationVariables>(
    generatePdfReportMutation,
  );

  // storing current form value for psu switch component
  const watchPsuCheckedVal = (formMethods as UseFormMethods<DataRangeFormType>).watch('psu');
  // storing current form value for purina switch component
  const watchPurinaCheckedVal = (formMethods as UseFormMethods<DataRangeFormType>).watch('purina');
  // storing current form value for sub herd heifers switch component
  const watchSubHerdHeiferCheckedVal = (formMethods as UseFormMethods<DataRangeFormType>).watch(
    'subHerdHeifer',
  );
  // storing current form value for historical heifer switch component
  const watchHistoricalHeiferCheckedVal = (formMethods as UseFormMethods<DataRangeFormType>).watch(
    'historicalHeifer',
  );

  // This function is invoke when user hit the preview button
  const onSubmit = (formData: GenerateReportForm) => {
    setShowBtnLoader(true);

    addReport({
      variables: {
        input_data: {
          reportTitle: formData.reportTitle,
          generalComments: formData.generalComments ? formData.commentsInput : '',
          author: currentUser && currentUser.displayName ? currentUser.displayName : undefined,
          fromDate: fromDate ? fromDate.format(dateFormat) : null,
          toDate: toDate ? toDate.format(dateFormat) : null,
          selectedSubHerdSummaryOption: subHerdSummaryOption,
          subHerdSummaryAgeFilterOptions,
          xAxisMinInterval,
          xAxisMaxInterval,
          subHerdId: selectedSubHerdId,
          showSection: {
            latestSubHerdData: formData.latestSubHerdData,
            tableData: formData.subHerdBasicSummary,
            weightChart: formData.heiferWeightChart,
            heightChart: formData.heiferHeightChart,
            percentChart: formData.heiferPcMatureChart,
          },
          dataSeries: {
            psu: watchPsuCheckedVal,
            purina: watchPurinaCheckedVal,
            current: watchSubHerdHeiferCheckedVal,
            historical: watchHistoricalHeiferCheckedVal,
          },
          historicalDataFromDate: historicalDataFromDate
            ? historicalDataFromDate.format(dateFormat)
            : null,
          historicalDataToDate: historicalDataToDate
            ? historicalDataToDate.format(dateFormat)
            : null,
        },
      },
    })
      .then(({ data }) => {
        if (data && data.insert_report_one && data.insert_report_one.id && selectedHerdName) {
          generatePdf({
            variables: {
              reportId: data.insert_report_one.id,
              farmName: selectedHerdName,
            },
          })
            .then(({ data: pdfData }) => {
              // if pdf url is obtained
              if (
                pdfData &&
                pdfData.generatePdfReport &&
                pdfData.generatePdfReport.url &&
                pdfData.generatePdfReport.s3Key
              ) {
                // obtaining report file name
                const fileName = pdfData.generatePdfReport.s3Key.split('/')[1];
                // saving generated pdf locally
                fetch(pdfData.generatePdfReport.url)
                  .then((res) => {
                    return res.blob();
                  })
                  .then((blob) => {
                    saveAs(blob, fileName);
                    setShowBtnLoader(false);
                  })
                  .catch((err) => {
                    logger(err);
                    setShowBtnLoader(false);
                  });
              }
            })
            .catch((err: ApolloError) => {
              logger(err);
              setShowBtnLoader(false);
            });
        }
      })
      .catch((err: ApolloError) => {
        logger(err);
        setShowBtnLoader(false);
      });
  };

  // componentDidMount
  useEffect(() => {
    // scroll to top to avoid scroll issues
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ marginTop: -20 }}>
      {/* header of report start from here */}
      <Affix>
        <div
          style={{
            backgroundColor: 'white',
            borderBottom: '0.2px solid rgba(0,0,0,0.6)',
            paddingBottom: 5,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: 5,
              alignItems: 'center',
              padding: '5px 54px 0 54px',
            }}
          >
            <Button
              type="primary"
              className="primaryBtn"
              style={{ marginLeft: 15 }}
              onClick={() => {
                onClickBackButton();
              }}
            >
              Back
            </Button>
            <div style={{ textAlign: 'center' }}>
              <div style={{ fontSize: 22, color: 'black' }}>Generate Report</div>
              <div style={{ fontStyle: 'italic', fontSize: 12, color: 'black', fontWeight: 700 }}>
                Select information that you want to appear in the report
              </div>
            </div>
            <div>
              <Button
                style={{ marginRight: 10 }}
                type="primary"
                className="primaryBtn"
                onClick={methods.handleSubmit(onSubmit)}
                loading={showBtnLoader}
                disabled={showBtnLoader}
              >
                Download Report
              </Button>
            </div>
          </div>
        </div>
      </Affix>
      {/* header of report ends  here */}
      <form style={{ margin: '0px 30px' }}>
        <div
          className={styles.inputContainer}
          style={{ padding: 0, marginTop: 40, marginBottom: 20, paddingLeft: 0 }}
        >
          <div className={styles.labelName} style={{ textAlign: 'left' }}>
            Report Title:
          </div>
          <Controller
            className={styles.reportTitleInput}
            name="reportTitle"
            control={methods.control}
            as={<Input placeholder="Enter a suitable  title for report" />}
          />
        </div>
        <Collapse
          expandIcon={(panelProps) =>
            panelProps.isActive ? (
              <UpOutlined style={{ marginTop: 3 }} />
            ) : (
              <DownOutlined style={{ marginTop: 3 }} />
            )
          }
          expandIconPosition="right"
          defaultActiveKey={['1', '2', '3', '4', '5', '6']}
        >
          <Collapse.Panel
            key="1"
            header={
              <ReportSectionHeader
                formName="latestSubHerdData"
                control={methods.control}
                // replaced latest sub-herd info with sub-herd information
                label="Sub-herd information"
              />
            }
          >
            <ViewLatestSubHerdDataSection
              layoutSize={300}
              userInputData={userInputData}
              avgInputDataFields={avgInputDataFields}
              layoutDirection="horizontal"
            />
          </Collapse.Panel>
          <Collapse.Panel
            key="2"
            header={
              <ReportSectionHeader
                formName="subHerdBasicSummary"
                control={methods.control}
                // replaced sub-herd basic summary to group summary
                label="Group Summary"
              />
            }
          >
            <SubHerdBasicSummaryTable
              tableData={tableData}
              formMethods={formMethods}
              setSubHerdSummaryAgeFilterOptions={setSubHerdSummaryAgeFilterOptions}
              hideInputFields
            />
          </Collapse.Panel>
          <Collapse.Panel
            key="3"
            header={
              <ReportSectionHeader
                formName="heiferWeightChart"
                control={methods.control}
                label="Heifer Weight Chart"
              />
            }
          >
            <HeiferWeightChart
              psu2013Data={watchPsuCheckedVal ? heiferWeightChartPsuData : []}
              purinaModelData={watchPurinaCheckedVal ? heiferWeightChartPurinaModelData : []}
              subHerdHeiferData={
                watchSubHerdHeiferCheckedVal ? subHerdHeifersDataWeightChartDataPoints : []
              }
              historicalHeiferData={
                watchHistoricalHeiferCheckedVal ? historicalHeiferDataWeightChartDataPoints : []
              }
              dchaData={heiferWeightChartDchaData}
              xAxisMinInterval={xAxisMinInterval}
              xAxisMaxInterval={xAxisMaxInterval}
              matureBodyWeight={matureBodyWeight}
            />
          </Collapse.Panel>
          <Collapse.Panel
            key="4"
            header={
              <ReportSectionHeader
                formName="heiferHeightChart"
                control={methods.control}
                label="Heifer Height Chart"
              />
            }
          >
            <HeiferHeightChart
              psu2013Data={watchPsuCheckedVal ? heiferHeightChartPsuData : []}
              purinaModelData={watchPurinaCheckedVal ? heiferHeightChartPurinaModelData : []}
              subHerdHeiferData={
                watchSubHerdHeiferCheckedVal ? subHerdHeifersDataHeightChartDataPoints : []
              }
              historicalHeiferData={
                watchHistoricalHeiferCheckedVal ? historicalHeiferDataHeightChartDataPoints : []
              }
              dchaData={heiferHeightChartDchaData}
              xAxisMinInterval={xAxisMinInterval}
              xAxisMaxInterval={xAxisMaxInterval}
              matureHipHeight={matureHipHeight}
            />
          </Collapse.Panel>
          <Collapse.Panel
            key="5"
            header={
              <ReportSectionHeader
                formName="heiferPcMatureChart"
                control={methods.control}
                label="Heifer % of Mature Weight and Height Chart"
              />
            }
          >
            <PercentMatureWeightHeightChart
              psu2013Data={watchPsuCheckedVal ? pcMatureWeightHeightChartPsuData : []}
              purinaModelData={watchPurinaCheckedVal ? pcMatureWeightHeightChartPurinaData : []}
              subHerdHeiferData={watchSubHerdHeiferCheckedVal ? subHerdHeiferDataPcMature : []}
              historicalHeiferData={
                watchHistoricalHeiferCheckedVal ? pcWeightHeightChartHistoricalData : []
              }
              dchaData={pcMatureWeightHeightDchaData}
              xAxisMinInterval={xAxisMinInterval}
              xAxisMaxInterval={xAxisMaxInterval}
            />
          </Collapse.Panel>
          <Collapse.Panel
            key="6"
            header={
              <ReportSectionHeader
                formName="generalComments"
                control={methods.control}
                label="General Comments"
              />
            }
          >
            <Controller
              name="commentsInput"
              control={methods.control}
              as={<Input.TextArea placeholder="Please enter your comments here" rows={6} />}
            />
          </Collapse.Panel>
        </Collapse>
      </form>
    </div>
  );
};

export default GenerateReportComponent;
