import { InboxOutlined } from '@ant-design/icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, message, Modal, Table } from 'antd';
import XLSX from 'xlsx';
import moment from 'moment-timezone';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import Dragger from 'antd/lib/upload/Dragger';
import React from 'react';
import {
  bulkAddHeiferDownloadUrl,
  dashDateFormat,
  dateFormat,
  dateFormatForDisplay,
} from '../utils/globals';
import { BulkHeiferSheetType } from '../utils/types';
import RequiredAlert from './RequiredAlert';
import {
  BulkAddHeifersMutation,
  BulkAddHeifersMutationVariables,
  Heifer,
  Heifer_Insert_Input,
  Maybe,
  Pen,
  PensOfFarmQuery,
  PensOfFarmQueryVariables,
} from '../graphql/graphql-types';
import { checkIsDateValid, logger, renderRemarks, renderWarningMessage } from '../utils/helpers';
import InfoBar from './InfoBar';

const pensOfHerdQuery = loader('../graphql/queries/pensOfFarmQuery.graphql');
const bulkAddHeifersMutation = loader('../graphql/mutations/bulkAddHeifersMutation.graphql');

// This is the props coming from parent component
type BulkAddHeiferComponentProps = {
  // This prop is used to show modal or not
  showBulkAddHeiferModal: boolean;
  // This prop is used for updating the state of showBulkAddHeiferModal
  setShowBulkAddHeiferModal: React.Dispatch<React.SetStateAction<boolean>>;
  // This is the id of sub herd
  idOfSubHerd: string;
  // This is the id of herd
  selectedHerdId: number;
  // This is the data of heifer
  heifersData:
    | ({
        __typename?: 'heifer' | undefined;
      } & Pick<Heifer, 'id' | 'name' | 'dob'> & {
          pen?:
            | Maybe<
                {
                  __typename?: 'pen' | undefined;
                } & Pick<Pen, 'id' | 'name'>
              >
            | undefined;
        })[]
    | undefined;
};

// This is the main functional component
const BulkAddHeiferComponent: React.FC<BulkAddHeiferComponentProps> = ({
  showBulkAddHeiferModal,
  setShowBulkAddHeiferModal,
  idOfSubHerd,
  selectedHerdId,
  heifersData,
}) => {
  // This state stores the data for the table and in this we store the sheet data as an array
  const [sheetDataToRender, setSheetDataToRender] = React.useState<BulkHeiferSheetType[]>([]);
  // This is the name of the file
  const [nameOfFile, setNameOfFile] = React.useState<string>('');
  // This state becomes true when heifer id or date of birth is empty because they both are required fields
  const [sheetHasError, setSheetHasError] = React.useState<boolean>(false);
  // This state holds the value when to show loading indication on add button
  const [isBtnLoading, setIsBtnLoading] = React.useState<boolean>(false);

  // This is the mutation for adding heifer in bulk
  const [bulkAddHeifers] = useMutation<BulkAddHeifersMutation, BulkAddHeifersMutationVariables>(
    bulkAddHeifersMutation,
  );

  // fetching data for pens of selected herd and herd is coming from parent component
  const { data: pensOfHerd } = useQuery<PensOfFarmQuery, PensOfFarmQueryVariables>(
    pensOfHerdQuery,
    {
      variables: { farm_id: selectedHerdId },
    },
  );

  // This is the pens array of herd and it is used for sending the pen_id in heifer data
  const pens = pensOfHerd?.pen;

  // This is the array of name of pens and it is used for checking whether the name of pen of sheet exists in herd or not.
  const nameOfPens =
    Array.isArray(pens) && pens.length > 0
      ? pens.map((pen) => {
          return pen.name;
        })
      : [];

  // This function is invoke when user hit the cancel button
  const handleModalCancel = () => {
    setNameOfFile('');
    setSheetDataToRender([]);
    setSheetHasError(false);
  };

  // This function is invoke when user hit the add button of modal
  const handleAddButton = () => {
    setIsBtnLoading(true);
    // values for adding heifer
    const valuesToSend: Heifer_Insert_Input[] = [];
    // Only those data are uploaded in the sheet whose remark is empty
    sheetDataToRender
      .filter(({ remark }) => {
        return remark === 'New heifer will be added.';
      })
      .forEach(({ heiferDob, penName, heiferID }) => {
        // checks if date is in dash format or not
        const isHeiferDobInDashFormat = (heiferDob as string).includes('-');
        // storing data of pen to pass id in db
        const penData =
          Array.isArray(pens) && pens.length > 0 && penName
            ? pens.find((pen) => pen.name.toLowerCase() === penName.toLowerCase())
            : null;
        valuesToSend.push({
          herd_id: idOfSubHerd,
          name: heiferID,
          pen_id: penName && penData ? penData.id : null,
          dob: moment(
            heiferDob,
            isHeiferDobInDashFormat ? dashDateFormat : dateFormatForDisplay,
          ).format(dateFormat),
        });
      });

    bulkAddHeifers({
      variables: {
        objects: valuesToSend,
      },
      // This is the update function for updating the cache
      update: (cache) => {
        // modifying cache to reflect entries without refresh
        cache.modify({
          fields: {
            heifer(existingHeiferRef: Array<Reference>) {
              if (Array.isArray(valuesToSend) && valuesToSend.length > 0) {
                return [...existingHeiferRef, valuesToSend];
              }
              return existingHeiferRef;
            },
          },
        });
      },
    })
      .then(() => {
        setIsBtnLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Heifers have been successfully added');
        setShowBulkAddHeiferModal(false);
        setSheetDataToRender([]);
      })
      .catch((bulkAddHeiferError: ApolloError) => {
        setIsBtnLoading(false);
        handleModalCancel();
        logger(bulkAddHeiferError);
      });
  };

  return (
    <div>
      <Modal
        title={<div style={{ fontSize: 19 }}>Bulk Add Heifers</div>}
        closable
        destroyOnClose
        bodyStyle={{ margin: '16px 24px' }}
        visible={showBulkAddHeiferModal}
        width="90%"
        footer={null}
        onCancel={(): void => {
          setShowBulkAddHeiferModal(false);
          setSheetDataToRender([]);
          setSheetHasError(false);
          setNameOfFile('');
        }}
      >
        {nameOfFile && Array.isArray(sheetDataToRender) && sheetDataToRender.length > 0 ? (
          <>
            <span>Selected file name:</span>
            <Button type="link" color="#2B78E4">
              {nameOfFile}
            </Button>

            {sheetHasError
              ? renderWarningMessage('Please fill the required fields and re-upload the sheet.', {
                  marginLeft: 0,
                  marginBottom: 10,
                })
              : null}
            <InfoBar
              marginLeft={0}
              info={
                <span>
                  Entries that have no <b>error</b> will be added in system.
                </span>
              }
            />
            <div className="bulkHeiferTableContainer">
              <Table<BulkHeiferSheetType>
                dataSource={sheetDataToRender}
                bordered
                size="small"
                style={{ padding: '20px 0', width: 500 }}
                pagination={{
                  defaultPageSize: 30,
                  total: sheetDataToRender.length,
                  size: 'small',
                  position: ['bottomLeft'],
                }}
              >
                <Table.Column<BulkHeiferSheetType>
                  key="heiferID"
                  dataIndex="heiferID"
                  title="Heifer ID"
                  align="center"
                  render={(text, { heiferID }) => {
                    return !heiferID ? <RequiredAlert /> : heiferID;
                  }}
                />
                <Table.Column<BulkHeiferSheetType>
                  key="heiferDob"
                  dataIndex="heiferDob"
                  title="Date of Birth"
                  align="center"
                  render={(text, { heiferDob }) => {
                    return !heiferDob ? <RequiredAlert /> : heiferDob;
                  }}
                />
                <Table.Column<BulkHeiferSheetType>
                  key="penName"
                  dataIndex="penName"
                  title="Pen ID"
                  align="center"
                  render={(text, { penName }) => {
                    return !penName ? '-' : penName;
                  }}
                />
                <Table.Column<BulkHeiferSheetType>
                  key="remark"
                  dataIndex="remark"
                  title="Remarks"
                  render={(text, { remark }) => {
                    if (remark === 'Error: Heifer already exists.') {
                      return renderRemarks('Heifer already exists.');
                    }
                    if (remark === "Error: Pen doesn't exist in herd.") {
                      return renderRemarks(`Pen doesn't exist in herd`);
                    }
                    return remark;
                  }}
                />
              </Table>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 20 }}>
              <Button
                style={{ marginRight: 20, width: 85 }}
                disabled={sheetHasError}
                className={sheetHasError ? '' : 'primaryBtn'}
                onClick={handleAddButton}
                loading={isBtnLoading}
              >
                Add
              </Button>
              <Button style={{ marginLeft: 20, width: 85 }} onClick={handleModalCancel}>
                Cancel
              </Button>
            </div>
          </>
        ) : (
          <div>
            <Button
              href={bulkAddHeiferDownloadUrl}
              type="primary"
              className="primaryBtn"
              icon={<FontAwesomeIcon icon={faDownload} style={{ marginRight: 10 }} />}
            >
              Download template file
            </Button>
            <div style={{ margin: '10px 0' }}>
              <i>
                Supported file types are: <b>.xls</b> & <b>.xlsx</b>
              </i>
            </div>
            <Dragger
              beforeUpload={(file) => {
                // checking if uploaded file type is either of .xlsx or .xls file format
                if (
                  file.type !==
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
                  file.type !== 'application/vnd.ms-excel'
                ) {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  message.error('You can upload only .xls or .xlsx files');
                } else {
                  // setting file name of uploaded file
                  setNameOfFile(file.name);
                  // js FileReader instance
                  const fileReader = new FileReader();
                  fileReader.onload = (event: ProgressEvent<FileReader>) => {
                    // storing read data for uploaded file
                    const rawData = event.target?.result;
                    // this stores an array of 8-bit unsigned integers for the uploaded file
                    const bufferData = new Uint8Array(rawData as ArrayBuffer);
                    // reading and storing excel data as xlsx workbook
                    const workBook = XLSX.read(bufferData, { type: 'array', cellDates: true });
                    // storing converted data from xlsx to json array format
                    const jsonSheetData: BulkHeiferSheetType[] = XLSX.utils.sheet_to_json(
                      workBook.Sheets[workBook.SheetNames[0]],
                      {
                        // default value if a cell data is not present
                        defval: null,
                        header: ['heiferID', 'heiferDob', 'penName', 'remark'],
                        raw: false,
                      },
                    );
                    const dataForTable: BulkHeiferSheetType[] = [];
                    // remark message to render for each data row
                    let remark: string;
                    // excluding sheet header row and storing it
                    const sheetData = jsonSheetData.slice(1);
                    // mutating sheetData to extract unique new heifer data
                    const uniqueNewHeiferData: BulkHeiferSheetType[] = [];
                    // storing row indexes with unique heifer combo
                    const uniqueNewHeiferIndexes: number[] = [];
                    // iterating over each cell for finding errors if value is not present
                    sheetData.forEach((data, index) => {
                      // checks if entered date is valid date
                      const isDateValid = data.heiferDob
                        ? checkIsDateValid(data.heiferDob as string)
                        : false;

                      // stores whether heiferDob format is separated by dash or not
                      const isHeiferDobDashFormat = !!(
                        data.heiferDob && (data.heiferDob as string).includes('-')
                      );

                      // stores whether row entry is unique
                      const hasUniqueHeiferCombo = !uniqueNewHeiferData.some(
                        (entry) =>
                          entry.heiferID === data.heiferID &&
                          moment(
                            entry.heiferDob,
                            isHeiferDobDashFormat ? dashDateFormat : dateFormatForDisplay,
                          ).isSame(
                            moment(
                              data.heiferDob,
                              isHeiferDobDashFormat ? dashDateFormat : dateFormatForDisplay,
                            ),
                            'day',
                          ),
                      );

                      // storing whether a heifer's name and dob is present in heifersData of a selected sub herd
                      const isHeiferMatched =
                        Array.isArray(heifersData) && heifersData.length > 0
                          ? heifersData.some((heifer) => {
                              return (
                                heifer.name === data.heiferID &&
                                moment(heifer.dob, dateFormat).isSame(
                                  moment(
                                    data.heiferDob,
                                    isHeiferDobDashFormat ? dashDateFormat : dateFormatForDisplay,
                                  ),
                                  'day',
                                )
                              );
                            })
                          : null;

                      if (hasUniqueHeiferCombo) {
                        uniqueNewHeiferData.push(data);
                        uniqueNewHeiferIndexes.push(index);
                      }

                      // storing pens of herd in lowercase for comparison
                      const pensToCompare = nameOfPens.map((item) => item.toLowerCase());
                      if (!isDateValid) {
                        remark = 'Invalid date';
                      } else if (isHeiferMatched) {
                        remark = 'Error: Heifer already exists.';
                      } else if (
                        data.penName &&
                        !pensToCompare.includes(data.penName.toLowerCase())
                      ) {
                        remark = "Error: Pen doesn't exist in herd.";
                      } else if (
                        uniqueNewHeiferIndexes.includes(index) ||
                        (!data.penName && uniqueNewHeiferIndexes.includes(index))
                      ) {
                        remark = 'New heifer will be added.';
                      } else {
                        remark = 'Duplicate entry.';
                      }

                      if (!data.heiferDob || !data.heiferID) {
                        remark = '-';
                      }

                      dataForTable.push({ ...data, remark });
                    });

                    // checking whether heiferId or dob is entered or if new heifer remark is unavailable
                    const hasSheetErr =
                      dataForTable.every((item) => item.remark !== 'New heifer will be added.') ||
                      sheetData.some(({ heiferID, heiferDob }) => !heiferID || !heiferDob);

                    if (hasSheetErr) {
                      setSheetHasError(true);
                    }

                    setSheetDataToRender(dataForTable);
                  };
                  fileReader.readAsArrayBuffer(file);
                }
                return false;
              }}
              fileList={[]}
              style={{
                width: 500,
                maxHeight: 150,
                border: '1.8px dashed black',
                strokeDasharray: 5,
                borderRadius: 10,
                marginBottom: 40,
              }}
            >
              <div>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
              </div>
            </Dragger>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default BulkAddHeiferComponent;
